/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { produce } from 'immer'
import { filter, forEach, get, isNil, last, map, set } from 'lodash'
import React from 'react'

import { multipleLanguages } from '../../../components/feature-flags'
import * as Icons from '../../../icons'
import {
  formatTotal,
  formatTotalNew
} from '../../../pages-builder/calculations/calculation-components'
import * as calculate from '../../../pages-builder/calculations/calculation-functions'
import { useFormbot, useFormbotData } from '../../engine/formbot-react/hooks'
import { MAX_REPEATS } from './config'
import {
  getRepeatStructure,
  useCreateRepeat,
  useFooterGadgets,
  useSetDefault
} from './utils'

export default function RepeaterEdit (props) {
  const { a11yDesc, childrenTemplate, details, onChange, value } = props
  const formbot = useFormbot()
  const formbotData = useFormbotData()
  const createRepeat = useCreateRepeat(childrenTemplate, formbot)
  useSetDefault(createRepeat, details?.defaultRepeatCount, onChange, value)
  const data = value?.data ?? []
  const disallowAdd =
    (details.limitRepeats?.enabled &&
      data.length >= details.limitRepeats?.value) ||
    data.length >= MAX_REPEATS
  const footerEnabled = details?.calculationFooter?.enabled
  const footerGadgets = useFooterGadgets(
    childrenTemplate,
    details?.calculationFooter?.fields ?? []
  )
  return (
    <div className='flex flex-col gap-4'>
      <ul className='flex flex-col gap-4'>
        {map(data, (repeat, i) => (
          <li key={repeat.id} className='flex items-center gap-2'>
            <fieldset className='flex-1'>
              <legend className='sr-only'>
                {a11yDesc} item {i + 1}
              </legend>
              {map(childrenTemplate, gadget =>
                formbot.render(
                  'Edit',
                  getRepeatStructure(gadget, repeat.id, i, props),
                  formbotData,
                  {
                    context: props.context,
                    onChange: (fullFormKey, newValue) => {
                      const path = fullFormKey.replace(`${props.formKey}.`, '')
                      return props.onChange(
                        produce(props.value, draft => {
                          set(draft, path, newValue)
                          if (footerEnabled) {
                            const formKey = last(
                              fullFormKey.split(/\.data\.\d+\.data\./)
                            )
                            const calcFields = filter(footerGadgets, field =>
                              field.formKey?.startsWith(formKey)
                            )
                            updateFooter(calcFields, draft)
                          }
                        })
                      )
                    }
                  }
                )
              )}
            </fieldset>
            {data.length > 1 && (
              <button
                className='kp-button-transparent kp-button-icon text-medium-gray-500'
                type='button'
                aria-label={`Delete item ${i + 1}`}
                onClick={() => {
                  onChange(
                    produce(value, draft => {
                      draft.data.splice(i, 1)
                      if (footerEnabled) {
                        updateFooter(footerGadgets, draft)
                      }
                    })
                  )
                }}
              >
                <Icons.Delete />
              </button>
            )}
          </li>
        ))}
      </ul>
      {footerEnabled && footerGadgets.length > 0 && (
        <CalculationFooter
          className={cx(data.length > 1 && 'mr-[calc(0.5rem_+_34px)]')}
          gadgets={footerGadgets}
          repeatCount={data.length}
          repeaterId={props.id}
          value={value?.footer ?? {}}
        />
      )}
      <button
        aria-label={`add another ${props.a11yDesc} item`}
        className='kp-button-outline gap-2 self-start'
        disabled={disallowAdd}
        onClick={() => {
          if (disallowAdd) return
          onChange(
            produce(value, draft => {
              draft.data.push(createRepeat())
            })
          )
        }}
      >
        <Icons.Add
          className={cx(
            disallowAdd && 'fill-light-gray-300 dark:fill-medium-gray-100'
          )}
        />
        <Trans id='add.another' />
      </button>
    </div>
  )
}

function updateFooter (fields, draft) {
  forEach(fields, field => {
    const values = map(draft?.data, val =>
      // using a string path because it might be a subfield
      get(val, `data.${field.formKey}`)
    )
    const calculated = calculate[field.calcFunction]?.(values)
    set(
      draft,
      [
        'footer',
        field.formKey, // the full formKey as a flat string, including subfields
        field.calcFunction // to allow for multiple calculations on the same field
      ],
      calculated
    )
  })
}

function CalculationFooter ({
  className,
  gadgets,
  repeatCount,
  repeaterId,
  value
}) {
  return (
    <div
      className={cx(
        className,
        'flex divide-x divide-light-gray-300 border border-light-gray-300 bg-light-gray-200 dark:divide-light-gray-400 dark:border-light-gray-400 dark:bg-light-gray-300'
      )}
    >
      {gadgets.map(gadget => (
        <CalculationFooterField
          key={gadget.id}
          calcFunction={gadget.calcFunction}
          fullIds={Array.from(
            { length: repeatCount },
            (v, i) => `${repeaterId}.${i}.${gadget.id}`
          )}
          gadget={gadget}
          id={`${repeaterId}.footer.${gadget.id}`}
          value={get(value, [gadget.formKey, gadget.calcFunction])}
        />
      ))}
    </div>
  )
}

function CalculationFooterField ({ calcFunction, fullIds, gadget, id, value }) {
  let formatted
  if (multipleLanguages) {
    const { currency, decimals } = calculate.extractCurrencyConfig(
      gadget?.details
    )
    formatted = formatTotalNew(
      value,
      calcFunction,
      gadget.type,
      currency,
      decimals
    )
  } else {
    formatted = formatTotal(value, calcFunction, gadget.type)
  }

  return (
    <div className='flex flex-1 flex-col justify-between px-6 py-3.5'>
      <label htmlFor={id} className='text-base dark:text-black'>
        {gadget.label}
      </label>
      <div className='flex justify-end gap-1 font-medium'>
        <span id={`${id}-function`} className='text-medium-gray-500'>
          {calcFunction}
        </span>
        <output
          id={id}
          htmlFor={fullIds.join(' ')}
          aria-describedby={`${id}-function`}
          className={cx('dark:text-black', {
            'text-medium-gray-500 dark:text-medium-gray-500': isNil(formatted)
          })}
        >
          {formatted ?? '--'}
        </output>
      </div>
    </div>
  )
}
