/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { debounce, startCase } from 'lodash'
import React from 'react'
import { Link, useOutletContext } from 'react-router'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import AnimatedOutlet from '../../../components/animated-outlet'
import ColumnsButton from '../../../components/data-table/columns-button'
import * as Empty from '../../../components/data-table/empty'
import Header from '../../../components/data-table/header'
import Pagination from '../../../components/data-table/pagination'
import Row from '../../../components/data-table/row'
import { SmallSearch } from '../../../components/data-table/search-bar'
import SortButton from '../../../components/data-table/sort-button'
import Loading from '../../../components/loading'
import { GraphQLError as Error } from '../../../components/system-error'
import { useQuery } from '../../../components/use-query'
import * as Icons from '../../../icons'
import { Announcer } from '../../../ui/a11y'
import Button from '../../../ui/button'
import { Option, Select } from '../../../ui/select'
import { Table, TableBody } from '../../../ui/table'
import { QueryContextProvider } from './components/query-context'

export default function UsersInner () {
  const { outerLoading } = useOutletContext()
  const [params, updateParams] = useImmer(defaultParams)
  const reset = React.useCallback(() => {
    updateParams(() => defaultParams)
  }, [updateParams])
  const debouncedSearch = React.useCallback(
    debounce(q => {
      updateParams(draft => {
        draft.skip = 0
        draft.q = q
      })
    }, 400),
    []
  )
  const { q, users, totalCount, loading, error, queryInput, setQueryInput } =
    useUsersData(params)
  if (outerLoading) return <Loading />
  if (error) return <Error error={error} />
  const visibleColumns = params.columns.filter(column => column.visible)

  return (
    <QueryContextProvider value={q}>
      <StyledUsersWrapper>
        <AnimatedOutlet />
        <Wrapper>
          <TopSection>
            <SmallSearch
              value={queryInput}
              onChange={newQueryInput => {
                setQueryInput(newQueryInput)
                debouncedSearch(newQueryInput)
              }}
              reset={() => {
                setQueryInput('')
                updateParams(draft => {
                  draft.skip = 0
                  draft.q = null
                })
              }}
              onBlur={() => {
                updateParams(draft => {
                  draft.skip = 0
                  draft.q = queryInput
                })
              }}
              onEnter={() => {
                updateParams(draft => {
                  draft.skip = 0
                  draft.q = queryInput
                })
              }}
            />
            <Buttons>
              <ButtonGrouping>
                <Select
                  small
                  value={params.active}
                  onChange={value =>
                    updateParams(draft => {
                      draft.active = value
                    })
                  }
                >
                  <Option value='ACTIVE'>
                    <Trans id='pages.identity.users.active' />
                  </Option>
                  <Option value='INACTIVE'>
                    <Trans id='pages.identity.users.inactive' />
                  </Option>
                  <Option value='BOTH'>
                    <Trans id='pages.identity.users.both' />
                  </Option>
                </Select>
                <ColumnsButton value={params.columns} update={updateParams} />
                <SortButton
                  columns={params.columns}
                  value={params.sorts}
                  update={updateParams}
                />
                <Button
                  transparent
                  small
                  onClick={() => {
                    setQueryInput('')
                    reset()
                  }}
                >
                  <Trans id='pages.identity.users.reset' />
                </Button>
              </ButtonGrouping>
              <Button as={Link} to='new'>
                <Icons.Add fill='var(--white)' mr={2} />
                <Trans id='pages.identity.users.person.add' />
              </Button>
            </Buttons>
          </TopSection>
          {loading ? (
            <StyledSpinnerContainer>
              <Loading size={400} />
            </StyledSpinnerContainer>
          ) : (
            <TableWrapper>
              <Table>
                <Header
                  params={params}
                  updateParams={updateParams}
                  columns={visibleColumns}
                />
                <TableBody>
                  {users.map(user => (
                    <Row
                      key={user.id}
                      document={user}
                      columns={visibleColumns}
                      data-testid={user.id}
                      linkTo={`${user.id}/view`}
                    />
                  ))}
                </TableBody>
                {totalCount !== 0 && (
                  <Pagination
                    onUpdate={({ skip, limit }) => {
                      updateParams(draft => {
                        draft.skip = skip
                        draft.limit = limit
                      })
                    }}
                    total={totalCount}
                    skip={params.skip}
                    limit={params.limit}
                  />
                )}
                <Announcer id='document-list-pagination'>
                  {getPaginationString(totalCount, params.skip, params.limit)}
                </Announcer>
              </Table>
              {totalCount === 0 &&
                (params.q ? (
                  <Empty.NoMatching
                    text={i18n._('pages.identity.users.no.matching.people')}
                    subtext={i18n._(
                      'pages.identity.users.no.matching.people.sub'
                    )}
                    reset={() => {
                      setQueryInput('')
                      updateParams(draft => {
                        draft.q = null
                      })
                    }}
                  />
                ) : (
                  <Empty.NoResults
                    text={i18n._('pages.identity.users.no.people')}
                    subtext=''
                  />
                ))}
            </TableWrapper>
          )}
        </Wrapper>
      </StyledUsersWrapper>
    </QueryContextProvider>
  )
}

const usersQuery = gql`
  query Users(
    $q: String
    $skip: Int!
    $limit: Int!
    $sort: [String!]
    $active: UserActive
  ) {
    usersConnection(
      args: {
        query: $q
        skip: $skip
        limit: $limit
        sort: $sort
        active: $active
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        skip
        limit
      }
      edges {
        node {
          id
          displayName
          email
          username
          role
          firstName
          lastName
          active
          approved
          schoolId
        }
      }
    }
  }
`

const PADDING = 16
const HALF_PADDING = PADDING / 2
const WIDTH = 1200
const FIRST_MEDIA_WIDTH = WIDTH + PADDING * 2

const StyledUsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const StyledSpinnerContainer = styled.div`
  grid-column: 2;
  height: 100%;
  position: relative;
`

const Wrapper = styled.div`
  padding-bottom: ${PADDING}px;
  display: grid;
  grid-template-columns:
    calc((100vw - ${WIDTH}px) / 2)
    ${WIDTH}px
    calc(100% - (${WIDTH}px + 100vw) / 2);
  grid-template-rows: min-content minmax(0, 1fr);
  align-items: start;
  width: 100%;
  height: 100%;
  max-height: 100%;

  @media (max-width: ${FIRST_MEDIA_WIDTH}px) {
    grid-template-columns:
      calc(${PADDING}px)
      calc(100vw - (${PADDING}px * 2))
      calc(${PADDING}px - (100vw - 100%));
  }

  @media (max-width: 800px) {
    padding: 0;

    td {
      padding: 4px;
      div {
        padding: 0;
      }
    }
    th {
      padding: 4px;
    }
  }

  @media (max-width: 512px) {
    grid-template-columns:
      calc(${HALF_PADDING}px)
      calc(100vw - (${HALF_PADDING}px * 2))
      calc(${HALF_PADDING}px - (100vw - 100%));
    td {
      max-width: 100px;
    }
  }

  @media (max-width: 400px) {
    td {
      max-width: 80px;
    }
  }
`

const TableWrapper = styled.div`
  grid-column: 2;
  -webkit-overflow-scrolling: touch;
  width: ${WIDTH}px;

  @media (max-width: ${FIRST_MEDIA_WIDTH}px) {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }

  td {
    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const TopSection = styled.div`
  grid-column: 2;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 32px;
  width: 100%;
  @media (max-width: 1270px) {
    display: block;
  }
`

const Buttons = styled.div`
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonGrouping = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin-right: 8px;
  }
`

const getPaginationString = (count, start, limit) => {
  if (!count) return <Trans id='pages.identity.users.no.matching.search' />
  const end = Math.min(start + limit, count)
  return i18n._('pagesbuilder.doclist.count.users', {
    start: start + 1,
    end,
    count
  })
}

const defaultColumns = [
  {
    type: 'Text',
    formKey: 'displayName',
    label: <Trans id='pages.identity.users.display.name' />,
    details: {},
    visible: true,
    unsortable: true,
    header: true,
    className: 'text-left'
  },
  {
    type: 'Email',
    formKey: 'email',
    label: <Trans id='pages.identity.users.email' />,
    details: {},
    visible: true
  },
  {
    type: 'Text',
    formKey: 'username',
    label: <Trans id='pages.identity.users.username' />,
    details: {},
    visible: true
  },
  {
    type: 'Text',
    formKey: 'role',
    label: <Trans id='pages.identity.users.role' />,
    details: {},
    visible: true
  },
  {
    type: 'Text',
    formKey: 'firstName',
    label: <Trans id='pages.identity.users.first.name' />,
    details: {},
    visible: false
  },
  {
    type: 'Text',
    formKey: 'lastName',
    label: <Trans id='pages.identity.users.last.name' />,
    details: {},
    visible: false
  },
  {
    type: 'Text',
    formKey: 'schoolId',
    label: <Trans id='pages.identity.users.school' />,
    details: {},
    visible: false
  },
  {
    type: 'Text',
    formKey: 'approved',
    label: <Trans id='pages.identity.users.approved' />,
    details: {},
    visible: false
  },
  {
    type: 'Text',
    formKey: 'active',
    label: <Trans id='pages.identity.users.active' />,
    details: {},
    visible: false
  }
]

const defaultParams = {
  skip: 0,
  limit: 25,
  q: undefined,
  active: 'ACTIVE',
  sorts: [],
  columns: defaultColumns
}

function useUsersData (params) {
  const graphqlVariables = React.useMemo(() => {
    const sort = params.sorts
      .filter(({ field }) => field)
      .map(({ ascending, field }) => {
        const prefix = ascending ? '-' : ''
        return `${prefix}${field}`
      })
    return {
      active: params.active,
      limit: params.limit,
      skip: params.skip,
      sort,
      q: params.q
    }
  }, [params])
  const [queryInput, setQueryInput] = React.useState()
  const q = {
    query: usersQuery,
    variables: graphqlVariables,
    fetchPolicy: 'network-only'
  }
  const { data, loading, error } = useQuery(q)
  const users = React.useMemo(() => {
    if (!data?.usersConnection) return
    return data.usersConnection.edges.map(({ node }) => mapUser(node))
  }, [data])
  const totalCount = data?.usersConnection?.totalCount ?? 0
  return {
    q,
    users,
    totalCount,
    loading,
    error,
    queryInput,
    setQueryInput
  }
}

function mapUser (user) {
  return {
    ...user,
    role: startCase(user.role),
    active: `${user.active}`,
    approved: `${user.approved}`
  }
}
