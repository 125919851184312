/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { Outlet, useLocation, useOutletContext } from 'react-router'
import styled from 'styled-components'

import { ModalPage } from '../../components/modal-page'
import * as Icons from '../../icons'
import * as Illustrations from '../../illustrations'
import Button from '../../ui/button'
import InfoBox from '../../ui/info-box'
import { Tab, TabInner, Tabs } from '../../ui/tabs'
import CATEGORIES_QUERY from './gql/query.categories'

export default function IdentityGroups () {
  const { currentUser } = useOutletContext()
  const canManageIdentity = currentUser?.canManageIdentity || false
  const { data, refetch, loading } = useQuery(CATEGORIES_QUERY)
  const location = useLocation()
  const acknowledgedNewUI = localStorage.getItem('acknowledged-new-identity-ui')
  const [showModal, setShowModal] = React.useState(!acknowledgedNewUI)
  const title = location.pathname.includes('groups') ? (
    <Trans id='pages.identity.groups' message='Groups' />
  ) : location.pathname.includes('people') ? (
    <Trans id='pages.identity.people' message='People' />
  ) : (
    <Trans id='pages.identity.blueprints' message='Blueprints' />
  )
  return (
    <ModalPage
      title={title}
      nav={<Nav canManageIdentity={canManageIdentity} />}
    >
      {showModal && (
        <StyledInfoBox
          width='800'
          title={i18n._({
            id: 'pages.identity.what.happened',
            message: "What the heck happened here and what are 'Blueprints?'"
          })}
          subtitle={
            <>
              <Trans
                id='pages.identity.remodeling'
                message='Yes, we definitely did some remodeling while you were out. We’ve totally revamped Groups. We’ve improved the user interface and added an “Admin” role to every group. Also, we changed the name from “Categories” to “Blueprints.” We’ve also changed the name from “Users” to “People” because “Users” is so last century. If you want to learn more about Blueprints and the other changes we’ve made, we encourage you to visit our <link>help page.</link>'
                components={{
                  link: (
                    <a
                      href='https://kuali.zendesk.com/hc/articles/22098304731291'
                      target='_blank'
                      rel='noreferrer'
                      className='text-text-link'
                    />
                  )
                }}
              />
            </>
          }
          Img={Illustrations.Riddler}
        >
          <CloseButton
            transparent
            icon
            onClick={() => {
              localStorage.setItem('acknowledged-new-identity-ui', true)
              setShowModal(false)
            }}
          >
            <Icons.Close />
          </CloseButton>
        </StyledInfoBox>
      )}
      <Outlet
        context={{
          outerLoading: loading,
          loading,
          blueprints: data?.categoriesConnection?.categories,
          refetchBlueprints: refetch,
          canManageIdentity
        }}
      />
    </ModalPage>
  )
}

function Nav ({ canManageIdentity }) {
  return (
    <Tabs>
      <Tab to='blueprints'>
        <TabInner>
          <Trans id='pages.identity.blueprints' message='Blueprints' />
        </TabInner>
      </Tab>
      <Tab to='groups'>
        <TabInner>
          <Trans id='pages.identity.groups' message='Groups' />
        </TabInner>
      </Tab>
      {canManageIdentity && (
        <Tab to='people'>
          <TabInner>
            <Trans id='pages.identity.people' message='People' />
          </TabInner>
        </Tab>
      )}
    </Tabs>
  )
}

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
`

const StyledInfoBox = styled(InfoBox)`
  display: flex;
  margin: 25px auto;
  position: relative;
  div {
    color: gray;
    font-size: 14px;
  }
  a {
    color: #3369a3;
  }
  @media (max-width: 750px) {
    width: 80%;
    div,
    h3 {
      white-space: normal;
      max-width: 90%;
    }
  }
`
