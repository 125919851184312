/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useLocation } from 'react-router'

import FinishedPage from '../../components/formbot-container/finished-page'
import Wrapper from '../../components/formbot-container/page-wrapper'

export default function Finished ({ data }) {
  const location = useLocation()
  return (
    <Wrapper>
      <div className='h-56' />
      <FinishedPage
        status='completed'
        newFormUrl={data.app.dataset.showNewFormButton ? location.pathname : ''}
        brandColor={data.app.branding.color}
        branding={data.app.branding}
      />
    </Wrapper>
  )
}
