/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, groupBy, isArray, isEqual, keyBy, map, some } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import * as Chooser from '../../formbot/data-chooser'
import {
  SelectGadgetInput,
  getInputFields,
  getOutputFields,
  useInputDefinitions
} from '../../formbot/integration-utils'
import { useAlerts } from '../../ui/alerts'
import { Flex, Wrapper } from '../../ui/layout'
import { Body1, H4 } from '../../ui/typography'
import * as Errors from './errors'

export const Config = ({ value, updateValue, fieldsAll, errors }) => {
  const alerts = useAlerts()
  const { appId } = useParams()
  const { data } = useQuery(
    ...getFetchIntegrationQuery(appId, value.integration?.id)
  )
  const [integrationReloaded, setIntegrationReloaded] = React.useState(false)
  React.useEffect(() => {
    if (data?.app) {
      const currentOutputs = value.integration?.outputFields || {}
      const currentInputs = value.integration?.inputFields || {}
      const integrationData = data?.app?.sharedWithMe?.integration?.data
      const newOutputs = keyBy(getOutputFields(integrationData), 'path')
      const newInputs = getInputFields(integrationData)
      for (const key in newInputs) {
        if (key in currentInputs) {
          newInputs[key].type = currentInputs[key].type || 'form'
          newInputs[key].value = getInputValue(
            newInputs[key].type,
            currentInputs[key].value
          )
        }
      }
      if (
        !isEqual(newOutputs, currentOutputs) ||
        !isEqual(newInputs, currentInputs)
      ) {
        if (
          value.integration.id === data.app.sharedWithMe.integration.id &&
          !isArray(value.integration.outputFields)
        ) {
          setIntegrationReloaded(true)
        }
        updateValue(draft => {
          draft.integration.outputFields = newOutputs
          draft.integration.inputFields = newInputs
        })
      }
    }
  }, [data])
  const inputs = useInputDefinitions(
    data?.app?.sharedWithMe?.integration,
    fieldsAll,
    [],
    true
  )
  if (integrationReloaded) {
    alerts.type2(i18n._('integration.step.relies.on.changed.alert'), 'warning')
  }
  const TYPE_NAMES = {
    url: 'Url Inputs',
    query: 'Query Parameter Inputs',
    requestBody: 'Request Body Inputs'
  }
  const organizedInputs = groupBy(inputs, 'type')
  return (
    <Wrapper px={3}>
      <Body1 py={2} as='label' required>
        <Trans id='which.integration.would.you.like.use' />
      </Body1>
      <Chooser.Send
        id={value.integration?.id}
        label={value.integration?.label}
        onSelect={patch => {
          updateValue(draft => {
            draft.integration = patch
          })
        }}
      />
      {inputs.length !== 0 && (
        <>
          <Body1 py={2} as='label'>
            <Trans id='required.information.for.integration' />
          </Body1>
          <InputsBox className='border border-light-gray-500 bg-light-gray-100 dark:border-light-gray-300 dark:bg-light-gray-400'>
            {map(organizedInputs, (inputList, inputType) => (
              <div key={inputType}>
                <H4>{TYPE_NAMES[inputType]}:</H4>
                <GadgetList>
                  {inputList.map((input, index) => (
                    <SelectGadgetInput
                      byFormKey
                      key={input.dataPath}
                      Gadgets={Gadgets}
                      index={index}
                      input={input}
                      value={value.integration?.inputFields?.[input.dataPath]}
                      onChange={value =>
                        updateValue(draft => {
                          if (!draft.integration.inputFields) {
                            draft.integration.inputFields = {}
                          }
                          draft.integration.inputFields[input.dataPath] = value
                        })
                      }
                    />
                  ))}
                </GadgetList>
              </div>
            ))}
          </InputsBox>
        </>
      )}
      <Errors.Config id='integration-errors' errors={errors} />
    </Wrapper>
  )
}

function getInputValue (type, value) {
  if (type === 'static') return value
  if (!value) return null
  return { formKey: value.formKey, type: value.type }
}

const InputsBox = styled.div`
  border-radius: 2px;
  padding: 16px;
`

const Padded = styled.div`
  padding: ${p => (p.unPadded ? 0 : 8)}px 0;
  display: flex;
  flex-direction: column;
  border-top: ${p => (p.noBorder ? 0 : 1)}px solid #ccc;
`

const Label = styled.label`
  color: var(--dark-gray-300);
  display: block;
  font-size: 14px;
  font-weight: 500;
`

const Gadgets = { Padded, Label }

const getFetchIntegrationQuery = (appId, id) => [
  gql`
    query FetchIntegration($appId: ID!, $id: ID!) {
      app(id: $appId) {
        id
        sharedWithMe {
          integration(id: $id) {
            id
            data
          }
        }
      }
    }
  `,
  {
    fetchPolicy: 'network-only',
    variables: { appId, id },
    skip: !appId || !id
  }
]

export const View = ({ details, errors }) => {
  errors = Errors.filter(errors, 'integration')
  if (errors.length) return <Errors.View errors={errors} />
  return (
    <Text className='text-dark-gray-300'>
      {get(details, 'integration.label')}
    </Text>
  )
}

const Text = styled(Flex)`
  padding: 8px 12px;
  font-size: 13px;
  font-weight: bold;
`

const GadgetList = styled.div`
  padding-bottom: 16px;
`

export const defaults = () => ({ integration: null })

const _validate = value => {
  if (!value.integration) return ['Integration is not configured']
  if (
    value.integration.inputFields &&
    some(value.integration.inputFields, input => !input || !input.value)
  ) {
    return ['Integration inputs are not all configured.']
  }
  return value.integration.error ? [value.integration.error] : []
}
export const validate = Errors.wrap(_validate, 'integration')
