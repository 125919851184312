/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { produce } from 'immer'
import { isEqual } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'

import { formbot } from '../../../formbot'
import * as Chooser from '../../data-chooser'
import { gatherAllSubGadgets } from '../../engine/formbot/utils'
import FiltersConfig from '../../filter-config'
import AutoUpdateConfig from '../form-typeahead/auto-update-config'
import { useLatestDatasetInfo } from '../form-typeahead/use-latest-dataset-info'

export function RequiredConfig ({
  Gadgets,
  updateDataLookupSource,
  indexed,
  indexType,
  formKey,
  value,
  onChange,
  allGadgets
}) {
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false)
  const { appId } = useParams()
  const datasetInfo = useLatestDatasetInfo(appId, value?.id, value?.pageId)
  const { data } = useQuery(sharedFormFieldsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      appId,
      sharedAppId: value?.id,
      sharedPageId: value?.pageId
    },
    skip: !appId || !value?.id
  })
  React.useEffect(() => {
    if (!datasetInfo || !value) return
    const prevDatasetInfo = {
      label: value.label,
      tileOptions: value.tileOptions,
      allowNewVersions: value.allowNewVersions
    }
    if (!isEqual(prevDatasetInfo, datasetInfo)) {
      return onChange({ ...value, ...datasetInfo })
    }
  }, [datasetInfo, value])

  const columns = gatherAllSubGadgets(
    data?.app?.sharedWithMe?.app?.dataset?.formVersion?.schema?.map(f => ({
      ...f,
      formKey: f.path
    })),
    formbot
  )

  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <Chooser.Multiselect
        id={value.id}
        label={value.label}
        tileOptions={value.tileOptions}
        isProduct={value.isProduct}
        onSelect={updateDataLookupSource}
        indexed={indexed}
        indexType={indexType}
      />
      <button
        className='text-left text-text-link underline'
        onClick={() => setShowAdvancedSettings(true)}
      >
        <Trans id='advanced.settings' />
      </button>
      <FiltersConfig
        isOpen={showAdvancedSettings}
        setIsOpen={setShowAdvancedSettings}
        params={{
          filter: value.filter,
          columns,
          versionConfig: value.versionConfig ?? 'LATEST_VERSION',
          versionFilter: value.versionFilter,
          needsVersionFilter: value.needsVersionFilter ?? false
        }}
        compareGadgets={allGadgets?.reduce((acc, gad) => {
          const transformedGad = {
            ...gad,
            formKey: `KUALI_THIS_FORM_DATA.${gad.formKey}`
          }
          if (!(gad.type in acc)) acc[gad.type] = [transformedGad]
          else acc[gad.type].push(transformedGad)
          return acc
        }, {})}
        updateParams={func => {
          const newVal = produce(value, func)
          return onChange(newVal)
        }}
        hasVersions={value.allowNewVersions}
      >
        <AutoUpdateConfig
          id={formKey}
          Gadgets={Gadgets}
          hasVersions={value.allowNewVersions}
        />
        <div className='mb-6 mt-3 border-t border-light-gray-300' />
      </FiltersConfig>
    </Gadgets.Padded>
  )
}

export function OptionalConfig ({ Gadgets, value }) {
  return (
    <>
      <Gadgets.ConfigBox
        configKey='placeholder.enabled'
        label={i18n._('show.placeholder.text')}
        description={i18n._('show.placeholder.text.data')}
      >
        <Gadgets.Text configKey='placeholder.value' />
      </Gadgets.ConfigBox>
    </>
  )
}

const sharedFormFieldsQuery = gql`
  query FormTypeaheadAppQuery(
    $appId: ID!
    $sharedAppId: ID!
    $sharedPageId: ID
  ) {
    app(id: $appId, isConfiguration: true) {
      id
      sharedWithMe {
        app(id: $sharedAppId) {
          id
          dataset(id: $sharedPageId) {
            id
            formVersion {
              id
              schema {
                id
                label
                type
                path: formKey
                details
                conditionalVisibility
                childrenTemplate {
                  id
                  label
                  type
                  formKey
                  details
                  children
                  conditionalVisibility
                }
              }
            }
          }
        }
      }
    }
  }
`
