/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import { Outlet } from 'react-router'
import styled from 'styled-components'

import AppsOrProducts from '../../../components/apps-or-products'
import { productBuilder } from '../../../components/feature-flags'
import Spinner from '../../../components/spinner'
import { Tab, TabInner, Tabs } from '../../../ui/tabs'

export default function SpacesConfigPanel ({
  tenantHasPortals,
  loading,
  space,
  onDelete
}) {
  if (loading) {
    return (
      <div className='flex'>
        <Spinner size={200} className='m-auto !border-8' />
      </div>
    )
  }

  return (
    <Container className='overflow-y-auto pt-4'>
      <Tabs>
        <Tab to='.' end>
          <TabInner>
            <Trans id='pages.spaces.config.general' />
          </TabInner>
        </Tab>
        <Tab to='apps'>
          <TabInner>
            {tenantHasPortals ? (
              <Trans id='pages.spaces.config.apps.links' />
            ) : (
              <AppsOrProducts />
            )}
          </TabInner>
        </Tab>

        {space.allowIntegrations && (
          <Tab to='integrations'>
            <TabInner>
              <Trans id='pages.spaces.config.integrations' />
            </TabInner>
          </Tab>
        )}
        {space.allowBranding && (
          <Tab to='branding'>
            <TabInner>
              <Trans id='pages.spaces.config.branding' />
            </TabInner>
          </Tab>
        )}
        {productBuilder && !space.parentId && (
          <Tab to='suite'>
            <TabInner>
              <Trans id='pages.spaces.config.suite' />
            </TabInner>
          </Tab>
        )}
      </Tabs>
      <Outlet context={{ space, tenantHasPortals, onDelete }} />
    </Container>
  )
}

const Container = styled.div`
  & .loading {
    position: static;
    background-color: inherit;
    padding-top: 2em;
  }
`
