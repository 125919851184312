/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import cx from 'clsx'
import { map, xor } from 'lodash'
import React from 'react'
import { Link, useOutletContext } from 'react-router'

import AbbreviationIcon from '../../../components/abbreviation-icon'
import AnimatedOutlet from '../../../components/animated-outlet'
import { AppIcon, AppSquare } from '../../../components/app-icon'
import AppsOrProducts from '../../../components/apps-or-products'
import PopoverButton from '../../../components/data-table/popover-button'
import { winnowList } from '../../../components/escape-string-regexp'
import * as routes from '../../../components/routes'
import SearchBar from '../../../components/search-bar'
import { useOutsideClick } from '../../../components/window-click'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Checkbox from '../../../ui/indeterminate-checkbox'
import { PortalLink } from './link-forms'
import { useDeleteLinkMutation } from './mutation.delete-link'
import { useMoveAppsToSpaceMutation } from './mutation.move-apps-to-space'
import { useMoveLinksToSpaceMutation } from './mutation.move-links-to-space'
import SpaceFinder from './space-finder'

export default function AppsPane () {
  const { tenantHasPortals, space } = useOutletContext()
  const { apps = [], links = [] } = space
  const alerts = useAlerts()
  const [appSearch, setAppSearch] = React.useState('')
  const [linkSearch, setLinkSearch] = React.useState('')
  const [selectedAppIds, setSelectedAppIds] = React.useState([])
  const [selectedLinkIds, setSelectedLinkIds] = React.useState([])
  const moveApps = useMoveAppsToSpaceMutation()
  const moveLinks = useMoveLinksToSpaceMutation()
  const deleteLink = useDeleteLinkMutation(space.id)
  const [previewId, setPreviewId] = React.useState()
  const ref = React.useRef()
  useOutsideClick(ref, () => setPreviewId(null))

  const handleCheckAll = () => {
    setSelectedAppIds(s => (s.length < apps.length ? map(apps, 'id') : []))
  }

  const handleCheckApp = id => {
    setSelectedAppIds(selected => xor(selected, [id]))
  }

  const handleCheckAllLinks = () => {
    setSelectedLinkIds(s => (s.length < links.length ? map(links, 'id') : []))
  }

  const handleCheckLink = id => {
    setSelectedLinkIds(selected => xor(selected, [id]))
  }

  const moveAppsIntoSpace = hide => spaceId => {
    moveApps(selectedAppIds, spaceId, space.id)
      .then(() => {
        setSelectedAppIds([])
        hide()
        alerts.type3(i18n._('pages.spaces.apps.move'), 'success')
      })
      .catch(err => {
        alerts.type2(i18n._('pages.spaces.apps.server.error'), 'error')
        Sentry.captureException(err)
      })
  }

  const moveLinksIntoSpace = hide => spaceId => {
    moveLinks(selectedLinkIds, spaceId, space.id)
      .then(() => {
        setSelectedLinkIds([])
        hide()
        alerts.type3(i18n._('pages.spaces.apps.move.complete'), 'success')
      })
      .catch(err => {
        alerts.type2(i18n._('pages.spaces.apps.server.error'), 'error')
        Sentry.captureException(err)
      })
  }

  const dateFormatter = new Intl.DateTimeFormat([], { dateStyle: 'medium' })

  return (
    <>
      <AnimatedOutlet context={{ spaceId: space.id, links }} />
      <div className='mx-auto mb-36 mt-10 flex w-fit flex-col gap-4'>
        <div className='flex items-end gap-2'>
          {tenantHasPortals && (
            <h4 className='text-base font-medium'>
              <AppsOrProducts />
            </h4>
          )}
          <div className='flex-1' />
          <SearchBar
            className='w-48'
            value={appSearch}
            onChange={setAppSearch}
          />
          <PopoverButton
            label={i18n._('pages.spaces.apps.move.selected')}
            buttonProps={{ disabled: !selectedAppIds.length }}
          >
            {hide => (
              <SpaceFinder
                onSelect={moveAppsIntoSpace(hide)}
                spaceId={space.id}
              />
            )}
          </PopoverButton>
        </div>

        {apps.length ? (
          <table data-testid='app-table' className='kp-table'>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    className='mt-1'
                    checked={selectedAppIds.length === apps.length}
                    indeterminate={
                      selectedAppIds.length > 0 &&
                      selectedAppIds.length !== apps.length
                    }
                    onChange={handleCheckAll}
                    aria-label={i18n._('pages.spaces.apps.select.all')}
                  />
                </th>
                <th>
                  <Trans id='pages.spaces.apps.name' />
                </th>
                <th>
                  <Trans id='pages.spaces.apps.creator' />
                </th>
                <th>
                  <Trans id='pages.spaces.apps.created' />
                </th>
              </tr>
            </thead>
            <tbody>
              {map(winnowList(apps, appSearch, 'name'), app => (
                <tr
                  key={app.id}
                  onClick={() => handleCheckApp(app.id)}
                  className={cx({
                    'bg-blue-100 hover:bg-[#ddebf2] dark:bg-light-gray-300 dark:hover:bg-light-gray-200':
                      selectedAppIds.includes(app.id),
                    'hover:bg-light-gray-100 dark:hover:bg-light-gray-300':
                      !selectedAppIds.includes(app.id)
                  })}
                  data-selected={selectedAppIds.includes(app.id)}
                >
                  <td>
                    <input
                      type='checkbox'
                      className='kp-checkbox mt-1'
                      checked={selectedAppIds.includes(app.id)}
                      onClick={e => e.stopPropagation()}
                      onChange={() => handleCheckApp(app.id)}
                      aria-label={
                        i18n._('pages.spaces.apps.select') + ` ${app.name}`
                      }
                    />
                  </td>
                  <td>
                    <div className='flex items-center gap-4'>
                      <AppSquare
                        backgroundColor={app.tileOptions?.backgroundColor}
                        className='w-8'
                      >
                        <AppIcon
                          iconName={app.tileOptions?.iconName}
                          isProduct={app.type === 'product'}
                        />
                      </AppSquare>
                      <GetLink app={app} />
                    </div>
                  </td>
                  <td>
                    <div className='inline-flex items-center gap-2 rounded-full bg-light-gray-200 pr-4 dark:bg-medium-gray-200'>
                      <AbbreviationIcon
                        name={app.createdBy?.displayName}
                        className='rounded-full text-[9px]'
                      />
                      <span>{app.createdBy?.displayName}</span>
                    </div>
                  </td>
                  <td>{formatDate(app.createdAt, dateFormatter)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='max-w-md pb-8 text-center text-base'>
            <Trans id='pages.spaces.apps.not.assigned' />
          </div>
        )}
        {tenantHasPortals && (
          <>
            <div className='mt-10 flex items-end gap-2'>
              <h4 className='text-base font-medium'>
                <Trans id='pages.spaces.apps.links' />
              </h4>
              <div className='flex-1' />
              <SearchBar
                className='w-48'
                value={linkSearch}
                onChange={setLinkSearch}
              />
              <PopoverButton
                label={i18n._('pages.spaces.apps.move.selected')}
                buttonProps={{ disabled: !selectedLinkIds.length }}
              >
                {hide => (
                  <SpaceFinder
                    onSelect={moveLinksIntoSpace(hide)}
                    spaceId={space.id}
                  />
                )}
              </PopoverButton>
              <Link className='kp-button-solid' to='links/new'>
                <Icons.Add className='mr-2' />
                <Trans id='pages.spaces.apps.link.add' />
              </Link>
            </div>
            {!!links.length && (
              <table className='kp-table'>
                <thead>
                  <tr>
                    <th className='align-bottom'>
                      <Checkbox
                        className='mt-1'
                        checked={selectedLinkIds.length === links.length}
                        indeterminate={
                          selectedLinkIds.length > 0 &&
                          selectedLinkIds.length !== links.length
                        }
                        onChange={handleCheckAllLinks}
                        aria-label={i18n._('pages.spaces.apps.select.all')}
                      />
                    </th>
                    <th>
                      <Trans id='pages.spaces.apps.name' />
                    </th>
                    <th>
                      <Trans id='pages.spaces.apps.description' />
                    </th>
                    <th>
                      <Trans id='pages.spaces.apps.preview' />
                    </th>
                    <th>
                      <Trans id='pages.spaces.apps.created' />
                    </th>
                    <th>
                      <Trans id='pages.spaces.apps.actions' />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {winnowList(links, linkSearch, 'title').map(link => (
                    <tr
                      key={link.id}
                      onClick={() => handleCheckLink(link.id)}
                      className={cx({
                        'bg-blue-100 hover:bg-[#ddebf2] dark:bg-light-gray-300 dark:hover:bg-light-gray-200':
                          selectedLinkIds.includes(link.id),
                        'hover:bg-light-gray-100': !selectedLinkIds.includes(
                          link.id
                        )
                      })}
                    >
                      <td className='align-bottom'>
                        <input
                          type='checkbox'
                          className='kp-checkbox mt-1'
                          checked={selectedLinkIds.includes(link.id)}
                          aria-label={
                            i18n._('pages.spaces.apps.select') +
                            ` ${link.title}`
                          }
                        />
                      </td>
                      <td className='max-w-[200px] truncate'>{link.title}</td>
                      <td className='max-w-[300px] truncate'>
                        {link.description}
                      </td>
                      <td onClick={e => e.stopPropagation()}>
                        <button
                          className='text-text-link underline'
                          onClick={e => setPreviewId(link.id)}
                        >
                          <Trans id='pages.spaces.apps.view' />
                        </button>
                        <div
                          ref={previewId === link.id ? ref : undefined}
                          className={cx('absolute', {
                            'w-[410px]': link.imageUrl,
                            block: previewId === link.id,
                            hidden: previewId !== link.id
                          })}
                        >
                          <PortalLink
                            link={link}
                            className='relative -left-20 z-10 shrink-0 border border-light-gray-300 shadow-xl'
                          />
                        </div>
                      </td>
                      <td>{formatDate(link.createdAt, dateFormatter)}</td>
                      <td onClick={e => e.stopPropagation()}>
                        <button
                          className='kp-button-transparent kp-button-sm kp-button-icon'
                          aria-label={
                            i18n._('pages.spaces.apps.delete.link') +
                            ` ${link.title}`
                          }
                          onClick={e => {
                            deleteLink(link.id).catch(error => {
                              alerts.type3(
                                i18n._('pages.spaces.apps.failed.delete'),
                                'error'
                              )
                              Sentry.captureException(error)
                            })
                          }}
                        >
                          <Icons.Delete />
                        </button>
                        <Link
                          className='kp-button-transparent kp-button-sm kp-button-icon'
                          aria-label={
                            i18n._('pages.spaces.apps.update.link') +
                            ` ${link.title}`
                          }
                          to={`links/${link.id}/edit`}
                        >
                          <Icons.Edit />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </>
  )
}

function formatDate (createdAt, formatter) {
  const date = new Date(+createdAt || createdAt)
  if (Number.isNaN(date)) return null
  try {
    return formatter.format(date)
  } catch (error) {
    return null
  }
}

function GetLink ({ app: { id, viewer = {}, firstPageId, name } }) {
  if (viewer.canViewDocuments || viewer.canManage) {
    return (
      <Link
        className='text-text-link underline'
        to={routes.documentList(id, firstPageId)}
      >
        {name}
      </Link>
    )
  }
  if (viewer.canSubmitDocuments) {
    return (
      <Link
        className='text-text-link underline'
        to={routes.run(id, firstPageId)}
      >
        {name}
      </Link>
    )
  }
  return <span>{name}</span>
}
