/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery, useSubscription } from '@apollo/client'
import { Trans } from '@lingui/react'
import React from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import config from '../config'
import Button from '../ui/button'

const GET_CLIENT_VERISON = gql`
  query getClientVersion {
    clientVersion {
      latestVersion
      minVersion
    }
  }
`

const CLIENT_UPDATED_SUBSCRIPTION = gql`
  subscription onClientUpdated {
    clientUpdated {
      latestVersion
      minVersion
    }
  }
`

function useVersionChecker (version) {
  const [shouldRefresh, setShouldRefresh] = React.useState(false)
  const [shouldReload, setShouldReload] = React.useState(false)
  const setVersion = React.useCallback(
    newVersion => {
      if (newVersion == null) return
      if (version >= newVersion.latestVersion) return
      if (version < newVersion.minVersion) {
        setShouldReload(true)
      } else if (version < newVersion.latestVersion) {
        setShouldRefresh(true)
      }
    },
    [version]
  )

  return [setVersion, shouldRefresh, shouldReload]
}

function useReconnect (cb) {
  React.useEffect(() => {
    window.addEventListener('online', cb)
    return () => window.removeEventListener('online', cb)
  }, [cb])
}

const blacklist = ['/action', '/start', '/run']
const isRunPage = path =>
  blacklist.find(slug => path.endsWith(slug) || path.includes(`${slug}/`))

export default () => {
  const location = useLocation()
  if (isRunPage(location.pathname)) return null
  return <ClientUpdatedBar />
}

function ClientUpdatedBar () {
  const [setVersion, shouldRefresh, shouldReload] = useVersionChecker(
    config.version
  )

  const [
    getClientVersion,
    { called, data: initialData, loading: initialLoading }
  ] = useLazyQuery(GET_CLIENT_VERISON, {
    fetchPolicy: 'network-only'
  })

  useSubscription(CLIENT_UPDATED_SUBSCRIPTION, {
    onData: ({ data: { data } }) => {
      setVersion(data.clientUpdated)
    }
  })

  useReconnect(getClientVersion)
  const refresh = React.useCallback(() => window.location.reload(true), [])

  React.useEffect(() => {
    if (!initialLoading && initialData) {
      setVersion(initialData.clientVersion)
    }
  }, [initialData, initialLoading, setVersion])

  React.useEffect(() => {
    if (!called) {
      getClientVersion()
    }
  }, [called, getClientVersion])

  return (
    <>
      {shouldRefresh && !shouldReload && (
        <Bar>
          <BarInner>
            <RefreshText>
              <Trans id='is.new.version.of.build' />
              <Button ml='12px' outline onClick={refresh}>
                <Trans id='update.now' />
              </Button>
            </RefreshText>
          </BarInner>
        </Bar>
      )}
      {shouldReload && (
        <BoxBackground>
          <Box>
            <BoxMain>
              <Trans id='is.new.version.of.build' />
            </BoxMain>
            <Button width='100%' mt={2} outline onClick={refresh}>
              <Trans id='update.now' />
            </Button>
          </Box>
        </BoxBackground>
      )}
    </>
  )
}

const Bar = styled.div`
  width: 100%;
  user-select: none;
`

const BarInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 12px;
  font-size: 14px;
  line-height: 1.2;
  cursor: default;
  height: 44px;
  color: white;
  fill: white;
  background: rgb(46, 170, 220);
`

const RefreshText = styled.div`
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Box = styled.div`
  position: relative;
  z-index: 1;
  box-shadow:
    rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 5px 10px,
    rgba(15, 15, 15, 0.2) 0px 15px 40px;
  border-radius: 3px;
  background: white;
  overflow: hidden;
  padding: 24px 32px;
  width: 380px;
  font-size: 16px;
  margin-left: 24px;
  margin-right: 24px;
`

const BoxMain = styled.div`
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BoxBackground = styled.div`
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0%;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
`
