import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { groupBy, pick } from 'lodash'
import React from 'react'
import { useOutletContext } from 'react-router'

import { winnowList } from '../../../components/escape-string-regexp'
import { Multiselect } from '../../../components/lookup-controls'
import { useTenantFeaturesContext } from '../../../components/tenant-features-context'
import Checkbox from '../../../ui/checkbox'
import Radios from '../../../ui/radios'

export default function AddConditionDialog ({
  schema,
  onCancel,
  onSave,
  saving,
  gadgetByFormKey,
  value,
  showDraft,
  close,
  datasetAllowsVersions
}) {
  const { refetchForm } = useOutletContext()
  const [error, setError] = React.useState(null)
  const [identityType, setIdentityType] = React.useState(
    value?.identity?.type ?? 'PERSON'
  )
  const [accessType, setAccessType] = React.useState(
    value?.access?.type ?? 'VIEW'
  )
  const [description, setDescription] = React.useState(value?.description ?? '')
  const [accessStates, setAccessStates] = React.useState(
    () => {
      if (value) {
        return Object.entries(value.access.states)
          .filter(state => state[1])
          .map(([key]) => key)
      }
      return showDraft
        ? ['draft', 'inprogress', 'complete']
        : ['inprogress', 'complete']
    },
    showDraft ? ['draft', 'inprogress', 'complete'] : ['inprogress', 'complete']
  )
  const gadgetsByType = React.useMemo(() => groupBy(schema, 'type'), [schema])
  const [personFormKey, setPersonFormKey] = React.useState(
    identityType === 'PERSON' ? value?.formKey : null
  )
  const [groupFormKey, setGroupFormKey] = React.useState(
    identityType === 'ROLE' ? value?.formKey : null
  )

  const [allowVersionCreation, setAllowVersionCreation] = React.useState(
    value?.allowVersionCreation ?? false
  )
  const formKey = identityType === 'PERSON' ? personFormKey : groupFormKey
  const [rolesQuery, setRolesQuery] = React.useState('')
  const [roles, setRoles] = React.useState(() => {
    return (value?.identity?.roles ?? []).map(role => ({
      ...role,
      id: `${role.categoryId}::${role.roleId}`
    }))
  })
  const gadget = gadgetByFormKey[formKey]
  const rolesData = useRolesByCategory(gadget?.details?.categoryId)
  // TODO: Come back to this and add a background process that updates the role labels

  const personGadgets = React.useMemo(() => {
    return [gadgetsByType.UserTypeahead, gadgetsByType.UserMultiselect]
      .filter(Boolean)
      .flat()
  }, [gadgetsByType])

  const groupGadgets = React.useMemo(() => {
    return [gadgetsByType.GroupTypeahead, gadgetsByType.GroupMultiselect]
      .filter(Boolean)
      .flat()
  }, [gadgetsByType])

  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false

  const showAllowVersionCreationCheckbox =
    tenantVersionSettings &&
    datasetAllowsVersions &&
    Object.values(accessStates).some(state => state === 'complete')

  const invalidGroup =
    identityType === 'ROLE' && groupFormKey && !gadget?.details?.categoryId
  const invalidRoles = identityType === 'ROLE' && groupFormKey && !roles?.length
  const disabled = saving || !formKey || invalidGroup || invalidRoles

  return (
    <div className='flex w-80 flex-col gap-4 bg-white p-4 text-sm'>
      <div>
        <p className='pb-1 leading-4'>
          <Trans id='pagesbuilder.form.perm.form.who' />
        </p>
        <Radios
          name='identityType'
          value={identityType}
          onChange={setIdentityType}
          disabled={saving}
          options={[
            {
              id: 'ROLE',
              label: `${i18n._('pagesbuilder.form.perm.form.selected')}`
            },
            {
              id: 'PERSON',
              label: `${i18n._('pagesbuilder.form.perm.form.specified')}`
            }
          ]}
        />
      </div>
      {identityType === 'PERSON' && (
        <div>
          <label>
            <Trans id='pagesbuilder.form.perm.form.choose' />
            {/** TODO: do person select with personGadgets */}
            <GadgetDropdown
              value={personFormKey}
              options={personGadgets}
              onChange={setPersonFormKey}
              disabled={saving}
            />
          </label>
          <p className='text-xs text-medium-gray-500'>
            <Trans id='pagesbuilder.form.perm.form.grant.access' />
          </p>
        </div>
      )}
      {identityType === 'ROLE' && (
        <>
          <div>
            <label>
              <Trans id='pagesbuilder.form.perm.form.choose.group' />
            </label>
            {/** TODO: do a group select with groupGadges */}
            {/** TODO: who should have access */}
            <GadgetDropdown
              value={groupFormKey}
              options={groupGadgets}
              onChange={setGroupFormKey}
              disabled={saving}
            />
          </div>

          {gadget?.details?.categoryId && (
            <div>
              <label>
                <Trans id='pagesbuilder.form.perm.form.group.roles' />
              </label>
              <Multiselect
                options={winnowList(rolesData.roles, rolesQuery, 'label')}
                loading={rolesData.loading}
                value={roles}
                onChange={setRoles}
                query={rolesQuery}
                setQuery={setRolesQuery}
                disabled={saving}
              />
            </div>
          )}
          {invalidGroup && (
            <p className='font-bold text-red-300'>
              <Trans id='pagesbuilder.form.perm.form.blueprint' />
            </p>
          )}
        </>
      )}
      <label>
        <Trans id='pagesbuilder.form.perm.form.what.access' />

        <br />
        <Radios
          className='mt-2'
          name='accessType'
          value={accessType}
          onChange={setAccessType}
          options={[
            {
              id: 'VIEW',
              label: `${i18n._('pagesbuilder.form.perm.form.viewer')}`
            },
            {
              id: 'EDIT',
              label: `${i18n._('pagesbuilder.form.perm.form.editor')}`
            }
          ]}
          disabled={saving}
        />
      </label>
      <div className='flex flex-col gap-1'>
        <label>
          <Trans id='pagesbuilder.form.perm.form.when.access' />
          <Checkboxes
            value={accessStates}
            onChange={setAccessStates}
            options={
              showDraft
                ? [
                    {
                      id: 'draft',
                      label: `${i18n._(
                        'pagesbuilder.form.perm.form.draft.records'
                      )}`
                    },
                    {
                      id: 'inprogress',
                      label: `${i18n._(
                        'pagesbuilder.form.perm.form.workflow.records'
                      )}`
                    },
                    {
                      id: 'complete',
                      label: `${i18n._(
                        'pagesbuilder.form.perm.form.completed.records'
                      )}`
                    }
                  ]
                : [
                    {
                      id: 'inprogress',
                      label: `${i18n._(
                        'pagesbuilder.form.perm.form.workflow.records'
                      )}`
                    },
                    {
                      id: 'complete',
                      label: `${i18n._(
                        'pagesbuilder.form.perm.form.completed.records'
                      )}`
                    }
                  ]
            }
            disabled={saving}
          />
        </label>
        {showAllowVersionCreationCheckbox && (
          <Checkbox
            className='ml-4'
            label={i18n._('pagesbuilder.form.perm.form.allow.versions')}
            checked={allowVersionCreation}
            onChange={() => setAllowVersionCreation(prevVal => !prevVal)}
            disabled={saving}
          />
        )}
      </div>
      <label>
        <Trans id='pagesbuilder.form.perm.form.description' />
        <br />
        <div>
          <input
            className='kp-input w-full'
            value={description}
            type='text'
            onChange={e => setDescription(e.target.value)}
          />
        </div>
      </label>
      <div className='flex gap-1'>
        <button
          className='kp-button-transparent'
          onClick={() => onCancel()}
          disabled={saving}
        >
          <Trans id='cancel' />
        </button>
        <button
          className='kp-button-solid'
          disabled={disabled}
          onClick={() => {
            const permission = {
              formKey,
              allowVersionCreation,
              identity: {
                type: identityType,
                roles:
                  identityType === 'ROLE'
                    ? roles.map(role =>
                        pick(role, ['roleId', 'categoryId', 'label'])
                      )
                    : undefined
              },
              access: {
                type: accessType,
                states: {
                  draft: accessStates.includes('draft'),
                  inprogress: accessStates.includes('inprogress'),
                  complete: accessStates.includes('complete')
                }
              },
              description
            }
            onSave(permission)
              .then(() => close())
              .catch(error => {
                refetchForm()
                setError(error)
              })
          }}
        >
          {saving ? (
            <Trans id='pagesbuilder.form.perm.form.saving' />
          ) : (
            <Trans id='save' />
          )}
        </button>
      </div>
      <div>
        {error && <p className='font-bold text-red-300'>{error.message}</p>}
      </div>
    </div>
  )
}

function Checkboxes ({ options, value, onChange, ...props }) {
  const valueSet = new Set(value)
  return options.map(option => (
    <Checkbox
      {...props}
      key={option.id}
      checked={valueSet.has(option.id)}
      label={option.label}
      onChange={checked => {
        if (checked) {
          if (!valueSet.has(option.id)) onChange([option.id, ...(value ?? [])])
        } else {
          onChange((value ?? []).filter(id => id !== option.id))
        }
      }}
    />
  ))
}

const hierarchicalCategoriesQuery = gql`
  query FetchHierarchicalCategories($id: ID!) {
    hierarchicalCategories(id: $id) {
      id
      label: name
      roleSchemas {
        id
        label: name
      }
    }
  }
`

function useRolesByCategory (categoryId) {
  const { data, loading, error } = useQuery(hierarchicalCategoriesQuery, {
    variables: {
      id: categoryId
    },
    skip: !categoryId
  })
  const roles = React.useMemo(() => (data ? formatRoles(data) : []), [data])
  return { roles, loading, error }
}

const formatRoles = data =>
  data.hierarchicalCategories.slice(0, 1).flatMap(category => {
    const builtInRoles = [
      {
        id: 'members',
        label: `${i18n._('pagesbuilder.form.perm.form.all.roles')}`
      },
      {
        id: 'admins',
        label: `${i18n._('pagesbuilder.form.perm.form.administrators')}`
      }
    ]
    return [...builtInRoles, ...category.roleSchemas].map(({ id, label }) => ({
      id: `${category.id}::${id}`,
      roleId: id,
      categoryId: category.id,
      label: `${category.label} - ${label}`
    }))
  })

function GadgetDropdown ({ options, value, onChange, ...props }) {
  const gadgets = groupBy(options, option => {
    if (option.deleted) return 'deleted'
    if (option.formKey?.startsWith('data.')) return 'form'
    if (option.formKey?.startsWith('integration.')) return 'integration'
    return 'meta'
  })
  return (
    <select
      {...props}
      className='kp-select'
      value={value}
      onChange={e => onChange(e.target.value)}
    >
      <option value=''>--</option>
      <GadgetOptgroup label={i18n._('form')} options={gadgets.form} />
      <GadgetOptgroup
        label={i18n._('pagesbuilder.form.perm.form.meta')}
        options={gadgets.meta}
      />
      <GadgetOptgroup
        label={i18n._('pagesbuilder.form.perm.form.integration')}
        options={gadgets.integration}
      />
      <GadgetOptgroup
        label={i18n._('pagesbuilder.form.perm.form.archived')}
        options={gadgets.deleted}
      />
    </select>
  )
}

function GadgetOptgroup ({ label, options }) {
  if (!options?.length) return null
  return (
    <optgroup label={label}>
      {options.map(option => (
        <option key={option.formKey} value={option.formKey}>
          {option.label} ({option.formKey})
        </option>
      ))}
    </optgroup>
  )
}
