/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { produce } from 'immer'
import { includes, map, without } from 'lodash'
import React from 'react'
import { Link, useLocation, useParams } from 'react-router'

import { useAcknowledgeDanger } from '../../../components/mutation.acknowledge-danger'
import { Tooltip, TooltipTrigger } from '../../../components/new-tooltip'
import { useTenantFeaturesContext } from '../../../components/tenant-features-context'
import { useQuery } from '../../../components/use-query'
import { AlertHelp } from '../../../icons'
import { ReactComponent as Stop } from '../../../illustrations/stop.svg.jsx'
import Checkbox from '../../../ui/checkbox'
import Popup from '../../../ui/pop-up'
import { useModifyPolicyMutation } from './mutation.modify-policy'

const permissions = shouldShowVersionSettings =>
  [
    {
      label: `${i18n._('pagesbuilder.pmanager.administer')}`,
      action: 'apps:administer',
      authRequired: true
    },
    {
      label: `${i18n._('pagesbuilder.pmanager.create')}`,
      action: 'apps:createDocuments',
      authRequired: false
    },
    {
      label: `${i18n._('pagesbuilder.pmanager.read')}`,
      action: 'apps:readDocuments',
      authRequired: true
    },
    {
      label: `${i18n._('pagesbuilder.pmanager.access')}`,
      action: 'apps:readDocumentsConditionally',
      authRequired: true,
      Tooltip: function ConditionalPermissionsTooltip () {
        const location = useLocation()
        const { appId } = useParams()
        return (
          <div>
            <p>
              <Trans
                id='pagesbuilder.pmanager.link'
                components={{
                  link: (
                    <Link
                      state={{ back: location.pathname }}
                      to={{
                        pathname: `../../form/${appId}/settings/conditional-permissions`
                      }}
                      className='text-text-link underline'
                    />
                  )
                }}
              />
            </p>
            <br />
            <p>
              <Trans id='pagesbuilder.pmanager.empty.list' />
            </p>
          </div>
        )
      }
    },
    {
      label: `${
        shouldShowVersionSettings
          ? i18n._('pagesbuilder.pmanager.edit')
          : i18n._('pagesbuilder.pmanager.update')
      } ${i18n._('pagesbuilder.pmanager.docs.in')}`,
      action: 'apps:updateDocuments',
      authRequired: true
    },
    {
      label: `${i18n._('pagesbuilder.pmanager.delete.docs')}`,
      action: 'apps:deleteDocuments',
      authRequired: true
    },
    shouldShowVersionSettings && {
      label: `${i18n._('pagesbuilder.pmanager.create.versions')}`,
      action: 'apps:createDocumentVersions',
      authRequired: true
    }
  ].filter(Boolean)

export default function PermissionManager ({
  group,
  policy,
  modifiable,
  authenticated = true
}) {
  const excludedActions = [
    'apps:createDocuments',
    'apps:readDocumentsConditionally'
  ]
  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false
  const { appId } = useParams()
  const q = getPermsQuery(appId)
  const { data } = useQuery(q)
  const mutateAcknowledged = useAcknowledgeDanger()
  const updatePolicy = useModifyPolicyMutation(group.id, group.policies[0].id)
  const [activePerm, setActivePerm] = React.useState('')
  const [acknowledged, setAcknowledged] = React.useState(false)
  const [understood, setUnderstood] = React.useState(
    !!data.app?.acknowledgedDanger?.dateDismissed
  )
  const anyDatasetHasVersions =
    data?.app?.pages?.filter(page => page.details.allowNewVersions).length > 0

  const onChange = (action, checked) => {
    if (checked) {
      if (
        group.name === 'All Authenticated Users' &&
        !excludedActions.includes(action) &&
        !understood
      ) {
        setActivePerm(action)
      } else {
        updatePolicy(
          produce(policy, draft => {
            draft.statements[0].action.push(action)
          })
        )
      }
    } else {
      updatePolicy(
        produce(policy, draft => {
          draft.statements[0].action = without(
            draft.statements[0].action,
            action
          )
        })
      )
    }
  }
  const actions = policy.statements[0].action

  function modalCb () {
    updatePolicy(
      produce(policy, draft => {
        draft.statements[0].action.push(activePerm)
      })
    )
    setActivePerm('')
  }

  React.useEffect(() => {
    if (activePerm && (data.app?.acknowledgedDanger || understood)) {
      modalCb()
      setActivePerm('')
    }
  }, [activePerm, data])

  const handleModalSubmission = decision => {
    if (decision) {
      if (acknowledged) mutateAcknowledged(appId)
      modalCb()
    }
    setActivePerm('')
    setAcknowledged(false)
  }

  return (
    <div className='m-2 min-w-[350px] flex-1'>
      <h3 className='text-xl font-medium'>
        <Trans id='permissions' />
      </h3>
      <div>
        <Popup
          showModal={activePerm}
          title={i18n._('pagesbuilder.pmanager.danger')}
          subtitle={i18n._('pagesbuilder.pmanager.danger.subtitle')}
          Img={Stop}
          width='550'
        >
          <>
            <Checkbox
              checked={acknowledged}
              label={i18n._('do.not.show.again.for.app')}
              onChange={() => setAcknowledged(!acknowledged)}
              value={acknowledged}
            />
            <button
              className='kp-button-outline mr-2 mt-4'
              onClick={() => handleModalSubmission(false)}
            >
              <Trans id='cancel' />
            </button>
            <button
              className='kp-button-solid'
              onClick={() => {
                handleModalSubmission(true)
                setUnderstood(true)
              }}
            >
              <Trans id='pagesbuilder.pmanager.continue' />
            </button>
          </>
        </Popup>
        {map(
          permissions(tenantVersionSettings && anyDatasetHasVersions),
          ({ label, action, authRequired, Tooltip }, index) =>
            (authenticated || !authRequired) && (
              <PermissionComponent
                key={action}
                group={group}
                action={action}
                modifiable={modifiable}
                onChange={onChange}
                label={label}
                checked={includes(actions, action)}
                index={index}
                Tooltip={Tooltip}
              />
            )
        )}
      </div>
    </div>
  )
}

function PermissionComponent ({
  group,
  action,
  modifiable,
  onChange,
  checked,
  label,
  index,
  Tooltip: PermissionTooltip
}) {
  return (
    <div className='flex items-end gap-1'>
      <Checkbox
        id={`permission-${group.id}-${index}`}
        key={`permission-${group.id}-${index}`}
        name={`permission-${group.id}-${index}`}
        label={label}
        disabled={!modifiable}
        checked={checked}
        onChange={checked => onChange(action, checked)}
      />
      {PermissionTooltip && (
        <TooltipTrigger
          id={`${group.id}-tooltip`}
          className='relative mb-0.5 inline-block'
        >
          <AlertHelp className='fill-blue-500' />
          <Tooltip position='bottom' className='w-60'>
            <PermissionTooltip />
          </Tooltip>
        </TooltipTrigger>
      )}
    </div>
  )
}

const getPermsQuery = appId => ({
  variables: { appId },
  query: gql`
    query PermsQuery($appId: ID!) {
      app(id: $appId, isConfiguration: true) {
        id
        acknowledgedDanger {
          dateDismissed
        }
        pages {
          id
          details
        }
      }
    }
  `
})
