/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export const options = () => [
  { id: 'IS', label: 'is', exposes: 'form-typeahead', transform },
  { id: 'IS_NOT', label: 'is not', exposes: 'form-typeahead', transform },
  { id: 'IS_EMPTY', label: 'is empty' },
  { id: 'IS_NOT_EMPTY', label: 'is not empty' }
]

function transform (filter, _data) {
  filter.value = filter.value.documentSetId
  return filter
}
