/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get, mapValues, sample } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Flex } from '../ui/layout'
import { AppIcon } from './app-icon'
import Spinner from './spinner'

const Context = React.createContext({})

const ungreetings = [
  'Goodbye',
  'Adios',
  'See ya',
  'Buh-bye',
  'Arrivederci',
  'Laters',
  'Ciao',
  'Peace out',
  'Catch ya later',
  'Cheerio',
  'Toodles',
  'Sayonara',
  'Take it easy',
  'BBL',
  'Kthxbai',
  'Bye',
  "I'm out",
  'Have a nice day',
  'Take care'
]

export const useTileTransition = () => {
  const { state, setState, state2, setState2 } = React.useContext(Context)
  return {
    appId: get(state2, 'appId'),
    toApp: data => setState({ ...data, type: 'enter' }),
    toHome: data =>
      setState2({ ...data, type: 'enter', ungreeting: sample(ungreetings) }),
    finish: dimensions => {
      if (get(state, 'type') === 'enter') {
        setState(data => ({ ...data, type: 'leave' }))
      }
      if (get(state2, 'type') === 'enter') {
        setState2(data => ({ ...data, dimensions, type: 'leave' }))
      }
    }
  }
}

export const TileTransitionProvider = ({ children }) => {
  const [state, setState] = React.useState(null)
  const [state2, setState2] = React.useState(null)
  return (
    <Context.Provider value={{ state, setState, state2, setState2 }}>
      {state && <AppTileFader {...state} setState={setState} />}
      {state2 && <AppTileUnFader {...state2} setState={setState2} />}
      {children}
    </Context.Provider>
  )
}

const MySpinner = styled(Spinner)`
  position: absolute;
  border-color: transparent;
  border-top-color: white;
  border-width: 7px;
  animation-duration: 1s;
  opacity: ${p => (p.show ? 1 : 0)};
  transition: opacity 300ms;
`

const AppTileFader = ({ type, app, dimensions, to, setState }) => {
  const backgroundColor = get(app, 'tileOptions.backgroundColor')
  const iconName = get(app, 'tileOptions.iconName')
  const isProduct = app?.type === 'product'
  const [animateState, setAnimateState] = React.useState(null)
  const [showSpinner, setShowSpinner] = React.useState(false)
  const navigate = useNavigate()
  React.useEffect(() => {
    setTimeout(() => setAnimateState('entering'), 10)
    setTimeout(() => setShowSpinner(true), 2000)
    setTimeout(() => navigate(to), 310)
  }, [navigate, to])
  React.useEffect(() => {
    if (type === 'leave') {
      setAnimateState('leaving')
      setTimeout(() => setState(null), 300)
    }
  }, [type, setState])
  if (!app || !dimensions) return null
  return (
    <Background
      backgroundColor={backgroundColor}
      borderRadius={animateState ? 0 : '10px'}
      opacity={animateState === 'leaving' ? 0 : 1}
      {...(animateState ? full : format(dimensions))}
    >
      {iconName ? (
        <AppIcon iconName={iconName} isProduct={isProduct} className='!w-20' />
      ) : null}
      <MySpinner show={showSpinner} size={200} />
    </Background>
  )
}

const AppTileUnFader = ({
  type,
  spaceId,
  backgroundColor,
  iconName,
  isProduct,
  dimensions,
  ungreeting,
  setState
}) => {
  const [animateState, setAnimateState] = React.useState(null)
  const [showSpinner, setShowSpinner] = React.useState(false)
  const navigate = useNavigate()
  const to = spaceId ? `/space/${spaceId}` : '/space'
  React.useEffect(() => {
    setTimeout(() => setAnimateState('entering'), 0)
    setTimeout(() => setShowSpinner(true), 2000)
    setTimeout(() => navigate(to), 300)
  }, [navigate])
  React.useEffect(() => {
    if (type === 'leave') {
      setAnimateState('leaving')
      setTimeout(() => setState(null), 300)
    }
  }, [type, setState])
  return (
    <Background
      backgroundColor={backgroundColor}
      borderRadius={animateState === 'leaving' ? '10px' : 0}
      opacity={animateState ? 1 : 0}
      {...(animateState === 'leaving' ? format(dimensions) : full)}
    >
      {iconName ? (
        <AppIcon iconName={iconName} isProduct={isProduct} className='!w-20' />
      ) : null}
      {animateState !== 'leaving' && <Ungreeting>{ungreeting}</Ungreeting>}
      <MySpinner show={showSpinner && animateState !== 'leaving'} size={200} />
    </Background>
  )
}

const full = { top: '0px', left: '0px', width: '100vw', height: '100vh' }
const format = d => (d ? mapValues(full, (_, key) => `${d[key]}px`) : notFound)
const notFound = format({ top: -120, left: -120, width: 120, height: 120 })

const Ungreeting = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
`

const Background = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  background: ${p => p.backgroundColor};
  border-radius: ${p => p.borderRadius};
  position: fixed;
  opacity: ${p => p.opacity};
  top: ${p => p.top};
  left: ${p => p.left};
  width: ${p => p.width};
  height: ${p => p.height};
  z-index: 99;
  transition: all 300ms;
`
