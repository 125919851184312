/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import { Link } from 'react-router'
import styled from 'styled-components'

import * as Icons from '../../icons'
import Tooltip, { TooltipTrigger } from '../tooltip'
import CheckedIcon from './checked-icon'

export default function PagesNav ({ pages, brandColor, paginationButtons }) {
  if (pages.length === 0) return null
  return (
    <>
      <PagesHeading>
        <Trans id='pages' />
      </PagesHeading>
      <Ul>
        {pages
          .filter(page => !page.hidden)
          .map((page, i) => (
            <li key={i}>
              <MyLink
                className='hover:bg-light-gray-300 dark:hover:bg-white'
                to={page.href}
                onClick={page.handleClick}
                as={page.href ? Link : 'a'}
              >
                <LinkText>{page.name}</LinkText>
                <PageIcons>
                  {page.errorMsg && (
                    <>
                      <TooltipTrigger
                        as={ErrorIcon}
                        className='fill-red-400'
                        label='Hidden Sections'
                        tooltipId={`page-${page.index}`}
                      />
                      <Tooltip id={`page-${page.index}`} place='left'>
                        {page.errorMsg}
                      </Tooltip>
                    </>
                  )}
                  <CheckedIcon
                    format={page.format}
                    color={brandColor || '#3369A3'}
                  />
                </PageIcons>
              </MyLink>
            </li>
          ))}
      </Ul>
      {!!paginationButtons && <div className='mt-4'>{paginationButtons}</div>}
    </>
  )
}

const PagesHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: var(--dark-gray-100);
  margin: 0;
  padding: 16px 0 8px;
  border-bottom: 1px solid #ccc;
  @media screen and (max-width: 1024px) {
    border-bottom: none;
  }
`

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const MyLink = styled.a`
  height: 48px;
  color: var(--dark-gray-300);
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  text-decoration: none;
  @media screen and (max-width: 1024px) {
    height: 28px;
    border-bottom: none;
    font-size: 14px;
  }
`

const LinkText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 16px;
`

const PageIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ErrorIcon = styled(Icons.AlertError)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`
