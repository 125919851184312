/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

import { multipleLanguages } from '../../../components/feature-flags'
import CalculationsConfig from '../../../pages-builder/calculations/calculation-config'
import Input from '../../../ui/input'
import { Option, Select } from '../../../ui/select'

export function OptionalConfig ({ Gadgets, allGadgets, id, formKey }) {
  return (
    <>
      <Gadgets.ConfigBox
        configKey='placeholder.enabled'
        label={i18n._('show.placeholder.text')}
        description={i18n._('show.placeholder.text.data')}
      >
        <Gadgets.Text configKey='placeholder.value' />
      </Gadgets.ConfigBox>
      <Gadgets.ConfigBox
        configKey='calculation.enabled'
        label={i18n._('enable.calculations')}
        description={i18n._('enable.calculations.data')}
      >
        <Gadgets.Custom configKey='calculation.value'>
          {({ onChange, value }) => {
            return (
              <CalculationsConfig
                Gadgets={Gadgets}
                allGadgets={allGadgets}
                onChange={onChange}
                value={value}
                gadgetType='Currency'
                id={id}
                formKey={formKey}
              />
            )
          }}
        </Gadgets.Custom>
      </Gadgets.ConfigBox>
      {multipleLanguages && (
        <Gadgets.ConfigBox
          configKey='currency.enabled'
          label={i18n._({
            id: 'config.currency.label',
            message: 'Change currency'
          })}
          description={i18n._({
            id: 'config.currency.description',
            message:
              'Choose the currency to use when inputting and displaying currencyamounts.'
          })}
        >
          <Gadgets.Custom configKey='currency.currency' defaultValue='USD'>
            {({ onChange, value }) => {
              return (
                <>
                  <label id='currency-label' for='currency' className='sr-only'>
                    {i18n._({
                      id: 'select.currency',
                      message: 'Select currency'
                    })}
                  </label>

                  <Select
                    id='currency'
                    aria-labelledby='currency-label'
                    value={value}
                    onChange={onChange}
                  >
                    <Option key='usd' value='USD'>
                      USD -{' '}
                      <Trans
                        id='united.states.dollar'
                        message='United States Dollar'
                      />
                    </Option>
                    <Option key='cad' value='CAD'>
                      CAD -{' '}
                      <Trans id='canadian.dollar' message='Canadian Dollar' />
                    </Option>
                    <Option key='clp' value='CLP'>
                      CLP - <Trans id='chilean.peso' message='Chilean Peso' />
                    </Option>
                    <Option key='eur' value='EUR'>
                      EUR - <Trans id='euro' message='Euro' />
                    </Option>
                    <Option key='gbp' value='GBP'>
                      GBP - <Trans id='british.pound' message='British Pound' />
                    </Option>
                  </Select>
                </>
              )
            }}
          </Gadgets.Custom>

          <Gadgets.Custom configKey='currency.decimals' defaultValue='2'>
            {({ onChange, value }) => {
              return (
                <>
                  <label
                    id='number-of-decimals-label'
                    for='number-of-decimals'
                    className='mt-2 font-medium text-dark-gray-300'
                  >
                    {i18n._({
                      id: 'number.of.decimals',
                      message: 'Number of decimals'
                    })}
                  </label>

                  <Input
                    id='number-of-decimals'
                    aria-labelledby='number-of-decimals-label'
                    type='text'
                    placeholder={i18n._({
                      id: 'number-of-decimals-placeholder',
                      message: '0, 1, 2, or 3, defaults to 2.'
                    })}
                    onChange={rawVal => {
                      const sanitizedVal = sanitizeAndLimitVal(rawVal)
                      return onChange(sanitizedVal)
                    }}
                    value={value}
                  />
                </>
              )
            }}
          </Gadgets.Custom>
        </Gadgets.ConfigBox>
      )}
    </>
  )
}

// Remove any non-numeric data and limit to 0, 1, 2, 3
// yes, there are currencies that use 3 decimals
const sanitizeAndLimitVal = val => {
  const sanitizedVal = val.match(/[0-3]/)
  return sanitizedVal ? sanitizedVal[0] : ''
}
