/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { debounce } from 'lodash'
import React from 'react'
import {
  Link,
  useHref,
  useLocation,
  useNavigate,
  useOutletContext
} from 'react-router'
import styled from 'styled-components'

import PopoverButton from '../../../../components/data-table/popover-button'
import { SmallSearch } from '../../../../components/data-table/search-bar'
import Loading from '../../../../components/loading'
import * as Users from '../../../../components/lookups/users'
import Spinner from '../../../../components/spinner'
import DropdownEdit from '../../../../formbot/gadgets/dropdown/edit'
import * as Icons from '../../../../icons'
import { useAlerts } from '../../../../ui/alerts'
import Button from '../../../../ui/button'
import Input from '../../../../ui/input'
import { Chip } from '../../../../ui/lookup'
import NewGroupPopup, { ParentGroups } from '../components/new-group-popup'
import { useAddUserMutation } from './components/mutation.add-group-role-user'
import { useDeleteGroupMutation } from './components/mutation.delete-group'
import { useRemoveUserMutation } from './components/mutation.remove-group-role-user'
import { useUpdateGroupMutation } from './components/mutation.update-group'

export default function GroupView () {
  const { blueprints, group, refetch, loading } = useOutletContext()
  if (loading) return <Loading />
  return (
    <GroupViewInner group={group} blueprints={blueprints} refetch={refetch} />
  )
}

function GroupViewInner ({ group, blueprints, refetch }) {
  const [name, setName] = React.useState(group?.name)
  const [parent, setParent] = React.useState(group?.parent)
  const [admins, setAdmins] = React.useState(
    group?.roles
      ?.find(r => r.id.includes('admins'))
      ?.membersConnection?.edges?.map(r => r?.node)
  )
  const [child, setChild] = React.useState(null)
  const [updateGroup] = useUpdateGroupMutation(group)
  const [removeUser, { loading: removeUserLoading }] = useRemoveUserMutation(
    group?.id,
    'admins'
  )
  const [addUser, { loading: addUserLoading }] = useAddUserMutation(
    group?.id,
    'admins'
  )
  const closeAlertsRef = React.useRef()
  const deleteGroup = useDeleteGroupMutation(group?.id)
  const navigate = useNavigate()
  const alerts = useAlerts()
  const debouncedUpdate = React.useCallback(
    debounce(e => {
      updateGroup(e)
        .then(() =>
          alerts.type3(
            i18n._({ id: 'group.saved', message: 'Group saved.' }),
            'success'
          )
        )
        .catch(() =>
          alerts.type3(
            i18n._({
              id: 'error.saving.group',
              message: 'Error saving group.'
            }),
            'error'
          )
        )
    }, 1000),
    []
  )
  const location = useLocation()
  const groupsPath = useHref('../..')

  const loading = removeUserLoading || addUserLoading

  const possibleChildrenBlueprints = blueprints
    ?.map(blueprint => ({
      key: blueprint.id,
      lbl: blueprint.name,
      parentId: blueprint?.parentId
    }))
    ?.filter(b => b?.parentId === group?.categoryId)
  const canBeDeleted = !group?.hasChildren
  const canAdminister = group?.access?.isAdmin
  const canAdministerParent = group?.access?.isParentAdmin

  return (
    <Wrapper>
      <h1>
        <Trans id='basic.information' message='Basic Information' />
      </h1>
      <Label className='text-dark-gray-300'>
        <Trans id='group.name' message='Group Name' />
      </Label>
      <Input
        aria-label={i18n._({ id: 'group.name', message: 'Group Name' })}
        disabled={!canAdminister}
        value={name}
        onChange={e => {
          setName(e)
          debouncedUpdate({ name: e })
        }}
      />
      {group?.category?.parentId && (
        <>
          <Label className='text-dark-gray-300'>
            <Trans id='parent' message='Parent' />
          </Label>
          {canAdministerParent ? (
            <ParentGroups
              id='view-parent-group-select'
              thisBlueprint={group.category}
              setParent={async e => {
                setParent(e)
                await updateGroup({ parentId: e ? e.id : null })
                  .then(() =>
                    alerts.type3(
                      i18n._({ id: 'group.saved', message: 'Group saved.' }),
                      'success'
                    )
                  )
                  .catch(() =>
                    alerts.type3(
                      i18n._({
                        id: 'error.saving.group',
                        message: 'Error saving group.'
                      }),
                      'error'
                    )
                  )
              }}
              parent={parent}
            />
          ) : (
            <Input
              aria-label={i18n._({
                id: 'group.parent',
                message: 'Group Parent'
              })}
              value={group?.parent?.label}
              disabled
            />
          )}
        </>
      )}
      {group?.category && (
        <>
          <Title>
            <label>
              <Trans id='blueprint.colon' message='Blueprint:' />
            </label>
            {blueprints?.find(b => b.id === group?.category?.id)?.name}
          </Title>
          <Button
            aria-label={i18n._({
              id: 'view.blueprint',
              message: 'View Blueprint'
            })}
            transparent
            icon
            as={Link}
            to={getBlueprintLink(group.category.id, location.pathname)}
            style={{ alignSelf: 'flex-start' }}
          >
            <Icons.Visible mr={2} className='fill-blue-500' />
            <Trans
              id='view.blueprint.based.on'
              message='View Blueprint this is based on'
            />
          </Button>
        </>
      )}
      {(canAdminister || !!admins?.length) && (
        <h1>
          <Trans id='group.administrators' message='Group Administrators' />
        </h1>
      )}
      {!canAdminister || loading ? (
        <>
          {loading && (
            <StyledSmallSearch
              disabled
              placeholder={i18n._({ id: 'loading', message: 'Loading' })}
              mb={1}
            />
          )}
          <ChipWrap>
            {admins?.map(admin => (
              <StyledChip key={admin.id} loading={loading}>
                {admin.label}
              </StyledChip>
            ))}
            {loading && (
              <StyledChip style={{ padding: '6px 30px' }}>
                <Spinner size={20} />
              </StyledChip>
            )}
          </ChipWrap>
        </>
      ) : (
        <Users.Multiselect
          standalone
          value={admins}
          label={i18n._({
            id: 'group.administrators',
            message: 'Group Administrators'
          })}
          placeholder={i18n._({
            id: 'search.for.users',
            message: 'Search for users'
          })}
          onAdd={e => {
            const resetAdminsOnError = admins
            addUser(e.id)
              .then(({ data }) => {
                setAdmins(data?.addGroupRoleUser?.result?.members)
                alerts.type3(
                  i18n._({ id: 'group.saved', message: 'Group saved.' }),
                  'success'
                )
              })
              .catch(err => {
                setAdmins(resetAdminsOnError)
                closeAlertsRef.current()
                alerts.type3(
                  i18n._({
                    id: 'could.not.add.user.role',
                    message: "Couldn't add user to role"
                  }),
                  'error'
                )
                Sentry.captureException(err)
              })
          }}
          onRemove={e => {
            const resetAdminsOnError = admins
            setAdmins(admins => admins.filter(a => a.id !== e))
            removeUser(e)
              .then(({ data }) => {
                setAdmins(data?.removeGroupRoleUser?.result?.members)
                alerts.type3(
                  i18n._({ id: 'group.saved', message: 'Group saved.' }),
                  'success'
                )
              })
              .catch(err => {
                setAdmins(resetAdminsOnError)
                closeAlertsRef.current()
                alerts.type3(
                  i18n._({
                    id: 'could.not.remove.user.role',
                    message: "Couldn't remove user from role"
                  }),
                  'error'
                )
                Sentry.captureException(err)
              })
          }}
        />
      )}
      {canAdminister && (
        <>
          {group?.category && possibleChildrenBlueprints?.length > 0 && (
            <>
              <h1 id='title'>
                <Trans id='add.child.group' message='Add Child Group' />
              </h1>
              <h2 id='description'>
                <Trans
                  id='add.child.group.data'
                  message='This will create a new group, as a child of this group'
                />
              </h2>
              <WeirdFlexButOk>
                <DropdownEdit
                  label={i18n._({
                    id: 'choose.child.blueprint',
                    message: 'Choose Child Blueprint'
                  })}
                  darkerModeDarkerBg
                  aria-labelledby='title'
                  aria-describedBy='description'
                  details={{
                    placeholder: `${i18n._({ id: 'choose.child.blueprint', message: 'Choose Child Blueprint' })}`,
                    options: possibleChildrenBlueprints
                  }}
                  onChange={setChild}
                  value={child}
                />
                <PopoverButton
                  hideArrow
                  buttonProps={{
                    'aria-label': `${i18n._({ id: 'add.group.child', message: 'Add Group Child' })}`,
                    transparent: false,
                    disabled: !child,
                    type: 'button'
                  }}
                  label={
                    <>
                      <Icons.Add fill='var(--white)' mr={2} />
                      <Trans id='add' message='Add' />
                    </>
                  }
                  bottom={40}
                >
                  {hide => (
                    <div style={{ width: '100%' }}>
                      <NewGroupPopup
                        onCancel={hide}
                        blueprint={blueprints.find(b => b.id === child.id)}
                        passedParent={group}
                        refetch={refetch}
                        path={groupsPath}
                      />
                    </div>
                  )}
                </PopoverButton>
              </WeirdFlexButOk>
            </>
          )}
          <h1>
            <Trans id='delete.group' message='Delete Group' />
          </h1>
          <div>
            <Trans
              id='delete.group.data'
              message='This can only be done when a group no longer has subgroups referencing it.'
            />
          </div>
          <button
            className='kp-button-solid mt-4 w-40 disabled:bg-light-gray-400 disabled:text-white disabled:shadow-none dark:disabled:bg-medium-gray-100'
            aria-label={i18n._({ id: 'delete.group', message: 'Delete Group' })}
            type='button'
            disabled={!canBeDeleted}
            onClick={() => {
              closeAlertsRef.current?.()
              closeAlertsRef.current = alerts.type2(
                <Trans
                  id='group.name.permanently.deleted'
                  message='Group {name} will be permanently deleted.'
                  values={{ name }}
                />,
                'error',
                close => (
                  <Button
                    small
                    ml={2}
                    onClick={() => {
                      close()
                      deleteGroup()
                        .then(() => {
                          navigate('../..')
                          alerts.type3(
                            i18n._({
                              id: 'successfully.deleted.group',
                              message: 'Successfully deleted group'
                            }),
                            'success'
                          )
                          refetch()
                        })
                        .catch(err => {
                          alerts.type3(
                            i18n._({
                              id: 'could.not.delete.group',
                              message: "Couldn't delete group"
                            }),
                            'error'
                          )
                          Sentry.captureException(err)
                        })
                    }}
                  >
                    <Trans id='continue' message='Continue' />
                  </Button>
                )
              )
            }}
          >
            <Icons.Delete mr={2} />
            <Trans id='delete.group' message='Delete Group' />
          </button>
        </>
      )}
    </Wrapper>
  )
}

const getBlueprintLink = (id, pathname) =>
  pathname.replace(/(identity).*/, `$1/blueprints/${id}/details/info`)

const Wrapper = styled.div`
  max-width: 344px;
  margin: 0 auto 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  h1 {
    margin: 24px 0 8px;
    font-size: 22px;
    font-weight: 600;
  }
  h2 {
    font-size: 14px;
    margin: 0;
  }
`

const WeirdFlexButOk = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  > :first-child {
    flex: 1;
  }
  > :not(:last-child) {
    margin-right: 16px;
  }
`

const Title = styled.label`
  margin: 8px 0;
  label {
    margin-right: 8px;
    font-weight: bold;
  }
`

const Label = styled.div`
  padding-top: 16px;

  &:first-of-type {
    padding-top: 0;
  }
`

const StyledChip = styled(Chip)`
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 6px 16px;
  padding-right: ${p => (p.loading ? '48' : '16')}px;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`

const ChipWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledSmallSearch = styled(SmallSearch)`
  min-width: 344px;
`
