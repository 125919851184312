/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import cx from 'clsx'
import React from 'react'
import { NavLink, useParams } from 'react-router'

import { productBuilder } from '../../../components/feature-flags'
import Spinner from '../../../components/spinner'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import {
  generateRandomName,
  useCreateSpaceMutation
} from './mutation.create-space'
import SpaceIcon from './space-icon'

export default function SpacesNav ({
  currentParentId,
  isRoot = false,
  onHoverSpace,
  parentId,
  children,
  clearSearch,
  spaces
}) {
  const alerts = useAlerts()
  const { id: activeId } = useParams()
  const [adding, setAdding] = React.useState(false)
  const createSpace = useCreateSpaceMutation()
  const addSpace = () => {
    setAdding(true)
    const name = generateRandomName()
    createSpace(
      name,
      parentId,
      isRoot,
      isRoot || activeId === parentId ? null : activeId
    )
      .catch(err => {
        alerts.type2(i18n._('pages.spaces.nav.server.error'), 'error')
        Sentry.captureException(err)
      })
      .finally(() => {
        setAdding(false)
      })
  }
  const isSuites = !!spaces[0]?.suite
  return (
    <ul className='w-56 overflow-auto border-r border-r-light-gray-300 bg-white'>
      {children}
      {currentParentId && (
        <BackSpaceItem
          id={currentParentId}
          onMouseOver={() => onHoverSpace(currentParentId)}
        />
      )}
      {spaces.map(space =>
        space.id.startsWith('new-space') ? (
          <SpaceBeingAdded key={space.id} name={space.name} />
        ) : (
          <SpaceListItem
            key={space.id}
            space={space}
            clearSearch={clearSearch}
            onMouseOver={() => onHoverSpace?.(space.id)}
          />
        )
      )}
      {(!isRoot || (productBuilder && isSuites)) && (
        <button
          className='kp-button-outline kp-button-sm my-2 ml-4'
          disabled={adding}
          onClick={addSpace}
        >
          <Icons.Add className='mr-1 h-3 w-3' />
          {isSuites ? (
            <Trans id='pages.spaces.nav.suite' />
          ) : (
            <Trans id='pages.spaces.nav.space' />
          )}
        </button>
      )}
    </ul>
  )
}

function SpaceListItem ({ onMouseOver, clearSearch, space }) {
  return (
    <li>
      <NavLink
        className={({ isActive }) =>
          cx(
            'flex items-center gap-4 px-4 py-2 text-dark-gray-500 hover:bg-blue-100 focus-visible:bg-blue-100 dark:hover:bg-light-gray-300',
            {
              'group bg-blue-100 font-bold text-text-link dark:bg-light-gray-300':
                isActive
            }
          )
        }
        to={`../spaces/${space.id}`}
        onMouseOver={onMouseOver}
        onClick={clearSearch}
      >
        {space.suite?.icon ? (
          <img
            src={`${process.env.PUBLIC_URL}/icons-suite/${space.suite.icon}.svg`}
            className='h-8 w-8 shrink-0'
          />
        ) : (
          <SpaceIcon name={space.name} />
        )}
        <span className='flex-1 overflow-hidden overflow-ellipsis'>
          {space.suite?.name || space.name}
        </span>
        <Icons.SelectDownArrow className='h-2 -rotate-90 group-[.active]:scale-125 group-[.active]:fill-current dark:fill-medium-gray-200' />
      </NavLink>
    </li>
  )
}

function BackSpaceItem ({ id, onMouseOver }) {
  return (
    <li>
      <NavLink
        className='flex items-center gap-4 px-4 py-2 text-dark-gray-500 hover:bg-blue-100 focus-visible:bg-blue-100 dark:hover:bg-light-gray-300'
        to={`../spaces/${id}`}
        onMouseOver={onMouseOver}
      >
        <span
          aria-hidden='true'
          className='inline-flex h-8 w-8 items-center justify-center rounded border border-medium-gray-500 dark:border-medium-gray-200'
        >
          <Icons.Back className='fill-blue-500' />
        </span>
        <span className='text-base font-medium'>
          <Trans id='pages.spaces.nav.back' />
        </span>
      </NavLink>
    </li>
  )
}

function SpaceBeingAdded ({ name }) {
  return (
    <li className='flex items-center gap-4 px-4 py-2'>
      <Spinner size={32} />
      <span className='flex-1 overflow-hidden overflow-ellipsis'>{name}</span>
    </li>
  )
}
