/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useSearchParams } from 'react-router'

export default function useKeyboardShortcuts () {
  const [searchParams, setSearchParams] = useSearchParams()
  const ref = React.useRef()
  ref.current = searchParams
  React.useEffect(() => {
    let shiftAlt = false
    const keys = []

    function startShortcut (e) {
      if (!shiftAlt && e.altKey && e.shiftKey) shiftAlt = true
      if (shiftAlt) checkShortcuts(e.code)
    }

    function endShortcut (e) {
      if (shiftAlt && (!e.altKey || !e.shiftKey)) {
        shiftAlt = false
        keys.length = 0
      }
    }

    function checkShortcuts (code) {
      keys.push(code)
      while (keys.length > 3) keys.shift()
      if (keys.join('-') === 'KeyF-KeyF-KeyF') {
        setSearchParams(toggle(ref.current, 'center-modal', 'feature-flags'))
        keys.length = 0
      } else if (keys.join('-') === 'KeyD-KeyD-KeyD') {
        setSearchParams(toggle(ref.current, 'center-modal', 'feedback'))
        keys.length = 0
      } else if (keys.join('-') === 'KeyA-KeyA-KeyA') {
        document.documentElement.classList.toggle('dark')
        const detail = document.documentElement.classList.contains('dark')
        window.dispatchEvent(new CustomEvent('theme-toggled', { detail }))
      }
    }

    window.addEventListener('keydown', startShortcut)
    window.addEventListener('keyup', endShortcut)
    return () => {
      window.removeEventListener('keydown', startShortcut)
      window.removeEventListener('keyup', endShortcut)
    }
  }, [])
}

function toggle (searchParams, key, val) {
  if (searchParams.get(key) === val) searchParams.delete(key)
  else searchParams.set(key, val)
  return searchParams
}
