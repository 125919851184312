/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import cx from 'clsx'
import { find, flatMap, get } from 'lodash'
import React from 'react'

import { multipleLanguages } from '../../components/feature-flags'
import { getRepeatableChildren } from '../../formbot/engine/formbot/utils'
import { CALC_FUNCTIONS } from './calculation-constants'
import * as calculate from './calculation-functions'

export default function Calculation ({
  gridded,
  placeholder,
  onChange,
  value,
  formbotData,
  details,
  type,
  context,
  ...rest
}) {
  const calcFunction = details.calculation?.value?.calcFunctionForAll
  const total = resolveCalculation(details, formbotData, calcFunction, context)

  React.useEffect(() => {
    if (total === value || !onChange) return
    if (total || total === 0 || total === null) {
      onChange(total)
    }
  }, [onChange, total, value])

  let formatted
  if (multipleLanguages) {
    const { currency, decimals } = calculate.extractCurrencyConfig(details)
    formatted = formatTotalNew(total, calcFunction, type, currency, decimals)
  } else {
    formatted = formatTotal(total, calcFunction, type)
  }
  return (
    <span
      className={cx('box-border h-8 min-w-0 rounded-sm text-sm', {
        'w-full cursor-auto border-none bg-none px-4 pb-4 text-right font-bold outline-none':
          gridded,
        'border-1 border-light-gray-500 bg-white px-2': !gridded
      })}
      aria-labelledby={rest['aria-labelledby']}
      aria-required={rest.required}
      id={rest.id}
      placeholder={placeholder}
      type='number'
      readOnly
    >
      {formatted}
    </span>
  )
}

export const CalcFooter = ({
  total,
  calcFunction,
  type,
  value,
  mode,
  currency,
  decimals
}) => {
  let formatted
  if (multipleLanguages) {
    formatted = formatTotalNew(total, calcFunction, type, currency, decimals)
  } else {
    formatted = formatTotal(total, calcFunction, type)
  }

  return (
    <div
      className={cx(
        'flex h-12 items-center justify-end overflow-hidden overflow-ellipsis whitespace-nowrap bg-light-gray-200 p-2 text-right font-medium dark:bg-light-gray-300',
        {
          'text-medium-gray-500': formatted === null || formatted === undefined,
          'text-black': formatted !== null && formatted !== undefined,
          'border-b border-r border-light-gray-300 dark:border-light-gray-400':
            mode === 'config'
        }
      )}
      height={value?.length}
    >
      <span className='mr-2 text-medium-gray-500'>
        {calcFunction && calcFunction !== 'none' && calcFunction + ':'}
      </span>
      <span className='mr-2'>{formatted ?? (calcFunction && '--')}</span>
    </div>
  )
}
export const formatTotalNew = (
  total,
  calcFunction,
  type,
  currency,
  decimals
) => {
  return type !== 'Currency' || [CALC_FUNCTIONS.COUNT].includes(calcFunction)
    ? total
      ? i18n.number(total)
      : ''
    : calculate.toCurrency(total, currency, decimals)
}

export const formatTotal = (total, calcFunction, type) => {
  return type !== 'Currency' || [CALC_FUNCTIONS.COUNT].includes(calcFunction)
    ? total
    : calculate.toDollars(total)
}

export const resolveCalculation = (
  details,
  formbotData,
  calcFunction,
  context
) => {
  const values = getValues(formbotData, details, context)
  const resolved = calculate?.[calcFunction]?.(values)
  return resolved
}

const getValues = (formbotData, details, context) => {
  const calcParts = details?.calculation?.value?.parts
  const valuesToCalculate = flatMap(calcParts, calcPart => {
    const calcPartIsInRepeatable = calcPart?.parentFormKey

    // if the calcPart is a number/currency gadget outside of a table just return the value
    if (!calcPartIsInRepeatable) {
      return get(formbotData, calcPart?.formKey)
    }

    // if the calcPart is a number/currency gadget inside a table, first check to see if the calcPart should use the footer, then check to see if the calcPart should use the rows
    if (calcPartShouldUseFooter(calcPart, details)) {
      return getFooterValues(calcPart, formbotData, context)
    }
    if (calcPartShouldUseRows(calcPart, details)) {
      return getRowValues(calcPart, details, context)
    }
  })
  return valuesToCalculate.filter(val => val != null)
}

const getFooterValues = (calcPart, formbotData, context) => {
  const repeatFooter = get(formbotData, `${calcPart?.parentFormKey}.footer`)
  const formKey = calcPart?.formKey || determineFormKey(calcPart, context)

  // gadget is a Repeater
  if (repeatFooter) {
    return get(repeatFooter, [formKey, calcPart?.footerCalcFunction])
  }

  // gadget is a Table
  const tableRows = get(formbotData, calcPart?.parentFormKey)
  const footer = find(tableRows, { _isFooter: true })
  return get(footer, formKey)
}

const determineFormKey = (calcPart, context) => {
  const parentFormKey = calcPart?.parentFormKey
  // Find the gadget that the calcPart is inside of
  const parentGadget =
    find(context?.gadgetInstances, {
      formKey: parentFormKey
    }) ?? {}
  // Find the subgadget in the parent gadget that the calcPart is linked from
  const parentId = calcPart?.details?.parentId?.replace('data.', '')
  const parentSubgadget = find(getRepeatableChildren(parentGadget), {
    id: parentId
  })
  // Construct the formKey from the subgadget and the output path
  return `${parentSubgadget?.formKey}.${calcPart?.details?.selectedOutputField?.path}`
}

const getRowValues = (calcPart, details, context) => {
  const data = get(details, 'parentValue.data')
  const formKey = calcPart?.formKey || determineFormKey(calcPart, context)

  // gadget is a Repeater
  if (Array.isArray(data)) {
    const parentValues = find(data, { id: details.rowId })
    return get(parentValues, ['data', ...formKey.split('.')])
  }

  // gadget is a Table
  const parentTableValues = find(details.parentValue, { _rowId: details.rowId })
  return get(parentTableValues, formKey)
}

const calcPartShouldUseFooter = (calcPart, details) => {
  return (
    calcPart?.parentFormKey &&
    calcPart?.parentFormKey !== 'data.' + details?.parentFormKey
  )
}

const calcPartShouldUseRows = (calcPart, details) => {
  // This function is set up so that calc gadgets inside a table will use the rows of the table, but gadgets outside of a table will use the footer.  If we decide to allow gadgets outside of a table to choose to use the rows inside of a table gadget, this will have to be changed to check for that.
  return (
    details.rowId &&
    calcPart?.parentFormKey &&
    calcPart?.parentFormKey === 'data.' + details?.parentFormKey
  )
}
