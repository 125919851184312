/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { useParams } from 'react-router'

import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import { Option, Select } from '../../../ui/select'
import {
  InvalidFileType,
  useUploadFile
} from '../file-upload/parts/upload-file'
import { useStaticImageUploadMutation } from './mutation.static-image-upload'

export function RequiredConfig ({ onChange, value, Gadgets }) {
  const alerts = useAlerts()
  const [uploading, setUploading] = React.useState(false)
  const [imagePreview, setImagePreview] = React.useState(value?.tempImg)
  const { appId } = useParams()
  const accepts = 'image/*'
  const uploadMutation = useStaticImageUploadMutation(appId)
  const {
    progress,
    error,
    handleChange: handleFileUpload,
    raiseError,
    checkForBadFileType
  } = useUploadFile(
    async (file, tempImg) => {
      if (!file) return
      onChange({
        ...value,
        image: file,
        tempImg
      })
      setUploading(false)
    },
    accepts,
    uploadMutation,
    'staticImageUpload'
  )
  return (
    <div>
      <div className='mb-4'>
        <div className='mb-4 font-medium text-dark-gray-300'>
          <Trans id='pagesbuilder.form.config.static.image' />
        </div>
        {value?.image?.filename ? (
          <div className='flex w-full items-center justify-between rounded-lg bg-light-gray-200 p-2'>
            <img
              className='mx-2 max-h-24 max-w-24'
              src={value.image.url || imagePreview}
              alt={value.image.filename}
            />
            <div>{value.image.filename}</div>
            <Button
              aria-label={i18n._('remove.static.image')}
              title={i18n._('remove.static.image')}
              transparent
              small
              ml={3}
              onClick={() => onChange({ ...value, image: null, tempImg: null })}
            >
              <Icons.Delete />
            </Button>
          </div>
        ) : (
          <div className='relative flex h-48 w-full max-w-md cursor-pointer items-center justify-center rounded-lg border border-dashed border-medium-gray-100 bg-light-gray-200'>
            <input
              type='file'
              className='absolute inset-0 h-full w-full cursor-pointer opacity-0'
              accept={accepts}
              aria-labelledby='image-upload-label'
              disabled={uploading}
              onChange={async e => {
                try {
                  setUploading(true)
                  const file = e.target.files[0]
                  checkForBadFileType(file.type)
                  const e2 = await loadFile(file)
                  const img = await loadImage(e2.target.result)
                  setImagePreview(e2.target.result)
                  file.height = img.height
                  file.width = img.width
                  await handleFileUpload(file, e2.target.result)
                } catch (error) {
                  if (error instanceof InvalidFileType) {
                    Sentry.captureException(error)
                    return alerts.type1(
                      i18n._('unable.to.upload.image'),
                      i18n._({
                        id: 'invalid.file.type',
                        message: 'Invalid file type'
                      }),
                      'error'
                    )
                  }
                  setUploading(false)
                  Sentry.captureException(error)
                  raiseError(new Error(i18n._('error.uploading.image')))
                }
              }}
            />
            {uploading ? (
              <div className='flex-row items-center justify-center'>
                <label
                  id='image-upload-label'
                  className='px-4 text-medium-gray-500'
                >
                  {i18n._('uploading.image', { progress: progress || 0 })}
                </label>
                <div
                  className='mt-4 h-4 rounded-lg bg-wintergreen-300'
                  style={{ width: `${progress}%` }}
                />
              </div>
            ) : (
              <label id='image-upload-label' className='text-medium-gray-500'>
                <Trans id='pagesbuilder.form.config.image.upload.instructions' />
              </label>
            )}
          </div>
        )}
        {error && (
          <div className='text-red-500'>
            <Trans id='error.uploading.colon' /> {error.message}
          </div>
        )}
      </div>

      <Gadgets.Text
        configKey='description'
        label={i18n._('description.alt.text')}
      />
    </div>
  )
}

export function OptionalConfig ({ Gadgets }) {
  return (
    <div className='px-4'>
      <Gadgets.Custom
        id='image-size'
        configKey='size'
        label={i18n._('pagesbuilder.form.config.static.image.size')}
      >
        {({ onChange, value }) => (
          <Select
            aria-labelledby='image-size'
            onChange={onChange}
            value={value}
          >
            <Option key='none' value='none'>
              {i18n._('pagesbuilder.form.config.static.image.size.none')}
            </Option>
            <Option key='tiny' value='tiny'>
              {i18n._('pagesbuilder.form.config.static.image.size.tiny')}
            </Option>
            <Option key='small' value='small'>
              {i18n._('pagesbuilder.form.config.static.image.size.small')}
            </Option>
            <Option key='medium' value='medium'>
              {i18n._('pagesbuilder.form.config.static.image.size.medium')}
            </Option>
            <Option key='large' value='large'>
              {i18n._('pagesbuilder.form.config.static.image.size.large')}
            </Option>
            <Option key='huge' value='huge'>
              {i18n._('pagesbuilder.form.config.static.image.size.huge')}
            </Option>
          </Select>
        )}
      </Gadgets.Custom>
      <Gadgets.Custom
        id='horizontal-alignment'
        configKey='horizontalAlignment'
        label={i18n._(
          'pagesbuilder.form.config.static.image.horizontal.alignment'
        )}
      >
        {({ onChange, value }) => (
          <Select
            aria-labelledby='horizontal-alignment'
            onChange={onChange}
            value={value || 'center'}
          >
            <Option key='left' value='left'>
              {i18n._(
                'pagesbuilder.form.config.static.image.horizontal.alignment.left'
              )}
            </Option>
            <Option key='center' value='center'>
              {i18n._(
                'pagesbuilder.form.config.static.image.horizontal.alignment.center'
              )}
            </Option>
            <Option key='right' value='right'>
              {i18n._(
                'pagesbuilder.form.config.static.image.horizontal.alignment.right'
              )}
            </Option>
          </Select>
        )}
      </Gadgets.Custom>
    </div>
  )
}

function loadFile (data) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = resolve
    reader.readAsDataURL(data)
  })
}

function loadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}
