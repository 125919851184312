/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import FocusTrap from 'focus-trap-react'
import { isEqual } from 'lodash'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useImmer } from 'use-immer'

import { useTransitionRef } from '../../components/use-transition-ref'
import * as Icons from '../../icons'

export default ({ visible, ...props }) => {
  const ref = useTransitionRef(props.value?.clientId)
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={visible ? props.value?.clientId : null}
        timeout={300}
        nodeRef={ref}
      >
        {visible ? (
          <div
            ref={ref}
            className={cx(
              // TODO:: Designs have width at 350px but some approval stuff doesn't fit
              'fixed right-6 z-[2] w-[500px] max-w-[100vw] text-sm',
              props.isTable ? 'bottom-6 top-[222px]' : 'bottom-10 top-[144px]'
            )}
          >
            <FocusTrap
              focusTrapOptions={{ clickOutsideDeactivates: true }}
              setReturnFocus={`[data-whatinput="keyboard"] #wfStep-${props.value.clientId}`}
            >
              <div
                onClick={e => e.stopPropagation()}
                className='kp-transition-slide-left relative h-full w-full overflow-auto rounded-br-lg border-l border-light-gray-300 bg-white transition-[left] duration-300'
              >
                <Configuration key={props.value.clientId} {...props} />
              </div>
            </FocusTrap>
          </div>
        ) : (
          <span />
        )}
      </CSSTransition>
    </TransitionGroup>
  )
}

const Configuration = ({
  appId,
  value,
  justCreatedId,
  onDelete,
  onSave,
  manifest,
  fieldsMultiUsers,
  fieldsUsers,
  fieldsEmails,
  fieldsGroups,
  fieldsIterable,
  fieldsAll,
  formSections,
  lineage,
  workflowSettings
}) => {
  const [data, updateData] = useImmer(value)
  const dataRef = React.useRef(data)
  dataRef.current = data
  React.useEffect(() => {
    return () => {
      if (!isEqual(data, dataRef.current)) {
        onSave(dataRef.current)
      }
    }
  }, [])
  const errors =
    manifest.validate && justCreatedId !== data.clientId
      ? manifest.validate(data, fieldsAll, lineage)
      : []
  return (
    <>
      <div className='p-4 text-sm uppercase text-dark-gray-400'>
        <Trans id='manifest.step.options' values={{ name: manifest.name }} />
      </div>
      {manifest.Config && (
        <manifest.Config
          appId={appId}
          value={data}
          updateValue={updateData}
          errors={errors}
          fieldsMultiUsers={fieldsMultiUsers}
          fieldsUsers={fieldsUsers}
          fieldsEmails={fieldsEmails}
          fieldsGroups={fieldsGroups}
          fieldsIterable={fieldsIterable}
          fieldsAll={fieldsAll}
          formSections={formSections}
          workflowSettings={workflowSettings}
        />
      )}
      <div className='flex items-center justify-between p-4'>
        <button
          className='kp-button-transparent gap-2 text-red-400'
          onClick={onDelete}
        >
          <Icons.Delete />
          <Trans id='delete.step' />
        </button>
      </div>
    </>
  )
}
