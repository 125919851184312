/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { useOutletContext, useParams } from 'react-router'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import Pagination from '../../../../components/data-table/pagination'
import Loading from '../../../../components/loading'
import { GraphQLError as Error } from '../../../../components/system-error'
import { useQuery } from '../../../../components/use-query'
import { Announcer } from '../../../../ui/a11y'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../../../../ui/table'

export default function UserHistory () {
  const props = useOutletContext()
  const _params = useParams()
  const id = props.id || _params.id
  const [params, updateParams] = useImmer(defaultParams)
  const { logs, totalCount, loading, error } = useLogsData(id, params)
  if (!logs || error) return loading ? <Loading /> : <Error error={error} />
  return (
    <TableWrapper>
      <Table>
        <TableHeader>
          <TableRow>
            {columns.map(col => (
              <TableHeaderCell key={col.id}>{col.label}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {logs.map(log => (
            <TableRow key={log.id}>
              {columns.map(col => (
                <TableCell key={col.id}>
                  {col.format?.(log[col.id]) || log[col.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {totalCount !== 0 && (
          <Pagination
            onUpdate={({ skip, limit }) => {
              updateParams(draft => {
                draft.skip = skip
                draft.limit = limit
              })
            }}
            total={totalCount}
            skip={params.skip}
            limit={params.limit}
          />
        )}
        <Announcer id='document-list-pagination'>
          {getPaginationString(totalCount, params.skip, params.limit)}
        </Announcer>
      </Table>
    </TableWrapper>
  )
}

const logsQuery = gql`
  query Users(
    $id: ID!
    $q: String
    $skip: Int!
    $limit: Int!
    $sort: [String!]
  ) {
    user(id: $id) {
      id
      historyConnection(
        args: { query: $q, skip: $skip, limit: $limit, sort: $sort }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          skip
          limit
        }
        edges {
          node {
            id
            createdAt
            updatedBy {
              id
              displayName
              email
            }
            operation
            description
          }
        }
      }
    }
  }
`

const TableWrapper = styled.div`
  padding-top: 96px;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;

  th,
  td {
    height: 52px;
  }
`

const Email = styled.div`
  color: #666;
`

const getPaginationString = (count, start, limit) => {
  if (!count) return <Trans id='no.matching.search.results' />
  const end = Math.min(start + limit, count)
  return i18n._('pagesbuilder.doclist.count.users', {
    start: start + 1,
    end,
    count
  })
}

const columns = [
  {
    id: 'createdAt',
    label: <Trans id='date.and.time' />,
    format: date =>
      i18n.date(new Date(date), { dateStyle: 'medium', timeStyle: 'medium' })
  },
  {
    id: 'updatedBy',
    label: <Trans id='user' />,
    format: user => (
      <div>
        <div>{user.displayName}</div>
        {user.email && <Email>{user.email}</Email>}
      </div>
    )
  },
  { id: 'operation', label: <Trans id='action' /> },
  { id: 'description', label: <Trans id='description' /> }
]

const defaultParams = { skip: 0, limit: 25 }

function useLogsData (id, params) {
  const graphqlVariables = React.useMemo(() => {
    return { id, limit: params.limit, skip: params.skip }
  }, [params])
  const [queryInput, setQueryInput] = React.useState()
  const { data, loading, error } = useQuery({
    query: logsQuery,
    variables: graphqlVariables,
    fetchPolicy: 'network-only'
  })
  const logs = React.useMemo(() => {
    if (!data?.user?.historyConnection) return
    return data.user.historyConnection.edges.map(({ node }) => mapLog(node))
  }, [data])
  const totalCount = data?.user?.historyConnection?.totalCount ?? 0
  return {
    logs,
    totalCount,
    loading,
    error,
    queryInput,
    setQueryInput
  }
}

function mapLog (log) {
  return {
    ...log,
    createdAt: new Date(log.createdAt),
    operation: operationMap[log.operation] || log.operation
  }
}

const operationMap = {
  create: 'Created',
  delete: 'Deleted',
  update: 'Updated'
}
