/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { get } from 'lodash'
import React from 'react'
import { Navigate, useParams } from 'react-router'

import { useQuery } from '../../components/use-query'

export default function () {
  const { publishUrlPath } = useParams()
  const resp = useQuery(getQuery(publishUrlPath))
  const appId = get(resp, 'data.app.id')
  if (appId) return <Navigate to={`/app/${appId}/run`} replace />
  return null
}

const getQuery = appPath => ({
  variables: { appPath },
  query: gql`
    query RunPageRedirectQuery($appPath: String!) {
      app: appRedirect(publishUrlPath: $appPath) {
        id
      }
    }
  `
})
