/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { Link } from 'react-router'

import { Problem } from '../illustrations'
import Forbidden from '../pages/forbidden'
import { Blurb, InnerWrapper, Wrapper } from '../ui/errors'

export default class SystemError extends React.Component {
  state = { hasError: false }

  componentDidCatch (error, errorInfo) {
    this.setState({ hasError: true })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render () {
    if (!this.state.hasError) return this.props.children
    return <ExplicitError />
  }
}

export function ExplicitError ({ fillHeight, error }) {
  if (error) {
    const notLoggedIn = error?.networkError?.statusCode === 401
    const notAllowed =
      error?.graphQLErrors?.[0]?.message ===
      'User does not have the correct permissions'
    if (notLoggedIn || notAllowed) {
      return <Forbidden redirecting={notLoggedIn} />
    }
  }
  return (
    <Wrapper
      height={fillHeight ? '100%' : '100vh'}
      className='text-sm dark:bg-light-gray-400'
    >
      <InnerWrapper>
        <Problem />
        <Blurb>
          <Blurb.Heading>
            <Trans id='hmmmm..' />
          </Blurb.Heading>
          <Blurb.Message>
            <Trans id='sorry.inconvience.something.unexpected' />
          </Blurb.Message>
          <Blurb.Action outline onClick={() => window.location.reload()}>
            <Trans id='try.again' />
          </Blurb.Action>
        </Blurb>
      </InnerWrapper>
    </Wrapper>
  )
}
export const GraphQLError = ExplicitError

export function NotFound ({ fillHeight }) {
  return (
    <Wrapper
      height={fillHeight ? '100%' : '100vh'}
      className='text-sm dark:bg-light-gray-400'
    >
      <InnerWrapper>
        <Problem />
        <Blurb>
          <Blurb.Heading>
            <Trans id='hmmmm..' />
          </Blurb.Heading>
          <Blurb.Message>
            <Trans id='cannot.find.page.requested' />
          </Blurb.Message>
          <Blurb.Action outline to='/' forwardedAs={Link}>
            <Trans id='go.to.kuali.dashboard' />
          </Blurb.Action>
        </Blurb>
      </InnerWrapper>
    </Wrapper>
  )
}

export function CannotDiscard ({ fillHeight }) {
  return (
    <Wrapper height={fillHeight ? '100%' : '100vh'} className='text-sm'>
      <InnerWrapper>
        <Problem />
        <Blurb>
          <Blurb.Heading>
            <Trans id='document.cannot.be.discarded' />
          </Blurb.Heading>
          <Blurb.Message>
            <Trans id='document.already.submitted.cannot.discard' />
          </Blurb.Message>
          <Blurb.Action outline to='/my/submissions' forwardedAs={Link}>
            <Trans id='back.to.documents' />
          </Blurb.Action>
        </Blurb>
      </InnerWrapper>
    </Wrapper>
  )
}
