/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

import { ModalPage } from '../../../../components/modal-page'
import { useTransitionRef } from '../../../../components/use-transition-ref'
import DisplaySignature from './display-signature'

export default function ViewModal ({ setShowModal, showModal, value }) {
  const ref = useTransitionRef(showModal)
  const labels = {
    draw: i18n._('drawn.with.mouse'),
    type: i18n._('typed.with.keyboard'),
    upload: i18n._('uploaded.signature.image')
  }
  return (
    <TransitionGroup component={null}>
      <CSSTransition key={showModal} timeout={450} nodeRef={ref}>
        {showModal ? (
          <ModalPage
            ref={ref}
            className='cursor-default'
            title={i18n._('view.signature')}
            onClose={event => {
              event.stopPropagation(true)
              setShowModal(false)
            }}
            onClick={e => e.stopPropagation()}
          >
            <ModalContents>
              <SignatureLabel>{labels[value.signatureType]}</SignatureLabel>
              <SignatureBox>
                <DisplaySignature value={value} />
              </SignatureBox>
              <SignatureFooterA>
                <Trans
                  id='electronically.signed.by'
                  values={{ displayName: value.displayName }}
                />
              </SignatureFooterA>
              <div>
                {value.date &&
                  new Intl.DateTimeFormat(window.navigator.language, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  }).format(+new Date(value.date))}
              </div>
            </ModalContents>
          </ModalPage>
        ) : (
          <span />
        )}
      </CSSTransition>
    </TransitionGroup>
  )
}

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ModalContents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  height: 100%;
`
const SignatureLabel = styled.div`
  font-size: 12px;
  color: #666666;
  padding: 8px;
`
const SignatureBox = styled(Center)`
  background: #ffffff;
  padding: 16px;
  min-width: 240px;
`
const SignatureFooterA = styled.div`
  padding: 16px 0 8px 0;
  font-weight: 500;
  color: #3369a3;
`
