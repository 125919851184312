/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { get, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { multipleLanguages } from '../../../components/feature-flags'
import Calculation from '../../../pages-builder/calculations/calculation-components'
import Input from '../../../ui/input'
import { useFormbotData } from '../../engine/formbot-react/hooks'

export default props => {
  const inputRef = React.createRef()
  const formbotData = useFormbotData()

  const { context, details, formKey, gridded, onChange, value, inline } = props
  const isCalculation = details?.calculation?.enabled

  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')
  const Component = gridded || inline ? MyInput : Input
  const validations = get(context, ['validations', formKey], [])

  return (
    <>
      {isCalculation ? (
        <Calculation
          gridded={gridded}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          formbotData={formbotData}
          details={details}
          {...props}
          type='Number'
        />
      ) : multipleLanguages ? (
        <Component
          className={cx(
            inline &&
              '!h-9/10 !w-12 !min-w-12 !rounded-full !bg-light-gray-200 !p-2 !text-center'
          )}
          aria-labelledby={props['aria-labelledby']}
          aria-describedby={props['aria-describedby']}
          aria-required={props.required}
          ref={inputRef}
          id={props.id}
          onWheel={e => e.target.blur()}
          onChange={onChange}
          value={value ?? ''}
          placeholder={placeholder}
          type='number'
          step='any'
          inputMode='numeric'
        />
      ) : (
        <Component
          className={cx(
            inline &&
              '!h-9/10 !w-12 !min-w-12 !rounded-full !bg-light-gray-200 !p-2 !text-center'
          )}
          aria-labelledby={props['aria-labelledby']}
          {...(props['aria-describedby'] && {
            'aria-describedby': props['aria-describedby']
          })}
          aria-required={props.required}
          ref={inputRef}
          id={props.id}
          onWheel={e => e.target.blur()}
          onChange={val => {
            if (val === '') return onChange(null)
            const number = parseFloat(val, 10)
            if (!isNaN(number)) return onChange(number)
          }}
          defaultValue={value ?? ''}
          placeholder={placeholder}
          type='number'
          step='any'
          onKeyPress={e => {
            const charCode =
              typeof e.which === 'undefined' ? e.keyCode : e.which
            const charStr = String.fromCharCode(charCode)

            if (!charStr.match(/^[-+]?[0-9eE.]*$/)) {
              e.preventDefault()
            }
          }}
        />
      )}
      {map(validations, message => (
        <Error className='text-red-500'>{message}</Error>
      ))}
    </>
  )
}

const MyInput = styled(Input)`
  border: none;
  padding: 0 16px 16px 16px;
  background: none;
  width: 100%;
  text-align: right;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  html.dark & {
    // Outlier: dark:bg-white
    background: #444;
  }
  [data-whatinput='mouse'] & {
    outline: none;
  }
  appearance: textfield;
  -moz-appearance: textfield;
`

const Error = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`
