/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { endsWith, isEmpty, map, reject } from 'lodash'
import React from 'react'
import { Link, useLocation, useOutletContext, useParams } from 'react-router'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import AnimatedOutlet from '../../../components/animated-outlet'
import Header from '../../../components/data-table/header'
import Pagination from '../../../components/data-table/pagination'
import Pills from '../../../components/data-table/pills'
import Loading from '../../../components/loading'
import Spinner from '../../../components/spinner'
import { GraphQLError as Error } from '../../../components/system-error'
import useActiveTab from '../../../components/use-active-tab'
import { useQuery } from '../../../components/use-query'
import {
  sizeFromWidth,
  useWindowWidth
} from '../../../components/use-window-width'
import { STATUS_ENUM } from '../../../formbot/gadgets/workflow-status/consts'
import WorkflowStatus from '../../../formbot/gadgets/workflow-status/view'
import { Duplicate, MenuVertical, Undo } from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import { Flex } from '../../../ui/layout'
import { Popover2 } from '../../../ui/popover'
import { Body1 } from '../../../ui/typography'
import EmptyState from './empty-state'
import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRow,
  TableWrapper
} from './layout'
import { useDuplicateMutation } from './mutation.duplicate'
import { useWithdrawMutationContext } from './mutation.withdraw'
import { QueryContextProvider } from './use-query-context'
import { formatFromNow, paramsToGql, switchTypenames } from './util'

// this ascending is backwards because of the header component
const DEFAULT_SORT = { ascending: true, field: 'meta.submittedAt', id: 'a1' }
const EXCLUDED_STATUSES = [
  STATUS_ENUM.WITHDRAWN.value,
  ...STATUS_ENUM.WITHDRAWN.additionalValues,
  STATUS_ENUM.REJECTED.value,
  ...STATUS_ENUM.REJECTED.additionalValues,
  STATUS_ENUM.COMPLETE.value,
  ...STATUS_ENUM.COMPLETE.additionalValues
]

export default function SubmissionsOuter () {
  return (
    <>
      <AnimatedOutlet context={useOutletContext()} />
      <Submissions />
    </>
  )
}

function Submissions () {
  const location = useLocation()
  const alerts = useAlerts()
  const activeTab = useActiveTab()
  const windowSize = sizeFromWidth(useWindowWidth())
  React.useEffect(() => {
    if (location?.state?.fromSubmit) {
      alerts.type2(
        i18n._({
          id: 'pages.submitter.submission.success',
          message: 'Your form was successfully submitted.'
        }),
        'info'
      )
    }
  }, [alerts, location])
  const [params, updateParams] = useImmer(defaultParams([]))
  React.useEffect(() => {
    if (isEmpty(params.sorts)) {
      updateParams(draft => {
        draft.sorts.push(DEFAULT_SORT)
      })
    }
  }, [params.sorts, updateParams])

  const query = mySubmissionsQuery(params)
  const { data, loading, error, startPolling, stopPolling } = useQuery(query)
  const variablesHash = JSON.stringify(query.variables)
  React.useEffect(() => {
    if (activeTab && endsWith(location.pathname, '/submissions')) {
      startPolling(10000)
    } else stopPolling()
    return () => stopPolling()
  }, [activeTab, startPolling, stopPolling, variablesHash, location])

  React.useEffect(() => {
    const appOptions = map(data?.mySubmissions?.apps, v => ({
      key: v._id,
      lbl: v.label
    }))
    if (appOptions.length > 0) {
      updateParams(draft => {
        draft.columns = defaultColumns(appOptions)
      })
    }
  }, [data, updateParams])
  const columns = React.useMemo(() => {
    if (windowSize === 'small') {
      return params.columns.filter(column => column.formKey !== 'timeElapsed')
    }
    return params.columns
  }, [params.columns, windowSize])

  const edges = data?.mySubmissions?.edges ?? []
  if (!edges || error) return <Error error={error} />
  if (!loading && edges?.length === 0 && isEmpty(params.filters)) {
    return (
      <EmptyState
        illustration='craig'
        message={i18n._({
          id: 'pages.submitter.submission.not.submitted',
          message: "You haven't submitted a form yet."
        })}
      />
    )
  }

  return (
    <QueryContextProvider query={query}>
      <Pills
        columns={params.columns}
        value={params.filters}
        remove={field => {
          updateParams(draft => {
            draft.filters = reject(draft.filters, { field })
          })
        }}
      />
      <StyledTableWrapper>
        {!edges.length && loading ? (
          <Loading />
        ) : (
          <Table>
            <MyHeader
              params={params}
              updateParams={updateParams}
              columns={columns}
            >
              <TableHeaderCell style={{ width: '1px' }}>
                <Trans id='actions' />
              </TableHeaderCell>
            </MyHeader>
            <TableBody>
              {edges?.map(edge => (
                <SubmissionRow
                  value={edge.node}
                  key={edge.node.id}
                  columns={columns}
                />
              ))}
            </TableBody>
            {edges?.length > 0 && (
              <Pagination
                total={data?.mySubmissions?.totalCount}
                skip={params.skip}
                limit={params.limit}
                onUpdate={({ skip, limit }) =>
                  updateParams(draft => {
                    draft.skip = skip
                    draft.limit = limit
                  })
                }
              />
            )}
          </Table>
        )}
      </StyledTableWrapper>
      {edges?.length === 0 && !isEmpty(params.filters) && (
        <EmptyState
          illustration='craig'
          message={i18n._({
            id: 'pages.submitter.submission.matching',
            message: 'No matching filtered results.'
          })}
        />
      )}
    </QueryContextProvider>
  )
}
function SubmissionRow ({ value, columns }) {
  const { suite } = useParams()
  const alerts = useAlerts()
  const [duplicate, { loading: duplicateLoading }] = useDuplicateMutation()
  const withdraw = useWithdrawMutationContext()

  return (
    <LinkRow>
      {columns.map(column => (
        <TableCell key={column.formKey}>
          <Link
            to={value.id}
            className='block h-full w-full px-4 py-3 max-md:px-0.5'
            tabIndex={column.tabIndex}
          >
            {column.render({ value })}
          </Link>
        </TableCell>
      ))}
      <TableCell onClick={e => e.stopPropagation()}>
        <StyledFlex justifyContent='center' alignItems='center'>
          <Popover2
            role='menu'
            top={40}
            right={-8}
            trigger={
              <PopoverTrigger
                as={Button}
                aria-label={i18n._('actions')}
                transparent
                icon
              >
                <MenuVertical />
              </PopoverTrigger>
            }
          >
            {hide => (
              <Menu className='dark:bg-light-gray-400'>
                {!EXCLUDED_STATUSES.includes(value.status) && (
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                    as='button'
                    onClick={async () => {
                      const {
                        data: { withdrawSubmission }
                      } = await withdraw(
                        value.id,
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                      const cannotWithdraw = () => {
                        alerts.type2(
                          i18n._({
                            id: 'pages.submitter.submission.withdraw.error',
                            message:
                              'We were unable to withdraw this document. Please contact an administrator.'
                          }),
                          'error'
                        )
                      }
                      switchTypenames(withdrawSubmission, {
                        Success: () => {
                          alerts.type2(
                            i18n._({
                              id: 'pages.submitter.submission.withdrawn',
                              message: 'Your submission has been withdrawn.'
                            }),
                            'info'
                          )
                        },
                        PermissionError: cannotWithdraw,
                        TerminationError: cannotWithdraw
                      })
                      hide()
                    }}
                  >
                    <Undo />
                    <span>
                      <Trans id='withdraw' message='Withdraw' />
                    </span>
                  </MenuLink>
                )}
                <MenuLink
                  className='hover:bg-light-gray-200 dark:hover:bg-light-gray-300'
                  loading={duplicateLoading}
                  as='button'
                  onClick={async () => {
                    hide()
                    const closeAlert = alerts.type3(
                      <Flex style={{ gap: '16px' }}>
                        <Trans id='pages.submitter.duplicating' />
                        <Spinner size={16} />
                      </Flex>,
                      'success'
                    )
                    const {
                      data: { duplicateDocument }
                    } = await duplicate(
                      value.id,
                      Intl.DateTimeFormat().resolvedOptions().timeZone
                    ).finally(() => setTimeout(closeAlert, 750))
                    switch (duplicateDocument.__typename) {
                      case 'Redirect': {
                        const closeAlert = alerts.type2(
                          <span>
                            <Trans
                              id='pages.submissions.duplicated'
                              components={{
                                link: (
                                  <Link
                                    className='text-text-link hover:underline'
                                    to={`${suite}/my/drafts/${duplicateDocument.newActionId}`}
                                    onClick={() => closeAlert()}
                                  />
                                )
                              }}
                            />
                          </span>,
                          'info'
                        )
                        break
                      }
                      case 'InvalidDocumentError': {
                        alerts.type2(
                          i18n._({
                            id: 'pages.submissions.no.longer.exists',
                            message:
                              'The document or application you requested no longer exists and cannot be duplicated.'
                          }),

                          'error'
                        )
                        break
                      }
                      case 'PermissionError': {
                        alerts.type2(
                          i18n._({
                            id: 'pages.submissions.not.accepting',
                            message:
                              'The application for the document you requested is not accepting new submissions at this time.'
                          }),
                          'error'
                        )
                        break
                      }
                      default: {
                        break
                      }
                    }
                  }}
                >
                  <Duplicate />
                  <span>
                    <Trans id='duplicate' message='Duplicate' />
                  </span>
                </MenuLink>
              </Menu>
            )}
          </Popover2>
        </StyledFlex>
      </TableCell>
    </LinkRow>
  )
}

const PopoverTrigger = styled(Button)`
  border: none;
  box-shadow: none;
`
const StyledFlex = styled(Flex)`
  min-height: 41px;
`

const StyledTableWrapper = styled(TableWrapper)`
  position: relative;
  min-height: 50vh;
`

const MyHeader = styled(Header)`
  @media (max-width: 768px) {
    & th {
      padding: 0 2px;
      font-weight: normal;
      font-size: 13px;
    }
  }
`

const Menu = styled.div``

const MenuLink = styled(Body1)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  border: none;
  width: 100%;
  color: ${p => p.loading && '#666666'};

  > svg,
  ${Spinner} {
    margin-right: 16px;
  }
`

const LinkRow = styled(TableRow)`
  cursor: pointer;
`

const defaultParams = options => ({
  skip: 0,
  limit: 10,
  q: undefined,
  filters: [],
  sorts: [DEFAULT_SORT],
  columns: defaultColumns(options)
})

const defaultColumns = options => [
  {
    type: 'Dropdown',
    formKey: 'meta.formContainer',
    label: `${i18n._('document.type')}`,
    details: { options },
    tabIndex: -1,
    visible: true,
    render: ({ value }) => value.label
  },
  {
    type: 'DatePicker',
    formKey: 'meta.title',
    label: `${i18n._('title')}`,
    details: {},
    visible: true,
    render: ({ value }) => value?.title ?? '--'
  },
  {
    type: 'DatePicker',
    formKey: 'meta.submittedAt',
    label: `${i18n._('date.submitted')}`,
    details: {},
    tabIndex: -1,
    visible: true,
    render: ({ value }) => {
      return value.submittedAt
        ? i18n.date(new Date(value.submittedAt), {
            dateStyle: 'medium',
            timeStyle: 'short'
          })
        : i18n.date(new Date(value.createdAt), {
            dateStyle: 'medium',
            timeStyle: 'short'
          })
    }
  },
  {
    type: 'Duration',
    formKey: 'timeElapsed',
    label: `${i18n._('time.elapsed')}`,
    details: {},
    unsortable: true,
    tabIndex: -1,
    visible: true,
    render: ({ value }) => {
      return value.submittedAt
        ? formatFromNow(new Date(value.submittedAt))
        : formatFromNow(new Date(value.createdAt))
    }
  },
  {
    type: 'WorkflowStatus',
    formKey: 'meta.workflowStatus',
    label: `${i18n._('status')}`,
    details: {},
    filterOut: ['draft'],
    tabIndex: -1,
    visible: true,
    render: ({ value }) => {
      return <WorkflowStatus value={value.status} />
    }
  }
]

const query = gql`
  query MySubmissions(
    $skip: Int!
    $limit: Int!
    $sort: [String!]
    $fields: Operator
  ) {
    mySubmissions(
      args: { skip: $skip, limit: $limit, sort: $sort, fields: $fields }
    ) {
      totalCount
      edges {
        node {
          id
          label
          status
          title
          # currentStep
          submittedAt
          createdAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        skip
        limit
      }
      apps
    }
  }
`

const mySubmissionsQuery = params => {
  return {
    variables: paramsToGql(params),
    fetchPolicy: 'cache-and-network',
    query
  }
}
