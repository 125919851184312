/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router'

import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import { GraphQLError as Error } from '../../components/system-error'
import { useQuery } from '../../components/use-query'
import { useDeleteAppMutation } from './components/mutation.delete-app'

export default function DeleteApp () {
  return (
    <ModalPage title={i18n._('delete.app')}>
      <DeleteAppInner />
    </ModalPage>
  )
}

function DeleteAppInner () {
  const navigate = useNavigate()
  const { appId } = useParams()
  const [confirmText, setConfirmText] = React.useState('')
  const query = getDeleteAppPageQuery(appId)
  const { data, error, loading } = useQuery(query)
  const deleteApp = useDeleteAppMutation(appId)
  if (loading) return <Loading />
  if (error) return <Error error={error} />
  return (
    <form
      className='mx-auto my-44 w-52'
      onSubmit={e => {
        e.preventDefault()
        if (confirmText !== 'goodbye') return
        deleteApp().then(() => navigate('/'))
      }}
    >
      <h1 className='text-3xl text-red-400'>
        <Trans id='pagesbuilder.delete.warning' />
      </h1>
      <div className='my-2 text-base'>
        <Trans
          id='pagesbuilder.delete.permanently'
          values={{ name: data?.app?.name }}
          components={{ bold: <b /> }}
        />
      </div>
      <label
        htmlFor='deletionConfirmation'
        className='mb-1 block text-xs text-medium-gray-500'
      >
        <Trans
          id='pagesbuilder.delete.goodbye'
          comment='goodbye needs to stay inbetween the quotation, no matter which language, since it is checked for submission'
        />
      </label>
      <input
        type='text'
        className='kp-input w-full'
        id='deletionConfirmation'
        autoFocus
        placeholder='goodbye'
        onChange={e => setConfirmText(e.target.value)}
      />
      <button
        className='kp-button-danger mt-4 w-full'
        type='submit'
        disabled={confirmText !== 'goodbye'}
      >
        <Trans id='pagesbuilder.delete.delete.app' />
      </button>
    </form>
  )
}

const getDeleteAppPageQuery = appId => ({
  variables: { appId },
  query: gql`
    query DeleteAppPage($appId: ID!) {
      app(id: $appId) {
        id
        name
      }
    }
  `
})
