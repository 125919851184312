/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { Link, useNavigate } from 'react-router'

import * as Empty from '../../../components/data-table/empty'
import { useIds } from '../../../components/use-ids'
import { useInitWorkflow } from '../../../pages-runner/run/components/mutation.init-workflow'
import InfoBox from '../../../ui/info-box'
import { ReactComponent as NoRecordsIllustration } from './illustration-no-records.svg.jsx'

export const NoMatching = Empty.NoMatching

export const NoResults = ({ formUrl }) => {
  const navigate = useNavigate()
  const initWorkflow = useInitWorkflow()
  const { appId, datasetId } = useIds()
  return (
    <div className='flex justify-center pt-60'>
      <NoRecordsIllustration className='relative left-9 mt-40 shrink-0' />
      <InfoBox
        title={i18n._('pagesbuilder.doclist.empty.no.records')}
        subtitle={i18n._('pagesbuilder.doclist.empty.no.records.subtitle')}
      >
        <Link className='kp-button-outline mt-2' to='settings'>
          <Trans id='pagesbuilder.doclist.empty.data.settings' />
        </Link>

        {formUrl && (
          <button
            className='kp-button-outline mt-2'
            onClick={() => {
              initWorkflow(appId, datasetId)
                .then(actionId => navigate(`${actionId}/action`))
                .catch(() => null)
            }}
          >
            <Trans id='pagesbuilder.doclist.empty.form' />
          </button>
        )}
      </InfoBox>
    </div>
  )
}
