/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useApolloClient } from '@apollo/client'
import { get } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'

import Loading, { LoadingPage } from '../components/loading'
import { useTileTransition } from '../components/tile-transition'
import Forbidden from './forbidden'

export function Protected ({ isPage, permission, children }) {
  const apolloClient = useApolloClient()
  const { appId: id, publishUrlPath } = useParams()
  const [hasRights, setHasRights] = React.useState(undefined)
  const { finish } = useTileTransition()
  React.useEffect(() => {
    async function fetchPermissions () {
      let resp
      try {
        if (id) {
          resp = await apolloClient.query({
            query: queryWithAppId,
            variables: { id }
          })
        } else if (publishUrlPath) {
          resp = await apolloClient.query({
            query: queryWithAppPath,
            variables: { publishUrlPath }
          })
        } else {
          resp = await apolloClient.query({ query: baseQuery })
        }
        const allPermissions = {
          ...(get(resp.data, 'viewer.user') || {}),
          ...(get(resp.data, 'app.viewer') || {})
        }
        setHasRights(allPermissions[permission] || false)
      } catch (err) {
        if (err?.networkError?.statusCode === 401) {
          setHasRights(null)
        } else {
          setHasRights(false)
        }
      }
    }
    fetchPermissions()
  }, [permission, id, publishUrlPath])
  React.useEffect(() => {
    if (hasRights === false) finish()
  }, [hasRights])
  if (hasRights === undefined) return isPage ? <LoadingPage /> : <Loading />
  if (hasRights) return children
  return <Forbidden redirecting={hasRights === null} />
}

const ViewerFragment = gql`
  fragment ViewerData on Query {
    viewer {
      id
      user {
        id
        canCreateApps
        canManageSettings
      }
    }
  }
`

const AppFragment = gql`
  fragment AppData on App {
    id
    viewer {
      canViewDocuments
      canEditDocuments
      canSubmitDocuments
      canManage
    }
  }
`

const baseQuery = gql`
  query RoutePermissions {
    ...ViewerData
  }
  ${ViewerFragment}
`

const queryWithAppId = gql`
  query RoutePermissionsWithAppId($id: ID) {
    ...ViewerData
    app(id: $id) {
      id
      ...AppData
    }
  }
  ${ViewerFragment}
  ${AppFragment}
`

const queryWithAppPath = gql`
  query RoutePermissionsWithAppId($publishUrlPath: String) {
    ...ViewerData
    app(publishUrlPath: $publishUrlPath) {
      id
      ...AppData
    }
  }
  ${ViewerFragment}
  ${AppFragment}
`
