/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useLocation, useParams } from 'react-router'

import { MaintenanceTableContext } from '../pages-builder/maintenance-tables'
import { bind as bindRoutes } from './routes'

export function useIds () {
  const { pathname } = useLocation()
  const { appId, pageId, tableId } = useParams()
  const inMaintenanceTable = React.useContext(MaintenanceTableContext)
  const datasetId = inMaintenanceTable ? tableId : pageId
  const [, slug] = pathname.match(/\/([^/]+)\/general-data/) || []
  const routes = bindRoutes(appId, pageId, { tableId, slug })
  return { appId, pageId, tableId, datasetId, routes }
}
