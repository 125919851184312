/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import {
  find,
  findIndex,
  forEach,
  get,
  has,
  includes,
  isArray,
  last,
  reject,
  toLower
} from 'lodash'
import React from 'react'
import shortid from 'shortid'

import { focusFirstInput } from '../../../components/use-focus-first-input'
import * as Icons from '../../../icons'
import Input from '../../../ui/input'
import { useFormbot } from '../../engine/formbot-react/hooks'
import RuleSearch from './components/rule-search'

export default function Rules ({ details, context, id, value, onChange }) {
  const [newestNodeId, setNewestNodeId] = React.useState()
  const handleStructureChange = newValue => onChange(newValue)

  const handleDataChange = (subGadgetFormKey, ruleNodeId, newData, path) => {
    const ruleIndex = findIndex(value.data, { id: ruleNodeId })
    const ruleGroups = getRuleGroups(path, value)

    const updatedData = value.data.map((item, i) =>
      i === ruleIndex
        ? {
            ...item,
            ruleGroups,
            data: {
              ...item.data,
              [subGadgetFormKey]: newData
            }
          }
        : item
    )

    if (ruleIndex === -1) {
      updatedData.push({
        id: ruleNodeId,
        ruleGroups,
        data: { [subGadgetFormKey]: newData }
      })
    }

    onChange({ ...value, data: updatedData })
  }

  return (
    <RuleGroupNode
      root
      node={value}
      decisionTree={details.tree}
      handleStructureChange={handleStructureChange}
      handleDataChange={handleDataChange}
      data={value.data}
      context={context}
      value={value}
      idPrefix={id}
      newestNodeId={newestNodeId}
      onNodeAdded={setNewestNodeId}
    />
  )
}

const RuleGroupNode = ({
  root,
  node,
  decisionTree,
  path = [],
  handleStructureChange,
  handleDataChange,
  data,
  value,
  context,
  idPrefix,
  newestNodeId,
  onNodeAdded
}) => {
  const [showRuleSearch, setShowRuleSearch] = React.useState(false)
  const [credits, setCredits] = React.useState(0)
  const [hasNewNode, setHasNewNode] = React.useState(false)

  React.useEffect(() => {
    setCredits(sumCredits(node, data))
  }, [node, data])

  const handleNodeAdded = newNodeId => {
    onNodeAdded(newNodeId)
    if (node.children.length === 1) {
      // We only want to show the highlight if RuleGroupLogic
      // is being shown for the first time
      setHasNewNode(true)
      setTimeout(() => setHasNewNode(false), 3000)
    }
  }

  return (
    <div className={cx(!root && 'my-2 border border-light-gray-300 bg-white')}>
      {!root && (
        <RuleGroupLabel
          credits={credits}
          isNew={node.id === newestNodeId}
          label={node.label}
          onEdit={label => {
            handleStructureChange(
              updateNodeInTree(value, path, {
                ...node,
                label
              })
            )
          }}
          onDelete={() => handleStructureChange(deleteNodeInTree(value, path))}
        />
      )}
      <div className={cx(!root && 'p-4')}>
        {node.children.length > 1 && (
          <RuleGroupLogic
            count={node.children.length}
            hasNewNode={hasNewNode}
            onChange={logic => {
              handleStructureChange(
                updateNodeInTree(value, path, {
                  ...node,
                  logic
                })
              )
            }}
          />
        )}
        <div className='mb-3'>
          {node.children.map((ruleOrGroupNode, index) => {
            const Component =
              ruleOrGroupNode?.type === 'ruleGroup' ? RuleGroupNode : RuleNode
            return (
              <Component
                key={index}
                node={ruleOrGroupNode}
                decisionTree={decisionTree}
                path={[...path, index]}
                handleStructureChange={handleStructureChange}
                handleDataChange={handleDataChange}
                data={data}
                context={context}
                value={value}
                idPrefix={`${idPrefix}-${ruleOrGroupNode.id}`}
                newestNodeId={newestNodeId}
                onNodeAdded={onNodeAdded}
              />
            )
          })}
        </div>
        <AddNodeButtons
          handleStructureChange={handleStructureChange}
          path={path}
          setShowRuleSearch={setShowRuleSearch}
          showRuleSearch={showRuleSearch}
          decisionTree={decisionTree}
          value={value}
          idPrefix={idPrefix}
          onNodeAdded={handleNodeAdded}
        />
      </div>
    </div>
  )
}

const RuleGroupLabel = ({ credits, isNew, label, onEdit, onDelete }) => {
  const [editLabel, setEditLabel] = React.useState(isNew)
  const inputRef = React.useRef(null)

  React.useEffect(() => {
    if (editLabel) inputRef.current.select()
  }, [editLabel])

  return (
    <div className='flex h-12 w-full items-center justify-between border-b border-light-gray-300 bg-light-gray-100 px-4 dark:bg-light-gray-300'>
      {!editLabel ? (
        <div className='group flex items-center font-medium text-medium-gray-500'>
          <button className='mr-1' onClick={() => setEditLabel(true)}>
            {label}
          </button>
          <button
            aria-label={i18n._('edit.rule.group', { label })}
            className='kp-button-icon flex rounded-sm p-1 font-medium text-medium-gray-500 opacity-0 transition-all hover:bg-blue-100 group-focus-within:opacity-100 group-hover:opacity-100'
            onClick={() => setEditLabel(true)}
          >
            <Icons.Edit />
          </button>
          <button
            aria-label={i18n._('delete.rule.group', { label })}
            className='kp-button-icon flex rounded-sm p-1 font-medium text-medium-gray-500 opacity-0 transition-all hover:bg-blue-100 group-focus-within:opacity-100 group-hover:opacity-100'
            onClick={onDelete}
          >
            <Icons.Delete />
          </button>
        </div>
      ) : (
        <Input
          ref={inputRef}
          value={label}
          onChange={onEdit}
          onBlur={() => setEditLabel(false)}
          onKeyDown={e => {
            // using keyDown here so it doesn't close immediately if you
            // opened it with the keyboard
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              setEditLabel(false)
            }
          }}
          onKeyUp={e => {
            // using keyUp so it doesn't bubble up to the modal page and close it
            if (e.key === 'Escape') {
              e.preventDefault()
              e.stopPropagation()
              setEditLabel(false)
            }
          }}
        />
      )}
      {credits > 0 && (
        <span className='font-medium text-medium-gray-500'>
          {credits} Credits
        </span>
      )}
    </div>
  )
}

const RuleGroupLogic = ({ count, hasNewNode, onChange }) => (
  <div
    className={cx(
      '-mx-2 -my-1 flex items-center gap-2 rounded-lg px-2 py-1 font-bold transition-colors',
      {
        'bg-yellow-100': hasNewNode
      }
    )}
  >
    <span>
      <Trans id='complete' />
    </span>
    <select
      aria-label={i18n._('rule.group.logic.select')}
      onChange={e => onChange(e.target.value)}
      className='cursor-pointer rounded-full bg-blue-100 p-1 pl-2 text-center'
    >
      <option value='all'>
        <Trans id='all' />
      </option>
      <option value='any'>
        <Trans id='any' />
      </option>
      {Array.from({ length: count }, (_, i) => (
        <option value={i + 1} key={i}>
          {i + 1}
        </option>
      ))}
    </select>
    <span>
      <Trans id='of.the.following' />
    </span>
  </div>
)

const RuleNode = ({
  node,
  decisionTree,
  path,
  handleStructureChange,
  handleDataChange,
  value,
  data,
  context,
  idPrefix,
  newestNodeId
}) => {
  const [showRuleSearch, setShowRuleSearch] = React.useState(false)
  const isNewest = node.id === newestNodeId

  // By setting the initial state in this way, we can automatically focus
  // the first input in the rule only when the rule is newly created. If
  // the rule is already visible on initial page load then this won't affect
  // focus.
  const [updated, setUpdated] = React.useState(isNewest)
  const ref = React.useRef()
  const formbot = useFormbot()

  React.useEffect(() => {
    if (updated) {
      focusFirstInput(ref)
      setTimeout(() => {
        setUpdated(false)
      }, [3000])
    }
  }, [updated])

  const gadgetGroups = createGadgetGroups(node, data)

  return (
    <div
      ref={ref}
      className={cx(
        'group relative my-3 flex flex-col gap-1 rounded-lg px-2 py-1 transition-all',
        updated && isNewest
          ? 'bg-yellow-100'
          : 'focus-within:bg-light-gray-100 hover:bg-light-gray-100 dark:focus-within:bg-light-gray-300 dark:hover:bg-light-gray-300'
      )}
    >
      {gadgetGroups.map((gadgetGroup, i) => (
        <GadgetGroup
          key={i}
          gadgetGroup={gadgetGroup}
          data={find(data, { id: node.id })?.data || []}
          ruleNodeId={node.id}
          formbot={formbot}
          context={context}
          handleDataChange={handleDataChange}
          path={path}
        />
      ))}

      <div className='absolute right-2 top-2 flex gap-1 opacity-0 transition-all group-focus-within:opacity-100 group-hover:opacity-100'>
        <button
          aria-label={i18n._('edit.rule')}
          onClick={() => setShowRuleSearch(true)}
          className='kp-button-icon flex rounded-sm p-1 font-medium text-medium-gray-500 transition-all hover:bg-blue-100'
        >
          <Icons.Edit />
        </button>
        <button
          aria-label={i18n._('delete.rule')}
          onClick={() => handleStructureChange(deleteNodeInTree(value, path))}
          className='kp-button-icon flex rounded-sm p-1 font-medium text-medium-gray-500 transition-all hover:bg-blue-100'
        >
          <Icons.Delete />
        </button>
      </div>

      {showRuleSearch && (
        <RuleSearch
          decisionTree={decisionTree}
          handleValueChange={newValue => {
            handleStructureChange(updateRuleValueInTree(value, path, newValue))
            setUpdated(true)
            setShowRuleSearch(false)
          }}
          hide={() => setShowRuleSearch(false)}
          idPrefix={idPrefix}
        />
      )}
    </div>
  )
}

const GadgetGroup = ({
  gadgetGroup,
  data,
  ruleNodeId,
  formbot,
  context,
  handleDataChange,
  path
}) => {
  if (gadgetGroup.values.length === 0) return null
  return (
    <div className='flex max-w-full flex-wrap justify-start gap-1 pr-12'>
      {gadgetGroup.type === 'multiSelectData' && gadgetGroup.values.length ? (
        <ul className='ml-4 flex flex-col gap-2'>
          {gadgetGroup.values.map(val => (
            <li
              key={val.id}
              className='flex w-min items-center justify-between whitespace-nowrap rounded-full bg-light-gray-200'
            >
              <span className='pl-4'>{val?.label}</span>
              <button
                aria-label={i18n._('remove.multiselect.item', {
                  label: val?.label
                })}
                className='kp-button-transparent kp-button ml-1'
                style={{ borderRadius: '4px 16px 16px 4px' }}
                onClick={() => {
                  const oldData = data[gadgetGroup.formKey] || []
                  const newData = reject(oldData, { id: val.id })
                  handleDataChange(
                    gadgetGroup.formKey,
                    gadgetGroup.ruleNodeId,
                    newData,
                    path
                  )
                }}
              >
                <Icons.Close width='8px' height='8px' />
              </button>
            </li>
          ))}
        </ul>
      ) : gadgetGroup.type === 'inline' ? (
        gadgetGroup.values.map((gadget, j, allGadgets) => {
          const GadgetManifest = formbot.getGadget(gadget.type)
          const details = {
            ...gadget.details,
            placeholder: { enabled: true, value: gadget.ruleText }
          }

          return (
            <div key={j} className='flex items-center whitespace-nowrap'>
              {gadget?.type === 'Spacer' ? (
                <GadgetGroupLabel nextGadgetId={allGadgets[j + 1]?.id}>
                  {gadget?.ruleText}
                </GadgetGroupLabel>
              ) : (
                <GadgetManifest.Edit
                  value={data?.[gadget.formKey]}
                  onChange={newValue =>
                    handleDataChange(gadget.formKey, ruleNodeId, newValue, path)
                  }
                  inline
                  id={gadget.id}
                  details={details}
                  context={context}
                  formKey={`data.${gadget.formKey}.${ruleNodeId}`}
                />
              )}
            </div>
          )
        })
      ) : null}
    </div>
  )
}

const GadgetGroupLabel = ({ children, nextGadgetId }) =>
  nextGadgetId ? <label htmlFor={nextGadgetId}>{children}</label> : children

const createGadgetGroups = (ruleNode, data) => {
  const ruleNodeData = find(data, { id: ruleNode.id }) || {}
  return ruleNode.value.value.reduce((acc, gadget, index, array) => {
    const isMultiSelect = gadget.type?.includes('Multiselect')
    const prevIsMultiSelect = array[index - 1]?.type?.includes('Multiselect')

    const currentType = isMultiSelect ? 'multiSelectData' : 'inline'

    if (isMultiSelect) {
      if (acc.length === 0 || prevIsMultiSelect) {
        acc.push({ type: currentType, values: [gadget] })
      } else {
        acc[acc.length - 1].values.push(gadget)
      }
      acc.push({
        type: currentType,
        formKey: gadget.formKey,
        ruleNodeId: ruleNode.id,
        values: ruleNodeData?.data?.[gadget.formKey] || []
      })
    } else {
      const isSpacer = gadget.type === 'Spacer'
      const prevIsSpacer = array[index - 1]?.type === 'Spacer'

      if (prevIsMultiSelect || acc.length === 0) {
        acc.push({ type: currentType, values: [gadget] })
      } else if (isSpacer && prevIsSpacer) {
        // need to create a new object because the original gadget object is frozen
        const lastValue = { ...last(acc).values.pop() }
        lastValue.ruleText += ` ${gadget.ruleText}`
        last(acc).values.push(lastValue)
      } else {
        acc[acc.length - 1].values.push(gadget)
      }
    }

    return acc
  }, [])
}

const AddNodeButtons = ({
  decisionTree,
  handleStructureChange,
  path,
  setShowRuleSearch,
  showRuleSearch,
  value,
  idPrefix,
  onNodeAdded
}) => (
  <>
    <button
      aria-label={i18n._('add.rule')}
      className='kp-button-outline mr-2'
      onClick={() => setShowRuleSearch(true)}
    >
      <Icons.Add className='mr-2' /> <Trans id='rule' />
    </button>
    <button
      aria-label={i18n._('add.rule.group')}
      className='kp-button-outline mr-2'
      onClick={() => {
        const newGroupId = addNode(
          handleStructureChange,
          value,
          'ruleGroup',
          path
        )
        onNodeAdded(newGroupId)
      }}
    >
      <Icons.Add className='mr-2' /> <Trans id='rule.group' />
    </button>
    {showRuleSearch && (
      <RuleSearch
        decisionTree={decisionTree}
        handleValueChange={newValue => {
          const newRuleId = addNode(
            handleStructureChange,
            value,
            'rule',
            path,
            newValue
          )
          onNodeAdded(newRuleId)
          setShowRuleSearch(false)
        }}
        hide={() => setShowRuleSearch(false)}
        idPrefix={idPrefix}
      />
    )}
  </>
)

function sumCredits (ruleNode, dataStructure) {
  let totalCredits = 0

  function traverse (node) {
    if (get(node, 'type') === 'rule' && has(node, 'value.value')) {
      forEach(node.value.value, item => {
        const isMultiselect = includes(toLower(item.type), 'multiselect')
        const isTypeahead = includes(toLower(item.type), 'typeahead')
        const gadgetData = get(dataStructure, [item.formKey, node.id])

        if (isMultiselect && isArray(gadgetData)) {
          forEach(gadgetData, doc => {
            totalCredits += get(doc, 'data.kuali_credits.credits', 0)
          })
        }

        if (isTypeahead && !isArray(gadgetData)) {
          totalCredits += get(gadgetData, 'data.kuali_credits.credits', 0)
        }
      })
    }

    forEach(node.children, traverse)
  }

  traverse(ruleNode)

  return totalCredits
}

const updateTree = (root, path, updateFn) => {
  if (path.length === 0) return updateFn(root, [])

  const validateChildren = node => {
    if (!node.children || !Array.isArray(node.children)) {
      throw new Error("Invalid node structure: missing or invalid 'children'.")
    }
  }

  validateChildren(root)

  const [currentIndex, ...restOfPath] = path
  const updatedChildren = [...root.children]

  const result = updateTree(root.children[currentIndex], restOfPath, updateFn)
  if (result === null) {
    updatedChildren.splice(currentIndex, 1)
  } else {
    updatedChildren[currentIndex] = result
  }

  return { ...root, children: updatedChildren }
}

const addNodeToTree = (root, path, newValue) =>
  updateTree(root, path, node => ({
    ...node,
    children: [...(node.children || []), newValue]
  }))

const updateNodeInTree = (root, path, newValue) =>
  updateTree(root, path, () => newValue)

const deleteNodeInTree = (root, path) =>
  updateTree(root, path, (node, updatedPath) =>
    updatedPath.length === 0 ? null : node
  )

const updateRuleValueInTree = (root, path, newValue) =>
  updateTree(root, path, node => ({ ...node, value: newValue }))

function addNode (setRuleTree, currentValue, type, path, value) {
  const id = shortid.generate()
  const newNode = {
    type,
    id,
    ...(type === 'rule' && { value })
  }

  if (type === 'ruleGroup') {
    Object.assign(newNode, {
      logic: 'all',
      children: [],
      label: i18n._('new.rule.group')
    })
  }
  setRuleTree(
    path
      ? addNodeToTree(currentValue, path, newNode)
      : { ...currentValue, children: [...currentValue.children, newNode] }
  )
  return id
}

function getRuleGroups (path, tree) {
  const ruleGroups = []
  path.reduce((acc, index) => {
    if (acc.type === 'ruleGroup') {
      ruleGroups.push(acc.label)
    }
    return acc.children[index]
  }, tree)
  return ruleGroups
}
