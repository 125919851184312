/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { useLocation, useSearchParams } from 'react-router'

import config from '../config'
import * as Icons from '../icons'
import { useAlerts } from '../ui/alerts'
import * as featureFlagExports from './feature-flags'
import CenterModal from './modal-centered'

const { flags, resolveState, ...ff } = featureFlagExports

export default function FeedbackModal () {
  const alerts = useAlerts()
  const location = useLocation()
  const [screenshot, setScreenshot] = React.useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const dismiss = () => setSearchParams(prev => prev.delete('center-modal'))
  const showing = searchParams.get('center-modal') === 'feedback'
  const overrides = window.localStorage.getItem('feature-flags')
  return (
    <CenterModal
      dismiss={dismiss}
      showing={showing}
      id='feedback-modal'
      className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-5xl sm:p-6'
    >
      <div className='sm:flex sm:items-start'>
        <div className='mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left'>
          <div className='flex items-center gap-4'>
            <h3
              className='text-base font-semibold leading-6 text-dark-gray-500'
              id='modal-title'
            >
              <Trans id='feedback' />
            </h3>
            <div className='flex-1' />
            <button
              className='kp-button-transparent kp-button-icon gap-2 text-medium-gray-500'
              onClick={dismiss}
            >
              <span className='sr-only'>
                <Trans id='close' />
              </span>
              <span aria-hidden className='text-medium-gray-100'>
                (esc)
              </span>
              <Icons.Close />
            </button>
          </div>

          <form
            onSubmit={e => {
              e.preventDefault()
              const form = e.target
              const body = new FormData(form)
              fetch('https://platform-feedback.kualibuild.ninja', {
                method: 'POST',
                body
              })
                .then(res => {
                  if (res.status !== 200) throw new Error('bad status')
                })
                .then(() => {
                  dismiss()
                  alerts.type3(i18n._('thanks.for.feedback'), 'success')
                  form.reset()
                  setScreenshot(null)
                })
                .catch(() => {
                  console.log('OH NO')
                  alerts.type3(i18n._('feedback.service.unavailable'), 'error')
                })
            }}
          >
            <div className='space-y-12 sm:space-y-16'>
              <div>
                <p className='mt-1 max-w-2xl text-sm leading-6 text-dark-gray-200'>
                  <Trans id='information.sent.directly.platform.team' />
                </p>

                <div className='sm:divide-dark-gray-500/10 mt-10 space-y-8 border-b border-dark-gray-500 pb-12 sm:space-y-0 sm:divide-y sm:border-t sm:pb-0'>
                  <fieldset>
                    <legend className='sr-only'>
                      <Trans id='type.of.feedback' />
                    </legend>
                    <div className='sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6'>
                      <div
                        className='text-sm font-medium leading-6 text-dark-gray-500'
                        aria-hidden='true'
                      >
                        <Trans id='type.of.feedback' />
                      </div>
                      <div className='mt-1 sm:col-span-2 sm:mt-0'>
                        <div className='max-w-lg'>
                          <div className='space-y-3'>
                            <div className='flex items-center gap-x-3'>
                              <input
                                id='bug'
                                value='bug'
                                name='feedback-type'
                                type='radio'
                                required
                                className='h-4 w-4 border-light-gray-300 text-wintergreen-400 focus:ring-wintergreen-400'
                              />
                              <label
                                htmlFor='bug'
                                className='block text-sm font-medium leading-6 text-dark-gray-500'
                              >
                                <Trans id='bug' />
                              </label>
                            </div>
                            <div className='flex items-center gap-x-3'>
                              <input
                                id='feature'
                                value='feature'
                                name='feedback-type'
                                type='radio'
                                required
                                className='h-4 w-4 border-light-gray-300 text-wintergreen-400 focus:ring-wintergreen-400'
                              />
                              <label
                                htmlFor='feature'
                                className='block text-sm font-medium leading-6 text-dark-gray-500'
                              >
                                <Trans id='feature.enhancement' />
                              </label>
                            </div>
                            <div className='flex items-center gap-x-3'>
                              <input
                                id='question'
                                value='question'
                                name='feedback-type'
                                type='radio'
                                required
                                className='h-4 w-4 border-light-gray-300 text-wintergreen-400 focus:ring-wintergreen-400'
                              />
                              <label
                                htmlFor='question'
                                className='block text-sm font-medium leading-6 text-dark-gray-500'
                              >
                                <Trans id='question.comment' />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6'>
                    <label
                      htmlFor='details'
                      className='block text-sm font-medium leading-6 text-dark-gray-500 sm:pt-1.5'
                    >
                      <Trans id='details' />
                    </label>
                    <div className='mt-2 sm:col-span-2 sm:mt-0'>
                      <textarea
                        id='details'
                        name='details'
                        required
                        rows='10'
                        className='kp-textarea h-40 w-full resize-y'
                      />
                    </div>
                  </div>

                  <div className='sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6'>
                    <label
                      htmlFor='photo'
                      className='block text-sm font-medium leading-6 text-dark-gray-500'
                    >
                      <Trans id='screenshot' />
                    </label>
                    <div className='mt-2 sm:col-span-2 sm:mt-0'>
                      <div className='flex items-center gap-x-3'>
                        {screenshot ? (
                          <div>
                            <img src={screenshot} />
                            <input
                              type='hidden'
                              name='screenshot'
                              value={screenshot}
                            />
                            <button
                              onClick={() => setScreenshot(null)}
                              className='kp-button-outline mt-4 gap-2'
                              type='button'
                            >
                              <Icons.Close />
                              <Trans id='remove.screenshot' />
                            </button>
                          </div>
                        ) : (
                          <button
                            type='button'
                            className='kp-button-solid'
                            onClick={() =>
                              captureScreenshot().then(img =>
                                setScreenshot(img)
                              )
                            }
                          >
                            <Trans id='take.screenshot' />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <Field
                    id='version'
                    label='Platform UI Version'
                    value={config.apollo.version}
                  />

                  <Field
                    id='url'
                    label='URL'
                    value={
                      window.location.protocol +
                      '//' +
                      window.location.host +
                      location.pathname
                    }
                  />

                  <Field
                    id='useragent'
                    label='User Agent'
                    value={window.navigator.userAgent}
                  />

                  <Field
                    id='user'
                    label='User'
                    value={JSON.stringify({
                      id: window.loggedInUser?.id,
                      name: window.loggedInUser?.displayName
                    })}
                  />

                  <Field
                    id='flags'
                    label='Feature Flags'
                    value={JSON.stringify(ff)}
                  />

                  <Field
                    id='overrides'
                    label='Feature Flag Overrides'
                    value={overrides}
                  />
                </div>
              </div>
            </div>

            <div className='mt-6 flex items-center justify-end gap-x-6'>
              <button
                type='button'
                className='kp-button-outline'
                onClick={dismiss}
              >
                <Trans id='cancel' />
              </button>
              <button type='submit' className='kp-button-solid'>
                <Trans id='submit' />
              </button>
            </div>
          </form>
        </div>
      </div>
    </CenterModal>
  )
}

function Field ({ id, label, value }) {
  return (
    <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6'>
      <label
        htmlFor={id}
        className='block text-sm font-medium leading-6 text-dark-gray-500 sm:pt-1.5'
      >
        {label}
      </label>
      <div className='mt-2 sm:col-span-2 sm:mt-0'>
        <input
          type='text'
          name={id}
          id={id}
          readOnly
          value={value}
          className='kp-input w-full cursor-not-allowed bg-light-gray-200'
        />
      </div>
    </div>
  )
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

async function captureScreenshot () {
  const stream = await window.navigator.mediaDevices.getDisplayMedia({
    preferCurrentTab: true
  })
  const modal = document.getElementById('feedback-modal')
  modal.style.display = 'none'
  const video = document.createElement('video')
  video.autoplay = true
  video.srcObject = stream
  await sleep(300)
  const canvas = document.createElement('canvas')
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
  const context = canvas.getContext('2d')
  context.drawImage(video, 0, 0, window.innerWidth, window.innerHeight)
  const frame = canvas.toDataURL('image/png')
  stream.getTracks().forEach(track => track.stop())
  modal.style.display = ''
  return frame
}
