/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useNavigate, useParams } from 'react-router'

import Loading from '../../components/loading'
import { GraphQLError as Error } from '../../components/system-error'
import { useInitWorkflowMutation } from './components/mutation.init-workflow'

export default function Run () {
  const navigate = useNavigate()
  const { appId, pageId } = useParams()
  const [initWorkflow, error] = useInitWorkflowMutation()
  React.useEffect(() => {
    initWorkflow(
      appId,
      pageId,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    )
      .then(resp => {
        const { actionId } = resp.data.initializeWorkflow
        navigate(`/my/drafts/${actionId}`)
      })
      .catch(() => null)
  }, [initWorkflow, appId, navigate])
  return error ? <Error error={error} /> : <Loading />
}
