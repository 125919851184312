/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isString, unescape } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import { Attachment } from '../../../icons'

export default function SimulatedEmail ({
  branding,
  hideFooter,
  noBodyStyles,
  toEmail,
  subject,
  body,
  exportOptions
}) {
  const from = useEmailFrom()
  return (
    <div className='h-full min-h-[340px] overflow-auto bg-white p-4'>
      <HeaderRow>
        <strong>
          <Trans id='from.colon' />
        </strong>
        {from}
      </HeaderRow>
      <HeaderRow>
        <strong>
          <Trans id='to.colon' />
        </strong>
        {toEmail}
      </HeaderRow>
      <HeaderRow>
        <strong>
          <Trans id='subject.colon' />
        </strong>
        {unescape(subject)}{' '}
        {exportOptions?.length > 0 && (
          <>
            <Tooltip className='w-[300px]' id='attachmentInfo' place='top'>
              <div>
                <Trans id='email.has.exported.document.attached' />
              </div>
            </Tooltip>
            <TooltipTrigger
              as={Attachment}
              label={i18n._('attachment')}
              tooltipId='attachmentInfo'
            />
          </>
        )}
      </HeaderRow>
      {branding && (
        <img
          style={{ marginTop: '15px' }}
          alt={branding.alt}
          height={branding.height}
          width={branding.width}
          src={branding.emailLogo || branding.logo}
        />
      )}
      {isString(body) && (
        <EmailBody
          className={noBodyStyles ? '' : 'prose prose-sm prose-ol:list-inside'}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
      {!isString(body) && <EmailBody>{body}</EmailBody>}
      {!hideFooter && (
        <EmailFooter>
          <p className='mb-3'>
            <Trans id='email.automatically.generated' />
          </p>
        </EmailFooter>
      )}
    </div>
  )
}

const emailFromSettingsQuery = gql`
  query EmailSettingsQuery {
    tenant {
      id
      features {
        emailSettings {
          sendFrom
          enabled
        }
      }
    }
  }
`

function HeaderRow ({ children }) {
  return (
    <div className='flex gap-2 border-b border-light-gray-300 p-4 text-sm'>
      {children}
    </div>
  )
}

function useEmailFrom (defaultFrom = 'no-reply@mail.kualibuild.com') {
  const { data, loading } = useQuery(emailFromSettingsQuery)
  const { enabled, sendFrom } = data?.tenant?.features?.emailSettings ?? {}
  if (loading) return ''
  return enabled ? sendFrom : defaultFrom
}

const EmailBody = styled.div`
  p {
    font-size: 14px;
    font-family: Helvetica;
    line-height: 28px;
    color: #444444;
    margin-left: 10px;
  }
  margin: 40px 0;
`

const EmailFooter = styled.div`
  p {
    color: #555555;
    font-size: 14px;
  }
`
