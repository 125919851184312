/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import FocusTrap from 'focus-trap-react'
import { flatMap, get, includes, map, reject, startsWith } from 'lodash'
import React from 'react'
import { useLocation } from 'react-router'

import ErrorBoundary from '../../components/error-boundary'
import {
  multipleLanguages,
  productBuilder
} from '../../components/feature-flags'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { formbot } from '../../formbot'
import { utils as fb } from '../../formbot/engine/formbot'
import StaticFormbot from '../../formbot/static'
import * as Icons from '../../icons'
import { utils } from '../../voronoi-dnd-formbot'
import ConditionalVisibility from './config-conditional-visibility'

const FormConfig = React.forwardRef(function FormConfig (props, ref) {
  return (
    <FocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        setReturnFocus: () =>
          document.querySelector(
            `[data-whatinput="keyboard"] #gadget-wrapper-${props.value?.id}`
          ) || false
      }}
    >
      <FormConfigInner {...props} ref={ref} />
    </FocusTrap>
  )
})

export default FormConfig

const FormConfigInner = React.forwardRef(function FormConfigInner (
  {
    isProduct,
    gadgetIndexType,
    selectedLinks,
    value,
    update,
    removeGadget,
    duplicateGadget,
    structure,
    errors,
    gadgets,
    beginDrag,
    beginA11yDrag,
    endDrag
  },
  ref
) {
  const location = useLocation()
  const isSettings = location.pathname.endsWith('/setting')
  const isConfiguringProductKey =
    productBuilder && isProduct && !isSettings && !gadgetIndexType
  const productGadget =
    !productBuilder && isProduct && gadgetIndexType?.canonicalGadget
  const gadgetDefinition = get(formbot, `context.gadgets.${value.type}`)
  const OptionalConfig = get(gadgetDefinition, 'OptionalConfig')
  const RequiredConfig = get(gadgetDefinition, 'RequiredConfig')
  const validationOptions = get(gadgetDefinition, 'validationOptions')
  const settingsHelp = get(gadgetDefinition, 'meta.settingsHelp')
  const deprecatedValidationOptions = get(
    gadgetDefinition,
    'deprecatedValidationOptions'
  )

  const allGadgets = React.useMemo(() => {
    const gadgetInstances = fb.structureToSchema(structure, formbot)
    return reject(gadgetInstances, gadget =>
      startsWith(gadget.formKey, `data.${value.formKey}`)
    )
  }, [structure, value.formKey])

  const parent = React.useMemo(() => {
    const repeatableChildrenMap = utils.getRepeatableChildrenMap(
      structure.template
    )
    return utils.findParent(repeatableChildrenMap, value.id)
  }, [structure, value.id])

  const siblingGadgets = React.useMemo(() => {
    if (!includes(['Repeater', 'Table'], parent?.type)) return []
    const gadgets = parent.children
      .filter(g => g.formKey && g.formKey !== value.formKey)
      .map(g => ({
        ...g,
        formKey:
          parent.type === 'Repeater'
            ? `data.${parent.formKey}.data.*.data.${g.formKey}`
            : `data.${parent.formKey}.*.${g.formKey}`
      }))
    return fb.gatherAllSubGadgets(gadgets, formbot)
  }, [parent, value.formKey])

  const conditionals = React.useMemo(() => {
    return flatMap([...allGadgets, ...siblingGadgets], (gadget, index) => {
      const { label, formKey, details, type } = gadget
      const definition = formbot.getGadget(type)
      if (!definition || !definition.progressiveDisclosure) return []
      const { component } = definition.progressiveDisclosure
      const customName =
        get(gadget, 'customName.enabled') && get(gadget, 'customName.value')
      return { formKey, label: customName || label, component, details, type }
    })
  }, [allGadgets, siblingGadgets])

  const isInTable = parent?.type === 'Table'
  const showRequired =
    isDataGadget(value) && !includes(['Repeater', 'Table'], value.type)
  const canDuplicate = !['Repeater', 'Section', 'Table'].includes(value.type)

  if (value.type === 'Spacer') {
    return (
      <StaticFormbot value={value} update={update}>
        {({ ConfigBox, RichText, Custom }) => (
          <div className='kp-form-config relative z-[15]' ref={ref}>
            <div className='flex items-center pb-4 pl-4 pt-6 text-sm uppercase text-dark-gray-400'>
              <Trans id='pagesbuilder.form.config.read.only' />
            </div>

            <div className='px-4'>
              <RichText
                configKey='description.value'
                label={i18n._('pagesbuilder.form.config.read.only.text')}
              />
            </div>
            <ConfigBox
              topBorder
              configKey='conditionalVisibility.enabled'
              label={i18n._('pagesbuilder.form.config.limit.visibility')}
            >
              <Custom configKey='conditionalVisibility.value' defaultValue={{}}>
                {({ onChange, value }) => (
                  <ConditionalVisibility
                    gadgets={conditionals}
                    onChange={onChange}
                    value={value}
                    context={{}}
                  />
                )}
              </Custom>
            </ConfigBox>
            <div className='m-4 flex justify-between'>
              <button
                className='kp-button-outline gap-2'
                onClick={removeGadget}
              >
                <Icons.Remove />
                <Trans id='pagesbuilder.form.config.remove.gadget' />
              </button>
              <button
                className='kp-button-outline gap-2'
                onClick={() => duplicateGadget(isInTable)}
              >
                <Icons.Duplicate />
                <Trans id='duplicate' message='Duplicate' />
              </button>
            </div>
          </div>
        )}
      </StaticFormbot>
    )
  }
  return (
    <StaticFormbot value={value} update={update}>
      {({ ConfigBox, Checkbox, Text, Textarea, RichText, Custom }) => (
        <div className='kp-form-config relative z-[15]' ref={ref}>
          {productGadget ? (
            <div className='flex items-center gap-2 pb-4 pl-4 pt-6'>
              <div className='text-sm uppercase text-dark-gray-400'>
                {productGadget.label}{' '}
                <Trans id='pagesbuilder.form.config.settings' />
              </div>
              <GadgetTooltip
                id={value.id}
                name={productGadget.label}
                description={productGadget.canonicalDescription}
                settingsHelp={settingsHelp}
                link={productGadget.canonicalLink}
              />
            </div>
          ) : (
            <div className='flex items-center pb-4 pl-4 pt-6 text-sm uppercase text-dark-gray-400'>
              {gadgetDefinition.meta?.label} Settings
              {settingsHelp && (
                <TooltipTrigger
                  as={Icons.AlertHelp}
                  ml={2}
                  mb={1}
                  tooltipId={`settings-help-for-gadget-${value?.id}`}
                />
              )}
            </div>
          )}
          <Tooltip id={`settings-help-for-gadget-${value?.id}`} place='bottom'>
            <div className='w-64'>{settingsHelp}</div>
          </Tooltip>
          {isLabelledGadget(value) && (
            <div className='px-4'>
              <Textarea
                unPadded
                configKey='label'
                label={
                  value.type === 'Validation'
                    ? i18n._({
                        message: 'Error Text',
                        id: 'pagesbuilder.form.config.error.text'
                      })
                    : i18n._('pagesbuilder.form.config.gadget.question')
                }
                autoFocus
                onFocus={event => event.target.select()}
              />
              {isDataGadget(value) && !isConfiguringProductKey && (
                <div className='pt-1 text-xs text-medium-gray-500'>
                  <Trans id='pagesbuilder.form.config.fieldid' />{' '}
                  {value?.formKey}
                </div>
              )}
            </div>
          )}
          {isDataGadget(value) && isConfiguringProductKey && (
            <div className='px-4 pt-4'>
              <Custom configKey='customFormKey' defaultValue={{}}>
                {({ onChange, value }) => (
                  <KualiIdInput onChange={onChange} value={value} />
                )}
              </Custom>
            </div>
          )}
          <div className='h-4' />
          {RequiredConfig && (
            <div className='px-4'>
              <Custom
                configKey={value.type !== 'Validation' && 'details'}
                defaultValue={{}}
                withGadgets
              >
                {({ onChange, value: value2, Gadgets }) => (
                  <ErrorBoundary>
                    <RequiredConfig
                      indexed={!!gadgetIndexType}
                      indexType={gadgetIndexType}
                      selectedLinks={selectedLinks}
                      context={{}}
                      allGadgets={allGadgets}
                      siblingGadgets={siblingGadgets}
                      id={value.id}
                      formKey={value.formKey}
                      value={value2}
                      onChange={onChange}
                      Gadgets={Gadgets}
                      childrenTemplate={value.childrenTemplate}
                      updateDataLookupSource={patch => {
                        update({ ...value, ...patch }, true)
                      }}
                      conditionals={conditionals}
                    />
                  </ErrorBoundary>
                )}
              </Custom>
            </div>
          )}
          {isDataGadget(value) &&
            productBuilder &&
            isProduct &&
            !isSettings && (
              <div className='border-t border-light-gray-300 px-4 py-4'>
                <div className='flex items-center gap-2 pb-4'>
                  <label className='text-sm font-medium'>
                    <Trans id='pagesbuilder.form.config.how.to' />
                  </label>
                  <TooltipTrigger
                    as={Icons.AlertHelp}
                    label={i18n._('pagesbuilder.form.config.how.to')}
                    tooltipId='how-to-use'
                  />
                  <Tooltip
                    id='how-to-use'
                    place='left'
                    delayHide={200}
                    clickable
                  >
                    <div className='w-52'>
                      <Trans id='pagesbuilder.form.config.contents' />
                    </div>
                  </Tooltip>
                </div>
                <Textarea
                  configKey='canonicalDescription'
                  label={i18n._('pagesbuilder.form.config.description')}
                  placeholder={{
                    enabled: true,
                    value: `${i18n._('pagesbuilder.form.config.admins')}`
                  }}
                  limit={320}
                />
                <Text
                  configKey='canonicalLink'
                  label={i18n._('pagesbuilder.form.config.link.documentation')}
                  placeholder={{
                    enabled: true,
                    value: 'https://kuali.zendesk.com/article_name'
                  }}
                />
                <label className='block text-sm font-medium text-dark-gray-300'>
                  <Trans id='pagesbuilder.form.config.preview' />
                </label>
                <div className='flex items-center gap-2 rounded bg-light-gray-100 p-2'>
                  <div className='text-sm uppercase text-dark-gray-400'>
                    {value.label}
                    <Trans id='pagesbuilder.form.config.settings' />
                  </div>
                  <GadgetTooltip
                    id={value.id}
                    name={value.label}
                    description={value.canonicalDescription}
                    settingsHelp={settingsHelp}
                    link={value.canonicalLink}
                  />
                </div>
              </div>
            )}
          {!isUnsetDataGadget(value) && (
            <>
              {value.type === 'Section' && (
                <ConfigBox
                  configKey='hideLabel'
                  label={i18n._('pagesbuilder.form.config.hide.label')}
                  topBorder
                />
              )}
              {showRequired && (
                <ConfigBox
                  configKey='required'
                  label={i18n._('pagesbuilder.form.config.required')}
                  topBorder
                />
              )}
              {isLabelledGadget(value) && (
                <ConfigBox
                  configKey='description.enabled'
                  label={i18n._('pagesbuilder.form.config.help.text')}
                  description={i18n._(
                    'pagesbuilder.form.config.help.text.description'
                  )}
                  topBorder={value.type !== 'Section' && !showRequired}
                >
                  <RichText configKey='description.value' />
                  {!isInTable && (
                    <Checkbox
                      configKey='description.displayAsPopover'
                      checkLabel={i18n._(
                        'pagesbuilder.form.config.help.bubble'
                      )}
                    />
                  )}
                </ConfigBox>
              )}
              {OptionalConfig && (
                <Custom
                  configKey='details'
                  defaultValue={{}}
                  withGadgets
                  unPadded
                >
                  {({ onChange, value: value2, Gadgets }) => (
                    <ErrorBoundary>
                      <OptionalConfig
                        context={{ parent }}
                        selectedLinks={selectedLinks}
                        allGadgets={allGadgets}
                        siblingGadgets={siblingGadgets}
                        id={value.id}
                        value={value2}
                        onChange={onChange}
                        Gadgets={Gadgets}
                        gadgets={gadgets}
                        beginDrag={beginDrag}
                        beginA11yDrag={beginA11yDrag}
                        endDrag={endDrag}
                        formKey={value.formKey}
                        childrenTemplate={value.childrenTemplate}
                      />
                    </ErrorBoundary>
                  )}
                </Custom>
              )}
              {value.type !== 'Validation' && (
                <ConfigBox
                  configKey='conditionalVisibility.enabled'
                  label={i18n._('pagesbuilder.form.config.limit.visibility')}
                >
                  <Custom
                    configKey='conditionalVisibility.value'
                    defaultValue={{}}
                  >
                    {({ onChange, value }) => (
                      <ConditionalVisibility
                        gadgets={conditionals}
                        onChange={onChange}
                        value={value}
                        context={{}}
                      />
                    )}
                  </Custom>
                </ConfigBox>
              )}
              {value.type === 'Section' &&
                parent?.type !== 'Repeater' &&
                !isSettings && (
                  <ConfigBox
                    configKey='customName.enabled'
                    label={i18n._('pagesbuilder.form.config.custom.name')}
                    description={i18n._(
                      'pagesbuilder.form.config.custom.name.description'
                    )}
                  >
                    <Text configKey='customName.value' />
                  </ConfigBox>
                )}
              <>
                {map(
                  validationOptions,
                  ({ key, label, UI, description }, i) => (
                    <ConfigBox
                      key={key}
                      configKey={`validations.${key}.enabled`}
                      label={label}
                      description={description}
                    >
                      <Custom
                        configKey={`validations.${key}.value`}
                        defaultValue={{}}
                        withGadgets
                      >
                        {multipleLanguages
                          ? ({ Gadgets }) => (
                              <UI Gadgets={Gadgets} details={value?.details} />
                            )
                          : ({ Gadgets }) => <UI Gadgets={Gadgets} />}
                      </Custom>
                    </ConfigBox>
                  )
                )}
                {map(
                  deprecatedValidationOptions,
                  ({ message, value: { key, label, UI, description } }, i) => (
                    <ConfigBox
                      key={key}
                      configKey={`validations.${key}.enabled`}
                      label={label}
                      description={description}
                      deprecated={message}
                    >
                      <Custom
                        configKey={`validations.${key}.value`}
                        defaultValue={{}}
                        withGadgets
                      >
                        {({ Gadgets }) => <UI Gadgets={Gadgets} />}
                      </Custom>
                    </ConfigBox>
                  )
                )}
              </>
              {isDataGadget(value) && !isSettings && (
                <ConfigBox
                  configKey='customName.enabled'
                  label={i18n._('pagsbuilder.form.config.custom.column')}
                  description={i18n._(
                    'pagsbuilder.form.config.custom.column.description'
                  )}
                >
                  <Text configKey='customName.value' />
                </ConfigBox>
              )}
              {isDataGadget(value) && !isConfiguringProductKey && (
                <ConfigBox
                  configKey='customFormKey.enabled'
                  label={i18n._('pagsbuilder.form.config.edit.json')}
                  description={
                    <span>
                      <Trans id='pagesbuilder.form.config.advanced.option' />{' '}
                      <div className='text-red-[#e9969b]'>
                        <Trans id='pagesbuilder.form.config.advanced.option.consequences' />
                      </div>
                    </span>
                  }
                >
                  <Text
                    configKey='customFormKey.value'
                    error={errors?.customFormKey}
                  />
                </ConfigBox>
              )}
            </>
          )}
          <div className='m-4 flex justify-between'>
            <button className='kp-button-outline gap-2' onClick={removeGadget}>
              <Icons.Remove />
              <Trans id='pagesbuilder.form.config.remove.gadget' />
            </button>
            {canDuplicate && (
              <button
                className='kp-button-outline gap-2'
                onClick={() => duplicateGadget(isInTable)}
              >
                <Icons.Duplicate />
                <Trans id='duplicate' message='Duplicate' />
              </button>
            )}
          </div>
        </div>
      )}
    </StaticFormbot>
  )
})

const isUnsetDataGadget = value =>
  ['DataLookup', 'DataMultiselect', 'DataFill'].includes(value.type)

const isDataGadget = value =>
  ![
    'Section',
    'DataLink',
    'CreatedBy',
    'ReadOnlyData',
    'Spacer',
    'Validation',
    'StaticImage'
  ].includes(value.type)
const isLabelledGadget = value =>
  !['Spacer', 'StaticImage'].includes(value.type)

function KualiIdInput ({ onChange, value }) {
  return (
    <div>
      <label className='block text-sm font-medium leading-6 text-dark-gray-300'>
        <Trans id='pagesbuilder.form.config.kuali' />
        <span className='text-red-400'>*</span>
      </label>
      <div className='relative'>
        <span className='absolute left-2 flex h-full items-center text-medium-gray-400'>
          kuali_
        </span>
        <input
          className='kp-input w-full pl-[42px]'
          value={value.value ?? ''}
          onChange={e =>
            onChange({
              enabled: true,
              value: e.target.value
                .replace(/ /g, '_')
                .replace(/[^a-zA-Z0-9_]/g, '')
            })
          }
        />
      </div>
    </div>
  )
}

function GadgetTooltip ({ id, name, description, link, settingsHelp }) {
  if (!description && !link && !settingsHelp) return null
  return (
    <>
      <TooltipTrigger
        as={Icons.AlertHelp}
        label={i18n._({
          id: 'pagesbuilder.form.config.how.name',
          values: { n: { name } }
        })}
        tooltipId={`how-to-use-${id}`}
      />
      <Tooltip id={`how-to-use-${id}`} place='bottom' delayHide={200} clickable>
        <div className='w-52'>
          <div>{description}</div>
          {!!link && (
            <a
              href={link}
              target='_blank'
              rel='noopener noreferrer'
              className='text-blue-200 underline'
            >
              <Trans id='pagesbuilder.form.config.link.documentation' />
            </a>
          )}
          {!!settingsHelp && (
            <div className={cx({ 'pt-4': description || link })}>
              {settingsHelp}
            </div>
          )}
        </div>
      </Tooltip>
    </>
  )
}
