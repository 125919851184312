/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import React from 'react'

import { useTenantFeaturesContext } from '../../../components/tenant-features-context'
import { ReactComponent as Illustration } from '../data-lookup/illustration-integration.svg.jsx'
import AssociationsChooser, {
  ASSOCIATIONS_OPTIONS
} from './associations-chooser'
import VersionConfig from './version-config'

export function RequiredConfig ({ value, ...props }) {
  const hasChosenDataset = value?.source?.dataset?.id

  return hasChosenDataset ? (
    <RequiredConfigWithDataset {...props} value={value} />
  ) : (
    <RequiredConfigWithoutDataset {...props} />
  )
}

export function RequiredConfigWithoutDataset ({
  Gadgets,
  updateDataLookupSource
}) {
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <AssociationsChooser
        onSelect={updateDataLookupSource}
        options={ASSOCIATIONS_OPTIONS}
      />
      <div className='mt-9 flex items-center justify-center'>
        <div className='flex h-24 w-36 items-center justify-center rounded-xl bg-light-gray-100 px-5 text-center text-base text-dark-gray-200 dark:bg-light-gray-300'>
          <Trans id='choose.source.to.begin' />
        </div>
        <Illustration />
      </div>
    </Gadgets.Padded>
  )
}

export function RequiredConfigWithDataset ({
  Gadgets,
  onChange,
  value,
  updateDataLookupSource,
  formKey
}) {
  const { data } = useQuery(datasetQuery, {
    variables: {
      appId: value?.source?.product?.id || value?.source.dataset?.id,
      datasetId: value?.source?.product?.id ? value?.source.dataset?.id : null
    }
  })
  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false

  const showVersionConfig = !!(
    tenantVersionSettings &&
    (value?.source?.dataset?.details?.allowNewVersions ||
      data?.app?.dataset?.allowNewVersions) &&
    value?.source?.gadget
  )

  const combinedLabel = value?.source?.gadget
    ? `${value?.source?.dataset?.label} - ${value?.source?.gadget?.label}`
    : value?.source?.dataset?.label
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <AssociationsChooser
        value={value}
        onSelect={updateDataLookupSource}
        id={value?.source?.dataset?.id}
        label={combinedLabel}
        tileOptions={value?.source?.dataset?.details?.tileOptions}
        options={ASSOCIATIONS_OPTIONS}
      />
      {showVersionConfig && (
        <VersionConfig id={formKey} Gadgets={Gadgets} className='mt-4' />
      )}
    </Gadgets.Padded>
  )
}

const datasetQuery = gql`
  query getDataset($appId: ID!, $datasetId: ID) {
    app(id: $appId) {
      id
      dataset(id: $datasetId) {
        id
        allowNewVersions
      }
    }
  }
`
