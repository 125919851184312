/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { Link } from 'react-router'

import { ModalPage } from '../../components/modal-page'
import * as routes from '../../components/routes'
import Spinner from '../../components/spinner'
import { useAlerts } from '../../ui/alerts'
import Button from '../../ui/button'
import Input from '../../ui/input'
import { useImportCMConfiguration } from './components/mutation.import-cm-configuration'

export default function Imports () {
  const importCMConfig = useImportCMConfiguration()
  const [value, setValue] = React.useState({ url: '', apiKey: '', name: '' })
  const [status, setStatus] = React.useState(null)
  const alerts = useAlerts()
  const disabled = status || !value.url || !value.apiKey || !value.name
  // TODO:: BUG: the modal's focus trap prevents you from dismissing type2 alerts
  return (
    <ModalPage title='Import Product Data'>
      <div className='mx-auto w-[350px] pt-12'>
        <h2 className='mb-3 text-2xl'>
          <Trans id='pages.imports.curriculum.management' />
        </h2>
        <label className='block text-sm font-medium text-dark-gray-300'>
          <Trans id='pages.imports.app.name' />
        </label>
        <Input
          className='mb-4 w-full'
          value={value.name}
          onChange={name => setValue(value => ({ ...value, name }))}
          placeholder='Monsters CM'
        />
        {/* Above. This placeholder is not being translated, for context, to maintain correlation to the URL placeholder that comes next, let me know if I need to translate this */}
        <label className='block text-sm font-medium text-dark-gray-300'>
          <Trans id='pages.imports.url' />
        </label>
        <Input
          className='mb-4 w-full'
          value={value.url}
          onChange={url => setValue(value => ({ ...value, url }))}
          placeholder='https://monsters.kuali.co'
        />
        <label className='block text-sm font-medium text-dark-gray-300'>
          <Trans id='pages.imports.api.key' />
        </label>
        <Input
          className='mb-4 w-full'
          value={value.apiKey}
          onChange={apiKey => setValue(value => ({ ...value, apiKey }))}
          placeholder='eyJhbGciOiJIUzI1NiIsIn...'
        />
        {/* Above. API Keys are standardized format, and this should be fine for a placeholder in all languages, let me know if it is not. */}
        {status?.id ? (
          <Button as={Link} to={routes.form(status.id, status.firstPageId)}>
            <Trans id='pages.imports.sucessfully.go' />
          </Button>
        ) : (
          <Button
            disabled={disabled}
            onClick={() => {
              if (disabled) return
              setStatus('loading')
              importCMConfig(value.url, value.apiKey, value.name)
                .then(resp => {
                  setStatus(resp.data.importCMConfiguration)
                  alerts.type3(
                    i18n._('pages.imports.sucessfully.imported'),
                    'success'
                  )
                })
                .catch(error => {
                  setStatus(null)
                  console.error(error)
                  alerts.type2(error?.graphQLErrors?.[0]?.message, 'error')
                })
            }}
          >
            <Trans id='pages.imports.import.configuration' />
            {status === 'loading' && (
              <Spinner style={{ marginLeft: 8 }} size={16} />
            )}
          </Button>
        )}
      </div>
    </ModalPage>
  )
}
