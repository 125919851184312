/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useParams } from 'react-router'

const Context = React.createContext({})

export const useAppIds = () => {
  const ids = React.useContext(Context)
  const params = useParams()
  return Object.assign({}, params, ids)
}

export const AppIdsProvider = ({ appId, pageId, children }) => (
  <Context.Provider value={{ appId, pageId }}>{children}</Context.Provider>
)
