/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { map, some } from 'lodash'
import React from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router'
import styled from 'styled-components'

import Loading from '../../components/loading'
import SearchBar from '../../components/search-bar'
import Spinner from '../../components/spinner'
import { GraphQLError, NotFound } from '../../components/system-error'
import useDebouncedValue from '../../components/use-debounced-value'
import { useAlerts } from '../../ui/alerts'
import SpacesConfigPanel from './components/spaces-config-panel'
import SpacesNav from './components/spaces-nav'

function usePrevious (value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const inSpace = (spaces, id) => some(spaces, { id })

export default function Spaces () {
  const { tenantHasPortals, rootSpaces } = useOutletContext()
  const [search, setSearch] = React.useState('')
  if (search) return <SpacesSearch search={search} setSearch={setSearch} />
  return (
    <SpacesList
      tenantHasPortals={tenantHasPortals}
      rootSpaces={rootSpaces}
      setSearch={setSearch}
    />
  )
}

function SpacesSearch ({ search, setSearch }) {
  const alerts = useAlerts()
  const debouncedSearch = useDebouncedValue(search, 500)
  const { data, loading } = useQuery(searchSpacesQuery, {
    variables: { search: debouncedSearch },
    skip: debouncedSearch.length < 2,
    onError: error => alerts.type3(error.message, 'error')
  })
  const spaces = map(data?.searchSpaceConnection.edges, 'node') ?? []
  const noMatch = !loading && data && !spaces.length
  return (
    <Layout className='text-sm'>
      <SpacesNav isRoot spaces={spaces} clearSearch={() => setSearch('')}>
        <>
          <SearchBar
            value={search}
            onChange={setSearch}
            autoFocus
            className='p-4'
          />
          {loading && (
            <div className='flex justify-center pt-16'>
              <Spinner size={100} />
            </div>
          )}
          {noMatch && (
            <div className='flex justify-center pt-16'>
              <Trans id='pages.spaces.none' />
            </div>
          )}
        </>
      </SpacesNav>
      <div />
    </Layout>
  )
}

function SpacesList ({ tenantHasPortals, rootSpaces, setSearch }) {
  const { id } = useParams()
  const navigate = useNavigate()
  const q = getSpaceQuery(id)
  const { data, loading, error, client } = useQuery(q.query, q)
  const isRootSpace = inSpace(rootSpaces, id)
  const oldRootSpaces = usePrevious(rootSpaces)
  React.useEffect(() => {
    if (inSpace(oldRootSpaces, id) && !inSpace(rootSpaces, id)) {
      const nextId = rootSpaces?.[0]?.id
      navigate(nextId ? `../spaces/${nextId}` : '/')
    }
  }, [isRootSpace])

  const handleDelete = React.useCallback(() => {
    if (inSpace(rootSpaces, id)) return
    navigate(`../spaces/${data.space.parentId}`)
  }, [navigate, id, data, rootSpaces])

  const prefetchSpace = id => {
    client.query({ ...getSpaceQuery(id), fetchPolicy: 'cache-first' })
  }

  React.useEffect(() => {
    if (error) Sentry.captureException(error)
  }, [error])

  if (!loading && !data?.space) return <NotFound fillHeight />
  if (error) return <GraphQLError fillHeight />
  if (!data) return <Loading />
  return (
    <Layout className='text-sm'>
      <SpacesNav
        isRoot={isRootSpace}
        parentId={data.space.parentId}
        spaces={isRootSpace ? rootSpaces : data.space.siblings}
        onHoverSpace={prefetchSpace}
        currentParentId={!isRootSpace && data?.space?.parentId}
      >
        <SearchBar onChange={setSearch} autoFocus className='p-4' />
      </SpacesNav>
      <SpacesNav
        parentId={data.space.id}
        spaces={data.space.children}
        onHoverSpace={prefetchSpace}
      />
      <SpacesConfigPanel
        tenantHasPortals={tenantHasPortals}
        space={data.space}
        onDelete={handleDelete}
        loading={loading}
      />
    </Layout>
  )
}

const getSpaceQuery = id => ({
  variables: { id },
  fetchPolicy: 'cache-and-network',
  query: gql`
    query Space($id: ID) {
      space(id: $id) {
        id
        name
        parentId
        allowBranding
        allowChildBranding
        allowIntegrations
        allowChildIntegrations
        suite {
          id
        }
        children {
          id
          name
        }
        siblings {
          id
          name
        }
        apps {
          id
          name
          type
          createdAt
          createdBy {
            id
            displayName
          }
          tileOptions {
            backgroundColor
            iconName
          }
          viewer {
            canManage
            canSubmitDocuments
            canViewDocuments
          }
        }
        links {
          id
          title
          description
          url
          imageUrl
          createdAt
        }
        integrationCount
        listPolicyGroups {
          id
          name
          removable
          policies {
            id
            version
            statements {
              action
              resource
              effect
            }
          }
          identities {
            type
            id
            label
          }
        }
      }
    }
  `
})

const Layout = styled.div`
  display: flex;
  min-height: calc(100vh - 80px);

  > :last-child {
    flex: 1;
  }
`

const searchSpacesQuery = gql`
  query SearchSpaces($search: String!) {
    searchSpaceConnection(args: { name: $search, skip: 0, limit: 50 }) {
      edges {
        node {
          id
          name
          parentId
        }
      }
    }
  }
`
