/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, isEqual } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import * as Chooser from '../../data-chooser'
import {
  SelectGadgetInput,
  getInputFields,
  getOutputFields,
  useInputDefinitions
} from '../../integration-utils'

function getInputValue (type, value) {
  if (type === 'static') return value
  if (!value) return null
  return { id: value.id, type: value.type }
}

export function RequiredConfig ({
  Gadgets,
  updateDataLookupSource,
  indexed,
  indexType,
  value,
  allGadgets,
  siblingGadgets,
  onChange
}) {
  const { appId } = useParams()
  const { data } = useQuery(...getFetchIntegrationQuery(appId, value))
  React.useEffect(() => {
    if (data?.app) {
      const currentOutputs = value?.outputFields || []
      const currentInputs = value?.inputFields || []
      const integrationData = data?.app?.sharedWithMe?.integration?.data
      const integrationName = data?.app?.sharedWithMe?.integration?.name
      const newOutputs = getOutputFields(integrationData)
      const newInputs = getInputFields(integrationData)
      for (const key in newInputs) {
        if (key in currentInputs) {
          newInputs[key].type = currentInputs[key].type || 'form'
          newInputs[key].value = getInputValue(
            newInputs[key].type,
            currentInputs[key].value
          )
          newInputs[key].options = currentInputs[key].options
        }
      }
      if (
        !isEqual(newOutputs, currentOutputs) ||
        !isEqual(newInputs, currentInputs) ||
        (integrationName && !isEqual(integrationName, value.label))
      ) {
        onChange({
          ...value,
          outputFields: newOutputs,
          inputFields: newInputs,
          label: integrationName
        })
      }
      // TODO:: check that searchParameter didn't change (update if it did)
    }
  }, [data])
  const inputs = useInputDefinitions(
    data?.app?.sharedWithMe?.integration,
    allGadgets,
    siblingGadgets
  )
  const handleInputChange = (key, val) =>
    onChange({
      ...value,
      inputFields: {
        ...(value?.inputFields ?? {}),
        [key]: val
      }
    })
  return (
    <>
      <Gadgets.Padded>
        <Gadgets.Label>
          <Trans id='data.source.colon' />
        </Gadgets.Label>
        <Chooser.Multiselect
          id={value.id}
          label={value.label}
          onSelect={updateDataLookupSource}
          indexed={indexed}
          indexType={indexType}
        />
      </Gadgets.Padded>
      {inputs.length !== 0 && (
        <>
          <Gadgets.Label>
            <Trans id='required.information.for.lookup' />
          </Gadgets.Label>
          <InputsBox className='border border-light-gray-500 bg-light-gray-100 dark:bg-light-gray-300'>
            {inputs.map(input => (
              <SelectGadgetInput
                key={input.dataPath}
                Gadgets={Gadgets}
                input={input}
                value={get(value, ['inputFields', input.dataPath])}
                onChange={value => handleInputChange(input.dataPath, value)}
              />
            ))}
          </InputsBox>
        </>
      )}
    </>
  )
}

export function OptionalConfig ({ Gadgets, value }) {
  if (!value?.searchParameter) return null
  return (
    <Gadgets.ConfigBox
      configKey='placeholder.enabled'
      label={i18n._('show.placeholder.text')}
      description={i18n._('show.placeholder.text.data')}
    >
      <Gadgets.Text configKey='placeholder.value' />
    </Gadgets.ConfigBox>
  )
}

const InputsBox = styled.div`
  border-radius: 2px;
  padding: 16px;
`

const getFetchIntegrationQuery = (appId, { id }) => [
  gql`
    query FetchIntegration($appId: ID!, $id: ID!) {
      app(id: $appId) {
        id
        sharedWithMe {
          integration(id: $id) {
            id
            name
            data
          }
        }
      }
    }
  `,
  {
    fetchPolicy: 'network-only',
    variables: { appId, id },
    skip: !appId || !id
  }
]
