/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import cx from 'clsx'
import {
  cloneDeep,
  debounce,
  filter,
  find,
  forEach,
  forEachRight,
  get,
  includes,
  map,
  reduce,
  sortBy,
  unionBy,
  values
} from 'lodash'
import React from 'react'
import {
  Link,
  unstable_usePrompt as usePrompt,
  useSearchParams
} from 'react-router'

import AnimatedOutlet from '../../components/animated-outlet'
import { productBuilder } from '../../components/feature-flags'
import isEditable from '../../components/is-editable'
import { LoadingPage } from '../../components/loading'
import * as ObjectId from '../../components/object-id'
import { GLaDOS, PortalBlue, PortalOrange } from '../../components/portals'
import { GraphQLError as Error } from '../../components/system-error'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { useDocumentTitle } from '../../components/use-document-title'
import { useIds } from '../../components/use-ids'
import onWindowClick from '../../components/window-click'
import * as Flowbot from '../../flowbot'
import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import Toggle from '../../ui/toggle'
import * as VoronoiDND from '../../voronoi-dnd'
import EmptyState from './components/empty-state'
import { useSaveWorkflowMutation } from './components/mutation.save-workflow'
import { useSaveWorkflowSettingsMutation } from './components/mutation.save-workflow-settings'
import { useUpdateWorkflowAppSettingsMutation } from './components/mutation.update-workflow-allow-new-versions'
import { useUpdateWorkflowViewerOnSubmissionsMutation } from './components/mutation.update-workflow-viewer-on-submissions'
import { useUndoRedoImmer } from './components/use-undo-redo-immer'
import WorkflowSimulator from './simulator'

const steps = Flowbot.steps

const format = (app, wf = {}) => {
  wf = cloneDeep(wf)
  iterateSteps(wf.steps, step => {
    step.clientId = step._id
    if (step.type === 'formfill') {
      step.formName = app.name
    }
    if (step.type === 'approval' && !get(step, 'subflows.length')) {
      step.subflows = [{ steps: [] }]
    }
    if (step.type === 'conditional') {
      const subflows = step.subflows || []
      if (!subflows.find(subflow => !subflow.rule)) {
        subflows.push({ steps: [], disabled: true })
      }
      step.subflows = sortBy(subflows, subflow => !!subflow.rule)
      forEachRight(step.subflows, subflow => {
        subflow.clientId = subflow._id
        if (get(subflow, 'rule.logicalOperator') === 'always') {
          subflow.rule.expressions = [{}]
        }
      })
    }
  })
  wf.schema = unionBy(
    app?.dataset?.form?.schema || [],
    values(wf.schema),
    'formKey'
  )
  return wf
}

export default function WorkflowPage ({ isProduct, isTable }) {
  useDocumentTitle('Workflow')
  const { appId, datasetId } = useIds()

  const q = getWorkflowsQuery(appId, datasetId)
  const { data, error } = useQuery(q.query, q)
  const saveWorkflowSettings = useSaveWorkflowSettingsMutation(
    appId,
    datasetId,
    data?.app?.dataset?.workflow ?? {}
  )
  const updateWorkflowViewerOnSubmissions =
    useUpdateWorkflowViewerOnSubmissionsMutation(appId, datasetId)
  const updateWorkflowAppSettings = useUpdateWorkflowAppSettingsMutation(
    appId,
    datasetId
  )
  if (!data?.app || error) return <LoadingPage />
  const workflow = format(data.app, data.app?.dataset?.workflow)

  return (
    <>
      <AnimatedOutlet
        context={{
          saveWorkflowSettings,
          updateViewerSetting: updateWorkflowViewerOnSubmissions,
          viewerSetting: data?.app?.dataset?.workflowViewerOnSubmission,
          updateWorkflowAppSettings,
          workflowAppSettings: {
            allowNewVersions: data?.app?.dataset?.allowNewVersions,
            disableDocumentHistoryNonAdmins:
              data?.app?.dataset?.disableDocumentHistoryNonAdmins
          },
          workflow
        }}
      />
      {!data?.app ? (
        <LoadingPage />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Flowbot.Wrapper>
          <Workflow isProduct={isProduct} isTable={isTable} q={q} data={data} />
        </Flowbot.Wrapper>
      )}
    </>
  )
}

function Workflow ({ isProduct, isTable, q, data }) {
  const { appId, datasetId } = useIds()
  const [saveWorkflow, { loading: workflowSaving }] = useSaveWorkflowMutation(
    appId,
    datasetId
  )
  usePrompt({
    when: workflowSaving,
    message: `${i18n._('pagesbuilder.workflow.sure.continue')}`
  })

  const [simulating, setSimulating] = React.useState(false)
  const formSections = []
  const workflow = format(data.app, data.app?.dataset?.workflow)
  const warnings = data.app ? Flowbot.validate(data.app.dataset.workflow) : []
  const hasValidationErrors = !!warnings.length
  const traverse = (template, level) => {
    if (!template) return
    let newLevel = level
    if (template.type === 'Section') {
      newLevel++
      formSections.push({ id: template.id, label: template.label, level })
    }
    forEach(template.children, child => {
      traverse(child, newLevel)
    })
  }
  traverse(data.app?.dataset?.form?.template, 1)
  const workflowSettings = {
    appAllowExport: data.app.dataset.allowExport,
    defaultFormToViewOnly: get(workflow, 'defaultFormToViewOnly'),
    disableSendback: get(workflow, 'disableSendback'),
    disableDeny: get(workflow, 'disableDeny'),
    disableDocumentHistory: get(workflow, 'disableDocumentHistory'),
    saveEmailsInDocumentHistory: get(workflow, 'saveEmailsInDocumentHistory')
  }
  return (
    <GLaDOS>
      <div className='flex h-16 items-center justify-between border-b border-b-light-gray-300'>
        <div className='flex w-[350px] items-center'>
          <PortalBlue />
        </div>
        <div className='relative'>
          <Toggle
            className='bg-blue-500'
            disabled={hasValidationErrors}
            value={simulating}
            onChange={() => setSimulating(a => !a)}
            off='Design'
            on='Test'
          />
          {hasValidationErrors && (
            <>
              <Tooltip id='test-warning' place='bottom' className='w-52'>
                <div>
                  <Trans id='pagesbuilder.workflow.validation.errors' />
                </div>
              </Tooltip>
              <TooltipTrigger
                className='absolute bottom-0 left-0 right-0 top-0'
                label={i18n._('pagesbuilder.workflow.test.warning')}
                tooltipId='test-warning'
              />
            </>
          )}
        </div>
        <div className='flex w-[350px] items-center justify-end pr-4'>
          {!simulating && (
            <Link className='kp-button-transparent kp-button-sm' to='settings'>
              <Icons.Settings className='fill-blue-500' mr={2} />
              <span>
                <Trans id='pagesbuilder.workflow.workflow.settings' />
              </span>
            </Link>
          )}
        </div>
      </div>
      {simulating && !hasValidationErrors ? (
        <WorkflowSimulator
          appId={get(data, 'app.id')}
          isTable={isTable}
          isProduct={isProduct}
          appName={get(data, 'app.name')}
          branding={data?.app?.branding}
          currentUser={data?.viewer?.user}
          form={{
            ...data.app.dataset.form,
            labelSize: data.app.dataset.labelSize
          }}
          formSections={formSections}
          workflow={workflow}
          workflowSettings={workflowSettings}
        />
      ) : (
        <WorkflowInner
          key={data.app.dataset.id}
          isProduct={isProduct}
          isTable={isTable}
          gadgetIndexTypes={data.app.dataset.formContainer.gadgetIndexTypes}
          formSections={formSections}
          workflow={workflow}
          appId={data.app.id}
          saveWorkflow={saveWorkflow}
          workflowSettings={workflowSettings}
        />
      )}
    </GLaDOS>
  )
}

const saveFlow = debounce((nodes, saveWorkflow, alerts) => {
  const flowSteps = cloneDeep(nodes)
  iterateSteps(flowSteps, step => {
    delete step.clientId
    if (step.type === 'conditional') {
      if (step.subflows[0].disabled) step.subflows.splice(0, 1)
      forEachRight(step.subflows, subflow => {
        delete subflow.clientId
        if (get(subflow, 'rule.logicalOperator') === 'always') {
          delete subflow.rule.expressions
        }
      })
    }
  })
  saveWorkflow(flowSteps)
    .then(() =>
      alerts.debouncedType3(
        i18n._('pagesbuilder.workflow.workflow.saved'),
        'success'
      )
    )
    .catch(error => {
      if (get(error, 'graphQLErrors.0.extensions.code') === 'WF_ERROR') {
        alerts.type3(
          i18n._('pagesbuilder.workflow.unable.save', {
            error: get(error, 'graphQLErrors.0.message')
          }),
          'error'
        )
      } else {
        alerts.type3(i18n._('pagesbuilder.error.occurred'), 'error')
      }
      Sentry.captureException(error)
    })
}, 200)

function WorkflowInner ({
  appId,
  isProduct,
  isTable,
  gadgetIndexTypes,
  formSections,
  saveWorkflow,
  workflow,
  workflowSettings
}) {
  const isProductTemplate = isProduct && productBuilder
  const altKey = useAltKeyHeld()
  const alerts = useAlerts()
  const [[selected, justCreatedId], setSelected] = React.useState([null, null])
  const saveWorkflowChange = React.useCallback(
    nodes => saveFlow(nodes, saveWorkflow, alerts),
    [alerts, saveWorkflow]
  )

  const [nodes, updateNodes, undo, redo] = useUndoRedoImmer(
    workflow.steps,
    undefined,
    saveWorkflowChange
  )
  const { pastSteps } = gatherPastSteps(selected?.clientId, nodes)
  const selectedLineage = map(filter(pastSteps, 'parent'), ps => ps.clientId)
  const schema = filter(
    workflow.schema,
    field =>
      supportedField(field, pastSteps) &&
      okForProductUse(field, isProductTemplate, gadgetIndexTypes)
  )
  const userFields = filter(schema, { type: 'UserTypeahead' })
  const multiUserFields = filter(schema, { type: 'UserMultiselect' })
  const groupFields = filter(schema, { type: 'GroupTypeahead' })
  const emailFields = filter(schema, { type: 'Email' })
  const iterableFields = filter(schema, x =>
    [
      'GroupMultiselect',
      'Table',
      'UserMultiselect',
      'IntegrationMultiselect',
      'Repeater',
      'FormMultiselect'
    ].includes(x.type)
  )
  const setJustSelected = React.useCallback(
    value => setSelected([value, null]),
    []
  )
  const [a11yDrag, setA11yDrag] = React.useState(null)
  const deselect = React.useCallback(() => {
    setSelected([null, null])
    setA11yDrag(null)
    setInvalidDragIds(null)
  }, [])
  const [dragging, setDragging] = React.useState(false)
  const configVisible = !!selected && !dragging
  const [faderId, setFaderId] = React.useState(false)
  const [invalidDragIds, setInvalidDragIds] = React.useState(null)
  const [delayedDragging, setDelayedDragging] = React.useState(false)
  const formFillId = nodes[0].clientId
  React.useEffect(() => {
    const fn = e => {
      if (isEditable(e.target)) return
      if (
        (e.ctrlKey || e.metaKey) &&
        e.shiftKey &&
        e.key === 'z' &&
        redo &&
        !selected
      ) {
        e.preventDefault()
        redo()
        deselect()
      }
      if (
        (e.ctrlKey || e.metaKey) &&
        !e.shiftKey &&
        e.key === 'z' &&
        undo &&
        !selected
      ) {
        e.preventDefault()
        undo()
        deselect()
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'c' && selected) {
        try {
          const value = JSON.stringify({ version: 1, value: selected })
          localStorage.setItem('wf:clipboard', value)
          alerts.type3(i18n._('pagesbuilder.step.copied'), 'success')
        } catch {}
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        try {
          const raw = localStorage.getItem('wf:clipboard')
          if (!raw) return
          const { value } = JSON.parse(raw)
          regenerateIds(value)
          updateNodes(draft => {
            const clientId = selected?.clientId ?? formFillId
            insertNewStep(draft, clientId, value)
          })
          setSelected([value, null])
        } catch {}
      }
      if (e.key === 'Delete' && selected) {
        updateNodes(draft => {
          removeStep(draft, selected.clientId)
        })
        deselect()
      }
      if (e.key === 'Escape') deselect()
    }
    document.addEventListener('keydown', fn)
    return () => document.removeEventListener('keydown', fn)
  }, [alerts, redo, undo, deselect, selected, updateNodes, formFillId])
  React.useEffect(() => {
    if (dragging) {
      let timeout
      window.requestAnimationFrame(() => {
        timeout = setTimeout(() => setDelayedDragging(true), 300)
      })
      return () => clearTimeout(timeout)
    }
    setDelayedDragging(false)
  }, [dragging])
  React.useEffect(() => {
    if (faderId) {
      let timeout
      window.requestAnimationFrame(() => {
        timeout = setTimeout(() => setFaderId(null), 300)
      })
      return () => clearTimeout(timeout)
    }
  }, [faderId])
  useInitialStepSelect(nodes, setSelected)
  const ref = useAutoScroller(selected)
  React.useEffect(() => {
    let cleanup
    const timeout = setTimeout(() => (cleanup = onWindowClick(deselect)), 0)
    return () => {
      clearTimeout(timeout)
      cleanup?.()
    }
  }, [deselect])
  const beginDrag = React.useCallback(
    step => {
      setDragging(true)
      setInvalidDragIds(getNestedIds(step))
      if (step && selected && step.clientId !== selected.clientId) deselect()
    },
    [selected, deselect]
  )
  const endDrag = React.useCallback(() => {
    setDragging(false)
    setInvalidDragIds(null)
  }, [])
  const invalidIds = altKey.current ? [] : invalidDragIds
  const handleDrop = React.useCallback(
    (dropContext, dragContext) => {
      if (isInvalid(invalidIds, dropContext.id)) return
      if (dragContext.type) {
        const manifest = steps[dragContext.type]
        const id = ObjectId.generate()
        const newStep = {
          ...manifest.defaultTemplate(),
          _id: id,
          clientId: id,
          type: dragContext.type
        }
        updateNodes(draft => {
          insertNewStep(draft, dropContext.id, newStep)
        })
        setSelected([newStep, newStep.clientId])
      } else if (dragContext.id) {
        if (altKey.current) {
          const step = getStepById(nodes, dragContext.id)
          const newStep = cloneDeep(step)
          regenerateIds(newStep)
          updateNodes(draft => {
            insertNewStep(draft, dropContext.id, newStep)
          })
          setSelected([newStep, null])
        } else {
          updateNodes(draft => {
            const step = removeStep(draft, dragContext.id)
            insertNewStep(draft, dropContext.id, step)
          })
          if (!selected) setFaderId(dragContext.id)
        }
      }
    },
    [altKey, invalidIds, nodes, selected, updateNodes]
  )
  return (
    <div className='relative'>
      <div className='absolute left-4 top-5 z-[2]'>
        <Flowbot.StepList
          steps={steps}
          beginDrag={beginDrag}
          endDrag={endDrag}
          a11yDrag={a11yDrag}
          setA11yDrag={setA11yDrag}
        />
      </div>
      <PortalOrange>
        <div className='flex items-center justify-between'>
          <button
            className='kp-button-transparent'
            title={undo ? null : i18n._('pagesbuilder.nothing.undo')}
            disabled={!undo || configVisible}
            onClick={() => {
              if (!undo) return
              undo()
              deselect()
            }}
          >
            <Icons.Undo
              className={cx(
                undo && !configVisible
                  ? 'fill-blue-500'
                  : 'fill-light-gray-500 dark:fill-medium-gray-200'
              )}
              mr={2}
            />
            <span>
              <Trans id='pagesbulder.workflow.undo' />
            </span>
          </button>
          <button
            className='kp-button-transparent'
            title={redo ? null : i18n._('pagesbuilder.nothing.redo')}
            disabled={!redo || configVisible}
            onClick={() => {
              if (!redo) return
              redo()
              deselect()
            }}
          >
            <Icons.Redo
              className={cx(
                redo && !configVisible
                  ? 'fill-blue-500'
                  : 'fill-light-gray-500 dark:fill-medium-gray-200'
              )}
              mr={2}
            />
            <span>
              <Trans id='pagesbuilder.workflow.redo' />
            </span>
          </button>
        </div>
      </PortalOrange>
      <div
        className={cx('relative overflow-auto', {
          'h-[calc(100vh-248px)]': isTable,
          'h-[calc(100vh-188px)]': !isTable,
          'min-[501px]:w-[calc(100vw-288px)]': isProduct,
          'min-[501px]:w-[calc(100vw-48px)]': !isProduct
        })}
        ref={ref}
      >
        <VoronoiDND.Gatherer dragging={delayedDragging} container={ref}>
          <div
            className={cx(
              'box-border flex shrink-0 items-center p-[100px] pl-[260px] transition-[padding]',
              {
                'min-h-[calc(100vh-248px)]': isTable,
                'min-h-[calc(100vh-188px)]': !isTable,
                'pr-[600px] duration-0': !!selected,
                'pr-[100px] duration-300': !selected
              }
            )}
          >
            <Flowbot.Editor
              nodes={nodes}
              beginDrag={beginDrag}
              endDrag={endDrag}
              onClick={setJustSelected}
              selectedId={get(selected, 'clientId')}
              justCreatedId={justCreatedId}
              faderId={faderId}
              fieldsAll={workflow.schema}
              a11yDrop={id => {
                handleDrop({ id }, a11yDrag)
                setInvalidDragIds(null)
                setA11yDrag(null)
              }}
              a11yDrag={a11yDrag}
              setA11yDrag={thing => {
                const step = getStepById(nodes, thing.id)
                setInvalidDragIds(getNestedIds(step))
                setA11yDrag(thing)
              }}
              isInvalidDrop={id => isInvalid(invalidIds, id)}
              removeStep={id => {
                updateNodes(draft => {
                  removeStep(draft, id)
                })
                deselect()
              }}
              freezeFirst
              isDragging={dragging}
              workflowSettings={workflowSettings}
              formSections={formSections}
            />
            {nodes.length === 1 && <EmptyState />}
          </div>
          <VoronoiComponents onDrop={handleDrop} invalidDragIds={invalidIds} />
        </VoronoiDND.Gatherer>
      </div>
      <Flowbot.Config
        appId={appId}
        isTable={isTable}
        visible={configVisible}
        value={selected}
        justCreatedId={justCreatedId}
        fieldsMultiUsers={multiUserFields}
        fieldsUsers={userFields}
        fieldsGroups={groupFields}
        fieldsEmails={emailFields}
        fieldsIterable={iterableFields}
        fieldsAll={schema}
        formSections={formSections}
        onDelete={() => {
          updateNodes(draft => {
            removeStep(draft, selected.clientId)
          })
          deselect()
        }}
        onSave={updatedStep => {
          updateNodes(draft => {
            updateStep(draft, updatedStep)
          })
        }}
        manifest={selected && steps[selected.type]}
        lineage={selectedLineage}
        workflowSettings={workflowSettings}
      />
    </div>
  )
}

function VoronoiComponents ({ onDrop, invalidDragIds }) {
  const [dropZoneProps, setDropZoneProps] = React.useState(null)
  const onHover = React.useCallback(
    dropContext => {
      if (!dropContext) return setDropZoneProps(null)
      if (isInvalid(invalidDragIds, dropContext.id)) {
        return setDropZoneProps(null)
      }
      const { left, top, width, height } = dropContext.dimensions
      if (dropContext.data === 'new-flow') {
        setDropZoneProps({ left, top, width, height })
        return
      }
      setDropZoneProps({
        left: dropContext.data === 'left' ? left - 8 : left - 4,
        top: top - 75,
        width: 28,
        height: 150
      })
    },
    [invalidDragIds]
  )
  return (
    <>
      {dropZoneProps && <VoronoiDND.DropZone {...dropZoneProps} />}
      <VoronoiDND.Voronoi onDrop={onDrop} onHover={onHover} />
    </>
  )
}

const getWorkflowsQuery = (id, pageId) => ({
  variables: { id, pageId },
  fetchPolicy: 'cache-and-network',
  query: gql`
    query WorkflowsPage($id: ID!, $pageId: ID) {
      viewer {
        id
        user {
          id
          schoolId
          displayName
          email
          canManageSettings
          username
        }
      }
      app(id: $id, isConfiguration: true) {
        id
        name
        type
        hasDraft
        branding {
          id
          color
          width
          height
          alt
          logo
          emailLogo
        }
        dataset(id: $pageId) {
          id
          labelSize
          allowExport
          workflowViewerOnSubmission
          allowNewVersions
          disableDocumentHistoryNonAdmins
          formContainer {
            id
            gadgetIndexTypes
          }
          form: formVersion {
            id
            schema {
              id
              formKey
              type
              label
              details
              fromIntegration
            }
            template
          }
          workflow
        }
      }
    }
  `
})

const supportedField = (field, pastSteps) => {
  const blackListedTypes = [
    'FileUpload',
    'PaymentOptions',
    'Spacer',
    'Validation',
    'DataLink',
    'CreatedBy'
  ]
  return (
    !includes(blackListedTypes, field.type) &&
    isFromPastStep(field, pastSteps) &&
    !isParentEchoField(field, pastSteps)
  )
}

const okForProductUse = (field, isProductTemplate, gadgetIndexTypes) => {
  if (!isProductTemplate) return true
  if (!field.formKey?.startsWith('data.')) return true
  const [key] = field.formKey.replace('data.', '').split('.')
  return !!gadgetIndexTypes[key]
}

const isFromPastStep = (field, pastSteps) => {
  if (field.fromIntegration) {
    const integrationStepId = field.id.split('.')[0]
    return find(pastSteps, { clientId: integrationStepId })
  } else if (field.fromEcho) {
    const integrationStepId = field.id.split('.')[0]
    return find(pastSteps, { clientId: integrationStepId, parent: true })
  }
  return true
}

const isParentEchoField = (field, pastSteps) => {
  return find(
    pastSteps,
    ps => ps.parent && ps.echoField?.formKey === field.formKey
  )
}

const gatherPastSteps = (
  currentStepClientId,
  steps,
  accumulatedPastSteps = []
) => {
  const nextStep = steps[0]
  if (!nextStep) {
    return { found: false, pastSteps: accumulatedPastSteps }
  }
  if (nextStep.clientId === currentStepClientId) {
    return {
      found: true,
      pastSteps: accumulatedPastSteps
    }
  }
  let newPastSteps = accumulatedPastSteps.concat({
    clientId: nextStep.clientId
  })
  if (nextStep.subflows) {
    const subflowSteps = map(nextStep.subflows, sf =>
      gatherPastSteps(currentStepClientId, sf.steps)
    )
    const specificSubflow = find(subflowSteps, { found: true })
    if (specificSubflow) {
      newPastSteps.pop()
      const parentStep = { clientId: nextStep.clientId, parent: true }
      if (nextStep.type === 'echo') {
        parentStep.echoField = nextStep.echoField
      }
      newPastSteps.push(parentStep)
      return {
        found: true,
        pastSteps: newPastSteps.concat(specificSubflow.pastSteps)
      }
    }
    newPastSteps = reduce(
      subflowSteps,
      (accum, sf) => {
        return accum.concat(sf.pastSteps)
      },
      newPastSteps
    )
  }
  return gatherPastSteps(currentStepClientId, steps.slice(1), newPastSteps)
}

const useAltKeyHeld = () => {
  const ref = React.useRef(false)
  React.useEffect(() => {
    const keydown = e => {
      if (e.key === 'Alt') ref.current = true
    }
    const keyup = e => {
      if (e.key === 'Alt') ref.current = false
    }
    const drop = e => {
      const isHeld = e.altKey
      setTimeout(() => (ref.current = isHeld))
    }
    document.addEventListener('keydown', keydown)
    document.addEventListener('keyup', keyup)
    document.addEventListener('drop', drop)
    return () => {
      document.removeEventListener('keydown', keydown)
      document.removeEventListener('keyup', keyup)
      document.removeEventListener('drop', drop)
    }
  }, [])
  return ref
}

const useInitialStepSelect = (nodes, setSelected) => {
  const [searchParams] = useSearchParams()
  const hasRun = React.useRef(false)
  const showStep = searchParams.get('showStep')
  React.useEffect(() => {
    if (hasRun.current) return
    hasRun.current = true
    const step = showStep && getStepById(nodes, showStep)
    if (step && step.type !== 'formfill') setSelected([step, null])
  }, [showStep, nodes, setSelected])
}

const useAutoScroller = selected => {
  const ref = React.useRef()
  React.useEffect(() => {
    if (!selected || !ref.current || window.innerWidth < 600) return
    const el = document.getElementById(`wfStep-${selected.clientId}`)
    if (!el) return
    const { x, width } = el.getBoundingClientRect()
    if (x + width > window.innerWidth - 500) {
      const amountToScroll = x + width - (window.innerWidth - 600)
      const el2 = ref.current
      const left = el2.scrollLeft + amountToScroll
      el2.scrollTo({ left })
    }
  }, [selected])
  return ref
}

// use immer here
function getNestedIds (step) {
  if (!step) return []
  const ids = []
  iterateSteps([step], step => ids.push(step.clientId))
  return ids
}

function isInvalid (invalidIds, rawId) {
  const [id] = rawId.split('::')
  return includes(invalidIds, id)
}

function insertNewStep (nodes, rawId, newStep) {
  const [id, a, b] = rawId.split('::')
  iterateSteps(nodes, (step, i, steps) => {
    if (step.clientId === id) {
      if (b === 'inner') step.subflows[a].steps.push(newStep)
      else if (a === 'first') steps.splice(i, 0, newStep)
      else steps.splice(i + 1, 0, newStep)
    }
  })
}

const regenerateIds = node => {
  const id = ObjectId.generate()
  if (node._id) node._id = id
  if (node.clientId) node.clientId = id
  if (node.subflows) {
    forEachRight(node.subflows, subflow => {
      const id = ObjectId.generate()
      if (subflow._id) subflow._id = id
      if (subflow.clientId) subflow.clientId = id
      forEachRight(subflow.steps, regenerateIds)
    })
  }
}

function updateStep (nodes, updatedStep) {
  iterateSteps(nodes, (step, i, steps) => {
    if (step.clientId === updatedStep.clientId) {
      const isPercentage = updatedStep?.voting?.rule === 'percentage'
      const assigneeRolIsUser = ['formUser', 'globalUser'].includes(
        updatedStep?.assignee?.type
      )
      steps[i] =
        assigneeRolIsUser & isPercentage
          ? { ...updatedStep, voting: { ...updatedStep.voting, rule: 'first' } }
          : updatedStep
    }
  })
}

function removeStep (nodes, clientId) {
  let removed
  iterateSteps(nodes, (step, i, steps) => {
    if (step.clientId === clientId) removed = steps.splice(i, 1)[0]
  })
  return removed
}

function getStepById (nodes, clientId) {
  let found
  iterateSteps(nodes, (step, i, steps) => {
    if (step.clientId === clientId) found = step
  })
  return found
}

const iterateSteps = (steps, cb) => {
  forEachRight(steps, (step, i) => {
    if (step.subflows) {
      forEachRight(step.subflows, subflow => {
        iterateSteps(subflow.steps, cb)
      })
    }
    cb(step, i, steps)
  })
}

export const forTesting = {
  gatherPastSteps
}
