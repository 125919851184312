/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import {
  assign,
  every,
  filter,
  find,
  flatMap,
  forEach,
  get,
  includes,
  isEqual,
  map,
  mapValues,
  some
} from 'lodash'
import React from 'react'
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css'

import { multipleLanguages } from '../components/feature-flags'
import {
  Column,
  Row,
  draggableGadgetsDecorator,
  emptyRepeaterDecorator,
  emptySectionDecorator,
  emptyTableDecorator,
  runtimeDecorator
} from '../voronoi-dnd-formbot'
import compareChangesDecorator from './decorators/compare-changes'
import keyboardMovableDecorator from './decorators/keyboard-movable'
import overlayDecorator from './decorators/overlay'
import secretFieldsDecorator from './decorators/secret-fields'
import { validationDecorator } from './decorators/validations'
import { createFormbot } from './engine/formbot'
import { bind } from './engine/formbot-react'
import {
  collectGadgets,
  filterVisibleGadgets,
  findValueInTemplate,
  gatherAllSubGadgets,
  traverseTemplate
} from './engine/formbot/utils'
import App from './gadgets/app/manifest'
import Associations from './gadgets/associations/manifest'
import Boolean from './gadgets/boolean/manifest'
import Checkboxes from './gadgets/checkboxes/manifest'
import CreatedBy from './gadgets/created-by/manifest'
import Credits from './gadgets/credits/manifest'
import Currency from './gadgets/currency/manifest'
import DataFill from './gadgets/data-fill/manifest'
import DataLink from './gadgets/data-link/manifest'
import DataLookup from './gadgets/data-lookup/manifest'
import DataMultiselect from './gadgets/data-multiselect/manifest'
import DatePicker from './gadgets/date-picker/manifest'
import Dropdown from './gadgets/dropdown/manifest'
import Duration from './gadgets/duration/manifest'
import Email from './gadgets/email/manifest'
import FileUpload from './gadgets/file-upload/manifest'
import FormMultiselect from './gadgets/form-multiselect/manifest'
import FormTypeahead from './gadgets/form-typeahead/manifest'
import GadgetDropdown from './gadgets/gadget-dropdown/manifest'
import GroupMultiselect from './gadgets/group-multiselect/manifest'
import GroupTypeahead from './gadgets/group-typeahead/manifest'
import Grouping from './gadgets/grouping/manifest'
import Iframe from './gadgets/iframe/manifest'
import ImageUpload from './gadgets/image-upload/manifest'
import IntegrationFill from './gadgets/integration-fill/manifest'
import IntegrationMultiselect from './gadgets/integration-multiselect/manifest'
import IntegrationTypeahead from './gadgets/integration-typeahead/manifest'
import LinearScale from './gadgets/linear-scale/manifest'
import {
  CountryDropdown,
  LanguagesDropdown,
  StateDropdown
} from './gadgets/location/manifest'
import NotFound from './gadgets/not-found/manifest'
import Number from './gadgets/number/manifest'
import PaymentOptions from './gadgets/payment-options/manifest'
import Radios from './gadgets/radios/manifest'
import ReadOnlyData from './gadgets/read-only-data/manifest'
import Repeater from './gadgets/repeater/manifest'
import RichText from './gadgets/rich-text/manifest'
import Rules from './gadgets/rules/manifest'
import Section from './gadgets/section/manifest'
import Signature from './gadgets/signature/manifest'
import Spacer from './gadgets/spacer/manifest'
import StaticImage from './gadgets/static-image/manifest'
import SurveyGrid from './gadgets/survey-grid/manifest'
import TableColumn from './gadgets/table-column/manifest'
import TableTemporary from './gadgets/table-temporary/manifest'
import Table from './gadgets/table/manifest'
import Terms from './gadgets/terms/manifest'
import Text from './gadgets/text/manifest'
import Textarea from './gadgets/textarea/manifest'
import TimePicker from './gadgets/time-picker/manifest'
import Timestamp from './gadgets/timestamp/manifest'
import Url from './gadgets/url/manifest'
import UserMultiselect from './gadgets/user-multiselect/manifest'
import UserTypeahead from './gadgets/user-typeahead/manifest'
import Validation from './gadgets/validation/manifest'
import WorkflowCurrentSteps from './gadgets/workflow-current-steps/manifest'
import WorkflowStatus from './gadgets/workflow-status/manifest'
import * as validations from './validations'

const basicGadgets = {
  Text,
  Textarea,
  RichText,
  Checkboxes,
  Radios,
  Dropdown,
  Date: DatePicker,
  TimePicker,
  Number,
  CountryDropdown,
  LanguagesDropdown,
  StateDropdown,
  Email,
  Url,
  Currency,
  Rules,
  Associations,
  LinearScale
}

const coreGadgets = {
  UserTypeahead,
  UserMultiselect,
  GroupTypeahead,
  GroupMultiselect,
  SurveyGrid
}

function createBuildFormbot (config, applyOverrides = () => null) {
  const formbot = createFormbot({ config })

  formbot.registerGadget('Column', Column)
  formbot.registerGadget('Row', Row)
  formbot.registerGadget('Section', Section)
  formbot.registerGadget('Spacer', Spacer)
  formbot.registerGadget('Validation', Validation)
  formbot.registerGadget('StaticImage', StaticImage)
  formbot.registerGadget('NotFound', NotFound)
  formbot.registerGadget('Grouping', Grouping)

  forEach(basicGadgets, (gadget, key) => formbot.registerGadget(key, gadget))
  formbot.registerGadget('Signature', Signature)
  formbot.registerGadget('ImageUpload', ImageUpload)

  formbot.registerGadget('Table', Table)
  formbot.registerGadget('TableColumn', TableColumn)
  formbot.registerGadget('TableTemporary', TableTemporary)
  formbot.registerGadget('Repeater', Repeater)
  formbot.registerGadget('DataFill', DataFill)
  formbot.registerGadget('DataLookup', DataLookup)
  formbot.registerGadget('DataMultiselect', DataMultiselect)
  formbot.registerGadget('Terms', Terms)

  forEach(coreGadgets, (gadget, key) => formbot.registerGadget(key, gadget))

  formbot.registerGadget('Boolean', Boolean)
  formbot.registerGadget('FileUpload', FileUpload)
  formbot.registerGadget('FormTypeahead', FormTypeahead)
  formbot.registerGadget('FormMultiselect', FormMultiselect)
  formbot.registerGadget('IntegrationTypeahead', IntegrationTypeahead)
  formbot.registerGadget('IntegrationMultiselect', IntegrationMultiselect)
  formbot.registerGadget('IntegrationFill', IntegrationFill)
  formbot.registerGadget('Iframe', Iframe)
  formbot.registerGadget('DataLink', DataLink)
  formbot.registerGadget('GadgetDropdown', GadgetDropdown)
  formbot.registerGadget('Timestamp', Timestamp)
  formbot.registerGadget('WorkflowStatus', WorkflowStatus)
  formbot.registerGadget('WorkflowCurrentSteps', WorkflowCurrentSteps)
  formbot.registerGadget('Duration', Duration)
  formbot.registerGadget('CreatedBy', CreatedBy)
  formbot.registerGadget('PaymentOptions', PaymentOptions)
  formbot.registerGadget('ReadOnlyData', ReadOnlyData)

  formbot.registerGadget('Credits', Credits)

  formbot.registerDecorator({}, validationDecorator)
  formbot.registerDecorator({}, compareChangesDecorator)
  applyOverrides(formbot)

  formbot.registerDecorator({}, secretFieldsDecorator)

  return { formbot, Formbot: bind(formbot) }
}

const old = createBuildFormbot(false, formbot => {
  formbot.registerDecorator({}, runtimeDecorator)
})
export const formbot = old.formbot
export default old.Formbot

const dnd = createBuildFormbot(true, formbot => {
  formbot.registerDecorator({}, overlayDecorator)
  formbot.registerDecorator({}, draggableGadgetsDecorator)
  formbot.registerDecorator({}, keyboardMovableDecorator)
  formbot.registerDecorator({ type: 'Repeater' }, emptyRepeaterDecorator)
  formbot.registerDecorator({ type: 'Section' }, emptySectionDecorator)
  formbot.registerDecorator({ type: 'Table' }, emptyTableDecorator)
})
export const formbotDND = dnd.formbot
export const FormbotDND = dnd.Formbot

const workflowGadgets = {
  Duration,
  WorkflowStatus,
  WorkflowCurrentSteps
}

export const gadgets = assign(
  {},
  { Section, Spacer, Validation, StaticImage, NotFound, Table, Repeater },
  basicGadgets,
  coreGadgets,
  {
    GadgetDropdown,
    Boolean,
    FileUpload,
    ImageUpload,
    Signature,
    DataLookup,
    DataMultiselect,
    DataFill,
    FormTypeahead,
    FormMultiselect,
    Terms,
    IntegrationTypeahead,
    IntegrationMultiselect,
    IntegrationFill,
    Iframe,
    DataLink,
    CreatedBy,
    PaymentOptions,
    Credits
  },
  workflowGadgets, // These are only used in the document list page
  { Timestamp, App }
)

// This function makes the number 0 truthy
// while keeping all other falsy things falsy.
// Without this the number 0 doesn't meet the "required" validations.
// This enables the Time Picker gadget to meet "required" validations
// when 12:00 AM is selected and 0 seconds is stored.
const isFalseExceptZero = value => {
  if (
    value !== undefined &&
    value !== null &&
    value !== false &&
    value !== '' &&
    value.length !== 0
  ) {
    return true
  } else return false
}

const shouldValidateRequired = (ignoreRequired, gadget) => {
  if (!Array.isArray(ignoreRequired)) return !ignoreRequired
  return !ignoreRequired.includes(gadget.errorKey)
}

export function validate (document, structure, ignoreRequired) {
  return mapValues(
    validateWithDetails(document, structure, ignoreRequired),
    v => v.errors
  )
}

export function validateWithDetails (document, structure, ignoreRequired) {
  if (!structure.template) return {}
  const template = filterVisibleGadgets(formbot, document, structure)
  const gadgets = collectGadgets(template)
  const allGadgets = gatherAllSubGadgets(gadgets, formbot)
  const validationGadgets = gatherAllSubGadgets(gadgets, formbot, {
    ignoreSubFields: definition => definition.subFieldsIgnoreValidation
  })
  forEach(validationGadgets, g => {
    if (g.formKey) {
      g.errorKey = g.formKey
      g.formKey = `data.${g.formKey}`
    }
  })
  const errors = {}

  // Because gatherAllSubgadgets filters out gadgets without formKeys;
  // this gathers errors for the Validation Gadgets still showing
  gadgets
    .filter(g => g.type === 'Validation')
    .forEach(gadget => {
      if (!gadget.conditionalVisibility?.value) {
        return
      }
      errors[gadget.id] = {
        gadget: { label: 'Error' },
        errors: [gadget.label]
      }
    })

  forEach(validationGadgets, gadget => {
    if (
      structure.fieldsToValidate &&
      !includes(structure.fieldsToValidate, gadget.formKey)
    ) {
      return
    }
    if (gadget.type === 'Repeater') {
      Object.assign(
        errors,
        validateRepeater(document, gadget, allGadgets, ignoreRequired)
      )
    }
    if (gadget.type === 'Table') {
      Object.assign(
        errors,
        validateTable(document, gadget, allGadgets, ignoreRequired)
      )
    }
    const docValue = get(document, gadget.formKey)
    const def = formbot.getGadget(gadget.type)
    if (!isFalseExceptZero(docValue) || isEqual(docValue, def.defaultValue)) {
      if (
        gadget.required &&
        !gadget.readOnly &&
        shouldValidateRequired(ignoreRequired, gadget)
      ) {
        errors[gadget.errorKey] = errors[gadget.errorKey] || {
          gadget,
          errors: []
        }
        errors[gadget.errorKey].errors.push(
          i18n._({ id: 'required.field', message: 'This field is required' })
        )
      }
    } else {
      const obj = Object.assign(
        {},
        def.validations || {},
        gadget.validations || {}
      )
      const allValidations = flatMap(obj, ({ enabled, value }, type) => {
        if (!enabled) return []
        return [{ type, value }]
      })
      forEach(allValidations, validation => {
        if (!(validation.type in validations)) {
          errors[gadget.errorKey] = errors[gadget.errorKey] || {
            gadget,
            errors: []
          }
          errors[gadget.errorKey].errors.push(
            `Unknown Validation: ${validation.type}`
          )
        } else {
          const { evaluate } = validations[validation.type]
          const gadgetErrors = multipleLanguages
            ? evaluate(docValue, validation.value, gadget)
            : evaluate(docValue, validation.value)
          if (gadgetErrors) {
            errors[gadget.errorKey] = errors[gadget.errorKey] || {
              gadget,
              errors: []
            }
            errors[gadget.errorKey].errors.push(...gadgetErrors)
          }
        }
      })
    }
  })
  return errors
}

export function getPages (document, structure, validations) {
  if (!document || !structure.template) return []
  const visibleTemplate = filterVisibleGadgets(formbot, document, structure)
  const visibleChildren = visibleTemplate?.children
  return map(getGrouped(structure.template?.children), (section, i) => ({
    errorMsg: determineErrorMsg(section, validations),
    name: findFirstSectionLabel(section),
    hidden:
      section.type === 'Section' &&
      !visibleChildren.some(child => child.id === section.id)
  }))
}

function determineErrorMsg (section, validations = {}) {
  let foundMatch = false
  traverseTemplate(section, gadget => {
    if (validations[gadget.formKey]) {
      foundMatch = true
    }
  })
  return foundMatch ? <Trans id='this.page.has.errors' /> : null
}

/*
When we render a template, gadgets outside of sections will get grouped
together. We want to group the template similarly when determining page numbers
to keep them accurate.
*/
function getGrouped (children) {
  const newChildren = []
  let temp = []
  const addTemp = () => {
    if (!temp.length) return
    newChildren.push({ type: 'Grouping', children: temp })
    temp = []
  }
  forEach(children, gadget => {
    if (gadget.type === 'Section') {
      addTemp()
      newChildren.push(gadget)
    } else {
      temp.push(gadget)
    }
  })
  addTemp()
  return newChildren
}

function findFirstSectionLabel (template) {
  return findValueInTemplate(template, gadget => {
    if (gadget.type === 'Section') {
      return gadget?.customName?.enabled
        ? gadget.customName.value
        : gadget.label
    }
  })
}

/**
 * This function looks suspiciously similar to `validateTable`, but
 * unfortunately it's different enough to merit being a separate function.
 * Specific differences include: the shape of the `childrenTemplate` and how
 * it's traversed, the way the document data is structured for repeaters (with
 * extra `data` properties), and the lack of columns (which means there's no need
 * for column-level errors).
 *
 * A day may come when these functions are merged, but it is not this day!
 */
function validateRepeater (document, gadget, allGadgets, ignoreRequired) {
  const dataGadgets = []
  const allValidations = { required: [], validations: {}, defs: {} }
  const validationGadgets = []
  traverseTemplate({ children: gadget.childrenTemplate }, child => {
    if (child.type === 'Validation') validationGadgets.push(child)
    if (!child.formKey) return
    dataGadgets.push(child)
    if (child.required) allValidations.required.push(child)
    const def = formbot.getGadget(child.type)
    allValidations.defs[child.formKey] = def
    const validations = Object.assign(
      {},
      def.validations || {},
      child.validations || {}
    )
    const activeVals = flatMap(validations, ({ enabled, value }, type) => {
      return enabled ? [{ type, value, gadget: child }] : []
    })
    allValidations.validations[child.formKey] = activeVals
  })
  const repeaterData = document.data[gadget.errorKey]?.data || []
  if (!repeaterData.length && allValidations.required.length) {
    return { [gadget.errorKey]: { gadget, errors: ['Missing Repeater Data'] } }
  }
  const repeaterErrors = repeaterData.reduce((acc, repeat, i) => {
    forEach(validationGadgets, valGad => {
      if (
        checkRepeatableGadgetShowing(
          dataGadgets,
          i,
          valGad,
          document,
          allGadgets
        ) &&
        valGad.conditionalVisibility?.value
      ) {
        acc[`${gadget.errorKey}.data.${i}.data.${valGad.id}`] = {
          index: i,
          gadget: { label: 'Error' },
          parent: gadget,
          errors: [valGad.label]
        }
      }
    })
    forEach(repeat.data, (value, key) => {
      const errorKey = `${gadget.errorKey}.data.${i}.data.${key}`
      if (
        !checkRepeatableGadgetShowing(
          dataGadgets,
          i,
          dataGadgets.find(k => k.formKey === key),
          document,
          allGadgets
        )
      ) {
        return acc
      }
      if (
        !isFalseExceptZero(value) ||
        isEqual(value, allValidations.defs[key].defaultValue)
      ) {
        // check required
        const requiredGadget = allValidations.required.find(
          k => k.formKey === key
        )
        if (
          requiredGadget &&
          shouldValidateRequired(ignoreRequired, requiredGadget)
        ) {
          acc[errorKey] = {
            index: i,
            gadget: requiredGadget,
            parent: gadget,
            errors: []
          }
          acc[errorKey].errors.push(
            i18n._({ id: 'required.field', message: 'This field is required' })
          )
        }
      } else {
        // check other validations
        forEach(allValidations.validations[key], validation => {
          if (!(validation.type in validations)) {
            acc[errorKey] = acc[errorKey] || {
              index: i,
              gadget: validation.gadget,
              parent: gadget,
              errors: []
            }
            acc[errorKey].errors.push(`Unknown Validation: ${validation.type}`)
          } else {
            const { evaluate } = validations[validation.type]
            const gadgetErrors = evaluate(value, validation.value)
            if (gadgetErrors) {
              acc[errorKey] = acc[errorKey] || {
                index: i,
                gadget: validation.gadget,
                parent: gadget,
                errors: []
              }
              acc[errorKey].errors.push(...gadgetErrors)
            }
          }
        })
      }
    })
    return acc
  }, {})
  if (Object.keys(repeaterErrors).length) {
    repeaterErrors[gadget.errorKey] = {
      gadget,
      meta: true,
      errors: ['Repeater has errors']
    }
  }
  return repeaterErrors
}

function validateTable (document, gadget, allGadgets, ignoreRequired) {
  const validationGadgets = []
  const allValidations = gadget.childrenTemplate.reduce(
    (acc, child) => {
      if (child.type === 'Validation') validationGadgets.push(child)
      if (child.required) acc.required.push(child)
      const def = formbot.getGadget(child.type)
      acc.defs[child.formKey] = def
      const validations = Object.assign(
        {},
        def.validations || {},
        child.validations || {}
      )
      const activeVals = flatMap(validations, ({ enabled, value }, type) => {
        return enabled ? [{ type, value, gadget: child }] : []
      })
      acc.validations[child.formKey] = activeVals
      return acc
    },
    { required: [], validations: {}, defs: {} }
  )
  const tableData = document.data[gadget.errorKey] || []
  if (!tableData.length && allValidations.required.length) {
    return { [gadget.errorKey]: { gadget, errors: ['Missing Table Data'] } }
  }
  const tableErrors = tableData.reduce((acc, row, i) => {
    if (row._isFooter) return acc
    forEach(validationGadgets, valGad => {
      if (
        checkRepeatableGadgetShowing(
          gadget.childrenTemplate,
          i,
          valGad,
          document,
          allGadgets
        ) &&
        valGad.conditionalVisibility?.value
      ) {
        acc[`${gadget.errorKey}.${i}.${valGad.id}`] = {
          index: i,
          gadget: { label: 'Error' },
          parent: gadget,
          errors: [valGad.label]
        }
      }
    })
    forEach(row, (value, key) => {
      const errorKey = `${gadget.errorKey}.${i}.${key}`
      if (key === '_rowId') return acc
      if (
        !checkRepeatableGadgetShowing(
          gadget.childrenTemplate,
          i,
          gadget.childrenTemplate.find(k => k.formKey === key),
          document,
          allGadgets
        )
      ) {
        return acc
      }
      if (
        !isFalseExceptZero(value) ||
        isEqual(value, allValidations.defs[key].defaultValue)
      ) {
        // check required
        const requiredGadget = allValidations.required.find(
          k => k.formKey === key
        )
        if (
          requiredGadget &&
          shouldValidateRequired(ignoreRequired, requiredGadget)
        ) {
          acc[errorKey] = {
            index: i,
            gadget: requiredGadget,
            parent: gadget,
            errors: []
          }
          acc[errorKey].errors.push(
            i18n._({ id: 'required.field', message: 'This field is required' })
          )
          if (!Object.keys(acc).includes(key)) {
            acc[key] = {
              gadget: requiredGadget,
              parent: gadget,
              meta: true,
              errors: ['Errors on this column']
            }
          }
        }
      } else {
        // check other validations
        forEach(allValidations.validations[key], validation => {
          if (!(validation.type in validations)) {
            acc[errorKey] = { index: i - 1, gadget, errors: [] }
            acc[errorKey].errors.push(`Unknown Validation: ${validation.type}`)
            if (!Object.keys(acc).includes(key)) {
              acc[key] = {
                gadget: validation.gadget,
                parent: gadget,
                column: true,
                errors: ['Errors on this column']
              }
            }
          } else {
            const { evaluate } = validations[validation.type]
            const gadgetErrors = evaluate(value, validation.value)
            if (gadgetErrors) {
              acc[errorKey] = {
                index: i - 1,
                gadget: validation.gadget,
                parent: gadget,
                errors: []
              }
              acc[errorKey].errors.push(...gadgetErrors)
              if (!Object.keys(acc).includes(key)) {
                acc[key] = {
                  gadget: validation.gadget,
                  parent: gadget,
                  column: true,
                  errors: ['Errors on this column']
                }
              }
            }
          }
        })
      }
    })
    return acc
  }, {})
  if (Object.keys(tableErrors).length) {
    tableErrors[gadget.errorKey] = {
      gadget,
      meta: true,
      errors: ['Table has errors']
    }
  }
  return tableErrors
}

function checkRepeatableGadgetShowing (
  childrenTemplate,
  i,
  gadget,
  document,
  allGadgets
) {
  const pd =
    gadget?.conditionalVisibility?.enabled &&
    gadget?.conditionalVisibility?.value
  if (!pd) return true
  const combineFn = pd.type === 'any' ? some : every
  const parts = filter(pd.parts, 'formKey')
  return combineFn(parts, part => {
    const inRepeatableGadget = part.formKey.includes('.*.')
      ? part.formKey.split(/\.\*\.(data\.)?/)[2]
      : null
    const datum = inRepeatableGadget
      ? get(document, part.formKey.replace('.*.', `.${i}.`))
      : get(document, part.formKey)
    const gadgetInstance = inRepeatableGadget
      ? find(childrenTemplate, { formKey: inRepeatableGadget })
      : find(allGadgets, { formKey: part.formKey })
    if (!gadgetInstance) return false
    const gadgetDefinition = formbot.getGadget(gadgetInstance.type)
    const actual = datum ?? gadgetDefinition.defaultValue
    return gadgetDefinition.progressiveDisclosure.check(
      actual,
      part.data,
      document
    )
  })
}
