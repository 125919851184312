/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { useOutletContext } from 'react-router'
import styled from 'styled-components'

import Spinner from '../../../components/spinner'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import Input from '../../../ui/input'
import CREATE_ROLE_MUTATION from '../gql/mutation.create-category-role'
import DELETE_ROLE_MUTATION from '../gql/mutation.delete-category-role'
import UPDATE_ROLE_MUTATION from '../gql/mutation.update-category-role'

export default function BlueprintDetailsRoles () {
  const { blueprint, canManageIdentity } = useOutletContext()
  const [addToggle, setAddToggle] = React.useState(false)
  const [createRole, { loading: createRoleLoading }] =
    useMutation(CREATE_ROLE_MUTATION)

  return (
    <StyledDetailsContainer>
      <StyledRoles>
        <BlueprintsRole
          permanent
          role={{
            name: i18n._({ id: 'administrators', message: 'Administrators' }),
            description: (
              <Trans
                id='default.admin.role.blueprint'
                message='The default admin role for this blueprint.'
              />
            )
          }}
        />
        <BlueprintsRole
          permanent
          role={{
            name: i18n._({ id: 'members', message: 'Members' }),
            description: (
              <Trans
                id='default.role.blueprint'
                message='The default role for this blueprint.'
              />
            )
          }}
        />
        {blueprint?.roleSchemas?.map(role => {
          return (
            <BlueprintsRole
              blueprint={blueprint}
              role={role}
              key={role.id}
              canManageIdentity={canManageIdentity}
            />
          )
        })}
        {canManageIdentity && !addToggle ? (
          <Button icon transparent onClick={() => setAddToggle(true)}>
            <Icons.Add mr={2} mt={2} mb={2} className='fill-blue-500' />
            <Trans id='add.role' message='Add Role' />
          </Button>
        ) : canManageIdentity ? (
          <CreateAndEditRole
            create
            blueprint={blueprint}
            hide={() => setAddToggle(false)}
            mutation={createRole}
            noClick={createRoleLoading}
          />
        ) : null}
      </StyledRoles>
    </StyledDetailsContainer>
  )
}

function BlueprintsRole ({ role, blueprint, permanent, canManageIdentity }) {
  const alerts = useAlerts()
  const [editToggle, setEditToggle] = React.useState(false)
  const [deleteRole, { loading: deleteRoleLoading }] =
    useMutation(DELETE_ROLE_MUTATION)
  const [editRole, { loading: editRoleLoading }] =
    useMutation(UPDATE_ROLE_MUTATION)

  const noClick = editRoleLoading || deleteRoleLoading

  if (editToggle) {
    return (
      <CreateAndEditRole
        role={role}
        blueprint={blueprint}
        hide={() => setEditToggle(false)}
        mutation={editRole}
        noClick={noClick}
      />
    )
  }

  return (
    <Card className='bg-white dark:bg-light-gray-300'>
      <StyledCardHeader>
        <h4>
          <Trans id='role.name.colon' message='Role Name:' />
        </h4>
        {!permanent && canManageIdentity && (
          <StyledEditDelete>
            <Button
              transparent
              aria-label={i18n._({ id: 'edit.role', message: 'Edit Role' })}
              icon
              disabled={noClick}
              onClick={() => setEditToggle(!editToggle)}
            >
              {editRoleLoading ? <Spinner size={14} /> : <Icons.Edit />}
            </Button>
            <Button
              transparent
              aria-label={i18n._({ id: 'delete.role', message: 'Delete Role' })}
              disabled={noClick}
              icon
              onClick={() => {
                deleteRole({
                  variables: {
                    id: role.id,
                    parentId: blueprint.id
                  }
                })
                  .then(() =>
                    alerts.type3(
                      i18n._({
                        id: 'successfully.deleted.role',
                        message: 'Successfully deleted role'
                      }),
                      'success'
                    )
                  )
                  .catch(err => {
                    alerts.type3(
                      i18n._({
                        id: 'could.not.delete.role',
                        message: "Couldn't delete role"
                      }),
                      'error'
                    )
                    Sentry.captureException(err)
                  })
              }}
            >
              {deleteRoleLoading ? <Spinner size={16} /> : <Icons.Delete />}
            </Button>
          </StyledEditDelete>
        )}
      </StyledCardHeader>
      <StyledCardBody>
        <h4>{role.name}</h4>
        <StyledSpan className='text-medium-gray-500'>
          {role.description}
        </StyledSpan>
      </StyledCardBody>
    </Card>
  )
}

function CreateAndEditRole ({
  create,
  role,
  blueprint,
  hide,
  mutation,
  noClick
}) {
  const alerts = useAlerts()
  const [name, setName] = React.useState(role?.name || '')
  const [description, setDescription] = React.useState(role?.description || '')

  const variables = create
    ? { name, description, parentId: blueprint.id }
    : {
        parentId: blueprint.id,
        id: role.id,
        data: {
          name,
          description
        }
      }

  return (
    <Card className='bg-white dark:bg-light-gray-300'>
      <StyledH4Edit>
        <Trans id='role.name' message='Role Name' />
      </StyledH4Edit>
      <Input autoFocus value={name} onChange={setName} />
      <StyledH4Edit>
        <Trans id='description' message='Description' />
      </StyledH4Edit>
      <Input value={description} onChange={setDescription} />
      <StyledButtonContainer>
        <Button
          disabled={noClick}
          outline
          onClick={() => {
            if (create) {
              setName('')
              setDescription('')
            }
            hide()
          }}
        >
          <Trans id='cancel' message='Cancel' />
        </Button>
        <StyledSaveButton
          disabled={noClick || !name}
          onClick={() =>
            mutation({ variables })
              .then(() => {
                if (create) {
                  setName('')
                  setDescription('')
                }
                hide()
                alerts.type3(
                  i18n._({ id: 'blueprint.saved', message: 'Blueprint Saved' }),
                  'success'
                )
              })
              .catch(err => {
                alerts.type3(
                  create ? (
                    <Trans
                      id='could.not.create.role'
                      message="Couldn't create role"
                    />
                  ) : (
                    <Trans
                      id='could.not.update.role'
                      message="Couldn't update role"
                    />
                  ),
                  'error'
                )
                Sentry.captureException(err)
              })
          }
        >
          {noClick ? (
            <Spinner size={16} />
          ) : create ? (
            <Trans id='create.role' message='Create Role' />
          ) : (
            <Trans id='save.role' message='Save Role' />
          )}
        </StyledSaveButton>
      </StyledButtonContainer>
    </Card>
  )
}

const StyledSaveButton = styled(Button)`
  width: 105px;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`

const PADDING = 16
const WIDTH = 322
const ROLE_MEDIA_WIDTH = WIDTH + PADDING * 2

const StyledDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: calc((100vw - ${WIDTH}px) / 2) ${WIDTH}px calc(
      100% - (${WIDTH}px + 100vw) / 2
    );

  @media (max-width: ${ROLE_MEDIA_WIDTH}px) {
    grid-template-columns:
      calc(${PADDING}px)
      calc(100vw - (${PADDING}px * 2))
      calc(${PADDING}px - (100vw - 100%));
  }
`

const StyledRoles = styled.div`
  width: ${WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 64px;
  grid-column-start: 2;

  @media (max-width: 500px) {
    width: 100%;
  }
`

const StyledEditDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    box-shadow: none;
    border: none;
    background: none;
    cursor: pointer;
    transition-duration: 0.2s;
    height: auto;
    width: auto;
    &:hover {
      background: none;
    }
    &:active {
      background: none;
    }
    &:focus {
      outline: none;
      background: none;
    }
  }
`

const StyledSpan = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.004em;
`

const StyledCardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.004em;
    color: #666666;
  }
`

const StyledCardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h4 {
    margin: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    margin-bottom: 2px;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 0.5rem;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.14),
    0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 3px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;

  input {
    width: 100%;
    margin-bottom: 16px;
    border: 1px solid #aaaaaa;
  }

  span {
    margin-right: 0.5rem;
  }

  p {
    margin: 0;
  }
`

const StyledH4Edit = styled.h4`
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
`
