/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import React from 'react'
import { Outlet, useOutletContext } from 'react-router'

import { useQuery } from '../../components/use-query'
import Layout from './components/layout'

export default function SubmitterControls (props) {
  const draftQ = getDraftsQuery()
  const { data } = useQuery(draftQ)
  const draftCount = data?.myDrafts?.totalCount
  return (
    <Layout draftCount={draftCount}>
      <Outlet context={useOutletContext()} />
    </Layout>
  )
}

const getDraftsQuery = () => ({
  query: gql`
    query getDrafts {
      myDrafts(args: { skip: 0, limit: 1 }) {
        totalCount
      }
    }
  `
})
