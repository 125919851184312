/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import cx from 'clsx'
import { find } from 'lodash'
import React from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router'

import { ModalPage } from '../../../components/modal-page'
import Spinner from '../../../components/spinner'
import { useAlerts } from '../../../ui/alerts'
import { useCreateLinkMutation } from './mutation.create-link'
import { useUpdateLinkMutation } from './mutation.update-link'
import { useUploadMutation } from './mutation.upload-link-image'

export function NewLink () {
  const { spaceId } = useOutletContext()
  const alerts = useAlerts()
  const navigate = useNavigate()
  const [link, setLink] = React.useState({
    title: '',
    url: '',
    description: '',
    imageUrl: ''
  })
  const [createLink, { loading }] = useCreateLinkMutation(spaceId)
  return (
    <ModalPage title={i18n._('pages.spaces.forms.new.link')}>
      <LinkForm
        link={link}
        updateLink={(key, value) =>
          setLink(link => ({ ...link, [key]: value }))
        }
        setLink={setLink}
        loading={loading}
        onSubmit={e => {
          e.preventDefault()
          createLink(link)
            .then(() => {
              alerts.type3(i18n._('pages.spaces.forms.link.created'), 'success')
              navigate('..')
            })
            .catch(error => {
              alerts.type3(i18n._('pages.spaces.forms.failed'), 'error')
              Sentry.captureException(error)
            })
        }}
        onCancel={() => navigate('..')}
      />
    </ModalPage>
  )
}

export function EditLink () {
  const { spaceId, links } = useOutletContext()
  const { linkId } = useParams()
  const alerts = useAlerts()
  const navigate = useNavigate()
  const currentLink = find(links, { id: linkId })
  const [link, setLink] = React.useState({})
  const [updateLink, { loading }] = useUpdateLinkMutation(spaceId, linkId)
  return (
    <ModalPage title={i18n._('pages.spaces.forms.edit.link')}>
      <LinkForm
        link={Object.assign({}, currentLink, link)}
        updateLink={(key, value) =>
          setLink(link => ({ ...link, [key]: value }))
        }
        setLink={setLink}
        loading={loading}
        onSubmit={e => {
          e.preventDefault()
          updateLink(link)
            .then(() => {
              alerts.type3(i18n._('pages.spaces.forms.link.updated'), 'success')
              navigate('..')
            })
            .catch(error => {
              alerts.type3(i18n._('pages.spaces.forms.failed.update'), 'error')
              Sentry.captureException(error)
            })
        }}
        onCancel={() => navigate('..')}
      />
    </ModalPage>
  )
}

function LinkForm ({ link, updateLink, loading, onSubmit, onCancel }) {
  const uploadFile = useUploadMutation()
  return (
    <div className='mt-16 flex justify-center gap-10'>
      <form
        className='flex min-w-[288px] flex-col text-sm text-dark-gray-500'
        onSubmit={onSubmit}
      >
        <h2 className='mb-4 text-xl font-medium'>
          <Trans id='pages.spaces.forms.link.basics' />
        </h2>

        <label htmlFor='link-title' className='mb-1'>
          <Trans id='pages.spaces.forms.link.title' />
        </label>
        <input
          value={link.title}
          onChange={e => updateLink('title', e.target.value)}
          required
          className='kp-input'
          type='text'
          id='link-title'
          name='title'
        />

        <label htmlFor='link-url' className='mb-1 mt-10'>
          <Trans id='url' />
        </label>
        <input
          value={link.url}
          onChange={e => updateLink('url', e.target.value)}
          required
          className='kp-input'
          type='url'
          id='link-url'
          name='url'
        />

        <label htmlFor='link-description' className='mb-1 mt-10'>
          <Trans id='pages.spaces.forms.description' />
        </label>
        <textarea
          value={link.description}
          onChange={e => updateLink('description', e.target.value)}
          className='kp-textarea resize-y'
          id='link-description'
          name='description'
        />

        <label htmlFor='link-image' className='mb-1 mt-7'>
          <Trans id='pages.spaces.forms.image' />
        </label>
        {link.imageUrl ? (
          <button
            type='button'
            className='kp-button-outline'
            onClick={() => updateLink('imageUrl', '')}
          >
            <Trans id='pages.spaces.forms.remove.image' />
          </button>
        ) : (
          <input
            id='link-image'
            name='imageUrl'
            type='file'
            accept='image/png, image/jpeg'
            onChange={async e => {
              const file = e.target.files[0]
              const { location } = await uploadFile(file)
              updateLink('imageUrl', location)
            }}
          />
        )}

        <div className='mt-8 flex items-center'>
          <button
            disabled={loading}
            className='kp-button-outline mr-4'
            type='button'
            onClick={onCancel}
          >
            <Trans id='cancel' />
          </button>
          <button disabled={loading} className='kp-button-solid'>
            {loading && <Spinner size={20} className='mr-2' />}
            {loading ? i18n._('saving') : i18n._('save')}
          </button>
        </div>
      </form>
      <div>
        <h2 className='mb-4 text-xl font-medium'>
          <Trans id='pages.spaces.forms.preview' />
        </h2>
        <PortalLink link={link} />
      </div>
    </div>
  )
}

export function PortalLink ({ link, className, spaceName }) {
  return (
    <div>
      <a
        href={link.url}
        target='_blank'
        rel='noopener noreferrer'
        className={cx(
          'flex h-[120px] overflow-hidden rounded-xl bg-white text-sm transition-shadow hover:shadow-lg',
          className
        )}
      >
        {link.imageUrl && (
          <img
            src={link.imageUrl}
            className='h-[120px] w-[120px] shrink-0 object-cover'
          />
        )}
        <div
          className={cx('p-4', {
            'w-72': link.imageUrl,
            'w-[264px]': !link.imageUrl
          })}
        >
          <label className='mb-2 block cursor-pointer truncate font-bold text-blue-500'>
            {link.title}
          </label>
          <p className='text-medium-gray-500'>{link.description}</p>
        </div>
      </a>
      {spaceName && (
        <label className='block break-all pt-1 text-center text-xs text-medium-gray-300'>
          ({spaceName})
        </label>
      )}
    </div>
  )
}
