/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import { Link, useHref, useOutletContext, useParams } from 'react-router'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import PopoverButton from '../../../components/data-table/popover-button'
import { SmallSearch } from '../../../components/data-table/search-bar'
import InfiniteScroll from '../../../components/infinite-scroll'
import { ModalPage } from '../../../components/modal-page'
import Spinner from '../../../components/spinner'
import { GraphQLError as Error } from '../../../components/system-error'
import { useQuery } from '../../../components/use-query'
import * as Icons from '../../../icons'
import * as Illustration from '../../../illustrations'
import Button from '../../../ui/button'
import GROUPS_QUERY from '../gql/query.groups'
import NewGroupPopup from '../groups/components/new-group-popup'

const defaultParams = {
  skip: 0,
  limit: 25,
  q: ''
}

export function BlueprintsGroupList () {
  const { blueprints, prefix, canManageIdentity } = useOutletContext()
  const { categoryId } = useParams()
  const [query, setQuery] = React.useState('')
  const [params, updateParams] = useImmer(defaultParams)
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      updateParams(draft => {
        draft.skip = 0
        draft.q = query
      })
    }, 400)
    return () => clearTimeout(timeout)
  }, [query])
  const { pageInfo, groups, loading, loadMore, error, refetch } = useGroupsData(
    params,
    categoryId
  )
  const blueprint = blueprints?.find(blueprint => blueprint.id === categoryId)
  const groupsPath = useHref('../../groups')

  const scrollFetch = ({ atBottom }) => {
    if (atBottom) loadMore()
  }
  const reset = React.useCallback(() => {
    setQuery('')
    updateParams(() => defaultParams)
  }, [updateParams])
  return (
    <ModalPage darkTop side title={blueprint?.name} width='375px'>
      <StyledModalContainer className='text-sm'>
        <StyledGroupListHeader className='border-b border-light-gray-300'>
          <h3 className='whitespace-nowrap'>
            <Trans id='groups.using.this.blueprint' />
          </h3>
          <StyledSmallSearch
            aria-label={
              `${blueprint?.name ?? <Trans id='blueprint' />}` +
              <Trans id='s.group.search' />
            }
            error={query.length > 0 && !groups?.length}
            onKeyDown={e => {
              if (e.key === 'Escape' && query) {
                e.preventDefault()
                e.stopPropagation()
                reset?.()
              }
            }}
            value={query}
            onChange={setQuery}
            reset={reset}
            onBlur={() =>
              updateParams(draft => {
                draft.skip = 0
              })
            }
            onEnter={() =>
              updateParams(draft => {
                draft.skip = 0
              })
            }
          />
        </StyledGroupListHeader>
        <StyledGroupList>
          {loading && <NextPageItem loading />}
          {!loading && groups?.length === 0 && query ? (
            <NoMatching reset={reset} />
          ) : (
            <InfiniteScroll onBoundary={scrollFetch}>
              {error ? (
                <Error />
              ) : (
                groups?.map(group => (
                  <StyledGroupListItem key={group.id}>
                    <Clickable
                      to={`${prefix}/groups/${group.id}/view`}
                      className='hover:text-text-link'
                    >
                      {group.name}
                    </Clickable>
                  </StyledGroupListItem>
                ))
              )}
              {pageInfo?.hasNextPage && (
                <NextPageItem handleClick={loadMore} loading={loading} />
              )}
            </InfiniteScroll>
          )}
        </StyledGroupList>
        {canManageIdentity && (
          <StyledGroupListFooter className='border-t border-t-light-gray-300'>
            <StyledPopoverButton
              right={-184}
              bottom={40}
              hideArrow
              buttonProps={{ transparent: true, icon: true }}
              label={
                <>
                  <Icons.Add className='fill-blue-500' mr={2} />
                  <Trans id='add.group' />
                </>
              }
            >
              {hide => (
                <NewGroupPopup
                  onCancel={hide}
                  blueprint={blueprint}
                  blueprints={blueprints}
                  refetch={refetch}
                  path={groupsPath}
                />
              )}
            </StyledPopoverButton>
          </StyledGroupListFooter>
        )}
      </StyledModalContainer>
    </ModalPage>
  )
}

function useGroupsData (params, categoryId) {
  const { data, loading, error, refetch, fetchMore } = useQuery({
    query: GROUPS_QUERY,
    variables: {
      categoryId,
      query: params.q,
      skip: params.skip,
      limit: params.limit
    },
    notifyOnNetworkStatusChange: true
  })
  const groups = data?.groupsConnection?.edges?.map(({ node }) => node)
  const pageInfo = data?.groupsConnection?.pageInfo

  const loadMore = React.useCallback(() => {
    if (!pageInfo?.hasNextPage) return
    fetchMore({
      variables: {
        categoryId,
        query: params.q,
        limit: pageInfo.limit,
        skip: pageInfo.skip + pageInfo.limit
      }
    })
  }, [params.q, pageInfo, fetchMore])
  return {
    groups,
    pageInfo,
    loading,
    error,
    loadMore,
    refetch
  }
}

const NextPageItem = ({ handleClick, loading }) => {
  if (loading) {
    return (
      <StyledListItemFetch className='bg-white text-text-link'>
        <Spinner size={32} />
      </StyledListItemFetch>
    )
  }
  return (
    <StyledListItemFetch
      className='bg-white text-text-link'
      onClick={handleClick}
    >
      <Trans id='scroll.load.more' />
    </StyledListItemFetch>
  )
}

const NoMatching = ({ reset }) => (
  <div className='flex h-full w-full flex-1 flex-col items-center justify-center'>
    <Illustration.Empty width={214} height={298} />
    <div className='my-6 text-center'>
      <p>
        <strong>
          <Trans id='no.matching.search.results' />
        </strong>
      </p>
      <p>
        <Trans id='please.try.again.general.search.terms' />
      </p>
    </div>
    <Button onClick={reset}>
      <Trans id='clear.all.filters' />
    </Button>
  </div>
)

const StyledListItemFetch = styled.button`
  width: 100%;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  justify-content: center;
  cursor: pointer;
  height: 52px;
`

const StyledPopoverButton = styled(PopoverButton)`
  background-color: white;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledSmallSearch = styled(SmallSearch)`
  &:focus-visible {
    outline-color: ${p => p.error && 'var(--red-400)'};
  }
  border-radius: 4px;
  border: none;
  color: #666666;
`

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

const StyledGroupListHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;

  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
    margin-right: 16px;
    text-transform: uppercase;
  }
`

const StyledGroupListFooter = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;

  h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
    margin-right: 16px;
  }
`

const StyledGroupList = styled.ul`
  list-style: none;
  flex: 1;
  width: 100%;
  overflow-y: auto;
`
const StyledGroupListItem = styled.li`
  line-height: 32px;
  font-size: 14px;
  letter-spacing: 0.25%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 16px;
`

const Clickable = styled(Link)`
  text-decoration: none;
`
