/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'

// todo :: confirm that this is the correct list of options
const VERSION_OPTIONS = [
  {
    id: 'ANY_LINKED_VERSION',
    label: (
      <Trans
        id='display.any.linked.version'
        message='Any linked submitted version'
      />
    )
  },
  {
    id: 'LATEST_SUBMITTED_LINKED_VERSION',
    label: (
      <Trans
        id='display.latest.submitted.linked.version'
        message='Only the latest submitted version linked to this document'
      />
    )
  },
  {
    id: 'LATEST_COMPLETE_LINKED_VERSION',
    label: (
      <Trans
        id='display.latest.complete.linked.version'
        message='Only the latest completed version linked to this document'
      />
    )
  }
]

export default function VersionConfig ({ id, Gadgets, className }) {
  const configKey = 'versionConfig'

  return (
    <Gadgets.Custom
      configKey={configKey}
      defaultValue={VERSION_OPTIONS[0]}
      group
      className={cx('space-y-1 rounded bg-light-gray-100 p-3', className)}
    >
      {({ onChange, value }) => (
        <>
          <legend className='float-start'>
            <Trans
              id='display.which.version'
              message='Display which version?'
            />
          </legend>
          {VERSION_OPTIONS.map(option => {
            const optionId = `${id}-${configKey}-${option.id}`
            return (
              <label key={option.id}>
                <input
                  id={optionId}
                  type='radio'
                  className='kp-radio self-start'
                  name={configKey}
                  value={option.id}
                  checked={option.id === value}
                  onChange={() => onChange(option.id)}
                />
                <span>{option.label}</span>
              </label>
            )
          })}
        </>
      )}
    </Gadgets.Custom>
  )
}
