/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

import { ModalPage } from '../../../components/modal-page'
import { useTransitionRef } from '../../../components/use-transition-ref'

export default function ViewModal ({
  setShowModal,
  showModal,
  title,
  children
}) {
  const ref = useTransitionRef(showModal)
  return (
    <TransitionGroup component={null}>
      <CSSTransition key={showModal} timeout={450} nodeRef={ref}>
        {showModal ? (
          <ModalPage
            ref={ref}
            className='cursor-default'
            title={title || i18n._('view.table')}
            onClose={event => {
              event.stopPropagation(true)
              setShowModal(false)
            }}
            onClick={e => e.stopPropagation()}
          >
            <ModalContents>{children}</ModalContents>
          </ModalPage>
        ) : (
          <span />
        )}
      </CSSTransition>
    </TransitionGroup>
  )
}

const ModalContents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 100px 100px 0;
  @media screen and (max-width: 768px) {
    padding: 48px 0 0;
  }
`
