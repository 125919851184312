/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isEmpty, keyBy, mapValues, omit } from 'lodash'
import React from 'react'
import { Link, useNavigate } from 'react-router'
import styled from 'styled-components'

import { ModalPage } from '../../../components/modal-page'
import Spinner from '../../../components/spinner'
import StaticFormbot from '../../../formbot/static'
import Button from '../../../ui/button'
import { useCreateUserMutation } from './components/mutation.create-user'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export default function UserNew () {
  const navigate = useNavigate()
  const [user, setUser] = React.useState({ role: 'user', approved: true })
  const [errors, setErrors] = React.useState({})
  const [submitting, setSubmitting] = React.useState(false)
  const createUser = useCreateUserMutation()
  return (
    <ModalPage title={i18n._('pages.users.new.new.user')}>
      <Form
        onSubmit={async e => {
          e.preventDefault()
          if (submitting) return
          setSubmitting(true)
          await sleep(1000)
          const errors = {}
          if (
            (user.password || user.confirmPassword) &&
            user.password !== user.confirmPassword
          ) {
            errors.confirmPassword = (
              <Trans id='pages.users.new.passwords.no.match' />
            )
          }
          if (user.password && user.password.length < 8) {
            errors.password = <Trans id='pages.users.new.passwords.length' />
          }
          if (!user.username && !user.email) {
            errors.username = <Trans id='pages.users.new.username.required' />
          }
          setErrors(errors)
          if (!isEmpty(errors)) return setSubmitting(false)
          const { data } = await createUser(omit(user, ['confirmPassword']))
          setSubmitting(false)
          if (data.createUser.__typename === 'InvalidFieldErrors') {
            setErrors(
              mapValues(keyBy(data.createUser.errors, 'field'), 'reason')
            )
          } else {
            navigate(`../${data.createUser.id}/view`)
          }
        }}
      >
        <StaticFormbot value={user} update={setUser} errors={errors}>
          {Gadgets => (
            <>
              <Gadgets.Text
                configKey='firstName'
                label={i18n._('pages.identity.users.new.first.name')}
              />
              <Gadgets.Text
                configKey='lastName'
                label={i18n._('pages.identity.users.new.last.name')}
              />
              <Gadgets.Text
                configKey='name'
                label={i18n._('pages.identity.users.new.display.name')}
              />
              <Gadgets.Text
                configKey='username'
                label={i18n._('pages.identity.users.new.username')}
              />
              <Gadgets.Text
                configKey='password'
                label={i18n._('pages.identity.users.new.password')}
                type='password'
              />
              <Gadgets.Text
                configKey='confirmPassword'
                label={i18n._('pages.identity.users.new.confirm.password')}
                type='password'
              />
              <Gadgets.Email
                configKey='email'
                label={i18n._('pages.identity.users.new.email')}
              />
              <Gadgets.Dropdown
                configKey='role'
                label={i18n._('pages.identity.users.new.role')}
                options={[
                  {
                    key: 'user',
                    lbl: `${i18n._('pages.identity.users.new.user')}`
                  },
                  {
                    key: 'admin',
                    lbl: `${i18n._('pages.identity.users.new.admin')}`
                  },
                  {
                    key: 'service',
                    lbl: `${i18n._('pages.identity.users.new.service')}`
                  }
                ]}
              />
              <Gadgets.Text
                configKey='schoolId'
                label={i18n._('pages.identity.users.new.school')}
              />
              <Gadgets.Checkbox
                configKey='approved'
                label={i18n._('pages.identity.users.new.approved')}
                checkLabel={i18n._('pages.identity.users.new.approved.check')}
              />
            </>
          )}
        </StaticFormbot>
        <WeirdFlexButOk>
          <Button
            disabled={submitting}
            transparent
            as={Link}
            to='..'
            type='button'
          >
            <Trans id='cancel' />
          </Button>
          <StyledButton disabled={submitting}>
            {submitting ? <Spinner size={16} /> : <Trans id='save' />}
          </StyledButton>
        </WeirdFlexButOk>
      </Form>
    </ModalPage>
  )
}

const Form = styled.form`
  padding-top: 48px;
  max-width: 500px;
  margin: 0 auto;
`

const WeirdFlexButOk = styled.div`
  display: flex;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 16px;
  }
`

const StyledButton = styled(Button)`
  width: 65px;
  &[disabled] {
    background: #3369a3 !important;
  }
`
