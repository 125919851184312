/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import { cloneDeep, isEmpty, isNil } from 'lodash'
import React from 'react'
import { useOutletContext } from 'react-router'

import { productBuilder } from '../../../components/feature-flags'
import IdentityPicker from '../../../components/identity-picker'
import useDebouncedCallback from '../../../components/use-debounced-callback'
import StaticFormbot from '../../../formbot/static'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import Button from '../../../ui/button'
import Checkbox from '../../../ui/checkbox'
import { useDeleteSpaceMutation } from './mutation.delete-space'
import { useUpdateSpaceMutation } from './mutation.update-space'
import SpaceIcon from './space-icon'

export default function GeneralPane () {
  const { space, onDelete } = useOutletContext()
  const [spaceData, setSpaceData] = React.useState(cloneDeep(space))
  const [errors, setErrors] = React.useState({})
  const updateSpace = useUpdateSpaceMutation()
  const deleteSpace = useDeleteSpaceMutation()
  const alerts = useAlerts()
  const closeIntegrationAlertsRef = React.useRef()

  const canDelete =
    !space.children?.length && !space.apps?.length && !space.integrationCount

  const handleUpdate = data => {
    setSpaceData(data)

    const errs = {}
    if (!data.name?.length) {
      errs.name = `${i18n._('pages.spaces.general.blank')}`
    }
    setErrors(errs)
    if (!isEmpty(errs)) return

    updateSpace(space.id, data)
      .then(() =>
        alerts.type3(i18n._('pages.spaces.general.settings.saved'), 'success')
      )
      .catch(err => {
        alerts.type2(i18n._('pages.spaces.general.server.error'), 'error')
        Sentry.captureException(err)
      })
  }

  const adminPg = space.listPolicyGroups.find(a => a.name === 'Space Admirals')
  const memberPg = space.listPolicyGroups.find(a => a.name === 'Space Members')

  const handleClickDelete = () => {
    deleteSpace(space.id)
      .then(() => {
        alerts.type3(
          `${space.name} ` + `${i18n._('pages.spaces.general.deleted')}`,
          'success'
        )
        onDelete?.()
      })
      .catch(err => {
        alerts.type2(i18n._('pages.spaces.general.server.error'), 'error')
        Sentry.captureException(err)
      })
  }

  return (
    <form
      className='mx-auto mb-36 mt-10 flex max-w-xs flex-col gap-8'
      onSubmit={e => e.preventDefault()}
    >
      <StaticFormbot value={spaceData} update={handleUpdate} errors={errors}>
        {Gadgets => (
          <>
            <Gadgets.Custom
              configKey='name'
              label={i18n._('pages.spaces.space.name')}
            >
              {({ onChange, value }) => (
                <NameField
                  isSuite={!!spaceData.suite}
                  disabled={isNil(spaceData.parentId) && !productBuilder}
                  error={errors.name}
                  onChange={onChange}
                  value={value}
                />
              )}
            </Gadgets.Custom>
            {adminPg && (
              <fieldset>
                <label className='text-xl font-medium'>
                  <Trans id='pages.spaces.general.administrators' />
                </label>
                <div className='pb-3 text-sm'>
                  <Trans id='pages.spaces.general.administrators.data' />
                </div>
                <IdentityPicker group={adminPg} />
              </fieldset>
            )}
            {memberPg && (
              <fieldset>
                <label className='text-xl font-medium'>
                  <Trans id='pages.spaces.general.members' />
                </label>
                <div className='pb-3 text-sm'>
                  <Trans id='pages.spaces.general.members.data' />
                </div>
                <IdentityPicker group={memberPg} />
              </fieldset>
            )}
            {(space.allowBranding || space.allowIntegrations) && (
              <fieldset>
                <label className='text-xl font-medium'>
                  <Trans id='pages.spaces.general.child.perms' />
                </label>
                {space.allowBranding && (
                  <Gadgets.Checkbox
                    configKey='allowChildBranding'
                    checkLabel={i18n._('pages.spaces.general.child.customize')}
                  />
                )}
                {space.allowIntegrations && (
                  <Gadgets.Custom configKey='allowChildIntegrations'>
                    {({ onChange, value }) => (
                      <Checkbox
                        label={i18n._(
                          'pages.spaces.general.child.integrations'
                        )}
                        onChange={val => {
                          if (val) {
                            onChange(val)
                          } else {
                            closeIntegrationAlertsRef.current?.()
                            closeIntegrationAlertsRef.current = alerts.type2(
                              i18n._('pages.spaces.general.unchecking'),
                              'error',
                              close => (
                                <Button
                                  small
                                  ml={2}
                                  onClick={() => {
                                    close()
                                    onChange(val)
                                  }}
                                >
                                  <Trans id='pages.spaces.general.continue' />
                                </Button>
                              )
                            )
                          }
                        }}
                        checked={value}
                      />
                    )}
                  </Gadgets.Custom>
                )}
              </fieldset>
            )}
            {space.parentId && (
              <fieldset>
                <label className='text-xl font-medium'>
                  <Trans id='pages.spaces.general.delete.space' />
                </label>
                <div className='text-sm'>
                  <Trans id='pages.spaces.general.delete.space.data' />
                </div>
                <button
                  type='button'
                  className='kp-button-solid mt-4 bg-red-400 hover:bg-red-410 active:bg-red-420 disabled:!bg-light-gray-400 disabled:text-white disabled:shadow-none dark:disabled:!bg-light-gray-300 dark:disabled:text-black'
                  disabled={!canDelete}
                  onClick={handleClickDelete}
                >
                  <Icons.Delete className='mr-2' />
                  <Trans id='pages.spaces.general.delete.space' />
                </button>
              </fieldset>
            )}
          </>
        )}
      </StaticFormbot>
    </form>
  )
}

function NameField ({
  isSuite,
  disabled,
  error,
  onChange,
  value: initialValue
}) {
  const [value, setValue] = React.useState(initialValue || '')
  const debouncedOnChange = useDebouncedCallback(onChange, 1000)
  const handleChange = React.useCallback(
    val => {
      setValue(val)
      debouncedOnChange(val)
    },
    [debouncedOnChange]
  )
  return (
    <div className='flex flex-wrap items-center gap-2'>
      <input
        className='kp-input disabled:bg-light-gray-200 disabled:text-medium-gray-100'
        id='name'
        value={value}
        onChange={e => handleChange(e.target.value)}
        disabled={disabled}
        aria-invalid={error ? 'true' : 'false'}
        aria-errormessage='name-error'
      />
      {!isSuite && <SpaceIcon name={value} />}
      {error && (
        <div id='name-error' className='text-xs text-red-400'>
          {error}
        </div>
      )}
    </div>
  )
}
