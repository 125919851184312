/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep, isEmpty, keyBy, mapValues, omit } from 'lodash'
import React from 'react'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router'
import styled from 'styled-components'

import Loading from '../../../../components/loading'
import Spinner from '../../../../components/spinner'
import { GraphQLError as Error } from '../../../../components/system-error'
import StaticFormbot from '../../../../formbot/static'
import Button from '../../../../ui/button'
import { useUpdateUserMutation } from './components/mutation.update-user'

export default function UserEdit () {
  const props = useOutletContext()
  const params = useParams()
  const id = props.id || params.id
  const { data, loading, error } = useQuery(userQuery, { variables: { id } })
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <UserEditInner
      key={data.user.id}
      id={id}
      isAccountPage={!!props.id}
      initialUser={cloneDeep(data.user)}
    />
  )
}

const userQuery = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      name
      username
      email
      role
      schoolId
      approved
      active
    }
  }
`

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

function UserEditInner ({ id, isAccountPage, initialUser }) {
  const navigate = useNavigate()
  const [user, setUser] = React.useState(initialUser)
  const [errors, setErrors] = React.useState({})
  const [submitting, setSubmitting] = React.useState(false)
  const updateUser = useUpdateUserMutation(id)
  return (
    <Form
      onSubmit={async e => {
        e.preventDefault()
        if (submitting) return
        setSubmitting(true)
        await sleep(1000)
        const errors = {}
        if (
          (user.password || user.confirmPassword) &&
          user.password !== user.confirmPassword
        ) {
          errors.confirmPassword = <Trans id='passwords.no.match' />
        }
        if (user.password && user.password.length < 8) {
          errors.password = <Trans id='passwords.length' />
        }
        if (!user.username && !user.email) {
          errors.username = <Trans id='username.email.required' />
          errors.email = <Trans id='username.email.required' />
        }

        setErrors(errors)
        if (!isEmpty(errors)) return setSubmitting(false)
        const { data } = await updateUser(
          omit(user, ['id', 'confirmPassword', '__typename'])
        )
        setSubmitting(false)
        if (data.updateUser.__typename === 'InvalidFieldErrors') {
          setErrors(mapValues(keyBy(data.updateUser.errors, 'field'), 'reason'))
        } else {
          navigate('../view')
        }
      }}
    >
      <StaticFormbot value={user} update={setUser} errors={errors}>
        {Gadgets => (
          <>
            <Gadgets.Text configKey='firstName' label={i18n._('first.name')} />
            <Gadgets.Text configKey='lastName' label={i18n._('last.name')} />
            <Gadgets.Text configKey='name' label={i18n._('display.name')} />
            {!isAccountPage && (
              <Gadgets.Text configKey='username' label={i18n._('username')} />
            )}
            <Gadgets.Text
              configKey='password'
              label={i18n._('password')}
              type='password'
            />
            <Gadgets.Text
              configKey='confirmPassword'
              label={i18n._('confirm.password')}
              type='password'
            />
            <Gadgets.Email configKey='email' label={i18n._('email')} />
            {!isAccountPage && (
              <>
                <Gadgets.Dropdown
                  configKey='role'
                  label={i18n._('role')}
                  options={[
                    { key: 'user', lbl: `${i18n._('user')}` },
                    { key: 'admin', lbl: `${i18n._('admin')}` },
                    { key: 'service', lbl: `${i18n._('service')}` }
                  ]}
                />
                <Gadgets.Text
                  configKey='schoolId'
                  label={i18n._('school.id')}
                />
                <Gadgets.Checkbox
                  configKey='approved'
                  label={i18n._('approved')}
                  checkLabel={i18n._('user.approved')}
                />
              </>
            )}
          </>
        )}
      </StaticFormbot>
      <WeirdFlexButOk>
        <Button
          disabled={submitting}
          transparent
          as={Link}
          to='../view'
          relative='path'
          type='button'
        >
          <Trans id='cancel' />
        </Button>
        <StyledButton disabled={submitting}>
          {submitting ? <Spinner size={16} /> : <Trans id='save' />}
        </StyledButton>
      </WeirdFlexButOk>
    </Form>
  )
}

const Form = styled.form`
  padding-top: 48px;
  max-width: 500px;
  margin: 0 auto;
`

const WeirdFlexButOk = styled.div`
  display: flex;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 16px;
  }
`

const StyledButton = styled(Button)`
  width: 65px;
  &[disabled] {
    background: #3369a3 !important;
  }
`
