/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { produce } from 'immer'
import { compact, isEqual, keyBy } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'

import { winnowList } from '../../../components/escape-string-regexp'
import FYI from '../../../components/fyi'
import Spinner from '../../../components/spinner'
import { formbot } from '../../../formbot'
import * as Icons from '../../../icons'
import { GadgetListItem } from '../../../pages-builder/form/gadget-list'
import { Checkbox } from '../../../ui/checkbox'
import * as Chooser from '../../data-chooser'
import { gatherAllSubGadgets } from '../../engine/formbot/utils'
import FiltersConfig from '../../filter-config'
import AutoUpdateConfig from './auto-update-config'
import { useLatestDatasetInfo } from './use-latest-dataset-info'

export function RequiredConfig ({
  Gadgets,
  updateDataLookupSource,
  onChange,
  indexed,
  indexType,
  selectedLinks,
  value,
  formKey,
  allGadgets
}) {
  const { appId } = useParams()
  const [showLinkedFieldsChooser, setShowLinkedFieldsChooser] =
    React.useState(false)
  const [showAdvancedSettings, setShowAdvancedSettings] = React.useState(false)
  React.useEffect(() => {
    if (!value) return
    if (!Array.isArray(value.outputFields)) setShowLinkedFieldsChooser(true)
  }, [value?.outputFields])
  const datasetInfo = useLatestDatasetInfo(appId, value?.id, value?.pageId)
  React.useEffect(() => {
    if (!datasetInfo || !value) return
    const prevDatasetInfo = {
      label: value.label,
      tileOptions: value.tileOptions,
      allowNewVersions: value.allowNewVersions
    }
    if (!isEqual(prevDatasetInfo, datasetInfo)) {
      return onChange({ ...value, ...datasetInfo })
    }
  }, [datasetInfo, value])

  const columns = gatherAllSubGadgets(
    value?.outputFields?.map(f => ({ ...f, formKey: f.path })),
    formbot
  )

  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <Chooser.Typeahead
        id={value.id}
        label={value.label}
        tileOptions={value.tileOptions}
        isProduct={value.isProduct}
        value={value}
        onSelect={updateDataLookupSource}
        indexed={indexed}
        indexType={indexType}
        selectedLinks={selectedLinks}
        hasLinked={!!selectedLinks}
      />
      <div>
        <button
          className='pr-4 text-left text-text-link underline'
          onClick={() => setShowLinkedFieldsChooser(true)}
        >
          <Trans id='edit.available.data' />
        </button>
        <button
          className='text-left text-text-link underline'
          onClick={() => setShowAdvancedSettings(true)}
        >
          <Trans id='advanced.settings' />
        </button>
      </div>
      {showLinkedFieldsChooser && value && (
        <LinkedFieldsChooser
          formKey={formKey}
          value={value}
          selectedLinks={selectedLinks}
          onHide={() => setShowLinkedFieldsChooser(false)}
          onContinue={outputFields => {
            onChange({ ...value, outputFields })
            setShowLinkedFieldsChooser(false)
          }}
        />
      )}

      <FiltersConfig
        isOpen={showAdvancedSettings}
        setIsOpen={setShowAdvancedSettings}
        params={{
          filter: value.filter,
          columns,
          versionConfig: value.versionConfig ?? 'LATEST_VERSION',
          versionFilter: value.versionFilter,
          needsVersionFilter: value.needsVersionFilter ?? false
        }}
        compareGadgets={allGadgets?.reduce((acc, gad) => {
          const transformedGad = {
            ...gad,
            formKey: `KUALI_THIS_FORM_DATA.${gad.formKey}`
          }
          if (!(gad.type in acc)) acc[gad.type] = [transformedGad]
          else acc[gad.type].push(transformedGad)
          return acc
        }, {})}
        updateParams={func => {
          const newVal = produce(value, func)
          return onChange(newVal)
        }}
        hasVersions={datasetInfo?.allowNewVersions}
      >
        <AutoUpdateConfig
          id={formKey}
          Gadgets={Gadgets}
          hasVersions={datasetInfo?.allowNewVersions}
        />
        <div className='mb-6 mt-3 border-t border-light-gray-300' />
      </FiltersConfig>
    </Gadgets.Padded>
  )
}

export function useFields ({
  id,
  formKey,
  value,
  outputFields = value?.outputFields,
  selectedLinks
}) {
  const { appId, pageId } = useParams()
  const { data, loading } = useQuery(formTypeaheadAppQuery, {
    fetchPolicy: 'network-only',
    variables: {
      appId,
      pageId,
      sharedAppId: value?.id,
      sharedPageId: value?.pageId
    },
    skip: !appId || !value?.id
  })
  const latestFields =
    data?.app?.sharedWithMe?.app?.dataset?.formVersion?.schema?.filter(
      field => field?.type !== 'FileUpload'
    ) || []
  const latestFieldsById = React.useMemo(
    () => keyBy(latestFields, 'id'),
    [latestFields]
  )
  const workflowFieldsByFormKey = React.useMemo(() => {
    const workflow = data?.app?.dataset?.workflow
    if (!workflow) return {}
    return Object.fromEntries(
      findAllReferencesInSteps(workflow.steps).flatMap(key => {
        if (!key.startsWith(`data.${formKey}`)) return []
        key = key.replace(`data.${formKey}.`, '')
        return [[key, key]]
      })
    )
  }, [data?.app?.dataset?.workflow, formKey])
  const formFieldsById = React.useMemo(
    () =>
      keyBy(
        selectedLinks
          ?.filter(link => link.id !== id)
          .map(link => link?.details?.selectedOutputField),
        'id'
      ),
    [selectedLinks]
  )
  const outputFieldsById = React.useMemo(
    () => keyBy(outputFields, 'id'),
    [outputFields]
  )
  const deprecatedFields = React.useMemo(
    () =>
      Object.values(outputFieldsById).filter(
        field => !latestFieldsById[field.id]
      ),
    [outputFieldsById, latestFieldsById]
  )
  const deprecatedFieldsById = React.useMemo(() => {
    return loading ? {} : keyBy(deprecatedFields, 'id')
  }, [deprecatedFields, loading])
  const fields = [...deprecatedFields, ...latestFields]

  return {
    loading: loading || !data,
    fields,
    deprecatedFieldsById,
    outputFieldsById,
    formFieldsById,
    latestFieldsById,
    workflowFieldsByFormKey
  }
}

export function OptionalConfig ({
  id,
  formKey,
  Gadgets,
  value,
  onChange,
  selectedLinks,
  beginDrag,
  beginA11yDrag,
  endDrag,
  gadgets
}) {
  const {
    loading,
    fields,
    deprecatedFieldsById,
    formFieldsById,
    workflowFieldsByFormKey,
    outputFieldsById
  } = useFields({
    id: value.id,
    formKey,
    value,
    selectedLinks
  })

  const selectedFields = fields.filter(gadget => outputFieldsById[gadget.id])

  const [search, setSearch] = React.useState('')
  const filteredFields = winnowList(selectedFields, search, 'label')

  return (
    <>
      <Gadgets.ConfigBox
        configKey='placeholder.enabled'
        label={i18n._('show.placeholder.text')}
        description={i18n._('show.placeholder.text.data')}
      >
        <Gadgets.Text configKey='placeholder.value' />
      </Gadgets.ConfigBox>
      {(!!fields.length || loading) && (
        <Gadgets.ConfigBox
          configKey='linkedGadgets.enabled'
          label={i18n._('add.linked.auto.filled.gadgets')}
          description={i18n._('add.linked.auto.filled.gadgets.data')}
          disableToggle={!!selectedLinks}
          disableToggleText={i18n._('remove.linked.gadgets.first')}
        >
          <div className='flex flex-col gap-2 pb-2'>
            {fields.length > 0 && (
              <SearchBar value={search} onChange={setSearch} />
            )}
            <div>
              {filteredFields.map((field, i) => (
                <GadgetListItem
                  key={field.id}
                  id={i}
                  className='!rounded !pl-2 !pr-4 hover:!bg-blue-100'
                  beginDrag={beginDrag}
                  beginA11yDrag={beginA11yDrag}
                  endDrag={dropped => {
                    if (
                      dropped &&
                      !value?.outputFields?.find(f => f.id === field.id)
                    ) {
                      onChange({
                        ...value,
                        outputFields: [field, ...(value?.outputFields ?? [])]
                      })
                    }
                    endDrag(dropped)
                  }}
                  Icon={gadgets[field.type]?.meta?.Icon}
                  text={field.label}
                  dragContext={{
                    type: 'DataLink',
                    details: {
                      label: field.label,
                      parentId: `data.${id}`,
                      selectedOutputField: field
                    }
                  }}
                >
                  <span className='flex gap-2'>
                    {formFieldsById[field.id] && (
                      <span className='kp-badge'>On Form</span>
                    )}
                    {workflowFieldsByFormKey[field.path] && (
                      <span className='kp-badge'>In Workflow</span>
                    )}
                    {deprecatedFieldsById[field.id] && (
                      <span
                        className='kp-badge'
                        title={i18n._('field.does.not.exist.latest.form')}
                      >
                        <Trans id='deprecated' />
                      </span>
                    )}
                  </span>
                </GadgetListItem>
              ))}
            </div>
          </div>
        </Gadgets.ConfigBox>
      )}
    </>
  )
}

const formTypeaheadAppQuery = gql`
  query FormTypeaheadAppQuery(
    $appId: ID!
    $pageId: ID
    $sharedAppId: ID!
    $sharedPageId: ID
  ) {
    app(id: $appId, isConfiguration: true) {
      id
      dataset(id: $pageId) {
        id
        workflow
      }
      sharedWithMe {
        app(id: $sharedAppId) {
          id
          dataset(id: $sharedPageId) {
            id
            formVersion {
              id
              schema {
                id
                label
                type
                path: formKey
                details
                conditionalVisibility
                childrenTemplate {
                  id
                  label
                  type
                  formKey
                  details
                  children
                  conditionalVisibility
                }
              }
            }
          }
        }
      }
    }
  }
`

// copied from app/src/components/search-bar
// TODO:: Should we make the search bar extensible? How should we extend styles
// for internal components?
export const SearchBar = ({ className, onChange, value, ...props }) => {
  const ref = React.useRef()
  return (
    <div className={cx('flex justify-end', className)}>
      <div className='relative flex w-full'>
        <Icons.Search className='absolute left-2 top-2 fill-light-gray-500' />
        <input
          ref={ref}
          role='search'
          placeholder={i18n._('search')}
          className='h-8 w-full min-w-0 rounded border border-light-gray-500 bg-white pl-8 pr-2 text-sm dark:border-light-gray-300'
          onKeyUp={e => {
            if (e.keyCode === 27 || e.key === 'esc') onChange('')
          }}
          onChange={e => onChange(e.target.value)}
          value={value}
          {...props}
        />
        {value && (
          <button
            className='kp-button-transparent kp-button-icon absolute right-0 top-0 hover:bg-transparent'
            onClick={() => {
              onChange('')
              ref.current.focus()
            }}
          >
            <span className='sr-only'>
              <Trans id='clear.search' />
            </span>
            <Icons.Close width={10} height={10} />
          </button>
        )}
      </div>
    </div>
  )
}

export function LinkedFieldsChooser ({
  onHide,
  formKey,
  value,
  selectedLinks,
  onContinue
}) {
  const [outputFields, setOutputFields] = React.useState(
    value?.outputFields ?? []
  )
  const {
    loading,
    fields,
    deprecatedFieldsById,
    formFieldsById,
    latestFieldsById,
    workflowFieldsByFormKey,
    outputFieldsById
  } = useFields({
    id: value.id,
    formKey,
    value,
    outputFields,
    selectedLinks
  })
  const [query, setQuery] = React.useState('')
  const filteredFields = winnowList(fields, query, 'label')

  React.useEffect(() => {
    setOutputFields(outputFields => {
      const outputFieldsById = keyBy(outputFields, 'id')
      const newFields =
        value?.outputFields?.filter(field => !outputFieldsById[field.id]) ?? []
      return newFields.length ? outputFields.concat(newFields) : outputFields
    })
  }, [value?.outputFields])

  React.useEffect(() => {
    setOutputFields(outputFields => {
      let changed = false
      const updatedOutputFields = outputFields.map(field => {
        const latestField = latestFieldsById[field.id]
        if (latestField && !isEqual(field, latestField)) {
          changed = true
          return latestField
        }
        return field
      })
      return changed ? updatedOutputFields : outputFields
    })
  }, [latestFieldsById])

  return (
    <div className='fixed z-10'>
      <div className='fixed left-[calc(50%-12rem)] top-20 flex min-h-max w-96 flex-col rounded bg-white shadow-lg shadow-medium-gray-100 dark:shadow-light-gray-200'>
        <div className='flex items-center justify-between border-b border-b-light-gray-300 bg-light-gray-100 px-4 py-2 uppercase text-medium-gray-500 dark:bg-light-gray-300 dark:text-dark-gray-500'>
          <p>
            <Trans id='select.data' />
          </p>
          <button
            className='kp-button-transparent kp-button-icon kp-button-sm'
            onClick={() => onHide()}
          >
            <Icons.Close width='12px' height='12px' />
          </button>
        </div>
        <div className='flex max-h-80 min-h-[20rem] flex-1 flex-col overflow-scroll p-4'>
          {loading ? (
            <Spinner size={16} />
          ) : (
            <>
              <SearchBar className='mb-2' value={query} onChange={setQuery} />
              <FYI
                className='bg-blue-100 p-4'
                icon={
                  <span className='rounded-sm bg-blue-200 px-2 py-1'>
                    <Trans id='fyi' />
                  </span>
                }
                messageKey='data_sharing_field_management'
                maxClose={10}
              >
                <p>
                  <Trans id='select.only.essential.data' />
                </p>
              </FYI>
              <ul>
                {filteredFields.map((field, i) => (
                  <li key={field.id} className='flex items-center py-2'>
                    <Checkbox
                      className='!mt-0'
                      id={`select-data-${field.id}`}
                      checked={Boolean(outputFieldsById[field.id])}
                      disabled={Boolean(
                        formFieldsById[field.id] ||
                          workflowFieldsByFormKey[field.path]
                      )}
                      onChange={e => {
                        if (
                          formFieldsById[field.id] ||
                          workflowFieldsByFormKey[field.path]
                        ) {
                          return
                        }
                        const checked = e.target.checked
                        setOutputFields(outputFields =>
                          checked
                            ? outputFields.concat([field])
                            : outputFields.filter(f => f.id !== field.id)
                        )
                      }}
                    />
                    <label
                      className='flex-grow text-sm'
                      htmlFor={`select-data-${field.id}`}
                    >
                      {field.label}
                    </label>
                    <span className='flex gap-2'>
                      {formFieldsById[field.id] && (
                        <span className='kp-badge'>On Form</span>
                      )}
                      {workflowFieldsByFormKey[field.path] && (
                        <span className='kp-badge'>In Workflow</span>
                      )}
                      {deprecatedFieldsById[field.id] && (
                        <span
                          className='kp-badge'
                          title={i18n._('field.does.not.exist.latest.form')}
                        >
                          <Trans id='deprecated' />
                        </span>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className='flex justify-end border-t border-t-light-gray-300 px-4 py-3'>
          <button
            className='kp-button-solid'
            onClick={() => onContinue(outputFields)}
          >
            <Trans id='continue' />
          </button>
        </div>
      </div>
    </div>
  )
}

function findAllReferencesInSteps (steps) {
  return (
    steps?.flatMap(step => {
      const assigneeReference = step?.assignee?.value?.formKey ?? ''
      const assigneeReferences = assigneeReference ? [assigneeReference] : []
      const subflowsToCheck = step?.subflows ?? []
      const subflowReferences = subflowsToCheck.flatMap(subflow =>
        findAllReferencesInSteps(subflow.steps)
      )
      switch (step.type) {
        case 'approval':
        case 'acknowledge': {
          let bodyReferences = []
          let subjectReferences = []
          if (step?.customNotification?.enabled) {
            const body = step?.customNotification?.body ?? ''
            bodyReferences = findTemplateVariables(body)
            const subject = step?.customNotification?.subject ?? ''
            subjectReferences = findTemplateVariables(subject)
          }
          return [
            ...assigneeReferences,
            ...subflowReferences,
            ...bodyReferences,
            ...subjectReferences
          ]
        }
        case 'notification': {
          const body = step?.body ?? ''
          const bodyReferences = findTemplateVariables(body)
          const subject = step?.subject ?? ''
          const subjectReferences = findTemplateVariables(subject)
          return [
            ...assigneeReferences,
            ...bodyReferences,
            ...subjectReferences
          ]
        }
        case 'conditional': {
          const ruleReferences = subflowsToCheck.flatMap(subflow => {
            if (subflow?.rule?.expressions) {
              return subflow.rule.expressions.flatMap(expr =>
                compact([expr?.left?.formKey, expr?.right?.formKey])
              )
            }
            return compact([
              subflow?.rule?.left?.formKey,
              subflow?.rule?.right?.formKey
            ])
          })
          return [...subflowReferences, ...ruleReferences]
        }
        default:
          return []
      }
    }) ?? []
  )
}

function findTemplateVariables (template) {
  return (template.match(/{{([^}]+)}}/g) ?? []).map(match =>
    match.replace(/{{([^}]+)}}/, '$1')
  )
}
