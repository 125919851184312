/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

import DisplayImage from './parts/display-image'

export default function ImageUploadView ({ value }) {
  return value ? (
    <div className='flex flex-col items-center justify-center'>
      <DisplayImage value={value} />
    </div>
  ) : null
}
