/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
/* eslint-disable no-unused-vars */
import { includes } from 'lodash'

// --- Hosts -------------------------------------------------------------------

const devhosts = [
  'buildprs.kualibuild.com',
  'greendale.kualibuild.com',
  'products.kualihub.com',
  'products-playground.kualihub.com',
  'dallin.kualibuild.com',
  'ksmithut.kualibuild.com',
  'kipernicus.kualibuild.com'
]

const localhosts = [
  'monsters-local.kuali.co',
  'greendale-local.kuali.co',
  'local.kualibuild.com',
  'local-test.kualibuild.com',
  'local.kuali.build',
  'localhost'
]

const testhosts = [
  'localhost',
  'buildprs.kualibuild.com',
  'local-test.kualibuild.com'
]

const extraNinja = [
  'victordemo-modern-sbx.kualihub.com',
  'student-team-modern-sbx.kualihub.com'
]

// -----------------------------------------------------------------------------

// This is a client-side only check. If a user sets their clock they can advance
// time and unlock a feature early. The date passed should be in ISO format with
// timezone offset attached: '2022-11-21T11:00:00.000-06:00'
// The '-06:00' is the offset to make this time the same as Lehi UT (MDT) (Mar - Nov)
// For MST (Nov - Mar), change the offset to '-07:00'
// Also, remember the time is done in military, so 1pm is 13:00
export function isAfter (date) {
  if (!date) return false
  return +new Date() >= +date
}

interface Flag {
  id: string
  title: string
  description: string
  deprecationReason?: string
  server?: boolean
  status: 'disabled' | 'alpha' | 'beta' | 'enabled'
  querystring?: string
  hosts?: string[]
  modernSbx?: boolean
  enabledIfAfter?: Date
}

type Overrides = { [key: string]: boolean }

const storageState = window.localStorage.getItem('feature-flags')
const overrides: Overrides = storageState ? JSON.parse(storageState) : {}
const host = window.location.host.replace(/:\d*$/, '')
const searchParams = new URLSearchParams(window.location.search)
const isNinja =
  host.endsWith('-ninja.kuali.co') ||
  host.endsWith('-ninja.kualibuild.com') ||
  includes(extraNinja, host)
const isDevHost = includes([...devhosts, ...localhosts], host)
const isResearchSandbox = host.includes('-modern-sbx.kuali')

export const flags = []

function createFlag (flag: Flag): boolean {
  flags.push(flag)
  return resolveState(flag, overrides)
}
export function resolveState (flag: Flag, overrides: Overrides): boolean {
  if (flag.status === 'enabled') return true
  if (flag.status === 'disabled') return false
  if (isAfter(flag.enabledIfAfter)) return true
  if (flag.id in overrides) return overrides[flag.id]
  if (flag.modernSbx && isResearchSandbox) return true
  if (flag.status === 'beta' && (isNinja || isDevHost)) return true
  if (includes(flag.hosts, host)) return true
  if (searchParams.get(flag.querystring) === 'true') return true
  return false
}

// ---- Flags ------------------------------------------------------------------

/*

# Defining a feature flag

- id (required): This must be the same as the exported key
- title (required): A human readable title of what this feature is.
- description (required): A short description of what this feature does and why
                          you would want to enable/disable it.
- deprecationReason: Marks the feature as deprecated.
- server: Indicates that there is a server component that needs to be enabled or
          a migration that needs to be run before this feature will function
          correctly.
- status (required): Must be one of the following
  - enabled: This feature is enabled for everyone. This cannot be overriden.
  - disabled: This feature is disabled for everyone. This cannot be overriden.
  - alpha: This feature is fairly new. It is only enabled for the specified
           hosts and querystring.
  - beta: This feature is pretty stable and ready for further testing. It is
          enabled for local environments, dev environments, -ninja environments,
          as well as the specified hosts and querystring.
- querystring: Entering this into the query of a URL will turn on the feature.
- hosts: These tenants will also have the feature.
- modernSbx: Enabled for tenants with '-modern-sbx.kuali' in their URL
- enabledIfAfter: A date in the future that the feature will be enabled for everyone.

*/

export const featureFlagsModal = createFlag({
  id: 'featureFlagsModal',
  title: 'Feature Flags Modal',
  description:
    "That's this modal! This flag controls whether or not an item shows up in the account menu which, when clicked, would navigate to this modal.",
  status: 'beta'
})

export const flumeIntegrations = createFlag({
  id: 'flumeIntegrations',
  title: 'Advanced Integrations',
  description:
    "Enables Kuali Lasso which is a tool that allows you to create more intricate integrations with external systems. Specifically it let's you perform multiple API calls and re-map data in a single integration.",
  status: 'beta',
  querystring: 'flumeintegrations'
})

export const creditsGadget = createFlag({
  id: 'creditsGadget',
  title: 'New Forms Gadget: Credits',
  description:
    'Adds a new gadget that allows a user to capture the number of credits a course offers. Supports single value, multiple value, and range.',
  deprecationReason:
    "This gadget was made during a different time. We have since decided that we don't want to have any product-specific code in platform. We'll be re-building this in a more generic way.",
  status: 'beta',
  hosts: ['institute.kualibuild.com']
})

export const externalRichText = createFlag({
  id: 'externalRichText',
  title: 'External Rich Text',
  description:
    "This allows us to display rich text content coming from integrations without extra formatting. We still sanitize the incoming data but with this flag we won't clean out any extra whitespace.",
  status: 'beta',
  hosts: ['institute.kualibuild.com', 'tstc-sbx.kualibuild.com'],
  querystring: 'externalrichtext'
})

export const productPlayground = createFlag({
  id: 'productPlayground',
  title: 'Product Playground',
  description:
    "This flag updates the branding for product builder to product playground. This flag requires the Product Builder flag to be enabled or it won't actually do anything.",
  status: 'alpha',
  hosts: ['products-playground.kualihub.com']
})

export const productBuilder = createFlag({
  id: 'productBuilder',
  title: 'Product Builder',
  description:
    "This one probably no longer belongs in the feature flag list. It's more of a permanent flag but only for very specific environments. If you aren't in one of those environments please don't turn this on. It will break you.",
  server: true,
  status: 'alpha',
  hosts: [
    'products.kualihub.com',
    'products-playground.kualihub.com',
    'local.kualibuild.com',
    'local-test.kualibuild.com',
    'greendale.kualibuild.com',
    'buildprs.kualibuild.com'
  ]
})

export const appHelpLink = createFlag({
  id: 'appHelpLink',
  title: 'Application Help Link',
  description:
    'This flag enables a new input in form settings for defining a help link. When filled out, that help link will appear on the side of every document for that form. This flag is only needed to _configure_ the help link; not display it.',
  status: 'beta',
  querystring: 'helplink'
})

export const guidedPersonPicker = createFlag({
  id: 'guidedPersonPicker',
  title: 'Guided Person Picker',
  description:
    'This is an exploration for how we could more easily configure workflows. It presents a more conversational format for deciding who will be taking action on a certain workflow step.',
  status: 'alpha',
  querystring: 'personpicker'
})

export const conditionalPermissions = createFlag({
  id: 'conditionalPermissions',
  title: 'Conditional Permissions',
  description:
    'This one enables a new permissions modal. It allows you to define permissions for people to edit/view the form based on fields entered on the form. You can do things like allow people to see documents that only belong to their department.',
  status: 'beta',
  querystring: 'coolperms'
})

export const useNewAnnouncekit = createFlag({
  id: 'useNewAnnouncekit',
  title: 'New Announcekit UI',
  description:
    'This removes announcekit from the top bar and places it inside the account menu',
  status: 'alpha'
})

export const showCompareVersions = createFlag({
  id: 'showCompareVersions',
  deprecationReason: "Please use the 'Track Changes' flag instead.",
  title: 'Show Compare Version Toggle',
  description:
    'This is a feature that allows you to compare the current version with the previous version. Currently it only works on the "My Documents" view and is working on outdated code. We hope that we\'ll be able to repurpose the code to work for track changes',
  status: 'alpha'
})

export const multipleLanguages = createFlag({
  id: 'multipleLanguages',
  title: 'Multiple Language Support',
  description: 'Adds capability to platform to support multiple languages.',
  status: 'alpha',
  hosts: [
    'taddgiles-ninja.kualibuild.com',
    'local.kualibuild.com',
    'ucchile.kualihub.com',
    'ucchile-sbx.kualihub.com',
    'ucchile-stg.kualihub.com',
    'ucchile-sbx.kualibuild.com'
  ]
})

export const relativeMinMaxDate = createFlag({
  id: 'relativeMinMaxDate',
  title: 'Relative Min/Max Date',
  description: 'Allows users to set relative min/max dates on date gadgets.',
  status: 'beta'
})

export const associationsGadget = createFlag({
  id: 'associationsGadget',
  title: 'Associations Gadget',
  description: 'This enables the associations gadget.',
  status: 'alpha'
})

export const newDocumentHistory = createFlag({
  id: 'newDocumentHistory',
  title: 'New Document History',
  description: 'This is a new version of the document history',
  status: 'alpha'
})

export const emailInHistory = createFlag({
  id: 'emailInHistory',
  title: 'Email in Document History',
  description: 'This flag adds email to the document history',
  status: 'alpha'
})

export const printDocumentHistory = createFlag({
  id: 'printDocumentHistory',
  title: 'Print Document History',
  description: 'Add a print button to the document history',
  status: 'alpha'
})

export const autoUpdatingFilters = createFlag({
  id: 'autoUpdatingFilters',
  title: 'Filters for Automatic Document Updating',
  description:
    'Adds an option for advanced filters to the automatic updating configuration.',
  status: 'alpha'
})

export const filtersOnDocList = createFlag({
  id: 'filtersOnDocList',
  title: 'Document List Filters',
  description:
    'This flag enables the new filters UI on the Document List. Previously it was only available on form-typeaheads a form-multiselects.',
  status: 'alpha'
})

export const trackChanges = createFlag({
  id: 'trackChanges',
  title: 'Track Changes',
  description:
    'This feature allows you to see what has changed between versions of a document. It can be found in the top right menu on the view-document page for any versioned document.',
  status: 'beta'
})

export const validationGadget = createFlag({
  id: 'validationGadget',
  title: 'Validation Gadget',
  description:
    'This flag enables the validation gadget. It allows you to add a validation gadget to your form that can be used to turn conditional visibility into validation logic.',
  status: 'beta',
  enabledIfAfter: new Date('2024-11-20T03:00:00.000-07:00')
})

export const extraFilterGadgets = createFlag({
  id: 'extraFilterGadgets',
  title: 'Extra Filter Gadgets',
  description: 'This flag enables extra gadgets to be used in filters.',
  status: 'beta'
})

export const limitSubmissionOptions = createFlag({
  id: 'limitSubmissionOptions',
  title: 'Additional Limit Submission Options',
  description:
    'This flag enables additional options for limiting submissions on a form.',
  status: 'alpha',
  enabledIfAfter: new Date('2024-11-21T03:00:00.000-07:00')
})

export const consolidateHomeRoutes = createFlag({
  id: 'consolidateHomeRoutes',
  title: 'Consolidate Home Routes',
  description:
    'This moves Action List and My Documents into modals on the home page.',
  status: 'alpha',
  enabledIfAfter: new Date('2024-12-16T03:00:00.000-07:00')
})

export const removeHomeRedirects = createFlag({
  id: 'removeHomeRedirects',
  title: 'Remove Home Route Redirects',
  description:
    'On 12/16/2024 we updated some of our home page routes and added redirects for the old routes. We decided to keep these redirects in place for 6 months. This flag ensures those redirects are removed on the correct date',
  status: 'alpha',
  enabledIfAfter: new Date('2025-06-16T03:00:00.000-07:00')
})
