/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import * as Illustration from '../../illustrations'
import { BrandingHeader } from '../branding-header'

const Wrapper = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 20px 50px;
  @media screen and (min-width: 1025px) {
    margin-top: ${p => (p.brandColor ? '-160px' : 0)};
    padding: 0 24px 100px;
    width: 800px;
  }
`

const Title = styled.div`
  border-radius: 10px;
  padding: 32px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
  & p {
    margin-bottom: 8px;
  }
  & p:last-child {
    margin-bottom: 0px;
  }
`

const getMessage = status => {
  switch (status) {
    case 'withdrawn':
      return (
        <Trans
          id='form.has.been.withdrawn'
          message='This form has been withdrawn.'
        />
      )
    case ('sent back', 'cancelled-sentback'):
      return (
        <Trans
          id='form.has.been.sent.back'
          message='This form has been sent back.'
        />
      )
    case ('rejected', 'cancelled-rejected'):
      return (
        <Trans id='form.has.been.denied' message='This form has been denied.' />
      )
    case 'cancelled-reassigned':
      return (
        <Trans
          id='form.has.been.reassigned'
          message='This form has been reassigned.'
        />
      )
    case 'completed':
      return (
        <Trans
          id='form.has.been.submitted'
          message='This form has been submitted.'
        />
      )
    case 'approved':
      return (
        <Trans
          id='form.has.been.approved'
          message='This form has been approved.'
        />
      )
    case 'acknowledged':
      return (
        <Trans
          id='form.has.been.acknowledged'
          message='This form has been acknowledged.'
        />
      )
    default:
      return (
        <Trans
          id='form.has.been.processed'
          message='This form has been processed.'
        />
      )
  }
}

export default function FinishedPage ({
  status,
  newFormUrl,
  brandColor,
  branding
}) {
  return (
    <Wrapper
      className='bg-white dark:bg-light-gray-300'
      zDepth={3}
      brandColor={brandColor}
    >
      <div className='relative top-3 mb-16 w-full'>
        <BrandingHeader branding={branding} />
      </div>
      <Title className='bg-light-gray-100 dark:bg-white'>
        <p>{getMessage(status)}</p>
        {status === 'completed' && newFormUrl && (
          <button
            className={brandColor ? 'kp-button-brand' : 'kp-button-solid'}
            style={{ '--kp-brand': brandColor }}
            onClick={() => (window.location = newFormUrl)}
          >
            <Trans id='start.new.form' />
          </button>
        )}
      </Title>
      <Illustration.FinishedGirl width={110} />
    </Wrapper>
  )
}
