/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import React from 'react'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import DisplaySignature from './parts/display-signature'
import ViewModal from './parts/view-modal'

export default function SignatureView ({ condensed, value }) {
  const [showModal, setShowModal] = React.useState(false)
  if (condensed) {
    return (
      <Center>
        {value && (
          <Button
            transparent
            onClick={event => {
              event.preventDefault()
              event.stopPropagation(true)
              setShowModal(true)
            }}
            aria-label={i18n._('electronically.signed.by.view', {
              displayName: value?.displayName
            })}
          >
            <Icons.Check />
          </Button>
        )}
        <ViewModal
          setShowModal={setShowModal}
          showModal={showModal}
          value={value}
        />
      </Center>
    )
  }
  return (
    <Wrapper>
      <DisplaySignature value={value} />
    </Wrapper>
  )
}
const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled(Center)`
  padding-bottom: 16px;
`
