/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { Navigate, useLocation } from 'react-router'

const nestedRoutes = [
  'audit-log',
  'permissions',
  'api-integrations',
  'identity',
  'account',
  'imports',
  'usage'
]

export default function RedirectOldHomeRoutes () {
  const { pathname, search } = useLocation()

  const parts = pathname.split('/').filter(Boolean)
  if (parts[1] === 'actions' && nestedRoutes.includes(parts[2])) {
    parts.splice(1, 1, 'space', 'favorites')
  } else if (parts[1] === 'my' && nestedRoutes.includes(parts[3])) {
    parts.splice(1, 2, 'space', 'favorites')
  } else {
    parts.splice(1, 0, 'space', 'favorites')
  }

  return <Navigate to={`/${parts.join('/')}${search}`} replace />
}
