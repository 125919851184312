/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

export const isDate = input => {
  if (Object.prototype.toString.call(input) === '[object Date]') return true
  return false
}

export const getDateObject = value => {
  if (!value) return null
  if (typeof value !== 'number' && typeof value !== 'string') return null

  let date

  if (typeof value === 'number') {
    const tempDate = new Date(value)
    date = new Date(
      tempDate.getUTCFullYear(),
      tempDate.getUTCMonth(),
      tempDate.getUTCDate()
    )
  } else {
    date = new Date(value)
  }

  if (date.toString() === 'Invalid Date') {
    return null
  }

  return date
}

export const getFormattedDate = dateObject => {
  if (!isDate(dateObject)) return ''

  return new Intl.DateTimeFormat(window.navigator.language, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }).format(dateObject)
}
