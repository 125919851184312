/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import React from 'react'
import { Outlet, useOutletContext, useParams } from 'react-router'
import styled from 'styled-components'

import Loading from '../../../components/loading'
import { ModalPage } from '../../../components/modal-page'
import { GraphQLError as Error } from '../../../components/system-error'
import { Tab, TabInner, Tabs } from '../../../ui/tabs'

export default function GroupDetails () {
  const { blueprints, refetch } = useOutletContext()
  const params = useParams()
  const id = params.id
  const { data, loading, error } = useQuery(groupQuery, { variables: { id } })

  return (
    <ModalPage
      testid='group-modal'
      className='text-sm'
      title={
        <Title>
          <StyledSpan1 className='text-medium-gray-500'>
            <Trans id='group.name.colon' message='Group Name:' />
          </StyledSpan1>{' '}
          <StyledSpan2 className='text-dark-gray-500'>
            {data?.group?.name ?? <Trans id='loading' message='Loading' />}
          </StyledSpan2>
        </Title>
      }
      nav={<Nav />}
    >
      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : (
        <Outlet context={{ blueprints, group: data?.group, refetch }} />
      )}
    </ModalPage>
  )
}

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  overflow: hidden;
`

const StyledSpan1 = styled.span`
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const StyledSpan2 = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-weight: 500;
`

function Nav () {
  return (
    <Tabs>
      <Tab to='view'>
        <TabInner>
          <Trans id='general' message='General' />
        </TabInner>
      </Tab>
      <Tab to='roles'>
        <TabInner>
          <Trans id='roles' message='Roles' />
        </TabInner>
      </Tab>
      <Tab to='fields'>
        <TabInner>
          <Trans id='fields' message='Fields' />
        </TabInner>
      </Tab>
      <Tab to='history'>
        <TabInner>
          <Trans id='history' message='History' />
        </TabInner>
      </Tab>
    </Tabs>
  )
}

const groupQuery = gql`
  query Group($id: ID!) {
    group(id: $id) {
      id
      name
      parentId
      categoryId
      parent {
        id
        label: name
      }
      hasChildren
      category {
        id
        parentId
        roleSchemas {
          id
          name
          description
        }
        fieldSchemas {
          id
          name
          type
        }
      }
      roles {
        id
        roleId
        name
        memberIds
        membersConnection(args: { limit: 12, skip: 0, query: "", sort: "" }) {
          totalCount
          edges {
            node {
              label: displayName
              id
              username
              firstName
              lastName
              email
              active
            }
          }
        }
      }

      roleSchemas {
        id
        name
        description
      }
      fields {
        id
        value
      }
      fieldSchemas {
        id
        name
        type
      }
      access {
        isAdmin
        isParentAdmin
      }
    }
  }
`
