/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import _ from 'lodash'
import React from 'react'
import { useSearchParams } from 'react-router'

import { useQuery } from '../../../components/use-query'
import VersionsDropdown from '../../../components/versions-dropdown'
import Formbot, { formbot } from '../../../formbot'
import {
  collectGadgets,
  expandTableColumns,
  structureToSchema
} from '../../../formbot/engine/formbot/utils'
import * as Icons from '../../../icons'
import Toggle from '../../../ui/toggle'

export function useCompareState (getQuery, documentId, res1, doc1, structure1) {
  const [searchParams] = useSearchParams()
  const [onlyChanges, setOnlyChanges] = React.useState(false)
  const changeKey = searchParams.get('change')
  const [selected, setSelected] = React.useState(changeKey || null)
  const id = searchParams.get('compare')
  const q = getQuery(id, null, { skip: !id || id === documentId })
  const { data: res2, loading, error } = useQuery(q)

  React.useEffect(() => {
    function handler (e) {
      const el = e.target
      if (document.querySelector('[data-changes-header]')?.contains(el)) return
      if (document.querySelector('[data-formbot]')?.contains(el)) return
      setSelected(null)
    }
    document.addEventListener('click', handler)
    return () => document.removeEventListener('click', handler)
  }, [])

  let doc2, structure2
  if (id) {
    const source = documentId === id ? res1 : res2
    doc2 = {
      data: source?.document?.data,
      meta: source?.document?.meta || {},
      integration: source?.document?.integration || {}
    }
    structure2 = {
      template: _.cloneDeep(source?.document?.form?.template),
      metaFields: source?.document?.form?.metaFields ?? [],
      integrationFields: source?.document?.form?.integrationFields ?? [],
      trashed: []
    }
  }

  const changes = diffDocuments(doc1, doc2, structure1, structure2)

  React.useEffect(() => {
    if (selected) {
      const id = changes.find(c => c.formKey === selected)?.id
      const gadget = document.querySelector(`#gadget-${id}`)
      const parent = gadget?.closest('[data-formbot]')
      const scrollparent = parent?.closest('[data-modalbody]')
      if (!gadget || !scrollparent || !parent) return
      const top =
        gadget.getBoundingClientRect().top - parent.getBoundingClientRect().top
      scrollparent.scrollTo?.({ smooth: true, top })
    }
  }, [selected, changes.length === 0])

  const versionIds = res1.document?.versions?.map(a => a.id) || []
  const i = versionIds.indexOf(documentId) + 1
  const previousVersionId = versionIds[i] || documentId

  return [
    {
      id: error ? null : id,
      previousVersionId,
      documentId,
      onlyChanges,
      setOnlyChanges,
      selected,
      setSelected,
      changes,
      loading,
      res1,
      res2,
      doc1,
      doc2,
      structure1,
      structure2
    },
    { changes, selected, setSelected }
  ]
}

export function Header ({ compareState: ctx, cb }) {
  const { id, onlyChanges, setOnlyChanges, documentId, res1, res2 } = ctx
  if (!id) return null
  return (
    <div
      className='sticky top-0 z-20 flex flex-col gap-4 bg-orange-100 p-4 dark:bg-orange-950'
      data-changes-header
    >
      <div className='flex flex-col items-center justify-between gap-2 md:flex-row'>
        <div className='text-base font-medium'>
          <Trans id='compare_changes.show_changes' message='Show Changes' />
        </div>
        <ChangePaginator ctx={ctx} />
        <Toggle
          off={i18n._({
            id: 'compare_changes.show_form',
            message: 'Show Form'
          })}
          on={i18n._({
            id: 'compare_changes.show_changes_only',
            message: 'Show Changes Only'
          })}
          value={onlyChanges}
          onChange={setOnlyChanges}
        />
      </div>
      <div className='flex flex-col justify-center gap-4 md:flex-row'>
        <VersionsDropdown
          document={documentId === id ? res1?.document : res2?.document}
          routeIdentifierCallback={v => `?compare=${v?.id}`}
        />
        <VersionsDropdown
          document={res1?.document}
          routeIdentifierCallback={version => `${cb(version)}?compare=${id}`}
        />
      </div>
    </div>
  )
}

function ChangePaginator ({ ctx }) {
  const { onlyChanges, changes, selected, setSelected } = ctx
  if (onlyChanges) return null
  if (!changes.length) {
    return <Trans id='compare_changes.no_changes' message='No Changes' />
  }
  const i = changes.findIndex(c => c.formKey === selected)
  return (
    <div className='flex items-center gap-2'>
      <Trans id='compare_changes.view_changes' message='View Changes' />
      <button
        className='kp-button-solid kp-button-icon kp-button-sm rotate-90'
        aria-label={i18n._({
          id: 'compare_changes.previous_change',
          message: 'Next Change'
        })}
        onClick={() => {
          const ii = ((i === -1 ? 1 : i) - 1 + changes.length) % changes.length
          setSelected(changes[ii]?.formKey)
        }}
      >
        <Icons.SelectDownArrow />
      </button>
      <button
        className='kp-button-solid kp-button-icon kp-button-sm -rotate-90'
        aria-label={i18n._({
          id: 'compare_changes.next_change',
          message: 'Previous Change'
        })}
        onClick={() => {
          const ii = (i + 1) % changes.length
          setSelected(changes[ii]?.formKey)
        }}
      >
        <Icons.SelectDownArrow />
      </button>
      {i !== -1 && `${i + 1}/${changes.length}`}
    </div>
  )
}

export function Changes ({ compareState: ctx }) {
  const { id, changes, onlyChanges, doc1, doc2, structure1, structure2 } = ctx
  if (!id || !onlyChanges) return null
  if (!changes.length) {
    return <Trans id='compare_changes.no_changes' message='No Changes' />
  }
  return (
    <ul className='flex flex-col gap-4'>
      {changes.map(change => (
        <li
          key={change.formKey}
          data-testid='form-change'
          className='flex flex-col items-center gap-2 rounded-lg bg-light-gray-100 px-5 py-4 md:flex-row'
        >
          {change.type === 'added' ? (
            <>
              <NewField />
              <Icons.Back className='-rotate-90 md:rotate-180' />
              <Form
                ctx={ctx}
                structure={structure1}
                doc={doc1}
                change={change.next}
              />
            </>
          ) : change.type === 'removed' ? (
            <>
              <Form
                ctx={ctx}
                structure={structure2}
                doc={doc2}
                change={change.prev}
              />
              <Icons.Back className='-rotate-90 md:rotate-180' />
              <RemovedField />
            </>
          ) : (
            <>
              <Form
                ctx={ctx}
                structure={structure2}
                doc={doc2}
                change={change.prev}
                context={{ next: change.next }}
              />
              <Icons.Back className='-rotate-90 md:rotate-180' />
              <Form
                ctx={ctx}
                structure={structure1}
                doc={doc1}
                change={change.next}
                context={{ prev: change.prev }}
              />
            </>
          )}
        </li>
      ))}
    </ul>
  )
}

function NewField () {
  return (
    <div className='flex flex-1 items-center justify-center gap-2 py-4'>
      <Icons.Add className='fill-green-400' />
      <Trans id='compare_changes.new_field' message='New Field' />
    </div>
  )
}

function RemovedField () {
  return (
    <div className='flex flex-1 items-center justify-center gap-2 py-4'>
      <Icons.Remove className='fill-red-500' />
      <Trans id='compare_changes.removed_field' message='Removed Field' />
    </div>
  )
}

function Form ({ ctx, structure, doc, change, context = {} }) {
  const { documentId, res1 } = ctx
  const labelSize = res1?.document?.dataset?.labelSize
  const template = _.omit(change.template, 'conditionalVisibility')
  return (
    <div className='w-full flex-1 bg-white'>
      <Formbot.View
        className='formbot formbot-view'
        document={change.doc}
        structure={{ ...structure, template, fullTemplate: structure.template }}
        context={{ documentId, documentMeta: doc.meta, labelSize, ...context }}
      />
    </div>
  )
}

export function Deletions ({ compareState: ctx }) {
  const { changes, documentId, selected, setSelected, doc2, structure2 } = ctx
  const deletions = changes.filter(c => c.type === 'removed').map(c => c.prev)
  if (!ctx.id || !deletions.length || ctx.onlyChanges) return null
  const children = deletions.map(c => ({ ...c.template, id: c.template.ogid }))
  return (
    <Formbot.View
      className='formbot formbot-view mt-10 border-t border-medium-gray-200 pt-10'
      document={deletions[0].doc}
      structure={{
        ...structure2,
        fullTemplate: structure2.template,
        template: {
          id: 'a',
          type: 'Section',
          label: i18n._({
            id: 'compare_changes.removed_fields',
            message: 'Removed Fields'
          }),
          children: [{ id: 'b', type: 'Column', children }]
        }
      }}
      context={{
        compare: { changes, selected, setSelected },
        documentId,
        documentMeta: doc2.meta,
        labelSize: ctx.res1?.document?.dataset?.labelSize
      }}
    />
  )
}

function diffDocuments (doc1, doc2, structure1, structure2) {
  if (!doc1?.data || !doc2?.data || !structure1 || !structure2) return []
  const [schema1, idMap1] = collect(structure1, 'new')
  const [schema2, idMap2] = collect(structure2, 'old')
  const schema1map = _.keyBy(schema1, 'formKey')
  const schema2map = _.keyBy(schema2, 'formKey')
  const changes = []
  for (const { ogid: id, formKey } of schema1) {
    const next = getCtx(doc1, formKey, schema1map, idMap1, doc2, idMap2)
    const prev = getCtx(doc2, formKey, schema2map, idMap2, doc1, idMap1)
    if (!prev) {
      changes.push({ id, formKey, type: 'added', next })
    } else if (!_.isEqual(next.value, prev.value)) {
      changes.push({ id, formKey, type: 'changed', prev, next })
    }
  }
  for (const { ogid: id, formKey } of schema2) {
    if (!schema1map[formKey]) {
      const prev = getCtx(doc2, formKey, schema2map, idMap2, doc1, idMap1)
      changes.push({ id, formKey, type: 'removed', prev })
    }
  }
  return changes
}

export function diffTables ({ context, childrenTemplate, value, formKey }) {
  const changes = {}
  if (context?.prev) {
    const { idMap, idMap2, template } = context.prev

    // find added columns
    const prev = collectChildren(template.childrenTemplate, idMap, 'old')
    const next = collectChildren(childrenTemplate, idMap2, 'new')
    for (const key in next) {
      if (!(key in prev)) changes[key] = { type: 'added', what: 'col' }
    }

    // find added rows
    const prev2 = _.keyBy(context.prev.value, '_rowId')
    const next2 = _.keyBy(value, '_rowId')
    for (const key in next2) {
      if (!(key in prev2)) changes[key] = { type: 'added', what: 'row' }
    }

    const oldIdToIndex = {}
    for (const i in context.prev.value) {
      oldIdToIndex[context.prev.value[i]._rowId] = i
    }
    // find changed cells
    for (const j in value) {
      const id = value[j]._rowId
      if (id in changes) continue
      for (const key in next) {
        if (key in changes) continue
        const i = oldIdToIndex[id]
        const oldKey = deriveKey(key, i, `${formKey}.${i}.${key}`)
        const newKey = deriveKey(key, j, `${formKey}.${j}.${key}`)
        const prev3 = getValue(context.prev.doc, oldKey)
        const next3 = getValue(context.prev.doc2, newKey)
        if (!_.isEqual(prev3, next3)) {
          changes[`${formKey}.${j}.${key}`] = {
            type: 'changed',
            what: 'cell',
            prev: { value: prev3, template: prev[key] }
          }
        }
      }
    }
  } else if (context?.next) {
    const { idMap, idMap2, template } = context.next

    // find deleted columns
    const prev = collectChildren(childrenTemplate, idMap2, 'old')
    const next = collectChildren(template.childrenTemplate, idMap, 'new')
    for (const key in prev) {
      if (!(key in next)) changes[key] = { type: 'removed', what: 'col' }
    }

    // find deleted rows
    const prev2 = _.keyBy(value, '_rowId')
    const next2 = _.keyBy(context.next.value, '_rowId')
    for (const key in prev2) {
      if (!(key in next2)) changes[key] = { type: 'removed', what: 'row' }
    }
  }
  return changes
}

export function diffRepeaters ({ context, value, childrenTemplate, formKey }) {
  const changes = {}
  if (context?.prev) {
    const { idMap, idMap2, template } = context.prev

    // find added gadgets
    const prev = collectChildren(template.childrenTemplate, idMap, 'old')
    const next = collectChildren(childrenTemplate, idMap2, 'new')
    for (const key in next) {
      const _key = `${formKey}.data.*.data.${key}`
      if (!(key in prev)) changes[_key] = { type: 'added', formKey: _key }
    }

    // find added repeats
    const prev2 = _.keyBy(context.prev.value?.data, 'id')
    const next2 = _.keyBy(value?.data, 'id')
    for (const id in next2) {
      if (!(id in prev2)) changes[id] = { type: 'added', id }
    }

    const oldIdToIndex = {}
    for (const i in context.prev.value?.data) {
      oldIdToIndex[context.prev.value.data[i].id] = i
    }

    // find changed cells
    for (const j in value?.data) {
      const id = value?.data[j].id
      if (id in changes) continue
      for (const key in next) {
        if (`${formKey}.data.*.data.${key}` in changes) continue
        const i = oldIdToIndex[id]
        const fullKey = `${formKey}.data.${j}.data.${key}`
        const oldKey = deriveKey(key, i, `${formKey}.data.${i}.data.${key}`)
        const newKey = deriveKey(key, j, `${formKey}.data.${j}.data.${key}`)
        const prev3 = getValue(context.prev.doc, oldKey)
        const next3 = getValue(context.prev.doc2, newKey)
        if (!_.isEqual(prev3, next3)) {
          changes[fullKey] = {
            formKey: fullKey,
            type: 'changed',
            prev: { value: prev3, template: prev[key] }
          }
        }
      }
    }
  } else if (context?.next) {
    const { idMap, idMap2, template } = context.next

    // find deleted gadgets
    const prev = collectChildren(childrenTemplate, idMap, 'old')
    const next = collectChildren(template.childrenTemplate, idMap2, 'new')
    for (const key in prev) {
      const _key = `${formKey}.data.*.data.${key}`
      if (!(key in next)) changes[_key] = { type: 'removed', formKey: _key }
    }

    // find deleted repeats
    const prev2 = _.keyBy(value?.data, 'id')
    const next2 = _.keyBy(context.next.value?.data, 'id')
    for (const id in prev2) {
      if (!(id in next2)) changes[id] = { type: 'removed', id }
    }
  }
  return changes
}

function collect (structure, prefix) {
  const gadgets = collectGadgets(structure.template)
  const idMap = generateIdMap(structure)
  const schema = doCollect(gadgets, idMap, prefix)
  return [schema, idMap]
}

function collectChildren (childrenTemplate, idMap, prefix) {
  const schema = []
  for (const i in childrenTemplate) {
    const gadgets = collectGadgets(childrenTemplate[i])
    schema.push(...doCollect(gadgets, idMap, prefix))
  }
  return _.keyBy(schema, 'formKey')
}

function doCollect (gadgets, idMap, prefix) {
  return gadgets
    .reverse()
    .map(gadget => {
      if (gadget.type === 'ReadOnlyData') {
        gadget.formKey = `derived.${gadget.id}.${gadget.details?.selectedGadget.formKey}`
      } else if (gadget.type === 'DataLink') {
        const key = idMap[gadget.details.parentId]
        gadget.formKey = `derived.${gadget.id}.${key}.${gadget.details.selectedOutputField.path}`
      }
      return gadget
    })
    .filter(g => g.formKey)
    .map(g => ({ ...g, id: `${prefix}-${g.id}`, ogid: g.id }))
}

function getCtx (doc, formKey, schema, idMap, doc2, idMap2) {
  const template = schema[formKey]
  if (!template) return
  const gadgetDef = formbot.getGadget(template.type)
  let value = getValue(doc, formKey)
  value = _.isNil(value) ? gadgetDef.defaultValue : value
  return { value, template, doc, doc2, idMap, idMap2 }
}

function getValue (doc, formKey) {
  if (formKey.startsWith('derived.')) {
    formKey = formKey.split('.').slice(2).join('.')
  } else {
    formKey = `data.${formKey}`
  }
  return _.get(doc, formKey)
}

function generateIdMap (structure) {
  if (!structure) return {}
  let schema = structureToSchema(structure, formbot)
  schema = expandTableColumns(schema, formbot)
  return _.mapValues(
    _.keyBy(schema, g => (g.id.startsWith('meta') ? g.id : `data.${g.id}`)),
    'formKey'
  )
}

function deriveKey (key, i, fullKey) {
  return key.startsWith('derived.') ? key.replace('.*.', `.${i}.`) : fullKey
}

export function ChangeBadge ({ type, show = true }) {
  if (!show) return null
  if (type === 'changed') {
    return (
      <div className='kp-badge inline-flex items-center gap-1 self-start justify-self-start bg-orange-200 text-[black]'>
        <Icons.Edit className='h-2.5 w-2.5 fill-[black]' />
        <Trans id='compare_changes.changed' message='Changed' />
      </div>
    )
  }

  if (type === 'removed') {
    return (
      <div className='kp-badge inline-flex items-center gap-1 self-start justify-self-start bg-[#d22e2f] text-[white]'>
        <Icons.Close className='h-2.5 w-2.5 fill-[white]' />
        <Trans id='compare_changes.removed' message='Removed' />
      </div>
    )
  }

  if (type === 'added') {
    return (
      <div className='kp-badge inline-flex items-center gap-1 self-start justify-self-start bg-green-200 text-[black]'>
        <Icons.Add className='h-2.5 w-2.5 fill-[black]' />
        <Trans id='compare_changes.added' message='Added' />
      </div>
    )
  }

  return null
}
