/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import _ from 'lodash'

const isNumber = val => (val || val === 0) && typeof +val === 'number'

function toNumbers (nums) {
  return nums.map(num => +num)
}

function undecimal (nums) {
  const numDecimals = _.max(nums.map(countDecimals))
  const factor = 10 ** numDecimals
  return [nums.map(num => Math.round(num * factor)), factor]
}

function countDecimals (num) {
  const stringNum = `${num}`
  if (stringNum.includes('e')) return 0
  if (!stringNum.includes('.')) return 0
  const [, decimal] = `${num}`.split('.')
  return decimal.length
}

export const none = () => ''

// Functions

export const sum = (nums = []) => {
  const validNums = _.filter(nums, isNumber)
  if (!validNums.length) return null
  const [vals, factor] = undecimal(validNums)
  const num = _.reduce(vals, (a, b) => a + b)
  return num / factor
}
export const subtract = (nums = []) => {
  const validNums = _.filter(nums, isNumber)
  if (!validNums.length) return null
  const [vals, factor] = undecimal(validNums)
  const num = _.reduce(vals, (a, b) => a - b)
  return num / factor
}
export const product = (nums = []) => {
  const validNums = _.filter(nums, isNumber)
  if (!validNums.length) return null
  const [vals, factor] = undecimal(validNums)
  const num = _.reduce(vals, (a, b) => a * b)
  return num / factor ** vals.length
}
export const min = (nums = []) => {
  const validNums = _.filter(nums, isNumber)
  return _.min(toNumbers(validNums))
}
export const max = (nums = []) => {
  const validNums = _.filter(nums, isNumber)
  return _.max(toNumbers(validNums))
}
export const avg = (nums = []) => {
  const validNums = _.filter(nums, isNumber)
  if (!validNums.length) return null
  const [vals, factor] = undecimal(validNums)
  const sum = _.reduce(vals, (a, b) => a + b)
  return sum / vals.length / factor
}
export const median = (nums = []) => {
  const filtered = nums.filter(Boolean)
  if (!filtered.length) return null
  const sorted = Array.from(filtered).sort((a, b) => a - b)
  const middle = Math.floor(sorted.length / 2)

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2
  }

  return sorted[middle]
}
export const mode = (nums = []) => {}
export const range = (nums = []) => {
  nums.sort()
  return nums[0] + '-' + nums[nums.length - 1]
}
export const count = (nums = []) => {
  const validNums = _.filter(nums, isNumber)
  if (!validNums.length) return null
  return validNums.length
}

export const extractCurrencyConfig = details => {
  const currency =
    details?.currency?.enabled && details?.currency?.currency
      ? details.currency.currency
      : 'USD'
  const decimals =
    details?.currency?.enabled &&
    details?.currency?.decimals !== '' &&
    !isNaN(details?.currency?.decimals)
      ? details.currency.decimals
      : 2

  return { currency, decimals, enabled: details?.currency?.enabled }
}

// todo :: create format file

// Format

export const toDollars = num => {
  if (!num && num !== 0) return null
  num = num / 100

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }).format(num)
}

export const toCurrency = (num, currency = 'USD', decimals = 2) => {
  if (!num && num !== 0) return null
  // restore pennies to original input value
  num = num / 100

  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(num)
}

export const toPercent = num => {
  if (!num && num !== 0) return null

  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2
  }).format(num)
}
