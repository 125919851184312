/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { useOutletContext, useParams } from 'react-router'
import styled from 'styled-components'

import PopoverButton from '../../../../components/data-table/popover-button'
import Loading from '../../../../components/loading'
import Spinner from '../../../../components/spinner'
import { GraphQLError as Error } from '../../../../components/system-error'
import * as Icons from '../../../../icons'
import { useAlerts } from '../../../../ui/alerts'
import Button from '../../../../ui/button'
import Input from '../../../../ui/input'
import { Wrapper } from '../../../../ui/layout'
import { Option, Select } from '../../../../ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../../../../ui/table'
import { useCreateTokenMutation } from './components/mutation.create-token'
import { useRevokeTokenMutation } from './components/mutation.revoke-token'

export default function UserApiKeys () {
  const props = useOutletContext()
  const params = useParams()
  const id = props.id || params.id
  const { data, loading, error, refetch } = useQuery(userApiKeysQuery, {
    variables: { id }
  })
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <UserApiKeysInner id={id} apiKeys={data.user.apiKeys} refetch={refetch} />
  )
}

const userApiKeysQuery = gql`
  query UserApiKeys($id: ID!) {
    user(id: $id) {
      id
      active
      apiKeys {
        id
        name
        createdAt
        expiresAt
      }
    }
    viewer {
      id
      user {
        id
        canCreateApiKeys
      }
    }
  }
`

export function useGetUserWithApiKeys (userId) {
  const params = useParams()
  userId = userId ?? params.id
  const { data, loading } = useQuery(userApiKeysQuery, {
    variables: { id: userId },
    skip: !userId
  })
  return {
    user: data?.user,
    canCreateApiKeys: data?.viewer?.user?.canCreateApiKeys,
    loading
  }
}

function UserApiKeysInner ({ id, apiKeys, refetch }) {
  const [token, setToken] = React.useState(null)
  return (
    <>
      <Buttons>
        <PopoverButton
          label={i18n._('create.key')}
          buttonProps={{ outline: false }}
        >
          {hide => (
            <CreateKeyDialog
              id={id}
              hide={hide}
              setToken={token => {
                setToken(token)
                refetch()
              }}
            />
          )}
        </PopoverButton>
      </Buttons>
      {token && <DisplayToken token={token} onClose={() => setToken(null)} />}
      <TableWrapper>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>
                <Trans id='name' />
              </TableHeaderCell>
              <TableHeaderCell>
                <Trans id='created' />
              </TableHeaderCell>
              <TableHeaderCell>
                <Trans id='expires' />
              </TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {apiKeys.map(apiKey => (
              <TableRow key={apiKey.id}>
                <WrappedTableCell>{apiKey.name}</WrappedTableCell>
                <TableCell>
                  {new Date(apiKey.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <ExpiresAt value={apiKey} />
                </TableCell>
                <TableCell>
                  <RevokeButton
                    id={id}
                    apiKey={apiKey}
                    refetch={refetch}
                    setToken={() => setToken(null)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  )
}

function RevokeButton ({ id, apiKey, refetch, setToken }) {
  const alerts = useAlerts()
  const closeAlertsRef = React.useRef()
  const [revokeToken, { loading: revokeTokenLoading }] =
    useRevokeTokenMutation(id)

  return (
    <StyledRevokeButton
      disabled={revokeTokenLoading}
      small
      onClick={() => {
        closeAlertsRef.current?.()
        closeAlertsRef.current = alerts.type1(
          i18n._('revoke.api.key'),
          i18n._('revoke.api.key.data'),
          'error',
          close => (
            <Button
              className='!bg-red-400'
              ml={2}
              onClick={() => {
                close()
                revokeToken(apiKey.id)
                  .then(() => {
                    setToken()
                    refetch()
                    alerts.type3(i18n._('api.key.revoked'), 'success')
                  })
                  .catch(err => {
                    Sentry.captureException(err)
                    alerts.type3(err.message, 'error')
                  })
              }}
            >
              <Trans id='revoke.key' />
            </Button>
          )
        )
      }}
    >
      {revokeTokenLoading ? <Spinner size={16} /> : <Trans id='revoke' />}
    </StyledRevokeButton>
  )
}

function daysSince (date) {
  const now = new Date()
  const then = new Date(date)
  const diffMs = now.getTime() - then.getTime()
  return Math.round(diffMs / 1000 / 60 / 60 / 24)
}

function ExpiresAt ({ value }) {
  const textValue = value.expiresAt
    ? new Date(value.expiresAt).toLocaleDateString()
    : 'Never'
  const days = React.useMemo(
    () => daysSince(value.createdAt),
    [value.createdAt]
  )
  if (value.expiresAt || days < 365) return textValue
  return (
    <CenteredCell title={i18n._('key.is.old')}>
      {textValue}
      &nbsp;
      <Icons.AlertWarning fill='#f7bd1b' />
    </CenteredCell>
  )
}

const CenteredCell = styled.span`
  display: flex;
  align-items: center;
`

const EXPIRATION_OPTIONS = [
  {
    value: '1week',
    label: <Trans id='1.week' />,
    getValue () {
      const now = new Date()
      return now.setDate(now.getDate() + 7)
    }
  },
  {
    value: '1mon',
    label: <Trans id='1.month' />,
    getValue () {
      const now = new Date()
      return now.setMonth(now.getMonth() + 1)
    }
  },
  {
    value: '3mon',
    label: <Trans id='3.months' />,
    getValue () {
      const now = new Date()
      return now.setMonth(now.getMonth() + 3)
    }
  },
  {
    value: '6mon',
    label: <Trans id='6.months' />,
    getValue () {
      const now = new Date()
      return now.setMonth(now.getMonth() + 6)
    }
  },
  {
    value: '1year',
    label: <Trans id='1.year' />,
    getValue () {
      const now = new Date()
      return now.setFullYear(now.getFullYear() + 1)
    }
  },
  {
    value: 'null',
    label: <Trans id='never' />,
    getValue () {
      return null
    }
  }
]

function CreateKeyDialog ({ id, hide, setToken }) {
  const [name, setName] = React.useState('')
  const [expiresAt, setExpiresAt] = React.useState('1year')
  const [saving, setSaving] = React.useState(false)
  const [error, setError] = React.useState(null)
  const createKey = useCreateTokenMutation(id)
  return (
    <Form
      className='text-sm'
      onSubmit={async e => {
        e.preventDefault()
        if (!name) return
        const expiresAtValue = EXPIRATION_OPTIONS.find(
          ({ value }) => value === expiresAt
        )?.getValue()
        setError(null)
        setSaving(true)
        try {
          const resp = await createKey(name, expiresAtValue)
          setToken(resp.data.createApiKey)
          hide()
        } catch (e) {
          setError(i18n._('failed.create.token'))
          setSaving(false)
        }
      }}
    >
      <label htmlFor='create-key-name'>
        <Trans id='key.name' />
      </label>
      <Input id='create-key-name' value={name} onChange={setName} />
      <label htmlFor='create-key-expires-in'>
        <Trans id='expires.in' />
      </label>
      <StyledSelect
        id='create-key-expires-in'
        value={expiresAt}
        onChange={setExpiresAt}
      >
        {EXPIRATION_OPTIONS.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
      <div className='text-red-400'>{error}</div>
      <Button disabled={saving} outline onClick={hide} type='button'>
        <Trans id='cancel' />
      </Button>
      <StyledCreateKeyButton processing={saving} disabled={!name || saving}>
        {saving ? <Spinner size={12} /> : <Trans id='create.key' />}
      </StyledCreateKeyButton>
    </Form>
  )
}

const StyledSelect = styled(Select)`
  padding: 0 0 8px;
`

function DisplayToken ({ token, onClose }) {
  const alerts = useAlerts()
  return (
    <AlertWrapper role='alert' className='bg-white dark:bg-light-gray-300'>
      <Color width='48px'>
        <Icons.AlertError fill='#fff' />
      </Color>
      <div>
        <Button transparent onClick={onClose}>
          <Trans id='dismiss' />
        </Button>
        <label>
          <Trans id='warning.read' />
        </label>
        <p className='text-sm'>
          <Trans id='copy.save.api' />
        </p>
        <StyledTokenContainer className='border border-light-gray-300 dark:border-medium-gray-200'>
          <textarea value={token} />
          <Button
            transparent
            icon
            ml={3}
            mr={3}
            onClick={() => {
              alerts.type3(i18n._('copied.clipboard'), 'success')
              navigator.clipboard.writeText(token)
            }}
          >
            <Icons.Duplicate className='fill-blue-500' mr={2} />
            <Trans id='copy' />
          </Button>
        </StyledTokenContainer>
      </div>
    </AlertWrapper>
  )
}

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 48px 0 16px;
`

const TableWrapper = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;

  th,
  td {
    height: 52px;
  }
`

const StyledTokenContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;

  textarea {
    border-radius: 4px;
  }
`

const StyledRevokeButton = styled(Button)`
  float: right;
  background: #d22e2f;
  width: 62px;
  &[disabled] {
    background: #d22e2f !important;
  }
  &:hover {
    background: #a41819;
  }
  &:active {
    background: #921616;
  }
`

const StyledCreateKeyButton = styled(Button)`
  height: 32px;
  width: 100px;
  &[disabled] {
    background: ${p => p.processing && '#3369a3 !important'};
  }
`

const Form = styled.form`
  padding: 16px;
  width: 230px;
  color: #666;
  input {
    width: 100%;
    margin-bottom: 8px;
  }
  button + button {
    margin-left: 8px;
  }
`

const AlertWrapper = styled.div`
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  display: flex;
  margin-bottom: 24px;

  label {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  p {
    margin: 0;
    padding: 16px 0;
  }

  textarea {
    height: 96px;
    width: 100%;
    resize: none;
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    border: none;
  }

  > :last-child {
    flex: 1;
    padding: 24px;
  }

  button {
    float: right;
  }
`

const Color = styled(Wrapper)`
  background: #ef6c05;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WrappedTableCell = styled(TableCell)`
  word-break: break-all;
`
