/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import * as Sentry from '@sentry/browser'
import cx from 'clsx'
import { cloneDeep, startCase } from 'lodash'
import React from 'react'
import { useOutletContext } from 'react-router'

import AbbreviationIcon from '../../../components/abbreviation-icon'
import * as iconNames from '../../../components/icon-names'
import Loading from '../../../components/loading'
import { GraphQLError } from '../../../components/system-error'
import StaticFormbot from '../../../formbot/static'
import * as Icons from '../../../icons'
import { useAlerts } from '../../../ui/alerts'
import { useUpdateSuiteMutation } from './mutation.update-suite'

export default function SuitePane () {
  const { space } = useOutletContext()
  const { data, error, loading } = useQuery(query, {
    variables: { id: space.id }
  })
  if (loading) return <Loading />
  if (error) return <GraphQLError />
  return <SuiteSettings suite={data.space.suite || {}} spaceId={space.id} />
}

function SuiteSettings ({ suite, spaceId }) {
  const [suiteData, setSuiteData] = React.useState(cloneDeep(suite))
  const updateSuite = useUpdateSuiteMutation(spaceId)
  const alerts = useAlerts()

  const handleUpdate = data => {
    setSuiteData(data)
    updateSuite(data)
      .then(() =>
        alerts.type3(i18n._('pages.spaces.suite.settings.saved'), 'success')
      )
      .catch(err => {
        alerts.type2(i18n._('pages.spaces.suite.server.error'), 'error')
        Sentry.captureException(err)
      })
  }

  return (
    <div className='mx-auto mb-36 mt-10 max-w-3xl'>
      <StaticFormbot value={suiteData} update={handleUpdate}>
        {Gadgets => (
          <form
            className='mb-10 flex flex-wrap items-center justify-center gap-2'
            onSubmit={e => e.preventDefault()}
          >
            <Gadgets.Text
              configKey='name'
              label={i18n._('pages.spaces.suite.name')}
            />
            <Gadgets.Text
              configKey='slug'
              label={i18n._('pages.spaces.suite.slug')}
            />
            <Gadgets.Dropdown
              configKey='icon'
              label={i18n._('pages.spaces.suite.logo')}
              transformOut={o => o?.id || null}
              options={iconNames.suite.map(name => ({
                key: name,
                lbl: startCase(name)
              }))}
            />
            <Gadgets.Text
              configKey='color'
              label={i18n._('pages.spaces.suite.header.color')}
            />
            <Gadgets.Checkbox
              configKey='invertColor'
              checkLabel={i18n._('pages.spaces.suite.invert')}
            />
          </form>
        )}
      </StaticFormbot>
      <Preview suite={suiteData} />
    </div>
  )
}

const query = gql`
  query SuiteSettingsQuery($id: ID!) {
    space(id: $id) {
      id
      suite {
        id
        name
        slug
        icon
        color
        invertColor
      }
    }
  }
`

function Preview ({ suite }) {
  return (
    <div>
      <div className='flex items-stretch rounded-t-lg border border-light-gray-400 bg-light-gray-300'>
        <div className='mr-4 flex items-center gap-2 px-4 pb-3 pt-4'>
          <i className='h-3 w-3 rounded-full bg-red-400' />
          <i className='h-3 w-3 rounded-full bg-yellow-400' />
          <i className='h-3 w-3 rounded-full bg-green-400' />
        </div>
        <div className='-mb-px mt-2 w-56 overflow-hidden rounded-t-lg border border-b-0 border-light-gray-400 bg-light-gray-100 px-4 pb-2 pt-3 text-sm'>
          <Trans id='pages.spaces.suite.kuali.platform' />
        </div>
      </div>
      <div className='flex items-center gap-4 border border-t-0 border-light-gray-400 bg-light-gray-100 px-4 py-2'>
        <Icons.Back className='fill-light-gray-400' />
        <Icons.Back className='rotate-180 fill-light-gray-400' />
        <Icons.Redo className='fill-light-gray-400' />
        <div className='flex-1 overflow-hidden rounded-md border border-light-gray-400 bg-white px-4 py-2'>
          https://tenant.kualihub.com/{suite.slug}
        </div>
      </div>
      <div className='border-l border-r border-light-gray-400'>
        <header
          className={cx(
            'flex h-20 flex-wrap items-center justify-center pl-4 pr-6 md:justify-between',
            {
              'text-white dark:text-black': suite.invertColor,
              'dark:text-light-gray-100': !suite.invertColor
            }
          )}
          style={{ background: suite.color }}
        >
          <div className='flex items-center'>
            <img
              className='mr-2 h-8 w-8'
              src={`${process.env.PUBLIC_URL}/icons-suite/${suite.icon}.svg`}
              alt=''
            />
            <div
              className={cx('mr-2 text-3xl font-medium', {
                'text-white dark:text-black': suite.invertColor,
                'text-black dark:text-light-gray-100': !suite.invertColor
              })}
            >
              {suite.name}
            </div>
            <Icons.MenuVertical
              className={cx({
                'fill-white dark:fill-black': suite.invertColor
              })}
            />
          </div>

          <div className='flex items-center gap-4'>
            <div className='border-b-2 font-medium'>
              <Trans id='pages.spaces.suite.home' />
            </div>
            <div>
              <Trans id='pages.spaces.suite.action' />
            </div>
            <div>
              <Trans id='pages.spaces.suite.documents' />
            </div>
          </div>
          <div className='flex items-center gap-4'>
            <div>
              <Trans id='pages.spaces.suite.new' />
            </div>
            <AbbreviationIcon
              className='h-8 w-8 rounded-full text-xs font-medium !text-black'
              name='Alfred Brennaman'
            />
          </div>
        </header>
        <div className='flex gap-6 px-4 pt-6'>
          <div className='aspect-square w-[120px] rounded-lg bg-gradient-to-b from-blue-300' />
          <div className='aspect-square w-[120px] rounded-lg bg-gradient-to-b from-red-300' />
          <div className='aspect-square w-[120px] rounded-lg bg-gradient-to-b from-magma-300' />
        </div>
      </div>
    </div>
  )
}
