/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { get, isEqual, map, uniqWith } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { AppIcon, AppSquare } from '../../../components/app-icon'
import CenterModal from '../../../components/modal-centered'
import Spinner from '../../../components/spinner'
import { gadgets } from '../../../formbot'
import { Img, formsQuery, getIcon, useOptions } from '../../data-chooser'
import {
  collectGadgets,
  getRepeatableChildren
} from '../../engine/formbot/utils'
import { ReactComponent as ImgExternal } from '../../source-generic.svg.jsx'
import { ReactComponent as ImgInternal } from '../../source-kuali.svg.jsx'

export const ASSOCIATIONS_OPTIONS = [
  {
    id: 'kuali',
    title: 'Kuali Data',
    Icon: ImgInternal,
    fetchOptions: async (apolloClient, params) => [
      ...(await fetchAppsAndProducts(apolloClient, params))
    ]
  }
]

export default function AssociationsChooser ({
  value,
  options,
  id,
  label,
  tileOptions,
  isProduct,
  onSelect,
  indexed,
  indexType,
  hasLinked
}) {
  const [showChooser, setShowChooser] = React.useState(false)
  const icon = getIcon(id, tileOptions, isProduct)
  return (
    <>
      <div className='flex items-center justify-between pb-2'>
        {id ? (
          <>
            <div className='flex items-center justify-between pr-4'>
              {icon}
              <div className='flex flex-col'>
                <span className='text-sm text-dark-gray-500'>
                  {value.product?.label && `${value.product?.label} - `}
                  {value.dataset?.title || value.dataset?.label}
                </span>
                <span className='text-sm'>{value?.gadget?.label}</span>
                <span className='text-sm'>{label}</span>
              </div>
            </div>
            <button
              className='kp-button-outline'
              disabled={indexed}
              onClick={() => {
                if (indexed) return
                if (!showChooser && hasLinked) {
                  const shouldShow = window.confirm(
                    i18n._('changing.data.will.remove.all.linked.gadgets')
                  )
                  if (!shouldShow) return
                }
                setShowChooser(a => !a)
              }}
            >
              <Trans id='change' />
            </button>
          </>
        ) : (
          <button
            className='kp-button-solid'
            onClick={() => setShowChooser(a => !a)}
          >
            <Trans id='choose' />
          </button>
        )}
      </div>
      <CenterModal
        dismiss={() => setShowChooser(false)}
        showing={showChooser}
        className='transform-none px-0 pb-0 pt-0'
      >
        <div className='w-full'>
          <MillerChooser
            value={value}
            hide={() => setShowChooser(false)}
            onSelect={(...args) => {
              setShowChooser(false)
              onSelect(...args)
            }}
            options={options}
            indexType={indexType}
          />
        </div>
      </CenterModal>
    </>
  )
}

const MillerChooser = ({ value, hide, onSelect, options, indexType }) => {
  // const [search, setSearch] = React.useState(null)
  const [filteredOptions, { loading }] = useOptions(options, indexType, null)
  const [selectedApp, setSelectedApp] = React.useState(null)
  const [source, setSource] = React.useState(null)
  const sources = filteredOptions?.[0]?.options
  const [selectedGadget, setSelectedGadget] = React.useState(null)

  React.useEffect(() => {
    if (!value?.source?.dataset) return
    if (value?.source?.product?.id) {
      const app = filteredOptions?.[0]?.options?.find(
        o => o.id === value?.source?.product?.id
      )
      setSelectedApp(app)
      setSource(app?.datasets?.find(o => o.id === value?.source?.dataset?.id))
    }
    if (!value?.source?.product?.id) {
      setSource(
        filteredOptions?.[0]?.options?.find(
          o => o.id === value?.source?.dataset?.id
        )
      )
    }
    if (value?.source?.gadget) {
      setSelectedGadget(value?.source.gadget)
    }
  }, [value?.source, filteredOptions])

  return (
    <Wrapper className='animate-fade-in rounded shadow-xl'>
      <div className='flex items-center justify-between border border-light-gray-300 bg-light-gray-100 px-4 py-2 uppercase text-medium-gray-500 dark:bg-light-gray-300'>
        <Trans id='sources' />
      </div>
      <div className='flex border-light-gray-300'>
        <SourceColumn
          className='border-r border-light-gray-300'
          columnValue={selectedApp || source}
          values={sources}
          title='Select App/Product'
          loading={loading}
          onChange={val => {
            if (
              val?.type === 'app' &&
              (!source?.id || source?.id !== val?.id)
            ) {
              setSource(val)
              if (selectedApp?.id !== val?.id) {
                setSelectedGadget(null)
                setSelectedApp(null)
              }
            }
            if (val?.type === 'product') {
              setSelectedApp(val)
              if (selectedApp?.id !== val?.id) {
                setSelectedGadget(null)
                setSource(null)
              }
            }
          }}
        />
        {selectedApp ? (
          <SourceColumn
            title='Select Dataset'
            className='border-r border-light-gray-300'
            columnValue={source}
            values={selectedApp?.datasets}
            onChange={val => {
              if (source?.id !== val?.id) {
                setSource(val)
                setSelectedGadget(null)
              }
            }}
          />
        ) : (
          <StyledColumn className='border-r border-light-gray-300'>
            {source?.id && (
              <GadgetChooserColumn
                value={source}
                selectedGadget={selectedGadget}
                setSelectedGadget={setSelectedGadget}
              />
            )}
          </StyledColumn>
        )}

        <StyledColumn className='border-light-gray-300'>
          {selectedApp?.datasets && (
            <GadgetChooserColumn
              value={source}
              selectedGadget={selectedGadget}
              setSelectedGadget={setSelectedGadget}
            />
          )}
        </StyledColumn>
      </div>
      <div className='flex items-center justify-end gap-2 border border-light-gray-300 bg-white px-4 py-2 uppercase text-medium-gray-500 dark:bg-light-gray-300'>
        <button className='kp-button-outline transition-all' onClick={hide}>
          <Trans id='cancel' />
        </button>
        <button
          className='kp-button-solid transition-all'
          disabled={!source || !selectedGadget}
          onClick={() =>
            onSelect({
              type: 'Associations',
              details: {
                ...value,
                versionConfig: 'ANY_LINKED_VERSION',
                source: {
                  product: selectedApp,
                  dataset: source,
                  gadget: {
                    formKey: selectedGadget?.formKey,
                    id: selectedGadget?.id,
                    label: selectedGadget?.label,
                    type: selectedGadget?.type
                  }
                }
              }
            })
          }
        >
          <Trans id='continue' />
        </button>
      </div>
    </Wrapper>
  )
}

function SourceColumn ({
  className,
  columnValue,
  values,
  onChange,
  title,
  loading
}) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref?.current && !ref.rendered) {
      ref.rendered = true
      if (ref.current.scrollIntoViewIfNeeded) {
        ref.current.scrollIntoViewIfNeeded?.()
      } else {
        // FireFox
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  })
  return (
    <StyledColumn>
      <div
        className={cx(
          'flex h-full animate-fade-in flex-col overflow-auto border-r border-light-gray-300',
          className
        )}
      >
        <div className='sticky top-0 z-10 flex w-full items-center justify-between border-b border-light-gray-300 bg-white p-2 pl-4 uppercase text-medium-gray-500'>
          <span>{title}</span>
          {loading && (
            <div className='animate-fade-in'>
              <Spinner size={16} />
            </div>
          )}
        </div>
        {map(values, value => {
          const Icon = value?.Icon
          return (
            <Choice
              ref={columnValue?.id === value?.id ? ref : null}
              className={cx(
                'max-w-full animate-fade-in scroll-mb-8 scroll-p-28 transition-all active:bg-blue-100',
                {
                  'bg-blue-100 dark:bg-light-gray-300':
                    columnValue?.id === value?.id,
                  'hover:bg-light-gray-100': columnValue?.id !== value?.id
                }
              )}
              as='button'
              key={value?.id}
              onClick={() => onChange(value)}
            >
              {Icon ? <Icon mr={3} /> : <Img mr={3} as={ImgExternal} />}
              <span>{value?.label}</span>
            </Choice>
          )
        })}
      </div>
    </StyledColumn>
  )
}

function GadgetChooserColumn ({ value, selectedGadget, setSelectedGadget }) {
  const { appId, pageId } = useParams()
  const [linkedGadgets, setLinkedGadgets] = React.useState(null)

  const { data, loading } = useQuery(formQuery, {
    variables: {
      id: value?.appId || value?.id,
      pageId: value?.type === 'dataset' ? value?.id : value?.id
    }
  })
  React.useEffect(() => {
    if (!data) return
    const template = get(data, 'app.dataset.formContainer.template')
    setLinkedGadgets(getDataGadgets(template, pageId || appId))
  }, [data])
  if (!value) return null
  return (
    <div className='relative flex h-full flex-col overflow-auto'>
      {value && (
        <div className='sticky top-0 flex w-full items-center justify-between border-b border-light-gray-300 bg-white p-2 pl-4 uppercase text-medium-gray-500'>
          <span>Select Gadget</span>
          {loading && (
            <div className='animate-fade-in'>
              <Spinner size={16} />
            </div>
          )}
        </div>
      )}
      <ul className='h-full w-full'>
        {!linkedGadgets ? (
          <div
            key='loading'
            className='flex h-full w-full animate-fade-in flex-col items-center justify-center p-6 text-medium-gray-500'
          >
            Searching for linked gadgets
          </div>
        ) : linkedGadgets.length === 0 ? (
          <div
            key='no-linked'
            className={cx(
              'flex h-full w-full animate-fade-in flex-col items-center justify-center p-6 transition-all',
              {
                'text-medium-gray-200': loading,
                'text-medium-gray-500': !loading
              }
            )}
          >
            No linked gadgets
          </div>
        ) : (
          linkedGadgets.map(field => {
            const Icon = gadgets[field.type]?.meta?.Icon
            return (
              <li key={field?.id}>
                <Choice
                  as='button'
                  disabled={loading}
                  onClick={() => setSelectedGadget(field)}
                  key={field?.id}
                  className={cx(
                    'flex min-h-12 animate-fade-in items-center px-2 py-4 transition-all active:bg-blue-100',
                    {
                      'bg-blue-100': selectedGadget?.id === field?.id,
                      'hover:bg-light-gray-100':
                        selectedGadget?.id !== field.id && !loading,
                      'text-dark-gray-500': !loading,
                      'cursor-default text-medium-gray-200': loading
                    }
                  )}
                >
                  {Icon && <Icon className='mr-3' />}
                  <span className='text-sm'>{field?.label}</span>
                </Choice>
              </li>
            )
          })
        )}
      </ul>
    </div>
  )
}

async function fetchAppsAndProducts (apolloClient, { appId }, type) {
  const { data } = await apolloClient.query({
    query: formsQuery,
    variables: { appId }
  })

  const appDatasets = []
  const products = []

  data?.app?.sharedWithMe?.apps.forEach(app => {
    if (app.type === 'app') {
      appDatasets.push({
        type: 'app',
        id: app.id,
        label: app.name,
        Icon: () => (
          <AppSquare
            backgroundColor={app.tileOptions?.backgroundColor}
            className='mr-2 w-8'
          >
            <AppIcon iconName={app.tileOptions?.iconName} />
          </AppSquare>
        ),
        details: {
          id: app.id,
          label: app.name,
          tileOptions: app.tileOptions,
          allowNewVersions: app.datasets[0]?.allowNewVersions
        }
      })
    } else if (app.type === 'product') {
      products.push({
        id: app.id,
        label: app.name,
        Icon: () => (
          <AppSquare
            backgroundColor={app.tileOptions?.backgroundColor}
            className='mr-2 w-8'
          >
            <AppIcon iconName={app.tileOptions?.iconName} isProduct />
          </AppSquare>
        ),
        type: 'product',
        datasets: app.datasets.map(a => {
          return {
            ...a,
            appId: app.id,
            Icon: () => (
              <AppSquare backgroundColor='transparent' className='mr-2 w-8'>
                <AppIcon iconName={a.icon} isDataset />
              </AppSquare>
            )
          }
        }),
        details: {
          id: app.id,
          label: app.name,
          tileOptions: app.tileOptions
        }
      })
    }
  })

  return [...products, ...appDatasets]
}

const getDataGadgets = (template, datasetId) => {
  return uniqWith(
    collectGadgets(template).reduce((acc, gadget) => {
      if (REPEATABLE_TYPES.includes(gadget.type)) {
        return getRepeatableChildren(gadget).reduce((acc, child) => {
          if (gadgetReferencesDataset(child, datasetId)) {
            acc.push(gadget)
          }
          return acc
        }, acc)
      }
      if (gadgetReferencesDataset(gadget, datasetId)) {
        acc.push(gadget)
      }
      return acc
    }, []),
    isEqual
  )
}

function gadgetReferencesDataset (gadget, datasetId) {
  if (!INTEGRATION_TYPES.includes(gadget.type)) return false
  if (gadget.details?.isProduct) {
    return gadget.details?.pageId === datasetId
  } else {
    return gadget.details?.id === datasetId
  }
}

const REPEATABLE_TYPES = ['Repeater', 'Table', 'Rules']
const INTEGRATION_TYPES = ['DataLookup', 'FormTypeahead', 'FormMultiselect']

const formQuery = gql`
  query FormQuery($id: ID!, $pageId: ID!) {
    app(id: $id) {
      id
      dataset(id: $pageId) {
        id
        formContainer {
          id
          template
        }
      }
    }
  }
`

const StyledColumn = styled.div`
  flex: 1;
  overflow: auto;
  height: 335px;

  @media (max-width: 768px) {
    height: 90vh;
  }
`

const Choice = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #7da4d9;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 768px;
  border-radius: 4px;
  background-color: white;
  transition: none;
`
