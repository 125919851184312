/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, isEqual, map } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { externalRichText } from '../../../components/feature-flags'
import { GadgetListItem } from '../../../pages-builder/form/gadget-list'
import * as Chooser from '../../data-chooser'
import {
  SelectGadgetInput,
  getInputFields,
  getOutputFields,
  useInputDefinitions
} from '../../integration-utils'

function getInputValue (type, value) {
  if (type === 'static') return value
  if (!value) return null
  return { id: value.id, type: value.type }
}

export function RequiredConfig ({
  Gadgets,
  updateDataLookupSource,
  indexed,
  indexType,
  selectedLinks,
  value,
  allGadgets,
  siblingGadgets,
  onChange
}) {
  const { appId } = useParams()
  const { data } = useQuery(...getFetchIntegrationQuery(appId, value))
  React.useEffect(() => {
    if (data?.app) {
      const currentOutputs = value?.outputFields || []
      const currentInputs = value?.inputFields || []
      const integrationData = data?.app?.sharedWithMe?.integration?.data
      const integrationName = data?.app?.sharedWithMe?.integration?.name
      const newOutputs = getOutputFields(integrationData)
      const newInputs = getInputFields(integrationData)
      for (const key in newInputs) {
        if (key in currentInputs) {
          newInputs[key].type = currentInputs[key].type || 'form'
          newInputs[key].value = getInputValue(
            newInputs[key].type,
            currentInputs[key].value
          )
          newInputs[key].options = currentInputs[key].options
        }
      }
      if (
        !isEqual(newOutputs, currentOutputs) ||
        !isEqual(newInputs, currentInputs) ||
        (integrationName && !isEqual(integrationName, value.label))
      ) {
        onChange({
          ...value,
          outputFields: newOutputs,
          inputFields: newInputs,
          label: integrationName
        })
      }
    }
  }, [data])
  const inputs = useInputDefinitions(
    data?.app?.sharedWithMe?.integration,
    allGadgets,
    siblingGadgets
  )
  const handleInputChange = (key, val) =>
    onChange({
      ...value,
      inputFields: {
        ...(value?.inputFields ?? {}),
        [key]: val
      }
    })
  return (
    <>
      <Gadgets.Padded>
        <Gadgets.Label>
          <Trans id='data.source.colon' />
        </Gadgets.Label>
        <Chooser.Fill
          id={value.id}
          label={value.label}
          onSelect={updateDataLookupSource}
          indexed={indexed}
          indexType={indexType}
          hasLinked={!!selectedLinks}
        />
      </Gadgets.Padded>
      {inputs.length !== 0 && (
        <>
          <Gadgets.Label>
            <Trans id='required.information.for.lookup' />
          </Gadgets.Label>
          <InputsBox className='border border-light-gray-500 bg-light-gray-100 dark:bg-light-gray-300'>
            {inputs.map(input => (
              <SelectGadgetInput
                key={input.dataPath}
                Gadgets={Gadgets}
                input={input}
                value={get(value, ['inputFields', input.dataPath])}
                onChange={value => handleInputChange(input.dataPath, value)}
              />
            ))}
          </InputsBox>
        </>
      )}
    </>
  )
}

const InputsBox = styled.div`
  border-radius: 2px;
  & > *:not(:last-child) {
    border-bottom: 1px solid #bbb;
  }
  & > * {
    padding: 16px;
  }
`

export function OptionalConfig ({
  id,
  Gadgets,
  value,
  selectedLinks,
  beginDrag,
  beginA11yDrag,
  endDrag,
  gadgets
}) {
  const fields = value?.outputFields || []
  return (
    <>
      <Gadgets.ConfigBox
        configKey='headless'
        label={i18n._('headless.integration')}
        description={i18n._('headless.integration.data')}
      />
      {fields.length > 0 && (
        <Gadgets.ConfigBox
          configKey='linkedGadgets.enabled'
          label={i18n._('add.linked.auto.filled.gadgets')}
          description={i18n._('add.linked.auto.filled.gadgets.data')}
          disableToggle={!!selectedLinks}
          disableToggleText={i18n._('remove.linked.gadgets.first')}
        >
          {map(fields, (field, i) => (
            <GadgetListItem
              key={i}
              id={i}
              beginDrag={beginDrag}
              beginA11yDrag={beginA11yDrag}
              endDrag={endDrag}
              Icon={gadgets[field.type].meta.Icon}
              text={field.label}
              dragContext={{
                type: 'DataLink',
                details: {
                  isExternal: externalRichText,
                  label: field.label,
                  parentId: `data.${id}`,
                  selectedOutputField: field
                }
              }}
            />
          ))}
        </Gadgets.ConfigBox>
      )}
    </>
  )
}

const getFetchIntegrationQuery = (appId, { id }) => [
  gql`
    query FetchIntegration($appId: ID!, $id: ID!) {
      app(id: $appId) {
        id
        sharedWithMe {
          integration(id: $id) {
            id
            name
            data
          }
        }
      }
    }
  `,
  {
    fetchPolicy: 'network-only',
    variables: { appId, id },
    skip: !appId || !id
  }
]
