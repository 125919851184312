/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import { Link, useLocation } from 'react-router'
import styled from 'styled-components'

import Button, { ButtonGroup } from '../../../ui/button'
import { Flex } from '../../../ui/layout'
import {
  Table as UITable,
  TableBody as UITableBody,
  TableCell as UITableCell,
  TableHeader as UITableHeader,
  TableHeaderCell as UITableHeaderCell,
  TableRow as UITableRow
} from '../../../ui/table'

export default function MyLayout ({ draftCount, children }) {
  const location = useLocation()
  return (
    <main>
      <Inner flexDirection='column'>
        <Flex alignSelf='center'>
          <ButtonGroup>
            <Button
              as={Link}
              outline
              to='submissions'
              data-active={location.pathname.includes('submissions')}
              className='dark:border-medium-gray-100 dark:disabled:border-dark-gray-100 dark:disabled:text-medium-gray-300 dark:disabled:hover:bg-transparent'
            >
              <Trans id='pages.submitter.layout.submitted' />
            </Button>
            <Button
              as={Link}
              outline
              to='drafts'
              data-active={location.pathname.includes('drafts')}
              className='dark:border-medium-gray-100 dark:disabled:border-dark-gray-100 dark:disabled:text-medium-gray-300 dark:disabled:hover:bg-transparent'
            >
              <Trans id='pages.submitter.layout.drafts' />
              {draftCount > 0 ? ` (${draftCount})` : null}
            </Button>
          </ButtonGroup>
        </Flex>
        {children}
      </Inner>
    </main>
  )
}

const Inner = styled(Flex)`
  margin: 16px 32px;

  @media (max-width: 768px) {
    & {
      margin: 16px 0px;
    }
  }
`

export const TableWrapper = styled.div`
  width: 100%;
`

export const Table = styled(UITable)`
  margin-top: 16px;
`

export const TableBody = styled(UITableBody)``

export const TableHeader = styled(UITableHeader)``

export const TableRow = styled(UITableRow)``

export const TableHeaderCell = styled(UITableHeaderCell)`
  @media (max-width: 768px) {
    & {
      padding: 0 2px;
      font-weight: normal;
    }
  }
`

export const TableCell = styled(UITableCell)`
  padding: 0;
`
