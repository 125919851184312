/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import { Link } from 'react-router'
import styled from 'styled-components'

const visuallyHiddenStyles = {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
  width: '1px'
}

export const VisuallyHidden = styled.span(visuallyHiddenStyles)

export function useAnnouncer (id) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    const el = document.createElement('div')
    ref.current = el
    el.id = id
    el.setAttribute('role', 'status')
    el.setAttribute('aria-live', 'polite')
    el.setAttribute('aria-atomic', 'true')
    Object.assign(el.style, visuallyHiddenStyles)
    document.body.appendChild(el)
    return () => {
      document.body.removeChild(ref.current)
      ref.current = null
    }
  }, [id])
  return React.useCallback(message => {
    ref.current.textContent = message
  }, [])
}

export const Announcer = React.memo(({ id, children }) => {
  const announce = useAnnouncer(id)
  React.useEffect(() => announce(children))
  return null
})

export const SkipToContent = () => {
  return (
    <SkipButton
      as={Link}
      tabIndex={0}
      to='#start-of-content'
      onClick={e => {
        e.preventDefault()
        const el = document.getElementById('start-of-content')
        if (el) {
          const el2 = el.nextElementSibling
          if (el2 instanceof HTMLElement) {
            el2.setAttribute('tabindex', '-1')
            el2.focus()
          }
        }
      }}
    >
      <Trans id='ui.a11y.skip' />
    </SkipButton>
  )
}

const SkipButton = styled(VisuallyHidden)`
  display: block;
  background: #0366d6;
  color: white;
  text-decoration: none;
  &:focus {
    margin: 0;
    padding: 16px;
    z-index: 100;
    width: inherit;
    height: 58px;
    clip: unset;
  }
  &:hover {
    text-decoration: underline;
  }
`

export const StartOfContent = styled.div.attrs({ id: 'start-of-content' })`
  & + main:focus {
    outline: 0;
  }

  & + main::-moz-focus-inner {
    border: 0;
  }
`
