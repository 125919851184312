/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router'
import styled from 'styled-components'
import { space } from 'styled-system'

import * as Icons from '../icons'
import { ReactComponent as Stop } from '../illustrations/stop.svg.jsx'
import { SkipToContent, StartOfContent } from '../ui/a11y'
import Button from '../ui/button'
import Checkbox from '../ui/checkbox'
import { Flex } from '../ui/layout'
import Popup from '../ui/pop-up'
import { Popover2 } from '../ui/popover'
import { Tab, TabInner, Tabs } from '../ui/tabs'
import { Body1, Body2, H6 } from '../ui/typography'
import { useAcknowledgeDanger } from './mutation.acknowledge-danger'
import { publish as publishRoute } from './routes'
import Sidebar from './sidebar'
import { GraphQLError as Error } from './system-error'
import { TenantFeaturesContextProvider } from './tenant-features-context'
import { useTileTransition } from './tile-transition'
import { useIds } from './use-ids'
import { PageContextProvider } from './use-page-context'
import { useQuery as useOldQuery } from './use-query'

export default function AppLayout () {
  const { appId, pageId } = useParams()
  const { finish } = useTileTransition()
  const q = getLayoutQuery(appId)
  const { error, data } = useOldQuery(q)
  const [acknowledged, setAcknowledged] = React.useState(false)
  const [understood, setUnderstood] = React.useState(false)
  const location = useLocation()
  const mutateAcknowledged = useAcknowledgeDanger()
  React.useEffect(() => {
    if (error) finish()
  }, [error])
  if (error) return <Error error={error} />

  const page = data?.app?.pages.find(page => page.id === pageId)

  const inSettingsModal =
    location.pathname.includes('settings') ||
    location.pathname.includes('permissions') ||
    location.pathname.includes('sharing')
  const showModal =
    !inSettingsModal &&
    !understood &&
    data?.app &&
    !data?.app.acknowledgedDanger &&
    data?.app.viewer.canManage &&
    data?.app.hasDangerousPermissions

  return (
    <PageContextProvider page={page}>
      <TenantFeaturesContextProvider
        suites={data?.suites}
        tenantFeatures={data?.tenant?.features}
      >
        <AppLayoutInner
          app={data?.app}
          isKualiAdmin={data?.viewer?.isKualiAdmin}
        >
          <Popup
            showModal={showModal}
            title={i18n._('danger.please.read.colon')}
            subtitle={i18n._('danger.please.read.data')}
            Img={Stop}
            width='550'
          >
            <>
              <Checkbox
                checked={acknowledged}
                label={i18n._('do.not.show.again.for.app')}
                onChange={() => setAcknowledged(!acknowledged)}
                value={acknowledged}
              />
              <Button
                outline
                mr={2}
                mt={3}
                as={Link}
                to={`${location.pathname}/permissions`}
              >
                <Trans id='view.permissions' />
              </Button>
              <Button
                onClick={() => {
                  if (acknowledged) mutateAcknowledged(appId)
                  setUnderstood(true)
                }}
              >
                <Trans id='i.understand' />
              </Button>
            </>
          </Popup>
          <Outlet />
        </AppLayoutInner>
      </TenantFeaturesContextProvider>
    </PageContextProvider>
  )
}

const getLayoutQuery = appId => ({
  variables: { appId },
  query: gql`
    query LayoutQuery($appId: ID!) {
      tenant {
        id
        features {
          rulesGadget
          versions
        }
      }
      suites {
        id
        name
      }
      viewer {
        id
        isKualiAdmin
      }
      app(id: $appId, isConfiguration: true) {
        id
        spaceId
        name
        type
        hasDraft
        firstTableId
        hasDangerousPermissions
        acknowledgedDanger {
          dateDismissed
        }
        pages {
          id
          type
          label {
            value
            locked
          }
          icon {
            value
            locked
          }
          details
          orderLocked
          removeLocked
        }
        tileOptions {
          backgroundColor
          iconName
        }
        viewer {
          canConfigureProduct
          canViewDocuments
          canManage
        }
      }
    }
  `
})

function AppLayoutInner ({ children, app, isKualiAdmin }) {
  const { toHome } = useTileTransition()
  const location = useLocation()
  const { appId, pageId } = useParams()
  const [sidebarActive, setSidebarActive] = React.useState(false)
  const isProduct = ['product', 'productTemplate'].includes(app?.type)
  const isExternal =
    location.pathname.endsWith('/external') ||
    location.pathname.endsWith('/dashboard') ||
    location.pathname.endsWith('/setting')
  const canSeeGeneralData =
    app?.viewer.canConfigureProduct ||
    (app?.viewer.canManage && app?.firstTableId)
  const configMode = app?.viewer.canManage && app?.viewer.canConfigureProduct
  const spaceId = app?.spaceId ? app.spaceId : undefined
  return (
    <div className='app-layout h-screen dark:bg-light-gray-300'>
      <SkipToContent />
      {app?.tileOptions && (
        <ColorBar color={app?.tileOptions.backgroundColor} />
      )}
      <Header className='dark:bg-light-gray-300 print:!hidden'>
        <Flex>
          <LeftLink
            aria-label='home'
            to={spaceId ? `/space/${spaceId}` : '/space'}
            onClick={e => {
              if (!e.ctrlKey && !e.metaKey && app.tileOptions) {
                e.preventDefault()
                toHome({
                  ...app.tileOptions,
                  isProduct: app.type === 'product',
                  appId,
                  spaceId
                })
              }
            }}
          >
            <BackIcon className='fill-blue-500' my={4} mx={[3, null, 2]} />
          </LeftLink>
          {app?.viewer.canManage ? (
            <Popover2
              role='menu'
              trigger={
                <PopoverTrigger py={3}>
                  <AppName title={app.name}>{app.name}</AppName>
                  <Draft draft={app.hasDraft}>Draft</Draft>
                  <Icons.MenuVertical />
                </PopoverTrigger>
              }
            >
              {hide => (
                <Menu>
                  <Title className='bg-[#f7f7f7] dark:bg-light-gray-300'>
                    App Options
                  </Title>
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                    to={publishRoute(appId, pageId)}
                    onClick={hide}
                  >
                    <Icons.Share />
                    <span>
                      <Trans id='publish' />
                    </span>
                  </MenuLink>
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                    to={`${location.pathname}/permissions`}
                    onClick={hide}
                  >
                    <Icons.Key />
                    <span>
                      <Trans id='permissions' />
                    </span>
                  </MenuLink>
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                    to={`${location.pathname}/sharing`}
                    onClick={hide}
                  >
                    <Icons.Settings />
                    <span>
                      <Trans id='data.sharing' />
                    </span>
                  </MenuLink>
                  {isProduct && canSeeGeneralData && (
                    <MenuLink
                      className='hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                      to={`${location.pathname}/general-data`}
                      onClick={hide}
                    >
                      <Icons.Module />
                      <span>
                        <Trans id='general.app.data' />
                      </span>
                    </MenuLink>
                  )}
                  <MenuLink
                    className='hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                    as='a'
                    target='_blank'
                    href='https://kuali.zendesk.com'
                  >
                    <Icons.Support />
                    <span>
                      <Trans id='help' />
                    </span>
                  </MenuLink>
                  {(!isProduct || isKualiAdmin) && (
                    <MenuLink
                      className='hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                      to={`${location.pathname}/delete`}
                      onClick={hide}
                    >
                      <Icons.Delete className='fill-red-400' />
                      <span>
                        <Trans id='delete.app' />
                      </span>
                    </MenuLink>
                  )}
                </Menu>
              )}
            </Popover2>
          ) : (
            <AppName>{app?.name}</AppName>
          )}
        </Flex>
        {app?.viewer.canManage && !isExternal && (
          <TopTabs hideDocuments={!app.viewer.canViewDocuments} />
        )}
      </Header>
      {isProduct ? (
        <ContentContainer className='dark:bg-light-gray-300'>
          <ActivateSidebarButton
            onClick={() => setSidebarActive(!sidebarActive)}
            active={sidebarActive}
          >
            <Icons.MenuVertical />
          </ActivateSidebarButton>
          <DeactivateSidebarArea
            active={sidebarActive}
            onClick={() => setSidebarActive(false)}
          />
          <Sidebar
            sidebarActive={sidebarActive}
            pages={app?.pages}
            configMode={configMode}
          />
          <StartOfContent />
          <Main className='bg-white print:!overflow-visible'>{children}</Main>
        </ContentContainer>
      ) : (
        <>
          <StartOfContent />
          <Main className='bg-white print:!overflow-visible'>{children}</Main>
        </>
      )}
    </div>
  )
}

export function TopTabs ({ hideDocuments, maint }) {
  const { appId, pageId, routes } = useIds()
  return (
    <MyFlex className={cx({ 'absolute top-3 mr-6': maint })}>
      <Tabs>
        {!hideDocuments && !pageId && (
          <Tab to={`/dashboard/${appId}`}>
            <TabInner>
              <Trans id='dashboard' />
            </TabInner>
          </Tab>
        )}
        {!hideDocuments && (
          <Tab to={routes.documentList()}>
            <TabInner>
              <Trans id='documents' />
            </TabInner>
          </Tab>
        )}
        <Tab to={routes.form()}>
          <TabInner>
            <Trans id='form' />
          </TabInner>
        </Tab>
        {!maint && (
          <Tab to={routes.workflow()}>
            <TabInner>
              <Trans id='workflow' />
            </TabInner>
          </Tab>
        )}
        <Tab to={routes.publish()}>
          <TabInner>
            <Trans id='publish' />
          </TabInner>
        </Tab>
      </Tabs>
    </MyFlex>
  )
}

const ActivateSidebarButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  top: 32px;
  left: 16px;
  @media not all and (max-width: 500px) {
    display: none;
  }
`

const DeactivateSidebarArea = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: ${p => (p.active ? 'block' : 'none')};
  background: transparent;
  z-index: 11;
`

const MyFlex = styled(Flex)`
  align-self: flex-end;

  ${Tab} {
    background: var(--light-gray-200);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    line-height: 28px;
    transition: background 200ms;
    margin-right: 4px;
    &:last-child {
      margin-right: 20px;
    }
    &.active {
      background: var(--white);
    }
    &:hover {
      background: #ddd;
    }
    html.dark &:hover {
      // Outlier: dark:hover:bg-white
      background: #444;
    }
  }

  ${TabInner} {
    font-weight: 400 !important;
    color: #666;
    border: none !important;
  }
`

const Main = styled.main`
  position: relative;
  overflow: auto;
  border-radius: 8px;
  margin: 0 24px 40px;
  height: calc(100vh - 120px);
`

const ContentContainer = styled.div`
  display: flex;
  > :last-child {
    flex: 1;
  }
`

const Draft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;
  background: #f7bd1b;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 12px;
  overflow: hidden;
  width: ${p => (p.draft ? 54 : 0)}px;
  transition: width 300ms;
  height: 24px;
`

const ColorBar = styled.div`
  position: fixed;
  width: 100vw;
  height: 16px;
  bottom: 0;
  background: ${p => p.color};
  z-index: 13;
`

const LeftLink = styled(Link)`
  display: flex;
`

const BackIcon = styled(Icons.Back)`
  ${space}
`

const AppName = styled(H6)`
  margin-right: 8px;
  max-width: 324px;
  overflow: hidden;
  text-overflow: ellipsis;
`
AppName.defaultProps = { as: 'div' }

const PopoverTrigger = styled(Flex)`
  height: inherit;
  &:active {
    border-color: transparent;
  }
  &:hover {
    background: none;
  }
`
PopoverTrigger.defaultProps = { as: Button, transparent: true }

const Header = styled.header`
  padding: 0 24px 0 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`

const Menu = styled.div`
  padding-bottom: 8px;
`

const Title = styled(Body2)`
  color: var(--medium-gray-500);
  text-transform: uppercase;
  padding: 8px 16px;
`

const MenuLink = styled(Body1)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  white-space: nowrap;
  background: none;
  border: none;
  cursor: pointer;
  > svg {
    margin-right: 16px;
  }
`
MenuLink.defaultProps = { as: Link }
