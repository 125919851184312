/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useApolloClient } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import FocusTrap from 'focus-trap-react'
import { filter, find, flatMap, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

import { AppIcon, AppSquare } from '../components/app-icon'
import Spinner from '../components/spinner'
import { useIds } from '../components/use-ids'
import { SearchBar } from '../formbot/gadgets/form-typeahead/config.new.jsx'
import * as Icons from '../icons'
import { Body1, Body2 } from '../ui/typography'
import {
  collectInputs,
  getInputFields,
  getOutputFields,
  getSearchParameter
} from './integration-utils'
import { ReactComponent as ImgExternal } from './source-generic.svg.jsx'
import { ReactComponent as ImgInternal } from './source-kuali.svg.jsx'

/**
 * @param {'fetch'|'fetchOne'|'push'} type
 * @param {string} [gadgetType]
 * @param {(any) => object} [getDetails]
 * @returns {(apolloClient: import('apollo-client').ApolloClient) => Promise<any[]>}
 */
function fetchIntegrations (type, gadgetType, getDetails = () => ({})) {
  return async (apolloClient, { appId }, search) => {
    const { data } = await apolloClient.query({
      query,
      variables: { appId, type, query: search }
    })
    return map(data?.app?.sharedWithMe?.integrations, node => ({
      id: node?.id,
      title: node?.data?.__name,
      workflow: false,
      Icon: null,
      type: gadgetType,
      node,
      details: getDetails(node)
    }))
  }
}

async function fetchInheritanceStuff (apolloClient, { appId }, type) {
  const { data } = await apolloClient.query({
    query: formsQuery,
    variables: { appId }
  })
  return flatMap(data?.app?.sharedWithMe?.apps, (app, i) => {
    if (app.type === 'app') {
      return [
        {
          id: app.id,
          title: app.name,
          workflow: false,
          Icon: () => (
            <AppSquare
              backgroundColor={app.tileOptions?.backgroundColor}
              className='mr-2 w-8'
            >
              <AppIcon iconName={app.tileOptions?.iconName} />
            </AppSquare>
          ),
          type,
          details: {
            id: app.id,
            label: app.name,
            isProduct: false,
            tileOptions: app.tileOptions,
            outputFields: null,
            allowNewVersions: app.datasets[0]?.allowNewVersions
          }
        }
      ]
    } else {
      return app.datasets.map(page => ({
        id: `${app.id}::${page.id}`,
        title: `${app.name} - ${page.label}`,
        workflow: false,
        Icon: () => (
          <AppSquare
            backgroundColor={app.tileOptions?.backgroundColor}
            className='mr-2 w-8'
          >
            <AppIcon iconName={app.tileOptions?.iconName} isProduct />
          </AppSquare>
        ),
        type,
        details: {
          id: app.id,
          pageId: page.id,
          label: `${app.name} - ${page.label}`,
          isProduct: true,
          tileOptions: {
            ...app.tileOptions,
            datasetIcon: page.icon
          },
          outputFields: null
        }
      }))
    }
  })
}

/**
 * @typedef {object} SubOption
 * @property {string} id
 * @property {string} title
 * @property {boolean} workflow
 * @property {React.Component} Icon
 * @property {string} type
 * @property {object} details
 *
 * @typedef {object} Option
 * @property {string} id
 * @property {string} title
 * @property {string} icon
 * @property {boolean} workflow
 * @property {SubOption[]} [options]
 * @property {(apolloClient: import('apollo-client').ApolloClient) => Promise<SubOption[]>} [fetchOptions]
 */

/**
 * These are the possible data categories and sources. Each source should
 * have a gadget type and details as that's what gets passed up in the onSelect
 * handlers.
 * For getting dynamic options such as integrations, you can use a `fetchOptions`
 * function to fetch and format the options.
 * @type {Record<string, Option>}
 */
const OPTIONS = {
  KUALI_TYPEAHEAD: {
    id: 'kuali',
    title: <Trans id='kuali.data' />,
    Icon: ImgInternal,
    workfow: true,
    fetchOptions: async (apolloClient, params) => [
      {
        id: 'users',
        title: <Trans id='people' />,
        workflow: true,
        Icon: Icons.User,
        type: 'UserTypeahead',
        details: {}
      },
      {
        id: 'groups',
        title: <Trans id='groups' />,
        workflow: true,
        Icon: Icons.Users,
        type: 'GroupTypeahead',
        details: {}
      },
      ...(await fetchInheritanceStuff(apolloClient, params, 'FormTypeahead'))
    ]
  },
  KUALI_TERMS: {
    id: 'kuali',
    title: 'Kuali Data',
    Icon: ImgInternal,
    workfow: true,
    fetchOptions: async (apolloClient, params) => [
      ...(await fetchInheritanceStuff(apolloClient, params, 'FormTypeahead'))
    ]
  },
  KUALI_ASSOCIATIONS: {
    id: 'kuali',
    title: 'Kuali Data',
    Icon: ImgInternal,
    workfow: true,
    fetchOptions: async (apolloClient, params) => [
      ...(await fetchInheritanceStuff(apolloClient, params, 'FormTypeahead'))
    ]
  },
  KUALI_MULTISELECT: {
    id: 'kuali',
    title: <Trans id='kuali.data' />,
    Icon: ImgInternal,
    workfow: false,
    fetchOptions: async (apolloClient, params) => [
      {
        id: 'users',
        title: <Trans id='people' />,
        workflow: true,
        Icon: Icons.User,
        type: 'UserMultiselect',
        details: {}
      },
      {
        id: 'groups',
        title: <Trans id='groups' />,
        workflow: true,
        Icon: Icons.Users,
        type: 'GroupMultiselect',
        details: {}
      },
      ...(await fetchInheritanceStuff(apolloClient, params, 'FormMultiselect'))
    ]
  },
  EXTERNAL_TYPEAHEAD: {
    id: 'external',
    title: <Trans id='external.data' />,
    Icon: ImgExternal,
    workflow: false,
    fetchOptions: fetchIntegrations('fetch', 'IntegrationTypeahead', node => ({
      id: node?.id,
      label: node?.data?.__name,
      outputFields: getOutputFields(node?.data),
      inputFields: getInputFields(node?.data),
      searchParameter: getSearchParameter(node?.data)
    }))
  },
  EXTERNAL_MULTISELECT: {
    id: 'external',
    title: <Trans id='external.data' />,
    Icon: ImgExternal,
    workflow: false,
    fetchOptions: fetchIntegrations(
      'fetch',
      'IntegrationMultiselect',
      node => ({
        id: node?.id,
        label: node?.data?.__name,
        inputFields: getInputFields(node?.data),
        searchParameter: getSearchParameter(node?.data)
      })
    )
  },
  EXTERNAL_FILL: {
    id: 'external',
    title: <Trans id='external.data' />,
    Icon: ImgExternal,
    workflow: false,
    fetchOptions: fetchIntegrations('fetchOne', 'IntegrationFill', node => ({
      id: node?.id,
      label: node?.data?.__name,
      outputFields: getOutputFields(node?.data),
      inputFields: getInputFields(node?.data)
    }))
  },
  EXTERNAL_SEND: {
    id: 'external',
    title: <Trans id='external.data' />,
    Icon: ImgExternal,
    workflow: false,
    fetchOptions: fetchIntegrations('workflowIntegration', undefined, node => ({
      id: node?.id,
      label: node?.data?.__name,
      outputFields: getOutputFields(node?.data),
      inputFields: getInputFields(node?.data)
    }))
  }
}

/**
 * This takes the given options and resolves the "fetchOptions" functions
 * for each option. After that, it filters out the options that don't match
 * the indexType when the indexType is there. The indexType should only be there
 * for a gadget that is on a published version of the form, so we only do the
 * filtering for gadgets that are published.
 */
async function populateOptions ({
  params,
  apolloClient,
  indexType,
  options,
  search
}) {
  // Fetch options if it's on the options
  options = await Promise.all(
    map(options, async ({ fetchOptions, ...option }) => {
      if (fetchOptions) {
        return {
          options: await fetchOptions(apolloClient, params, search),
          ...option
        }
      }
      option.options = map(option.options, opt => ({ ...opt, details: {} }))
      return option
    })
  )
  // Filter out gadgets that aren't the given index type
  if (indexType) {
    options = options
      .map(option => ({
        ...option,
        options: filter(option.options, { type: indexType.gadgetType })
      }))
      .filter(option => option.options.length)
  }
  return options
}

/**
 * This is a hook to handle resolving the async options in a react way.
 * Basically just a hook around calling populateOptions
 */
export function useOptions (options, indexType, search) {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [populatedOptions, setPopulatedOptions] = React.useState([])
  const params = useIds()
  React.useEffect(() => {
    setLoading(true)
    setError(null)
    populateOptions({ params, apolloClient, indexType, options, search })
      .then(options => setPopulatedOptions(options))
      .catch(err => setError(err))
      .finally(() => setLoading(false))
  }, [apolloClient, options, indexType, params.appId, params.datasetId, search])
  return [populatedOptions, { loading, error }]
}

export const getIcon = (id, tileOptions, isProduct) => {
  if (tileOptions) {
    return (
      <AppSquare
        backgroundColor={tileOptions.backgroundColor}
        className='mr-2 w-8'
      >
        <AppIcon iconName={tileOptions.iconName} isProduct={isProduct} />
      </AppSquare>
    )
  }
  if (id === 'users') return <Icons.User mr={2} />
  if (id === 'groups') return <Icons.Users mr={2} />
  return <Img mr={2} as={ImgExternal} />
}

const TERMS_OPTIONS = [OPTIONS.KUALI_TERMS]

export const TermsTypeahead = ({
  id,
  label,
  tileOptions,
  isProduct,
  onSelect,
  indexed,
  indexType,
  hasLinked
}) => (
  <ChooserWrapper
    indexed={indexed}
    indexType={indexType}
    hasLinked={hasLinked}
    options={TERMS_OPTIONS}
    id={id}
    label={label}
    tileOptions={tileOptions}
    isProduct={isProduct}
    onSelect={onSelect}
    terms='Terms'
  />
)

export const ASSOCIATIONS_OPTIONS = [OPTIONS.KUALI_ASSOCIATIONS]

export const AssociationsTypeahead = ({
  id,
  label,
  tileOptions,
  isProduct,
  onSelect,
  indexed,
  indexType,
  hasLinked
}) => (
  <ChooserWrapper
    indexed={indexed}
    indexType={indexType}
    hasLinked={hasLinked}
    options={ASSOCIATIONS_OPTIONS}
    id={id}
    label={label}
    tileOptions={tileOptions}
    isProduct={isProduct}
    onSelect={onSelect}
    terms='Associations'
  />
)

const TYPEAHEAD_OPTIONS = [OPTIONS.KUALI_TYPEAHEAD, OPTIONS.EXTERNAL_TYPEAHEAD]

export const Typeahead = ({
  id,
  label,
  tileOptions,
  isProduct,
  onSelect,
  indexed,
  indexType,
  hasLinked
}) => (
  <ChooserWrapper
    indexed={indexed}
    indexType={indexType}
    hasLinked={hasLinked}
    options={TYPEAHEAD_OPTIONS}
    id={id}
    label={label}
    tileOptions={tileOptions}
    isProduct={isProduct}
    onSelect={onSelect}
  />
)

const MULTISELECT_OPTIONS = [
  OPTIONS.KUALI_MULTISELECT,
  OPTIONS.EXTERNAL_MULTISELECT
]

export const Multiselect = ({
  id,
  label,
  tileOptions,
  isProduct,
  onSelect,
  indexed,
  indexType
}) => (
  <ChooserWrapper
    indexed={indexed}
    indexType={indexType}
    options={MULTISELECT_OPTIONS}
    id={id}
    label={label}
    tileOptions={tileOptions}
    isProduct={isProduct}
    onSelect={onSelect}
  />
)

const FILL_OPTIONS = [OPTIONS.EXTERNAL_FILL]

export const Fill = ({
  id,
  label,
  onSelect,
  indexed,
  indexType,
  hasLinked
}) => (
  <ChooserWrapper
    indexed={indexed}
    indexType={indexType}
    hasLinked={hasLinked}
    options={FILL_OPTIONS}
    id={id}
    label={label}
    onSelect={onSelect}
  />
)

const SEND_OPTIONS = [OPTIONS.EXTERNAL_SEND]

export const Send = ({ id, label, indexed, indexType, onSelect }) => (
  <ChooserWrapper
    options={SEND_OPTIONS}
    id={id}
    label={label}
    indexed={indexed}
    indexType={indexType}
    onSelect={({ details }) => onSelect(details)}
  />
)

const ChooserWrapper = ({
  type,
  options,
  id,
  label,
  tileOptions,
  isProduct,
  onSelect,
  indexed,
  indexType,
  hasLinked,
  terms
}) => {
  // TODO:: If you try to change the datasource and you have linked fields then warn, then remove those fields
  const [showChooser, setShowChooser] = React.useState(false)
  const icon = getIcon(id, tileOptions, isProduct)
  const name =
    id === 'users'
      ? 'Kuali People'
      : id === 'groups'
        ? 'Kuali Groups'
        : label || 'External Integration'

  return (
    <>
      <div className='flex items-center justify-between pb-2'>
        {id ? (
          <>
            <div className='flex items-center justify-between pr-4'>
              {icon}
              <span>{name}</span>
            </div>
            <button
              className='kp-button-outline'
              disabled={indexed}
              onClick={() => {
                if (indexed) return
                if (!showChooser && hasLinked) {
                  const shouldShow = window.confirm(
                    i18n._('changing.data.will.remove.all.linked.gadgets')
                  )
                  if (!shouldShow) return
                }
                setShowChooser(a => !a)
              }}
            >
              <Trans id='change' />
            </button>
          </>
        ) : (
          <button
            className='kp-button-solid'
            onClick={() => setShowChooser(a => !a)}
          >
            <Trans id='choose' />
          </button>
        )}
      </div>
      {showChooser && (
        <Chooser
          hide={() => setShowChooser(false)}
          type={type}
          options={options}
          indexed={indexed}
          indexType={indexType}
          onSelect={(...args) => {
            setShowChooser(false)
            onSelect(...args)
          }}
          terms={terms}
        />
      )}
    </>
  )
}

const Chooser = ({ hide, onSelect, options, indexType, terms }) => {
  const [search, setSearch] = React.useState(null)
  const [filteredOptions, { loading }] = useOptions(options, indexType, search)
  const [categoryId, setCategoryId] = React.useState(() => {
    if (terms) return 'kuali'
    return null
  })
  const [sourceId, setSourceId] = React.useState(null)
  const category = find(filteredOptions, { id: categoryId })
  const sources = category?.options
  const source = find(sources, { id: sourceId })
  const inputs = collectInputs(source?.node?.data).map(input => ({
    dataPath: input.__dataPath,
    label: input.__label,
    required: input.required
  }))
  return (
    <FocusTrap focusTrapOptions={{ onPostDeactivate: hide }}>
      <div>
        <div className='fixed z-10'>
          <Wrapper className='bg-white dark:bg-light-gray-300'>
            <div className='flex items-center justify-between border-b border-b-light-gray-300 bg-light-gray-100 px-4 py-2 uppercase text-medium-gray-500 dark:bg-light-gray-300'>
              <Trans id='sources' />
              <button
                className='kp-button-transparent kp-button-icon kp-button-sm text-medium-gray-500'
                onClick={hide}
                aria-label='close'
              >
                <Icons.Close width='12px' height='12px' />
              </button>
            </div>
            <div className='flex flex-1'>
              <Column
                className={cx(
                  'border-r border-r-light-gray-300 dark:bg-white',
                  {
                    hidden: terms
                  }
                )}
              >
                {loading && (
                  <div className='flex w-full justify-center py-4'>
                    <Spinner size='16' />
                  </div>
                )}
                {filteredOptions.map(({ id, title, Icon }) => (
                  <Choice
                    className={cx({
                      'bg-light-gray-200 dark:bg-light-gray-300':
                        categoryId === id,
                      'bg-inherit': categoryId !== id
                    })}
                    as='button'
                    key={id}
                    onClick={() => {
                      if (categoryId === id) return
                      setCategoryId(id)
                      setSourceId(null)
                    }}
                  >
                    <Img mr={3} as={Icon} />
                    <Body1>{title}</Body1>
                  </Choice>
                ))}
              </Column>
              <Column className='border-r border-r-light-gray-300 dark:bg-white'>
                {categoryId === 'external' && (
                  <SearchBar
                    value={search}
                    onChange={e => setSearch(e)}
                    className='mx-4 my-2'
                  />
                )}
                {map(sources, ({ id, title, Icon }) => (
                  <Choice
                    className={cx('transition-all active:bg-light-gray-200', {
                      'bg-light-gray-200 dark:bg-light-gray-300':
                        sourceId === id,
                      'bg-inherit': sourceId !== id
                    })}
                    as='button'
                    key={id}
                    onClick={() => setSourceId(id)}
                  >
                    {Icon ? <Icon mr={3} /> : <Img mr={3} as={ImgExternal} />}
                    <Body1>{title}</Body1>
                    {terms === 'Associations' && sourceId === id && (
                      <button
                        className='kp-button-solid ml-auto animate-fade-in'
                        onClick={() =>
                          onSelect({
                            type: terms ?? source.type,
                            details: source.details
                          })
                        }
                      >
                        <Trans id='continue' />
                      </button>
                    )}
                  </Choice>
                ))}
              </Column>
              <Column
                className={cx('dark:bg-white', {
                  hidden: terms === 'Associations'
                })}
              >
                {source && (
                  <div className='flex h-full flex-col p-4 pt-2'>
                    {inputs?.length ? (
                      <div className='flex'>
                        <Icons.AlertError mr={2} className='fill-red-400' />
                        <div>
                          <ErrorText role='alert'>
                            <Trans id='data.source.requires.extra.pieces' />
                          </ErrorText>
                          <div className='max-h-40 overflow-auto'>
                            {map(inputs, input => (
                              <div key={input.dataPath}>
                                <span
                                  className={cx(
                                    input.required &&
                                      'after:ml-1 after:text-red-400 after:content-["*"]'
                                  )}
                                >
                                  {input.label}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='flex'>
                        <Icons.AlertInfo mr={2} />
                        <Body2>
                          <Trans id='data.source.no.required.fields' />
                        </Body2>
                      </div>
                    )}
                    {category.workflow && source.workflow && (
                      <>
                        <div className='h-4' />
                        <div className='flex'>
                          <Icons.AlertInfo mr={2} />
                          <Body2>
                            <Trans id='fields.data.used.approvals' />
                          </Body2>
                        </div>
                      </>
                    )}
                    <div className='flex-grow' />
                    <button
                      className='kp-button-solid'
                      onClick={() =>
                        onSelect({
                          type: terms ?? source.type,
                          details: source.details
                        })
                      }
                    >
                      <Trans id='continue' />
                    </button>
                  </div>
                )}
              </Column>
            </div>
          </Wrapper>
        </div>
      </div>
    </FocusTrap>
  )
}

const query = gql`
  query FetchIntegrations($appId: ID!, $type: IntegrationType, $query: String) {
    app(id: $appId) {
      id
      sharedWithMe {
        integrations(
          args: { sort: "data.__name", type: $type, query: $query }
        ) {
          id
          data
        }
      }
    }
  }
`

export const formsQuery = gql`
  query FetchFormsData($appId: ID!) {
    app(id: $appId) {
      id
      sharedWithMe {
        apps {
          id
          name
          type
          tileOptions {
            backgroundColor
            iconName
          }
          datasets {
            id
            type
            label
            icon
            allowNewVersions
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  box-shadow:
    0px 4px 5px rgba(26, 26, 26, 0.2),
    0px 3px 14px rgba(26, 26, 26, 0.12),
    0px 8px 10px rgba(26, 26, 26, 0.14);
  display: flex;
  flex-direction: column;
  width: 708px;
  position: fixed;
  border-radius: 4px;
  left: calc(50% - 354px);
  top: 80px;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
    top: 0;
  }
`

export const Column = styled.div`
  flex: 1;
  overflow: auto;
  height: 335px;

  @media (max-width: 768px) {
    height: 90vh;
  }
`

export const Img = styled.img`
  flex-shrink: 0;
  width: ${p => (p.small ? 16 : 24)}px;
  height: ${p => (p.small ? 16 : 24)}px;
  ${space}
`

const Choice = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #7da4d9;
  }
`

const ErrorText = styled(Body2)`
  color: var(--red-400);
  margin-bottom: 16px;
`
