/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import _ from 'lodash'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import shortid from 'shortid'

import AnimatedOutlet from '../../components/animated-outlet'
import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import Spinner from '../../components/spinner'
import { GraphQLError } from '../../components/system-error'
import { useIds } from '../../components/use-ids'
import { useTransitionRef } from '../../components/use-transition-ref'
import { gadgets as allGadgets, formbot } from '../../formbot'
import * as formbotUtils from '../../formbot/engine/formbot/utils'
import * as Icons from '../../icons'
import Forbidden from '../../pages/forbidden'
import { useAlerts } from '../../ui/alerts'
import { GadgetDropdown, Label } from './components'
import insightDefinitions from './insights'
import { docListRedirect } from './utils'

let filterGadgets = _.flatMap(allGadgets, (g, key) => {
  if (!g.filters) return []
  return [key]
})
filterGadgets = _.keyBy(filterGadgets)

const prep = insight => {
  insight = _.cloneDeep(insight)
  delete insight.value
  delete insight.__typename
  insight.filters.forEach(filter => {
    delete filter.__typename
  })
  return insight
}

export default function DashboardOuter () {
  return (
    <>
      <AnimatedOutlet />
      <Dashboard />
    </>
  )
}

function Dashboard () {
  const [editing, setEditing] = React.useState(null)
  const ref = useTransitionRef(editing)
  const { appId, datasetId } = useIds()
  const { data, loading, error } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: { appId, datasetId: datasetId || 'BUILD' }
  })
  if (loading) return <Loading />
  if (error && error.message === 'User does not have the correct permissions') {
    return <Forbidden fillHeight message={`${i18n._('forbidden.dashboard')}`} />
  }
  if (error) return <GraphQLError fillHeight />
  const { insights, id } = data.app.dashboard
  const { canManage, canConfigureProduct } = data.app.viewer
  const configMode = canManage && canConfigureProduct
  const datasets = _.filter(data.app.pages, { type: 'dataset' })
  const [counts, others] = _.partition(insights, i => i.type === 'count')
  return (
    <>
      <TransitionGroup component={null}>
        <CSSTransition key={editing} timeout={450} nodeRef={ref}>
          {configMode && editing ? (
            <EditInsight
              ref={ref}
              dashboardId={id}
              insight={editing}
              datasets={datasets}
              onClose={() => setEditing(null)}
            />
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>

      <div className='flex flex-col gap-4 p-4'>
        {configMode && (
          <button
            className='kp-button-solid self-end'
            onClick={() => setEditing('new')}
          >
            <Icons.Add className='mr-2' />
            <Trans id='pagesbuilder.dashboard.insight' />
          </button>
        )}
        <div className='flex flex-wrap gap-4'>
          {counts.map(insight => (
            <Insight
              key={insight.id}
              insight={insight}
              onClick={configMode ? () => setEditing(prep(insight)) : undefined}
            />
          ))}
        </div>
        <div className='flex flex-wrap gap-4'>
          {others.map(insight => (
            <Insight
              key={insight.id}
              insight={insight}
              onClick={configMode ? () => setEditing(prep(insight)) : undefined}
            />
          ))}
        </div>
      </div>
    </>
  )
}

function Insight ({ onClick, insight }) {
  const Chart = insightDefinitions[insight.type]?.Chart || InvalidChart
  return (
    <div
      className={cx(
        'group relative flex max-w-full flex-1 flex-col gap-2 rounded-lg bg-light-gray-100 px-6 py-4 dark:bg-light-gray-300',
        { 'min-w-full': insight.fullWidth }
      )}
    >
      {onClick && (
        <button
          aria-label={i18n._('pagesbuilder.dashboard.edit.insight')}
          className='kp-button-transparent kp-button-sm kp-button-icon absolute right-4 top-4 opacity-0 focus:opacity-100 group-hover:opacity-100'
          onClick={onClick}
        >
          <Icons.Settings />
        </button>
      )}
      <label
        className='cursor-pointer text-base font-medium text-medium-gray-500 hover:bg-light-gray-300 hover:text-blue-400'
        onClick={() => docListRedirect(insight)}
      >
        {insight.label}
      </label>
      {insight.value == null ? (
        <div className='flex flex-1 items-center justify-center'>
          <Spinner size={64} />
        </div>
      ) : (
        <Chart value={insight.value} insight={insight} />
      )}
    </div>
  )
}

function InvalidChart ({ insight }) {
  return (
    <div className='text-red-250'>Unknown insight type: {insight.type}</div>
  )
}

const newInsight = () => ({
  id: shortid.generate(),
  type: '',
  datasetId: '',
  filters: [],
  details: {},
  label: '',
  fullWidth: false
})

const EditInsight = React.forwardRef(function EditInsight (
  { dashboardId, insight, datasets, onClose },
  ref
) {
  const isNew = insight === 'new'
  const client = useApolloClient()
  const alerts = useAlerts()
  const [saving, setSaving] = React.useState(false)
  const [value, setValue] = React.useState(isNew ? newInsight() : insight)
  const [addInsight] = useMutation(mutationAdd)
  const [updateInsight] = useMutation(mutationUpdate)
  const [removeInsight] = useMutation(mutationRemove)
  const [moveInsight] = useMutation(mutationMove)
  const { appId, datasetId } = useIds()
  const { data, loading } = useQuery(query2, {
    variables: { appId, datasetId: value.datasetId },
    skip: !value.datasetId
  })
  function performAction (mutation, variables) {
    setSaving(true)
    return mutation({ variables })
      .then(result => {
        setSaving(false)
        return result
      })
      .catch(error => {
        console.error(error)
        alerts.type3(i18n._('pagesbuilder.dashboard.failed.save'), 'error')
        setSaving(false)
        throw error
      })
  }

  const gadgets = formbotUtils.gatherAllSubGadgets(
    [
      ..._.map(data?.app.dataset.formContainer.gadgetIndexTypes, g => ({
        formKey: `data.${g.formKey}`,
        id: g.id,
        label: g.label,
        type: g.gadgetType,
        details: g.details
      })),
      ...(data?.app.dataset.formContainer.metaFields || [])
    ],
    formbot
  )
  if (gadgets.length) {
    gadgets.push({
      formKey: 'meta.title',
      id: 'meta.title',
      label: 'Title',
      type: 'Text'
    })
  }

  const Config = insightDefinitions[value.type]?.Config
  return (
    <ModalPage
      ref={ref}
      title={
        isNew
          ? `${i18n._('pagesbuilder.dashboard.add.insight')}`
          : `${i18n._('pagesbuilder.dashboard.edit.insight')}`
      }
      side
      onClose={onClose}
    >
      <form
        className='flex flex-col gap-4 p-4'
        onSubmit={e => {
          e.preventDefault()
          const variables = { appId, datasetId, insight: value }
          if (value.id) {
            client.cache.modify({
              id: `Insight:${value.id}`,
              fields: { value: null }
            })
          }
          const mutation = isNew ? addInsight : updateInsight
          performAction(mutation, variables).then(onClose)
        }}
      >
        <h2 className='text-lg font-medium text-dark-gray-300'>
          <Trans id='pagesbuilder.dashboard.generalconfig' />
        </h2>
        <div>
          <Label>
            <Trans id='pagesbuilder.dashboard.type' />
          </Label>
          <select
            required
            className='kp-select'
            value={value.type}
            onChange={e =>
              setValue({
                ...value,
                type: e.target.value,
                details: insightDefinitions[e.target.value].details()
              })
            }
          >
            <option />
            {_.map(insightDefinitions, (val, key) => (
              <option key={key} value={key}>
                <Trans id={val.getName()} />
              </option>
            ))}
          </select>
        </div>

        <div>
          <Label>
            <Trans id='pagesbuilder.dashboard.label' />
          </Label>
          <input
            required
            type='text'
            className='kp-input w-full'
            value={value.label}
            onChange={e => setValue({ ...value, label: e.target.value })}
          />
        </div>

        <Label className='flex items-center gap-2'>
          <input
            type='checkbox'
            className='kp-checkbox'
            checked={value.fullWidth}
            onChange={e => setValue({ ...value, fullWidth: e.target.checked })}
          />
          <Trans id='pagesbuilder.dashboard.full.width' />
        </Label>

        <div>
          <Label>
            <Trans id='pagesbuilder.dashboard.dataset' />
          </Label>
          <select
            required
            className='kp-select'
            value={value.datasetId}
            onChange={e =>
              setValue({
                ...value,
                datasetId: e.target.value,
                details: insightDefinitions[value.type]?.details?.() || {},
                filters: []
              })
            }
          >
            <option />
            {datasets.map(d => (
              <option key={d.id} value={d.id}>
                {d.label.value}
              </option>
            ))}
          </select>
        </div>

        <Filters
          value={value.filters}
          onChange={filters => setValue({ ...value, filters })}
          gadgets={gadgets}
        />

        {!!Config && (
          <>
            <h2 className='text-lg font-medium text-dark-gray-300'>
              <Trans id='pagesbuilder.dashboard.specificconfig' />
            </h2>
            <Config
              details={value.details}
              setDetails={fn =>
                setValue(v => {
                  const details = _.isFunction(fn) ? fn(v.details) : fn
                  return { ...v, details }
                })
              }
              loading={loading}
              gadgets={gadgets}
            />
          </>
        )}

        <div className='flex justify-between'>
          {isNew ? (
            <div />
          ) : (
            <button
              type='button'
              className='kp-button-danger'
              onClick={() => {
                const variables = { appId, datasetId, insightId: value.id }
                performAction(removeInsight, variables).then(onClose)
              }}
              disabled={saving}
            >
              <Trans id='delete' />
            </button>
          )}
          {!isNew && (
            <button
              type='button'
              className='kp-button-outline'
              onClick={() => {
                const variables = { appId, datasetId, insightId: value.id }
                performAction(moveInsight, { ...variables, direction: 'left' })
              }}
            >
              <Trans id='pagesbuilder.dashboard.move.left' />
            </button>
          )}
          {!isNew && (
            <button
              type='button'
              className='kp-button-outline'
              onClick={() => {
                const variables = { appId, datasetId, insightId: value.id }
                performAction(moveInsight, { ...variables, direction: 'right' })
              }}
            >
              <Trans id='pagesbuilder.dashboard.move.right' />
            </button>
          )}
          <button className='kp-button-solid' disabled={saving}>
            <Trans id='save' />
          </button>
        </div>
      </form>
    </ModalPage>
  )
})

const newFilter = () => ({
  id: shortid.generate(),
  formKey: '',
  value: null
})

function Filters ({ value, onChange, gadgets }) {
  return (
    <div>
      <Label>
        <Trans id='pagesbuilder.dashboard.filters' />
      </Label>
      <div className='flex flex-col gap-2 py-2'>
        {value.map((filter, i) => (
          <Filter
            key={filter.id}
            value={filter}
            onChange={filter => {
              const next = [...value]
              next[i] = filter
              onChange(next)
            }}
            onDelete={() => {
              const next = [...value]
              next.splice(i, 1)
              onChange(next)
            }}
            gadgets={gadgets}
          />
        ))}
      </div>
      <button
        type='button'
        className='kp-button-solid'
        disabled={!gadgets.length}
        onClick={() => onChange([...value, newFilter()])}
      >
        <Trans id='pagesbuilder.dashboard.add.filter' />
      </button>
    </div>
  )
}

function Filter ({ value, onChange, onDelete, gadgets }) {
  const gadget = _.find(gadgets, { formKey: value.formKey })
  const FilterUI = allGadgets[gadget?.type]?.filters.UI
  return (
    <div className='flex flex-col gap-2 rounded bg-light-gray-200 p-2 dark:bg-light-gray-400'>
      <div className='flex gap-2'>
        <div className='flex-1'>
          <Label>
            <Trans id='pagesbuilder.dashboard.gadget' />
          </Label>
          <GadgetDropdown
            gadgets={_.filter(gadgets, g => filterGadgets[g.type])}
            value={value.formKey}
            onChange={formKey => onChange({ ...value, formKey, value: null })}
          />
        </div>
        <button
          type='button'
          className='kp-button-transparent kp-button-icon'
          onClick={onDelete}
        >
          <Icons.Delete />
        </button>
      </div>
      {FilterUI && (
        <FilterUI
          gadget={gadget}
          value={value.value}
          onChange={result => {
            if (!result) return onChange({ ...value, value: null })
            const newValue = _.pick(result, ['custom', 'min', 'max', 'values'])
            onChange({ ...value, value: newValue })
          }}
        />
      )}
    </div>
  )
}

const query = gql`
  query FetchDashboard($appId: ID!, $datasetId: ID!) {
    app(id: $appId) {
      id
      viewer {
        canManage
        canConfigureProduct
      }
      dashboard(id: $datasetId) {
        id
        insights {
          id
          type
          details
          datasetId
          filters {
            id
            formKey
            value
          }
          label
          fullWidth
          value
        }
      }
      pages {
        id
        label {
          value
        }
        type
      }
    }
  }
`

const query2 = gql`
  query FetchDatasetSchema($appId: ID!, $datasetId: ID!) {
    app(id: $appId) {
      id
      dataset(id: $datasetId) {
        id
        formContainer {
          id
          gadgetIndexTypes
          metaFields {
            id
            label
            type
            formKey
          }
        }
      }
    }
  }
`

const mutationAdd = gql`
  mutation AddInsight($appId: ID!, $datasetId: ID!, $insight: InsightInput!) {
    addInsight(appId: $appId, pageId: $datasetId, insight: $insight) {
      dashboard {
        id
        insights {
          id
        }
      }
      insight {
        id
        type
        details
        datasetId
        filters {
          id
          formKey
          value
        }
        label
        fullWidth
        value
      }
    }
  }
`

const mutationUpdate = gql`
  mutation UpdateInsight(
    $appId: ID!
    $datasetId: ID!
    $insight: InsightInput!
  ) {
    updateInsight(appId: $appId, pageId: $datasetId, insight: $insight) {
      dashboard {
        id
        insights {
          id
          type
          details
          datasetId
          filters {
            id
            formKey
            value
          }
          label
          fullWidth
        }
      }
      insight {
        id
        value
      }
    }
  }
`

const mutationRemove = gql`
  mutation RemoveInsight($appId: ID!, $datasetId: ID!, $insightId: ID!) {
    removeInsight(appId: $appId, pageId: $datasetId, insightId: $insightId) {
      id
      insights {
        id
      }
    }
  }
`

const mutationMove = gql`
  mutation MoveInsight(
    $appId: ID!
    $datasetId: ID!
    $insightId: ID!
    $direction: String!
  ) {
    moveInsight(
      appId: $appId
      pageId: $datasetId
      insightId: $insightId
      direction: $direction
    ) {
      id
      insights {
        id
      }
    }
  }
`
