/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { Link } from 'react-router'
import styled from 'styled-components'

import { render } from './columns'

export default function ({ results }) {
  return (
    <div>
      {results.map((result, index) => (
        <Link to={result.id} key={index}>
          <Card className='hover:bg-[#f5f5f5] dark:hover:bg-light-gray-300'>
            <Line1 className='text-dark-gray-300'>
              <div>{render(result, 'documentType')}</div>
              <div>{render(result, 'createdAt')}</div>
            </Line1>
            <Line2 className='text-dark-gray-100'>
              {render(result, 'step')}
            </Line2>
            <Line3 className='text-dark-gray-100'>
              <div>From: {render(result, 'submitter')}</div>
              <div>#{render(result, 'serialNumber')}</div>
            </Line3>
            <Line4>{render(result, 'action')}</Line4>
          </Card>
        </Link>
      ))}
    </div>
  )
}

const Card = styled.div`
  border: 1.5px solid #cdcdcd;
  border-radius: 2px;
  margin-bottom: 0.6em;
`

const Line1 = styled.div`
  padding: 5px 8px 0 8px;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
`

const Line2 = styled.div`
  padding: 0 8px 3px 8px;
  font-size: 1.1rem;
`

const Line3 = styled.div`
  padding: 0 8px 3px 8px;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Line4 = styled.div`
  padding: 3px 8px;
  background-color: #3e9bc8;
  text-transform: uppercase;
  font-size: 0.8rem;
  box-shadow: 0 0.1em 0.2em hsla(0, 0%, 0%, 0.15);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin: 0.5px;
`
